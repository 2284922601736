<template>
    <div class="mhome">
        <div class="homelogo">
            <img :src="'https://www.vifa.cn' + aboutVifa.mobileDynamicBanner" alt="">
        </div>
        <div class="mnew">
            <ul>
                <li v-for="(item,index) in newListData" :key="index" @click="todetails(item.id)">
                    <div class="mnewleft">
                        <div>{{item.title}}</div>
                        <p>{{item.topic}}</p>
                        <span>{{item.publishDate}}</span>
                    </div>
                    <div class="mnewright">
                        <img :src="'https://www.vifa.cn' + item.image" alt="">
                    </div>
                </li>
            </ul>
        </div>
        <!-- <div class="mnew">
            <div class="mnewtitle">
                <div>企业动态</div>
                <p>全部动态</p>
            </div>
            <ul>
                <li v-for="(item,index) in enterpriseDynamic" :key="index">
                    <div class="mnewleft">
                        <div>{{item.title}}</div>
                        <p></p>
                        <span>{{item.publishDate}}</span>
                    </div>
                    <div class="mnewright">
                        <img :src="'https://www.vifa.cn' + item.image" alt="">
                    </div>
                </li>
            </ul>
        </div> -->
        <div class="subnav" >
            <router-link to="/mAbout">品牌简介</router-link>
            <router-link to="/mBrandConcept">品牌理念</router-link>
            <router-link to="/mEnterpriseDynamics" class="active">企业动态</router-link>
        </div>
    </div>
</template>

<script>
import { getAboutVifa,getAboutVifaall } from "@/api/api";
export default {
    data(){
        return{
            newListData:[],
            aboutVifa:''
        }
    },
    components: {
        // Mzixun
    },
    mounted(){
        let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mnewsList?listbanner=' + this.$route.query.listbanner);
        } else {
            this.$router.replace(paths+'/allList?listbanner=' + this.$route.query.listbanner);
        }
        this.getPageData(this.$route.query.listbanner)
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getPageData(listbanner){
            getAboutVifaall()
            .then(res => {
                console.log(res)
                this.aboutVifa = res.aboutVifa
                if(listbanner == 0){
                    this.newListData = res.enterpriseNews
                }else{
                    this.newListData = res.enterpriseDynamic
                }
            })
            .catch(res => {
                console.log(res)
            });
        },
        todetails(id){
            this.$router.push({path:'/mnewDetails',query:{id:id,isnew:this.$route.query.listbanner}})
        }
        
    },
}
</script>

<style scoped>
.homelogo{
    width: 750px;
    position: relative;
    
}
.homelogo img,.video{
    width: 750px;
}
.homelogo div{
    width: 750px;
    text-align: center;
    font-size: 45px;
    color: #fff;
    position: absolute;
    left: 0;
    line-height: 90px;
    top:50%;
    margin-top: -45px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #000000cc;
    text-align: center;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav a{
    flex: 1;
    display: block;
    color: #fff;
}
.subnav .active{
    color: #f08518;
}
.mnew{
    width: 670px;
    margin: 40px auto;
    font-size: 26px;
    line-height: 1.8;
    text-align: justify;
}
.mnew .mnewtitle{
    width: 100%;
    overflow: hidden;
}
.mnew .mnewtitle div{
    font-size: 45px;
    font-weight: bold;
    float: left;
}
.mnew .mnewtitle p{
    color: #f08518;
    float: right;
    padding-top: 22.5px;
}
.mnew ul{}
.mnew ul li{
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #b1adac;
}
.mnew ul li .mnewleft{
    width: 400px;
    flex: 1 400px;
}
.mnew ul li .mnewleft div{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
.mnew ul li .mnewleft p{
    font-size: 18px;
}
.mnew ul li .mnewleft span{
    font-size: 18px;
    color: #b1adac;
}
.mnew ul li .mnewright{
    width: 250px;
    flex: 1 250px;
    text-align: right;
}
.mnew ul li .mnewright img{
    width: 250px;
    height: 175px;
}
</style> 