<template>
    <div class="brandConcept quality andService">
        <div class="aboutBaner">
                <img :src="'https://www.vifa.cn' + qualityService.banner" alt="">
                <p>{{qualityService.title}}</p>
            </div>
        <div class="founder spaceCustomization">
            <div class="yishu"></div>
            <div class="beijing">五星服务流程</div>
            <p class="jijian">Five-Star Services</p>
        </div>
        <div class="xingxing">
            <!-- <ul>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
            </ul> -->
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu1.png" alt=""></li>
                <li><img src="@/common/image/fuwu2.png" alt=""></li>
                <li><img src="@/common/image/fuwu3.png" alt=""></li>
                <li><img src="@/common/image/fuwu4.png" alt=""></li>
            </ul>
        </div>
        <div class="founder spaceCustomization qzh" v-html="qualityService.serviceText">                    
        </div>

        <div class="cangchu">
            <img src="@/common/image/fuwu5.png" alt="">
        </div>
        <div class="wufuliucheng">
            <div class="liuchengcont">
                <ul>
                    <li>毛坯房<br/>上门初尺</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>店面免费设计<br/>及效果图</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>出水电图<br/>并留档备查</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>上门布水电图<br/>并与施工队交接</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>现场贴瓷砖前<br/>水电位检查</li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li>各生产环节完<br/>成后信息主动<br/>推送客户</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>客户扫二维码<br/>可随时<br/>查询进度</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>订单自动上传<br/>拆单开始生产</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>调整方案并<br/>签订合约</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>隐蔽工程完成后<br/>上门现场复尺</li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li>货物抵达后<br/>信息提醒</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>安装师傅<br/>实名认证</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>与安装师傅<br/>预约安排时间</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>安装现场<br/>清洁及保护</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>柜体拼装<br/>并拍照记录</li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li>现场清洁<br/>及产品保护</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>现场自检并<br/>拍照记录上传</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>门板及五金<br/>安装调试</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>台面上门安装<br/>及验收</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>厨柜台面<br/>上门测量</li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li>预约客户<br/>检验验收</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>在线验收并对<br/>安装师傅<br/>进行评价</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>搬家前产品<br/>深度清洁及调试</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>客户在线<br/>保修售后无忧</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>威法周致<br/>“焕新”服务</li>
                </ul>
            </div>
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu6.png" alt=""></li>
                <li><img src="@/common/image/fuwu7.png" alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
import {getQualityService} from '@/api/api'

export default {
    data(){
        return{
            qualityService:''
        }
    },
    components: {
    },
    created () {
    },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mAndService');
        } else {
            this.$router.replace(paths+'/andService');
        }
        window.exec_main(document.title)
        this.getData()
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getData(){
            getQualityService()
            .then(res => {
                console.log(res)
                this.qualityService = res.qualityService
            })
            .catch(res => {
                console.log(res)
            });
        },
    },
}
</script>

<style>
.tuji{
    padding-top:75px ;
}
.tujiswiper{
    width: 100%;
    overflow: hidden;
}
.tujiswiper ul{
    overflow: auto;
}
.tujiswiper ul li{
    float: left;
    width: 900px;
    height: 540px;
    padding-right:25px
}
.tujiswiper ul li img{
    width: 100%;
    height: 540px;
    cursor: pointer;
}
.quality .spaceCustomization p{
    text-align: center;
    padding-bottom: 0;
    line-height: 2;
}
.quality .founder .yishu{
    margin-bottom: 20px;
}
.cangchu{
    padding-top: 50px;
}
.andService .qzh{
    padding-top: 0;
    padding-bottom: 0;
    white-space: pre-wrap;
    line-height: 1.5;
}
.iamgetitle{
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: -50px;
}
.andService .founder .beijing{
    padding-bottom: 10px;
}
.xingxing{
    padding:  25px 0;
    padding-top: 10px;
}
.xingxing ul{
    width: 250px;
    margin: 0 auto;
    display: flex;
}
.xingxing ul li{
    flex: 1 40px;
}
.xingxing ul li img{
    width: 35px;
    height: 35px;
}
.fuwu{
    padding-bottom: 80px;
}
.fuwu ul{
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.fuwu ul li{
    flex: 1;
}
.fuwu ul li img{
    width: 99%;
}
.wufuliucheng{
    width: 100%;
    padding: 50px 0;
    background: #d2cdca;
    color: #5e5450;
    margin: 80px 0;
}
.liuchengcont{
    width: 1200px;
    margin: 0 auto;
}
.liuchengcont ul{
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5% 0;
}
.liuchengcont ul li{
    flex: 1 132px;
    text-align: center;
    line-height: 2;
}
.liuchengcont ul li p{}
.liuchengcont ul li img{
    width: 100%;
}
.liuchengcont ul li.shupai img{
    width: auto;
}
</style>
