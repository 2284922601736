<template>
  <div class="case alllist">
    <div class="storeShowImg">
      <img src="@/common/image/allListbanner.png" alt="" />
    </div>
    <div class="enterpriseDynamics newlist">
      <div class="newlix">
        <div class="enterpriseDynamics_box">
          <div class="enterpriseDynamics_news tran">
            <ul>
              <li v-for="(item, index) in newListData" :key="index">
                <div class="newImg">
                  <img :src="'https://www.vifa.cn' + item.image" alt="" />
                </div>
                <p>{{ item.topic }}</p>
                <div class="newName">{{ item.title }}</div>
                <div class="newMore">
                  <span>{{ item.publishDate }}</span>
                  <button>
                    <router-link
                      :to="{
                        path: '/newDetails',
                        query: {
                          id: item.id,
                          isnew: this.$route.query.listbanner,
                        },
                      }"
                      >了解更多</router-link
                    >
                  </button>
                </div>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutVifaall } from "@/api/api";

export default {
  data() {
    return {
      newListData: [],
    };
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(
        paths + "/mnewsList?listbanner=" + this.$route.query.listbanner
      );
    } else {
      this.$router.replace(
        paths + "/allList?listbanner=" + this.$route.query.listbanner
      );
    }
    this.getnewsData(this.$route.query.listbanner);
    console.log(this.$route.query.listbanner);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getnewsData(listbanner) {
      getAboutVifaall()
        .then((res) => {
          console.log(res);
          if (listbanner == 0) {
            this.newListData = res.enterpriseNews;
          } else {
            this.newListData = res.enterpriseDynamic;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style>
@import "../../../common/styles/about.css";
.case .storeShowImg,
.case .storeShowImg img {
  height: 720px;
}
.newlist .newlix .enterpriseDynamics_news ul,
.newlist .newlix .enterpriseDynamics_box {
  width: 1200px;
}
.newlist .enterpriseDynamics_news {
  width: 1200px;
}
.case .newlist .enterpriseDynamics_news ul li {
  flex: 1 32%;
  width: 32%;
  margin-bottom: 2%;
}
.alllist .enterpriseDynamics_news ul li > p {
  height: 60px;
}
.case .newlist .enterpriseDynamics_news ul li img {
  width: 100%;
}
</style>
