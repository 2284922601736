<template>
    <div class="brandConcept quality andService">
        <div class="aboutBaner">
                <img :src="'https://www.vifa.cn' + qualityService.banner" alt="">
                <p>{{qualityService.titleEng}}</p>
            </div>
        <div class="founder spaceCustomization">
            <div class="yishu"></div>
            <div class="beijing pwjstrone">Five-star service process</div>
            <p class="jijian">Five-Star Services</p>
        </div>
        <div class="xingxing">
            <!-- <ul>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
            </ul> -->
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu1.png" alt=""></li>
                <li><img src="@/common/image/fuwu2.png" alt=""></li>
                <li><img src="@/common/image/fuwu3.png" alt=""></li>
                <li><img src="@/common/image/fuwu4.png" alt=""></li>
            </ul>
        </div>
        <div class="founder spaceCustomization qzh" v-html="qualityService.serviceTextEng">                    
        </div>

        <div class="cangchu">
            <img src="@/common/image/fuwu5.png" alt="">
        </div>
        <div class="wufuliucheng">
            <div class="liuchengcont">
                <ul>
                    <li>Roughcast house<br/>first gauging on site</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Store free design<br/>and renderings</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Issue water<br/>and electricity diagrams and keep a file for reference </li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Arrange water<br/>and electricity diagram and hand over with construction team</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Check water  <br/>and electricity level before fixing tile on site </li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li>Information is actively pushed<br/>to customers after completion<br/>of each production link</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Customers can scan the <br/>QR code to check<br/> the progress at any time </li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>The order is automatically uploaded<br/>and opened to start production</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Adjust the plan and<br/>sign the contract</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Re-gauging on site after<br/>the completion of concealed work </li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li>Information reminder<br/>upon arrival of goods</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Installer real<br/>name certification</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Make an appointment<br/>with the installer</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Installation site<br/>cleaning and protection</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Cabinet assembly <br/>and photo recording </li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li>Site cleaning<br/>and product protection</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>On-site self-check<br/>take photos and upload records</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Door panel and hardware<br/>installation and commissioning</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Cabinet table installation<br/> and acceptance</li>
                    <li><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li>Cabinet table<br/>gauging on site</li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li>Appointment of <br/>customer check acceptance</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li> Online acceptance   <br/>and evaluation <br/>of the installer</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Deep cleaning and  <br/>commissioning of products before moving</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>Customer online warranty <br/>and after-sale service</li>
                    <li><img src="@/common/image/jiantou.png" alt=""></li>
                    <li>vifa thorough <br/>“brand-new” service</li>
                </ul>
            </div>
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu6.png" alt=""></li>
                <li><img src="@/common/image/fuwu7.png" alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
import {getQualityService} from '@/api/api'

export default {
    data(){
        return{
            qualityService:''
        }
    },
    components: {
    },
    created () {
    },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mAndService');
        } else {
            this.$router.replace(paths+'/andService');
        }
        window.exec_main(document.title)
        this.getData()
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getData(){
            getQualityService()
            .then(res => {
                console.log(res)
                this.qualityService = res.qualityService
            })
            .catch(res => {
                console.log(res)
            });
        },
    },
}
</script>

<style>
.tuji{
    padding-top:75px ;
}
.tujiswiper{
    width: 100%;
    overflow: hidden;
}
.tujiswiper ul{
    overflow: auto;
}
.tujiswiper ul li{
    float: left;
    width: 900px;
    height: 540px;
    padding-right:25px
}
.tujiswiper ul li img{
    width: 100%;
    height: 540px;
    cursor: pointer;
}
.quality .spaceCustomization p{
    text-align: center;
    padding-bottom: 0;
    line-height: 2;
}
.quality .founder .yishu{
    margin-bottom: 20px;
}
.cangchu{
    padding-top: 50px;
}
.andService .qzh{
    padding-top: 0;
    padding-bottom: 0;
    white-space: pre-wrap;
    line-height: 1.5;
}
.iamgetitle{
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: -50px;
}
.andService .founder .beijing{
    padding-bottom: 10px;
}
.xingxing{
    padding:  25px 0;
    padding-top: 10px;
}
.xingxing ul{
    width: 250px;
    margin: 0 auto;
    display: flex;
}
.xingxing ul li{
    flex: 1 40px;
}
.xingxing ul li img{
    width: 35px;
    height: 35px;
}
.fuwu{
    padding-bottom: 80px;
}
.fuwu ul{
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.fuwu ul li{
    flex: 1;
}
.fuwu ul li img{
    width: 99%;
}
.wufuliucheng{
    width: 100%;
    padding: 50px 0;
    background: #d2cdca;
    color: #5e5450;
    margin: 80px 0;
}
.liuchengcont{
    width: 1200px;
    margin: 0 auto;
}
.liuchengcont ul{
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5% 0;
}
.liuchengcont ul li{
    flex: 1 132px;
    text-align: center;
    line-height: 2;
}
.liuchengcont ul li p{}
.liuchengcont ul li img{
    width: 100%;
}
.liuchengcont ul li.shupai img{
    width: auto;
}
</style>
