<template>
  <div class="brandProfile">
    <div
      class="jianbianq"
      :class="{ jianbianh: isbrandProfile_introduce }"
      ref="brandProfile_introduce"
    >
      <div class="brandProfile_introduce">
        <!-- <p>vifa威法是广东威法定制家居股份有限公司的高端定制家居品牌，专注于提供极具功能性的现代极简定制家居产品。致力于为中国高品质精英家庭提供一整套定制家居的系统解决方案。</p>
                <p>vifa威法始终追求将产品设计与空间设计做到周致的品牌理念，凭借严选的进口主材、现代极简的设计和细致入微的服务致力成为高端家居定制行业的引领者。</p> -->
        <div v-html="aboutVifa.brandProfileEng"></div>
      </div>
    </div>
    <div
      class="jianbianq"
      :class="{ jianbianh: isdevelopment }"
      ref="development"
    >
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing pwjstrone">Brand development</div>
      </div>
      <!-- <div class="development">
                <div class="kuang" ref="kuang1">
                    <div class="kuang_left">
                        <div class="shuyi">
                            <transition-group name="shuyiaani">
                                <div :style="{marginTop:shuyia + 'px'}"></div>
                            </transition-group>
                        </div>
                        <div class="year">
                            <ul>
                                <li :class="{'avtive':yearavtive1}">
                                    <p class="bold">1993</p>
                                    <p>Vifa品牌诞生</p>
                                </li>
                                <li>
                                    <p>2009</p>
                                    <p>开放式中国厨厅</p>
                                </li>
                                <li>
                                    <p>2015</p>
                                    <p>全屋定制</p>
                                </li>
                                <li>
                                    <p>2021</p>
                                    <p>工业4.0柔性制造</p>
                                </li>
                            </ul>
                        </div>
                        <div class="xiangqing">
                            <div class="xiangqingwap pt175">
                                <div :class="{'martopfucla':yearavtive1}">1933</div>
                                <p :class="{'martopfuclaaa':yearavtivep1}">1933年，vifa诞生于丹麦小镇维德拜克，并逐渐发展成为享有声誉的高端音响品牌。近九十年来，vifa持续为全球500多家知名品牌提供专业的技术支持，是欧洲精湛制造工艺和技术典范的代表。2002年，杨炼先生收购丹麦vifa品牌及音响业务，随后在“周致工艺、极简设计”的vifa品牌理念下于2008年创立威法家居，立志为中国精英家庭提供全屋定制系统解决方案。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kuang">
                    <img src="@/common/image/d1.png" alt="">
                </div>
                <div class="kuang">
                    <img src="@/common/image/d2.png" alt="">
                </div>
                <div class="kuang" ref="kuang2">
                    <div class="kuang_right">
                        <div class="xiangqing">
                            <div class="xiangqingwap pt175">
                                <div :class="{'martopfucla':yearavtive2}">2009</div>
                                <p :class="{'martopfuclaaa':yearavtivep2}">vifa威法作为中国高端的全屋家居定制专家，2009年把“开放式中国厨厅”这一全新概念引入中国整体厨房行业。</p>
                            </div>
                        </div>
                        <div class="year">
                            <ul>
                                <li style="color:#4c413d" :class="{'favtive':yearavtive2}">
                                    <p>1933</p>
                                    <p>Vifa品牌诞生</p>
                                </li>
                                <li :class="{'avtive':yearavtive2}">
                                    <p class="bold">2009</p>
                                    <p>开放式中国厨厅</p>
                                </li>
                                <li>
                                    <p>2015</p>
                                    <p>全屋定制</p>
                                </li>
                                <li>
                                    <p>2021</p>
                                    <p>工业4.0柔性制造</p>
                                </li>
                            </ul>
                        </div>
                        <div class="shuyi">
                            <transition-group name="shuyiaani">
                            <div :style="{marginTop:shuyib + 'px'}"></div>
                            </transition-group>
                        </div>
                    </div>
                </div>
                <div class="kuang" ref="kuang3">
                    <div class="kuang_left">
                        <div class="shuyi">
                            <transition-group name="shuyiaani">
                            <div :style="{marginTop:shuyic + 'px'}"></div>
                            </transition-group>
                        </div>
                        <div class="year">
                            <ul>
                                <li>
                                    <p>1933</p>
                                    <p>Vifa品牌诞生</p>
                                </li>
                                <li style="color:#4c413d" :class="{'favtive':yearavtive3}">
                                    <p>2009</p>
                                    <p>开放式中国厨厅</p>
                                </li>
                                <li :class="{'avtive':yearavtive3}">
                                    <p class="bold">2015</p>
                                    <p>全屋定制</p>
                                </li>
                                <li>
                                    <p>2021</p>
                                    <p>工业4.0柔性制造</p>
                                </li>
                            </ul>
                        </div>
                        <div class="xiangqing">
                            <div class="xiangqingwap pt175">
                                <div :class="{'martopfucla':yearavtive3}">2015</div>
                                <p :class="{'martopfuclaaa':yearavtivep3}">2015年产品从厨柜延伸至全屋功能定制家居。Vifa威法产品逐步过渡到意大利实木门板，西班牙烤漆门板，德国五金配件，罗马尼亚柜身基材等全方位进口高端定制。现在，vifa威法体验店遍布全国，已拓展超过150家，基本完成中国核心城市的网店布局。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kuang">
                    <img src="@/common/image/d3.png" alt="">
                </div>
                <div class="kuang">
                    <img src="@/common/image/d4.png" alt="">
                </div>
                <div class="kuang" ref="kuang4">
                    <div class="kuang_right">
                        <div class="xiangqing">
                            <div class="xiangqingwap pt175">
                                <div :class="{'martopfucla':yearavtive4}">2021</div>
                                <p :class="{'martopfuclaaa':yearavtivep4}">vifa威法以全进口主材，比国内更高的欧洲环保标准，欧洲百万级产品的配置标准向客户提供更安全，更环保，更耐用的定制产品和服务。vifa威法13万平方米的制造基地坐落在中山小榄“威法路”。2020年12月3万平方米全新生产制造基地正式投产。vifa新制造基地是定制行业中少有的高度现代化制造体系。2021年将正式投产,实现工业4.0柔性制造的升级。</p>
                            </div>
                        </div>
                        <div class="year">
                            <ul>
                                <li>
                                    <p>1933</p>
                                    <p>Vifa品牌诞生</p>
                                </li>
                                <li>
                                    <p>2009</p>
                                    <p>开放式中国厨厅</p>
                                </li>
                                <li style="color:#4c413d" :class="{'favtive':yearavtive4}">
                                    <p>2015</p>
                                    <p>全屋定制</p>
                                </li>
                                <li :class="{'avtive':yearavtive4}">
                                    <p class="bold">2021</p>
                                    <p>工业4.0柔性制造</p>
                                </li>
                            </ul>
                        </div>
                        <div class="shuyi">
                            <transition-group name="shuyiaani">
                            <div :style="{marginTop:shuyid + 'px'}"></div>
                            </transition-group>
                        </div>
                    </div>
                </div>
            </div> -->
      <div
        class="development"
        v-for="(item, index) in aboutBrandDevelop"
        :key="index"
      >
        <!-- <div> -->
        <div class="kuang" v-if="index % 2 == 0">
          <div class="kuang_left">
            <div class="shuyi" :ref="'shuyiHeight' + index">
              <transition-group name="shuyiaani">
                <div
                  :style="{
                    marginTop:
                      zhizhengHeight + zhizhengHeight * index * 2 + 'px',
                  }"
                ></div>
              </transition-group>
            </div>
            <div class="year">
              <ul>
                <!-- <li :class="{'avtive':yearavtive1}">
                                    <p class="bold">1993</p>
                                    <p>Vifa品牌诞生</p>
                                </li> -->
                <li
                  v-for="(itemli, indexli) in aboutBrandDevelop"
                  :key="indexli"
                  :class="[indexli == index ? 'avtive' : '']"
                >
                  <p>{{ itemli.year }}</p>
                  <!-- <p>{{itemli.title}}</p> -->
                </li>
              </ul>
            </div>
            <div class="xiangqing">
              <div class="xiangqingwap pt175">
                <div>{{ item.year }}</div>
                <p>{{ item.synopsisEng }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="kuang" v-if="index % 2 == 0">
          <img :src="'https://www.vifa.cn' + item.image" alt="" />
        </div>

        <div class="kuang" v-if="index % 2 != 0">
          <img :src="'https://www.vifa.cn' + item.image" alt="" />
        </div>
        <div class="kuang" v-if="index % 2 != 0">
          <div class="kuang_right">
            <div class="xiangqing">
              <div class="xiangqingwap pt175">
                <div>{{ item.year }}</div>
                <p>{{ item.synopsisEng }}</p>
              </div>
            </div>
            <div class="year">
              <ul>
                <li
                  v-for="(itemli, indexli) in aboutBrandDevelop"
                  :key="indexli"
                  :class="[indexli == index ? 'avtive' : '']"
                >
                  <p>{{ itemli.year }}</p>
                  <!-- <p>{{itemli.title}}</p> -->
                </li>
              </ul>
            </div>
            <div class="shuyi" :ref="'shuyiHeight' + index">
              <transition-group name="shuyiaani">
                <div
                  :style="{
                    marginTop:
                      zhizhengHeight + zhizhengHeight * index * 2 + 'px',
                  }"
                ></div>
              </transition-group>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="jianbianq" :class="{ jianbianh: iscplnani }" ref="cplnani">
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing pwjstrone">Product concept</div>
        <p v-html="aboutVifa.productConceptEng" class="pwjp"></p>
      </div>
      <div class="movies">
        <video
          poster="@/common/image/poter1.jpg"
          :src="'https://www.vifa.cn' + aboutVifa.productConceptVideo"
          controls="controls"
          preload="preload"
          loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutVifa } from "@/api/api";

export default {
  name: "brandProfile",
  data() {
    return {
      shuyia: 0,
      shuyib: 75,
      shuyic: 225,
      shuyid: 375,
      // yearavtive1:false,
      // yearavtive2:false,
      // yearavtive3:false,
      // yearavtive4:false,
      // yearavtivep1:false,
      // yearavtivep2:false,
      // yearavtivep3:false,
      // yearavtivep4:false,
      isbrandProfile_introduce: false,
      iscplnani: false,
      isdevelopment: false,
      aboutBrandDevelop: [],
      aboutBrandDevelopleft: [],
      aboutBrandDevelopright: [],
      zhizhengHeight: "",
      aboutVifa: {
        productConceptVideo:''
      },
    };
  },
  components: {},
  mounted() {
    console.log("创建");
    window.addEventListener("scroll", this.handleScroll);
    this.isbrandProfile_introduce = true;
    this.isdevelopment = true;
    this.getAboutVifaData();
    //    this.shuyib = this.$refs.kuang2.clientHeight/8
    //    this.shuyic = this.$refs.kuang3.clientHeight/8*3
    //    this.shuyid = this.$refs.kuang4.clientHeight/8*5
    setTimeout(() => {
      this.$nextTick(() => {
        this.zhizhengHeight =
          (this.$refs.shuyiHeight0.clientHeight + 1) /
          this.aboutBrandDevelop.length /
          2;
        // this.pplswidth = (this.$refs.pplsli0.clientWidth+1)*this.shmxiamgData.length
        // this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft
        // this.pplsmarginLeft = this.pplsuloffsetLeft
        // this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
        console.log(this.zhizhengHeight);
      });
    }, 1000);
  },
  methods: {
    getAboutVifaData() {
      getAboutVifa()
        .then((res) => {
          console.log(res);
          this.aboutBrandDevelop = res.aboutBrandDevelop;
          this.aboutVifa = res.aboutVifa;
          this.aboutBrandDevelop.forEach((item, index) => {
            this.$nextTick(() => {
              setTimeout(() => {
                this.initData(item, index);
              }, 100);
            });
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    initData(item, index) {
      let aboutBrandDevelopleft = [];
      let aboutBrandDevelopright = [];
      if (index % 2 === 0) {
        aboutBrandDevelopleft.push(item);
      } else {
        aboutBrandDevelopright.push(item);
      }
      this.aboutBrandDevelopleft = [
        ...this.aboutBrandDevelopleft,
        ...aboutBrandDevelopleft,
      ];
      this.aboutBrandDevelopright = [
        ...this.aboutBrandDevelopright,
        ...aboutBrandDevelopright,
      ];
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        if (
          this.$refs.development.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isdevelopment = false;
        }
        if (
          this.$refs.brandProfile_introduce.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandProfile_introduce = false;
        }
        if (
          this.$refs.development.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isdevelopment = true;
        }
        if (
          this.$refs.brandProfile_introduce.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandProfile_introduce = true;
        }
        if (
          this.$refs.cplnani.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.iscplnani = true;
        }
        // if (this.$refs.kuang1.offsetTop < this.scroll +document.documentElement.clientHeight-this.$refs.kuang1.clientHeight/2) {
        //     // console.log(this.$refs.kuang1.clientHeight/8)
        //     this.shuyia = 0 + this.$refs.kuang1.clientHeight/8
        //     this.yearavtive1 = true
        //     setTimeout(() => {
        //         this.yearavtivep1 = true
        //     }, 1000);
        // }
        // if (this.$refs.kuang2.offsetTop < this.scroll +document.documentElement.clientHeight-this.$refs.kuang1.clientHeight/2) {
        //     this.shuyib = this.shuyia + this.$refs.kuang1.clientHeight/4
        //     this.yearavtive2 = true
        //     setTimeout(() => {
        //         this.yearavtivep2 = true
        //     }, 1000);
        // }
        // if (this.$refs.kuang3.offsetTop < this.scroll +document.documentElement.clientHeight-this.$refs.kuang1.clientHeight/2) {
        //     this.shuyic = this.shuyib + this.$refs.kuang1.clientHeight/4
        //     this.yearavtive3 = true
        //     setTimeout(() => {
        //         this.yearavtivep3 = true
        //     }, 1000);
        // }
        // if (this.$refs.kuang4.offsetTop < this.scroll +document.documentElement.clientHeight-this.$refs.kuang1.clientHeight/2) {
        //     this.shuyid = this.shuyic + this.$refs.kuang1.clientHeight/4
        //     this.yearavtive4 = true
        //     setTimeout(() => {
        //         this.yearavtivep4 = true
        //     }, 1000);
        // }

        // }else if (e.deltaY < 0) {
        if (
          this.$refs.development.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isdevelopment = false;
        }
        if (
          this.$refs.brandProfile_introduce.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isbrandProfile_introduce = false;
        }
        if (
          this.$refs.cplnani.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.iscplnani = false;
        }
      });
      // }
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.brandProfile_introduce {
  width: 875px;
  margin: 0 auto;
  text-align: left;
  line-height: 2.3;
  padding-top: 100px;
}
.brandProfile_introduce > div {
  white-space: pre-wrap;
}
.about .brandProfile .founder {
  padding-top: 0;
}
.development {
  width: 100%;
  background: #e5e2e0;
  display: flex;
  flex-wrap: wrap;
  color: #4c413d;
}
.development > div {
  width: 50%;
  flex: 1 50%;
  height: 600px;
}
.development img {
  width: 100%;
  height: 100%;
}
.kuang .kuang_left {
  overflow: hidden;
  padding-left: 217.5px;
}
.kuang .kuang_left > div {
  float: left;
}
.kuang .kuang_right {
  overflow: hidden;
}
/* .pt75{
    padding-top: 50px;
}
.pt175{
    padding-top: 125px;
} */
.kuang .kuang_right > div {
  float: left;
}
.kuang .shuyi {
  width: 1px;
  background: #fff;
  height: 600px;
  margin-left: 42.5px;
  /* margin: 0 42.5px; */
}
.kuang .shuyi div {
  width: 50px;
  height: 1px;
  background: #f08518;
  margin-left: -25px;
  /* margin-top: 75px; */
}
.kuang .year {
  /* width: 185px; */
  padding-left: 52.5px;
}
.kuang .year ul {
  line-height: 28px;
  text-align: left;
}
.kuang .kuang_right .year ul {
  text-align: right;
}
/* .kuang .year ul li{
    height: 150px;
    transform: translateY(47px);
    color: #fff;
} */
.kuang .year ul li {
  color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kuang .year ul li.avtive {
  animation: yearavtive 2s;
  -moz-animation: yearavtive 2s;
  -webkit-animation: yearavtive 2s;
  -o-animation: yearavtive 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.kuang .year ul li.favtive {
  animation: yearfavtive 2s;
  -moz-animation: yearfavtive 2s;
  -webkit-animation: yearfavtive 2s;
  -o-animation: yearfavtive 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes yearavtive {
  0% {
    color: #fff;
  }
  100% {
    color: #4c413d;
  }
}
@-webkit-keyframes yearavtive {
  0% {
    color: #fff;
  }
  100% {
    color: #4c413d;
  }
}
@-moz-keyframes yearavtive {
  0% {
    color: #fff;
  }
  100% {
    color: #4c413d;
  }
}
@-o-keyframes yearavtive {
  0% {
    color: #fff;
  }
  100% {
    color: #4c413d;
  }
}
@keyframes yearfavtive {
  0% {
    color: #4c413d;
  }
  100% {
    color: #fff;
  }
}
@-webkit-keyframes yearfavtive {
  0% {
    color: #4c413d;
  }
  100% {
    color: #fff;
  }
}
@-moz-keyframes yearfavtive {
  0% {
    color: #4c413d;
  }
  100% {
    color: #fff;
  }
}
@-o-keyframes yearfavtive {
  0% {
    color: #4c413d;
  }
  100% {
    color: #fff;
  }
}
.kuang .year ul li.avtive .bold {
  font-weight: bold;
}
.kuang .xiangqing {
  width: 490px;
  line-height: 30px;
  text-align: left;
  padding-left: 60px;
}
.kuang .xiangqing .xiangqingwap {
  display: table-cell;
  vertical-align: middle;
  height: 600px;
}
.kuang .xiangqing .xiangqingwap div {
  font-size: 50px;
  padding-bottom: 5px;
  line-height: initial;
  font-weight: bold;
  /* margin-top: 50px;
    opacity: 0; */
}
/* .kuang .xiangqing .xiangqingwap p{
    margin-top: 50px;
    opacity: 0;
} */
.kuang .xiangqing .xiangqingwap p {
  text-align: justify;
}
.shuyiaani-move {
  transition: transform 2s;
}
.movies {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 60px 0;
}
.movies img {
  width: 100%;
}
.martopfucla {
  animation: martopfu 1s;
  -moz-animation: martopfu 1s;
  -webkit-animation: martopfu 1s;
  -o-animation: martopfu 1s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
.martopfuclaaa {
  animation: martopfuaa 0.5s;
  -moz-animation: martopfuaa 0.5s;
  -webkit-animation: martopfuaa 0.5s;
  -o-animation: martopfuaa 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes martopfu {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-webkit-keyframes martopfu {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-moz-keyframes martopfu {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-o-keyframes martopfu {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes martopfuaa {
  0% {
    opacity: 0;
    margin-top: 25px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-webkit-keyframes martopfuaa {
  0% {
    opacity: 0;
    margin-top: 25px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-moz-keyframes martopfuaa {
  0% {
    opacity: 0;
    margin-top: 25px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@-o-keyframes martopfuaa {
  0% {
    opacity: 0;
    margin-top: 25px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.kuang .year ul {
  height: 600px;
  display: flex;
  flex-direction: column;
}
.founder  .pwjp{
  width: 924px !important;
    margin: 0 auto;
}
</style>
