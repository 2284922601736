<template>
  <div class="brandConcept quality shipei">
    <div :class="{ jianbianbanner: isqualityBaner }" ref="qualityBaner">
      <div class="aboutBaner">
        <img :src="'https://www.vifa.cn' + qualityService.banner" alt="" />
        <p>{{ qualityService.title }}</p>
      </div>
    </div>
    <div class="founder spaceCustomization">
      <div class="ush">
        本公司郑重承诺：橱柜产品符合GB/T 2531-2010《厨房家具》标准，衣柜、护墙板产品符合GB/T 3324-2017《木家具通用技术条件》标准，室内门产品符合LY/T 1923-2020《室内木质门》标准。
      </div>
      <div class="yishu"></div>
      <div class="pvhtml" v-html="qualityService.qualityText"></div>
    </div>
    <div class="cangchu kjmxvideo">
      <video
        poster="@/common/image/poter4.jpg"
        width="1200"
        height="540"
        :src="'https://www.vifa.cn' + qualityService.qualityVideo"
        controls="controls"
        preload="preload"
        loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
      ></video>
    </div>
    <div class="founder spaceCustomization">
      <div class="yishu"></div>
      <div class="beijing">欧洲知名供应商提供高品质家居材料</div>
      <p class="jijian">BRAND RARTNER</p>
    </div>
    <div class="cangchu kjmxvideo">
      <video
        poster="@/common/image/poter5.jpg"
        width="1200"
        height="540"
        :src="'https://www.vifa.cn' + qualityService.supplierVideo"
        controls="controls"
        preload="preload"
        loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
      ></video>
    </div>
    <div class="founder spaceCustomization">
      <div class="yishu"></div>
      <div class="pvhtml" v-html="qualityService.baseTextOne"></div>
    </div>
    <div class="cangchu">
      <img :src="'https://www.vifa.cn' + qualityService.baseImage" alt="" />
    </div>



    <div class="founder spaceCustomization">
      <div class="yishu"></div>
       <div class="pvhtml" v-html="baseTextThere"></div>
    </div>
    <div class="cangchu">
      <img src="@/common/image/zhiliang01.jpg" alt="" />
    </div>

    <div class="founder spaceCustomization qzh">
      <!-- <div class="yishu"></div> -->
      <div class="pvhtml" v-html="qualityService.baseTextTwo"></div>
    </div>
    <div class="tuji ptone">
      <div class="tujiswiper">
        <ul
          :style="{
            width: tujiswiperWideh + 'px',
            marginLeft: tujiswipermLeft + 'px',
          }"
          :class="{ tran: istran }"
        >
          <li>
            <div><img :src="'http://www.vifa.cn' + lastiamgea" alt="" /></div>
          </li>
          <li>
            <div><img :src="'http://www.vifa.cn' + lastiamge" alt="" /></div>
          </li>
          <li
            v-for="(item, index) in tujiData"
            :key="index"
            :ref="'tujili' + index"
          >
            <div><img :src="'http://www.vifa.cn' + item.image" alt="" /></div>
          </li>
          <li>
            <div><img :src="'http://www.vifa.cn' + firstiamge" alt="" /></div>
          </li>
          <li>
            <div><img :src="'http://www.vifa.cn' + firstiamgea" alt="" /></div>
          </li>
        </ul>
      </div>
      <div class="newswi">
        <div class="left" @click="tujiswiperLeft"><p></p></div>
        <div class="center">
          <ul>
            <li
              v-for="(item, index) in tujiData"
              :key="index"
              :class="{ active: isactive == index }"
              @click="newzhiindex(index)"
            >
              <span class="no"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span><span class="yes"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span>
            </li>
          </ul>
        </div>
        <div class="right" @click="tujiswiperRight"><p></p></div>
        <p
          class="iamgetitle"
          v-for="(item, index) in tujiData"
          :key="index"
          v-show="isactive == index"
        >
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getQualityService } from "@/api/api";

export default {
  name: "quality",
  data() {
    return {
      tujiswiperWideh: 99999,
      tujiswipermLeft: -document.body.clientWidth / 4,
      tujiData: [],
      isactive: 0,
      isactivea: 0,
      isqualityBaner: false,
      tujilastindex: 5,
      baseTextThere:'作为中国高端定制家居行业的先驱，威法在产品质量上不遗余力。\n内部严控质量，斥资800多万元打造专业检验检测实验室，开展工艺、性能、环保等各项指标的检测。\n威法坚持材质与工艺并驾齐驱致力于打造更耐用环保的高端定制产品，为客户带来健康优质的体验。',
      qualityService: {
        qualityText:'',
        qualityVideo:'',
        supplierVideo:'',
        baseTextOne:'',
        baseImage:'',
        baseTextTwo:'',
        banner:''
      },
      firstiamge: "",
      lastiamge: "",
      firstiamgea: "",
      lastiamgea: "",
      istran: true,
    };
  },
  components: {},
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mQuality");
    } else {
      this.$router.replace(paths + "/quality");
    }
    this.getData();
    window.exec_main(document.title);
    this.isqualityBaner = true;
    setTimeout(() => {
      this.$nextTick(() => {
        this.tujiswiperWideh =
          (this.$refs.tujili0.clientWidth + 10) * (this.tujiData.length + 4);
        // this.tujiulleft = this.$refs.tujiul.offsetLeft
        // this.tujiswipermLeft = this.tujiulleft
        this.tujilastindex = this.tujiData.length - 1;
        this.tujiswipermLeft =
          -document.body.clientWidth / 4 - (this.$refs.tujili0.clientWidth + 1);
        console.log(this.tujilastindex);
      });
    }, 1000);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getData() {
      getQualityService()
        .then((res) => {
          console.log(res);
          this.qualityService = res.qualityService;
          this.tujiData = res.vifaBase;
          this.firstiamge = this.tujiData[0].image;
          let tujilength = this.tujiData.length - 1;
          this.lastiamge = this.tujiData[tujilength].image;
          this.firstiamgea = this.tujiData[1].image;
          this.lastiamgea = this.tujiData[tujilength - 1].image;
          // this.tujiswiperWideh = (this.$refs.tujisli0.offsetWidth+1) * this.tujiData.length
          // console.log(this.tujiData[0].image)
          console.log(this.lastiamge);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    tujiswiperLeft() {
      if (this.isactive > 0) {
        this.tujiswipermLeft =
          this.tujiswipermLeft + (this.$refs.tujili0.clientWidth + 1);
        this.isactive--;
      } else {
        this.istran = false;
        this.isactive = this.tujiData.length;
        this.tujiswipermLeft =
          -document.body.clientWidth / 4 -
          (this.$refs.tujili0.clientWidth + 1) * (this.isactive + 1);
        setTimeout(() => {
          this.istran = true;
          this.isactive--;
          this.tujiswipermLeft =
            this.tujiswipermLeft + (this.$refs.tujili0.clientWidth + 1);
        }, 5);
      }
    },
    tujiswiperRight() {
      if (this.isactive < this.tujiData.length - 1) {
        this.tujiswipermLeft =
          this.tujiswipermLeft - (this.$refs.tujili0.clientWidth + 1);
        this.isactive++;
      } else {
        this.istran = false;
        this.isactive = -1;
        this.tujiswipermLeft = -document.body.clientWidth / 4;
        setTimeout(() => {
          this.istran = true;
          this.isactive++;
          this.tujiswipermLeft =
            this.tujiswipermLeft - (this.$refs.tujili0.clientWidth + 1);
        }, 5);
      }
    },

    newzhiindex(index) {
      this.isactive = index;
      this.tujiswipermLeft =
        -document.body.clientWidth / 4 -
        (this.$refs.tujili0.clientWidth + 1) * (this.isactive + 1);
    },
  },
};
</script>

<style>
.tuji {
  padding-top: 75px;
}
.quality .ptone {
  padding-top: 50px;
}
.tujiswiper {
  width: 100%;
  overflow: hidden;
}
.tujiswiper ul {
  overflow: auto;
}
.shipei .tujiswiper ul li {
  float: left;
  width: 960px;
  height: 560px;
  padding-right: 25px;
}
.shipei .tujiswiper ul li img {
  width: 960px;
  height: 560px;
  cursor: pointer;
}
.quality .spaceCustomization p {
  text-align: center;
  padding-bottom: 0;
  line-height: 2;
}
.quality .founder .yishu {
  margin-bottom: 20px;
}
.ush{
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-wrap;
  color: #4c413d;
  text-align: center;
  font-weight: 500;
  /* margin-bottom: 20px; */
  margin-top: 70px;
  width: 70%;

}
.cangchu {
  padding-top: 100px;
}
.quality .qzh {
  padding-top: 50px;
  padding-bottom: 0;
}
.iamgetitle {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  top: -50px;
}
.pvhtml {
  line-height: 1.5;
  white-space: pre-wrap;
}
</style>
