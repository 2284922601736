<template>
  <div class="xiazai">
    <div class="xiazaibeijin" @click="hidexiazai"></div>
    <div class="xiazaitanchuan">
      <div class="shouji">
        <!-- <img src="./img/shoujiwaike.png" alt="" class="waike" /> -->
        <img src="./img/shouji.png" alt="" class="neirong" />
      </div>
      <div class="wenzhi">
       <div class="wenzhi_title">
              <h1>Smart</h1>
          <h1>Home</h1>
       </div>
       <div class="App" v-if="language==1">
           <h1>App</h1>
            <h1>下载</h1>
            
       </div>
       <div class="App" v-if="language==2">
           <h1>Scarica</h1>
            <h1> l’app</h1>
            
       </div>
       <div class="App" v-if="language==3">
           <h1>App</h1>
            <h1>download</h1>
            
       </div>
       <div class="erweima">
           <div class="isoerweima">
               <div class="ioserima_title">
                   <img src="./img/IOS.png" alt="">
                   <p>ios</p>

               </div>
               <div class="erweima_img">
                   <!-- iosUrlImage -->
                   <!--  -->
                   <img :src="`https://www.vifa.cn${crystal.iosUrlImage}`" alt="">
               </div>
           </div>
            <div class="isoerweima">
               <div class="ioserima_title">
                   <img src="./img/anzhuo.png" alt="">
                   <p>Android</p>

               </div>
               <div class="erweima_img">
                   <!-- iosUrlImage -->
                   <!--  -->
                   <img :src="`https://www.vifa.cn${crystal.androidUrlImage}`" alt="">
               </div>
           </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { toRaw } from '@vue/reactivity';
export default {
    props:['smartHomeobj'],
    data(){
       return {
            crystal:{},
            language:  sessionStorage.getItem("language")
       }
    },
    mounted(){
        

 
        const ss =toRaw(this.smartHomeobj);
         this.crystal=ss



    },
    methods:{
     hidexiazai(){
       let hide=false
      this.$emit('hide',hide)
     },
    }
};
</script>

<style>
.xiazai {
  /* width: 675px;
    height:486px ; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.xiazaibeijin {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  background: rgb(0, 0, 0, 0.5);
}
.xiazaitanchuan {
  width: 720px;
  height: 535px;
  position: absolute;
  top: 16%;
  left: 27%;
  z-index: 10002;
  background: rgb(0, 0, 0, 0.5);
  background: white;
  padding: 40px 30px 40px 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.shouji {
  position: relative;
  margin-top: 1.625rem;
  margin-left: 32px;
}
.shouji img {
  position: absolute;
  /* left: 0;
    top: 0; */
  width: 240px;
    height: 480px;
}
.waike {
  /* width: 9.125rem; */
  height: 19.125rem;
  left: 0;
  top: 0;
  z-index: 100;
}
.neirong {
  /* width: 8.45rem; */
  /* height: 18.825rem; */
  /* left: 0.35rem;
  top: 0.4625rem;
  z-index: 99; */
}
.wenzhi{
     margin-top: 5.625rem;
  margin-right: 32px;
}
.wenzhi .wenzhi_title{
    display: flex;
    justify-content: center;
    font-size: 50px;
}
.wenzhi .wenzhi_title h1:nth-of-type(2){
    margin-left: 0.625rem;
}
.wenzhi .App{
     display: flex;
     justify-content: center;
     font-weight: 0;
    font-size: 30px;
    margin-top: 36.8px;
    /* margin-left: 2.125rem; */
}
.wenzhi .App h1:nth-of-type(2){
    margin-left: 4px;
}
.erweima{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    width: 361px;

}
.isoerweima{
    width: 155px;
    height: 186px;
    overflow: hidden;
    position: relative;
    /* padding: 12px 10px 15px 10px ; */
    border: 1px solid black;
    border-radius: 5px;
}
.ioserima_title{
    display: flex;
    justify-content: center;
    /* font-size: 14px; */
    margin-top: 10px;
}
.ioserima_title img{
    /* width: 18px;
    height: 18px; */

}
.ioserima_title p{
line-height:32px;
margin-left: 3px;
}
.erweima_img {
  position: absolute;
  left: -3px;
  top: 32px;
  z-index: -1;
    /* margin-top: 3.2px; */
}
.erweima_img img{
    /* width: 7.25rem;
    height: 7.25rem; */
    width: 160px;
    height: 160px;
}

</style>