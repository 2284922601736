<template>
  <div class="product christophBrand">
    <!-- <Header/> -->
    <div :class="{ jianbianbanner: iscaseBaner }" ref="caseBaner">
      <div
        class="spaceCustomization_img homeDecoration productkjdz"
        ref="homeDecoration"
      >
        <div class="spaceCustomization">
          <img
            :src="'https://www.vifa.cn' + productCenterBanner.mqImage"
            alt=""
          />
        </div>
        <div
          class="homeDecorationMask"
          v-show="ishdMask"
          :style="{ height: hdmHeight }"
        >
          <div class="homeDecorationCenten">
            <transition-group name="hdcm">
              <ul>
                <li
                  v-for="(item, index) in kjdzData"
                  :key="index"
                  @click="kjdz(index)"
                  :class="{
                    midl: midlIndex == index,
                    left: leftIndex == index,
                    right: rightIndex == index,
                    pwjstrone: midlIndex == index
                    
                  }"
                >
                  <div class="zhoyao">{{ item.eName }}</div>
                  <p >{{ item.eName }}</p>
                </li>
              </ul>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <!-- <div  :class="{'jianbianbanner':isproductBaner}" ref="productBaner">
            <div class="spaceCustomization_img homeDecoration productkjdz" ref="homeDecoration">
                <div class="spaceCustomization">
                    <img class="pbanner" src='@/common/image/spaceCustomization.jpg' alt="" >
                </div>
                <div class="homeDecorationMask" v-show="ishdMask" :style="{'height':hdmHeight}">
                    <div class="homeDecorationCenten">
                        <transition-group name="hdcm">
                            <ul>
                                <li  v-for="(item, index) in kjdzData" :key="index" @click="kjdz(index)" :class="{'midl':midlIndex==index,'left':leftIndex == index,'right':rightIndex == index}">
                                    <div>{{item.name}}</div>
                                    <p>{{item.eName}}</p>
                                </li>
                            </ul>
                        </transition-group>
                    </div>
                </div>
            </div>
        </div> -->

    <div class="jianbianq" :class="{ jianbianh: ismcsp }" ref="mcsp">
      <div class="c-konjian"></div>
      <!-- <div class="founder spaceCustomization">
                <div class="yishu"></div>
                <div class="beijing">科技美学</div>
                <p class="jijian">以科技应用不断提升产品与服务，创造更高生活品质；</p>            
                <p class="jijian">以科技创新不断提升精准对接和服务效率</p>            
            </div> -->
      <div class="kjmxvideo mtab">
        <video
          poster="@/common/image/poter6.jpg"
          :src="'https://www.vifa.cn' + productCenterBanner.mqVideo"
          controls="controls"
          preload="preload"
          loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
        ></video>
      </div>
    </div>

    <!-- <div class="kjmxvideo mtab">
                  
                <video poster="https://www.vifa.cnimg/poter1.c6a25750.jpg" width="1200" height="540" src="https://www.vifa.cnresource/userfiles/fileupload/202112/1468112105666363392.mp4" controls='controls' preload='preload' loop='loop'>
                    </video>
            </div> -->

    <div
      class="jianbianq mqxtpb"
      :class="{ jianbianh: isbrandConcepttitle }"
      ref="brandConcepttitle"
    >
      <div v-for="(item, index) in productDoorwall" :key="index">
        <div class="founder spaceCustomization">
          <div class="yishu marginTX"></div>
          <div class="beijing pwjstrone">{{ item.titleEng }}</div>
          <div class="doorwallText" v-html="item.doorwallTextEng"></div>
        </div>
        <div class="mqimg">
          <img :src="'https://www.vifa.cn' + item.image" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
        <div class="founder spaceCustomization">
            <div class="yishu marginTX"></div>
            <div class="beijing">滑门系统</div>
            <p>威法滑门系统纤细的金属边框，炫酷又方便。</p>            
            <p>门扇移动顺滑，玻璃移门使用起来轻松方便。</p>                      
            <p>并有电动和手动两种款式可供选择。满足您的不同场景需求。</p>                      
            <p>玻璃分为欧洲灰玻璃、夹丝玻璃、竖条纹玻璃等，满足不同空间和设计的搭配需求。</p>                
        </div>
        <div class="mqimg"><img src="@/common/image/mqimg2.png" alt=""></div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':iskjmx}" ref="kjmx">
        <div class="founder spaceCustomization">
            <div class="yishu marginTX"></div>
            <div class="beijing">护墙系统</div>
            <p>威法的护墙系统的运用让整个家居设计效果更加统一；</p>            
            <p>护墙板设计与柜类产品的自然结合，能够大大提高空间的格调。</p>                      
            <p>升级护墙板系统配件包括平接件、脚线、挂墙配件、标准封斜边阳角配件，美观实用，安装、维修更方便。</p>                     
        </div>
        <div class="mqimg"><img src="@/common/image/mqimg3.png" alt=""></div>
        </div> -->
  </div>
</template>

<script>
import { getProductCenter } from "@/api/api";

export default {
  data() {
    return {
      kjdzData: [
        {
          name: "大家居",
          eName: "Light & Decoration",
        },
        {
          name: "门墙系统",
          eName: "Door & Wall",
        },
        {
          name: "空间定制",
          eName: "Space Customization",
        },
      ],

      ishdMask: true,
      midlIndex: 1,
      leftIndex: 0,
      rightIndex: 2,
      isSpaceCustomization: 1,
      hdmHeight: 920,
      kjdzallIndex: 0,
      isSpaceActiv: 0,
      iscaseBaner: false,
      isbrandConcepttitle: false,
      ismcsp: false,
      issjmx: false,
      iskjmx: false,
      productDoorwall: [],
      productCenterBanner: "",
    };
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mdoorwallsystem");
    } else {
      this.$router.replace(paths + "/doorwallsystem");
    }
    setInterval(() => {
      this.maskHeight();
    }, 1000);
    window.addEventListener("scroll", this.handleScroll);
    this.iscaseBaner = true;
    this.getProductCenterData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getProductCenterData() {
      getProductCenter()
        .then((res) => {
          this.productDoorwall = res.productDoorwall;
          this.productCenterBanner = res.productCenterBanner;
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        if (
          this.$refs.brandConcepttitle.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandConcepttitle = true;
        }
        if (
          this.$refs.mcsp.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.ismcsp = true;
        }
        if (
          this.$refs.sjmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issjmx = true;
        }
        if (
          this.$refs.kjmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.iskjmx = true;
        }

        // }else if (e.deltaY < 0) {
        if (
          this.$refs.brandConcepttitle.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isbrandConcepttitle = false;
        }
        if (
          this.$refs.mcsp.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.ismcsp = false;
        }
        if (
          this.$refs.sjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.issjmx = false;
        }
        if (
          this.$refs.kjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.iskjmx = false;
        }
      });
      // }
    },
    maskHeight() {
      this.quanpinl = this.quanpin * this.jiaxiangData.length;
      this.offtop = this.$refs.germanBox.offsetTop;
    },

    homeDM() {
      this.ishdMask = true;
      this.hdcm = 0;
    },
    homeDMout() {
      this.ishdMask = false;
      // this.hdcm = -300
    },
    kjdz(index) {
      if (this.midlIndex != index) {
        if (index == 0) {
          this.$router.push("/christophBrand");
        } else if (index == 2) {
          this.$router.push("/productCenter");
        }
        this.isSpaceCustomization = index;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../../common/styles/productCenter.css";
.mqimg {
  padding-top: 50px;
}
.mqxtpb {
  padding-bottom: 80px;
}
.founder .doorwallText {
  line-height: 1.5;
  white-space: pre-wrap;
}
.c-konjian {
  margin-top: 80px;
  width: 100%;
  height: 1px;
}
.cangchu {
  padding-top: 100px;
}
.midl .zhoyao{
  opacity: 0;
}
/* .product .productkjdz ul li.midl{
  padding-top:60px ;
} */
</style>
