<template>
    <div class="mhome">
        <div class="homelogo">
            <img :src="'https://www.vifa.cn' + object.mobileImage" alt="">
        </div>
        <div class="hometopswipt">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="subtitle">{{object.title}}</div>
                    <div class="newdetailtime">{{object.publishDate}}</div>
                </div>
                <div class="pplstext content" v-html="object.content">
                </div>
            </div>
            <!-- <div class="pinpailishi"> -->
                <!-- <div class="pplstext" v-html="caseDetails.synopsis"> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import { getEnterpriseNews } from "@/api/api";
import { getEnterpriseDynamic } from "@/api/api";
export default {
    data(){
        return{
            object:{},
            aboutVifa:''
        }
    },
    components: {
        // Mzixun
    },
    mounted(){
        let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mnewDetails?id='+this.$route.query.id+'&isnew='+this.$route.query.isnew);
        } else {
            this.$router.replace(paths+'/newDetails?id='+this.$route.query.id+'&isnew='+this.$route.query.isnew);
        }
        let data = {
            id:this.$route.query.id
        }
        if(this.$route.query.isnew == 0){
            this.getnewsData(data)
        }else{
            this.getEnterprissData(data)
        }
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getnewsData(data){
            getEnterpriseNews(data)
            .then(res => {
                this.aboutVifa = res.aboutVifa
                this.object = res.object
                 let str="video"
                let indext=res.object.content.indexOf(str)
                if(indext!=-1){
                 res.object.content=  res.object.content.slice(0,indext-0+5)+" autoplay"+res.object.content.slice(indext-0+5)
                     this.object = res.object
                }
            })
            .catch(res => {
                console.log(res)
            });
        },
        getEnterprissData(data){
            getEnterpriseDynamic(data)
            .then(res => {
                this.aboutVifa = res.aboutVifa
                console.log(res)
                this.object = res.object
            })
            .catch(res => {
                console.log(res)
            });
        },
        
        
    },
}
</script>

<style scoped>
.homelogo{
    width: 750px;
    position: relative;
    
}
.homelogo img,.video{
    width: 750px;
}
.homelogo div{
    width: 750px;
    text-align: center;
    font-size: 45px;
    color: #fff;
    position: absolute;
    left: 0;
    line-height: 90px;
    top:50%;
    margin-top: -45px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #000000cc;
    text-align: center;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav a{
    flex: 1;
    display: block;
    color: #fff;
}
.subnav .active{
    color: #f08518;
}
.mnew{
    width: 670px;
    margin: 40px auto;
    font-size: 26px;
    line-height: 1.8;
    text-align: justify;
}
.mnew .mnewtitle{
    width: 100%;
    overflow: hidden;
}
.mnew .mnewtitle div{
    font-size: 45px;
    font-weight: bold;
    float: left;
}
.mnew .mnewtitle p{
    color: #f08518;
    float: right;
    padding-top: 22.5px;
}
.mnew ul{}
.mnew ul li{
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #b1adac;
}
.mnew ul li .mnewleft{
    width: 400px;
    flex: 1 400px;
}
.mnew ul li .mnewleft div{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
.mnew ul li .mnewleft p{
    font-size: 18px;
}
.mnew ul li .mnewleft span{
    font-size: 18px;
    color: #b1adac;
}
.mnew ul li .mnewright{
    width: 250px;
    flex: 1 250px;
    text-align: right;
}
.mnew ul li .mnewright img{
    width: 250px;
    height: 175px;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 35px;
}  
.pinpailishi .title{
    font-size: 45px;
    padding: 20px 0;
    text-align: center;
}
.pinpailishi .subtitle{
    font-size: 29px;
    text-align: center;
    font-weight: bold;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
    text-align: center; 
} 
.pinpailishi .newdetailtime{
    padding-top: 35px;
    text-align: center;
}
.pplstext{
    text-align: left;
    line-height: 2;
}
.pplstext >>> img{
    width: 100%;
    margin: 20px 0;
}
.content{
    width: 670px;
    margin: 0 auto;
}
.hometopswipt{
    margin-bottom: 100px;
}

</style> 