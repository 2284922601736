<template>
    <div class="mhome mcontact" @click="mzxclose">
        <div class="containerbox">
            <div id="container" class="map"></div>
        </div>
        <div class="climap">
            <transition-group name="pinpailishiani">
                <ul :style="{marginLeft:mdmarginLeft + 'px',width:mdwidth+'px'}">
                    <li :style="{width:bodywidth+'px'}" v-for="(item,index) in shopList" :key="index" @touchstart="mdtouchstart" @touchmove='mdtouchmove' @click.stop="daohang">
                        <div>
                            <div>{{item.shopName}}</div>
                            <p>{{item.shopAddress}}</p>
                        </div>
                    </li>
                </ul>
            </transition-group>
        </div>
        <!-- <transition-group name="pinpailishiani">
            <div class="anibut" :style="{marginBottom:marginbottom + 'px'}" @click.stop="">
                <div @click="lijiyuyue">立即预约</div>
                <div @click="daohang">导航</div>
            </div>
        </transition-group> -->
        <div class="ljyy" v-if="ismzixun" @click="close">
            <div @click.stop=""><Mzixun/></div>
        </div>
    </div>
</template>

<script>
import AMap from 'AMap'
import { getShopByKeyword ,getContactus} from "@/api/api";
import arricon from '@/common/image/arricon.png'
import Mzixun from '@/components/mzixun'
export default {
    data(){
        return{
            arriveCoor:[113.336369, 23.01617],
            shopList:[],
            mdmarginLeft:0,
            mdwidth:0,
            bodywidth:document.body.clientWidth,
            mdstartx:0,
            mdmovex:0,
            mdchangex:0,
            ismdshow:0,
            ismzixun:false,
            marginbottom:-250
        }
    },
    components: {
        Mzixun
    },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    this.$router.replace(
      paths +
        "/mmapsearch?searchcityshop=" +
        this.$route.query.clickindex +
        "&&clickindex=" +
        this.$route.query.searchcityshop
    );
    // this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
    if (this.$route.query.navtype) {
      this.isclick = this.$route.query.navtype;
    }
    this.ismdshow = this.$route.query.clickindex;
    this.mdmarginLeft =
      -document.body.clientWidth * this.$route.query.clickindex;
    if (this.$route.query.searchcityshop != "") {
      let data = {
        keyword: encodeURIComponent(this.$route.query.searchcityshop),
      };
      this.getShopByKeywordData(data);
    } else {
      this.getContactusData();
    }
  },
    methods:{
        getContactusData(){
            getContactus()
            .then(res => {
                this.shopList = res.shopList
                this.arriveCoor = [res.shopList[this.$route.query.clickindex].shopLongitude,res.shopList[this.$route.query.clickindex].shopLatitude]
                this.init(this.arriveCoor)
                this.mdwidth = document.body.clientWidth*res.shopList.length
                this.ismdshow = this.$route.query.clickindex
                this.mdmarginLeft = -document.body.clientWidth*(this.$route.query.clickindex)
            })
        },
        getShopByKeywordData(data){
            getShopByKeyword(data)
            .then(res => {
                this.shopList = res.shopList
                this.arriveCoor = [res.shopList[this.$route.query.clickindex].shopLongitude,res.shopList[this.$route.query.clickindex].shopLatitude]
                this.init(this.arriveCoor)
                this.mdwidth = document.body.clientWidth*res.shopList.length
                this.ismdshow = this.$route.query.clickindex
                this.mdmarginLeft = -document.body.clientWidth*(this.$route.query.clickindex)
            })
        },
        init (arriveCoor) {
            new AMap.Marker({
                icon: new AMap.Icon({            
                    image: arricon,
                    imageSize: new AMap.Size(30,30) 
                }),
                position: arriveCoor,
                offset: new AMap.Pixel(-13, -30),
                // 设置是否可以拖拽 n
                draggable: true,
                // cursor: 'move',
                // 设置拖拽效果
                // raiseOnDrag: true
                enableHighAccuracy: true,
                timeout: 10000, 
                buttonPosition:'RB', 
                zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
                traffic:false
            }).setMap(
                new AMap.Map('container', {
                center: arriveCoor,
                zoom: 12,
                mapStyle: 'amap://styles/whitesmoke',
                resizeEnable: true,
            })
            )
        },
        
        mdshow(index){
            this.mdchangex = this.mdstartx
            this.mdmarginLeft = -document.body.clientWidth*(index)
            this.arriveCoor = [this.shopList[index].shopLongitude,this.shopList[index].shopLatitude]
            this.init(this.arriveCoor)
        },
        mdtouchstart(e){
            console.log(e)
            this.mdstartx = e.touches[0].clientX
        },
        mdtouchmove(e){
            console.log(e)
            if(this.mdchangex == this.mdstartx){
                return
            }
            this.mdmovex = e.touches[0].clientX
            let mdleftslide = this.mdstartx - this.mdmovex
            if(mdleftslide > 10 && this.ismdshow < this.shopList.length-1){
                this.mdshow(++this.ismdshow)
            }else if(mdleftslide < -10 && this.ismdshow > 0){
                this.mdshow(--this.ismdshow)
            }
        },
        yuyuedao(){
            this.marginbottom = 0
        },
        lijiyuyue(){
            this.ismzixun = true
            this.marginbottom = -250
        },
        close(){
            this.ismzixun = false
        },
        mzxclose(){
            this.marginbottom = -250
        },
        daohang(){
            // let queryStr = 'androidamap://navi?sourceApplication=msite&lat='+this.shopList[this.ismdshow].shopLatitude+'&lon='+this.shopList[this.ismdshow].shopLongitude+'&dev=1&style=2'
            // console.log(this.shopList[this.ismdshow].shopLatitude,,)
            // return
            let queryStr = 'https://uri.amap.com/navigation?from=""&to='+this.shopList[this.ismdshow].shopLatitude+','+this.shopList[this.ismdshow].shopLongitude+'&dev=1&style=2'
            // console.log(queryStr)
            // return
            window.open(queryStr,'_blank') 
        },
    },
}
</script>

<style scoped>
.mheader{
    width: 46.875rem;
    text-align: left;
}
#container{
    height: 100vh;
}
.pinpailishiani-move{
    transition: all .3s;
}

.climap{
    position: fixed;
    left: 0;
    bottom: 30px;
    width: 100%;
    /* background: #fff; */
    z-index: 999;
    /* height: 200px; */
    overflow: hidden;
}
.climap ul{
    display: flex; 
}
.climap ul li{
    line-height: 53px;
    text-align: left;
}
.climap ul li>div{
    width: 600px;
    background: #fff;
    margin: 0 auto;
    font-size: 26px;
    padding:20px 40px;
}
.climap ul li>div div{
    font-weight: bold;
}
.climap ul li>div p{
    min-height: 106px;
}
.anibut{
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 9999;
}
.anibut div{
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 120px;
    border-bottom: 1px solid #000;
    background: #fff;
}
.anibut div:last-child{
    border-bottom: none;
}
.ljyy{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #0000006b;
}
.ljyy>div{
    padding-top: 1px;
    padding-bottom: 1px;
    /* margin-top: 120px; */
    background: #fff;
}
</style> 