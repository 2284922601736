<template>
  <div class="distributed">
    <div class="maplists">
      <div id="allmap" class="Map">
      </div>
      <div class="mapsuo">
        <!-- <div>{{souname}}</div> -->
        <div class="goxiaos">vifa工程经销商查询</div>
        <div><input type="text" placeholder="请输入关键字" v-model="souname"><span><img :src="imgsrc"></span></div>
        <div @click="getmycontact(souname)" class="cx-button"><button>查询结果</button></div>
      </div>
      <div class="weizhimap" v-show="sounameshow">
        <div class="weizhilist">
          <div class="weiziinput"><input type="text" placeholder="请输入关键字" v-model="souname"><span><img
                :src="imgsrc"></span></div>
          <div class="scrollable">
            <div class="map-for" v-for="(ietm, index) in shoplist" :key="index"
              @click="inmapto(ietm.shopName, ietm.shopLatitude, ietm.shopLongitude)">
              <h1>{{ ietm.shopName }}</h1>
              <p>{{ ietm.shopAddress }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import imgsrcone from '@/common/image/searchIco.png'
import { getAgentSelect } from '@/api/api'
export default {
  name: 'Mapbox',
  data() {
    return {
      sounameshow: false,
      imgsrc: imgsrcone,
      souname: '',
      shoplist: [],
      mapdata: [
        { x: 116.297047, y: 39.979542, name: '张三' },
        { x: 116.321768, y: 39.88748, name: '李四' },
        { x: 116.494243, y: 39.756539, name: '王五' }
      ],
      mymap: '',
      mymaplx: 116.297047,
      mymaply: 39.979542,
      mapmax: 10
    }
  },
  mounted: function () {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + '/mmapserch');
    } else {
      this.$router.replace(paths + '/Mapdisplay');
    }
    window.exec_main(document.title)

    this.$nextTick(() => {
      var map = new BMap.Map("allmap");   //初始化map, 绑定id=allmap
      this.mymap = map
      var point = new BMap.Point(this.mymaplx, this.mymaply);   // 初始化point, 给定一个默认x,y值
      map.centerAndZoom(point, 10);        // 将point点放入map中，展示在页面中心展示，10=缩放程度
      map.enableScrollWheelZoom();         // 开启滚动鼠标滑轮

      // 获取当前地理位置
      // var geolocation = new BMap.Geolocation();
      // geolocation.getCurrentPosition(function (r) {
      //   if (this.getStatus() == BMAP_STATUS_SUCCESS) {
      //     var mk = new BMap.Marker(r.point);
      //     map.addOverlay(mk);
      //     map.panTo(r.point);
      //     // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
      //   } else {
      //     // alert('failed' + this.getStatus());
      //   }
      // });


      // 如有多个point去展示，可根据后端接口传入为主
    })

  },
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    souname: function () {
      this.getAgentSelectdata(this.souname)
    }
  },
  methods: {

    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    exec_main(title) {
      var text = encodeURIComponent(title);

      var url = 'https://www.vifa.cntest/middle.html?v=' + Math.random() + '&title=' + text

      if (typeof (exec_obj) == 'undefined') {
        console.log(1111)
        exec_obj = document.createElement('iframe');
        exec_obj.name = 'tmp_frame';
        exec_obj.src = url
        exec_obj.style.display = 'none';
        document.body.appendChild(exec_obj);
      } else {
        console.log(222222)
        exec_obj.src = url
      }
      console.log(3333)
    },
    // 在地图显示位置
    inmapto(name, latit, long) {
      this.sounameshow = false
      this.mymaplx = long,
        this.mymaply = latit
      this.mapchushua()
      this.mapfun()
      // this.mapfun()

    },
    // 地图初始化
    mapchushua() {
      //this.$nextTick(() => {
      var map = new BMap.Map("allmap");   //初始化map, 绑定id=allmap
      this.mymap = map
      var point = new BMap.Point(this.mymaplx, this.mymaply);   // 初始化point, 给定一个默认x,y值
      map.centerAndZoom(point, 15);        // 将point点放入map中，展示在页面中心展示，10=缩放程度
      map.enableScrollWheelZoom();         // 开启滚动鼠标滑轮

      // 获取当前地理位置
      // var geolocation = new BMap.Geolocation();
      // geolocation.getCurrentPosition(function (r) {
      //   if (this.getStatus() == BMAP_STATUS_SUCCESS) {
      //     var mk = new BMap.Marker(r.point);
      //     map.addOverlay(mk);
      //     map.panTo(r.point);
      //     // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
      //   } else {
      //     // alert('failed' + this.getStatus());
      //   }
      // });


      // 如有多个point去展示，可根据后端接口传入为主



      //   })
    },
    //获取地理位子
    getAgentSelectdata(data) {
      var datato = {
        shopAddress: data
      }

      //获取地理位置

      getAgentSelect(datato).then(

        res => {
          console.log('getAgentSelect', res)
          this.shoplist = res.agentSelect
          // console.log(this.porjectlist)
          this.mapdata = res.agentSelect
          console.log('mapdata', this.mapdata)

        }).catch(res => {
          console.log(res, 'catch')
        });
    },

    mapfun() {
      var t = this
      t.mapdata.forEach((e, i) => {
        // 创建point, 将x,y值传入
        let pointNumber = new BMap.Point(e.shopLongitude, e.shopLatitude)

        // 创建信息窗口对象 
        let infoWindow = new BMap.InfoWindow("World", {
          width: 150,     // 信息窗口宽度   
          height: 100,     // 信息窗口高度   
          title: "Hello" + i  // 信息窗口标题  
        });
        // 将data中的name加入地图中
        var label = new BMap.Label(e.shopName, {
          offset: new BMap.Size(25, 5)
        });
        t.markerFun(pointNumber, infoWindow, label)
      })




    },
    getmycontact(name) {
      this.getAgentSelectdata(name)
      this.sounameshow = true

    },
    markerFun(points, infoWindows, label) {
      let markers = new BMap.Marker(points);
      this.mymap.addOverlay(markers);  // 将标注添加到地图中
      markers.setLabel(label);  // 将data中的name添加到地图中
      // 标注的点击事件
      markers.addEventListener("click", function (event) {
        this.mymap.openInfoWindow(infoWindows, points);//参数：窗口、点  根据点击的点出现对应的窗口
      });
    }

  },
  unmounted() {
    // 标注的点击事件
    markers.removeEventListener("click", function (event) {
      this.mymap.openInfoWindow(infoWindows, points);//参数：窗口、点  根据点击的点出现对应的窗口
    });
  }
}
</script>
 
<style scoped>
.Map {
  /* height: calc(100vh - 200px); */
  height: 600px;
  width: 100%;

}

.goxiaos {
  text-align: left;
}

.mapsuo .goxiaos {
  background: none;
  font-weight: 600;
  border: none;
  height: 1.5rem;
  margin-top: 2rem;
}

.mapsuo .cx-button {
  border: none;
  background: #f19c39;
}

.maplists {
  position: relative;
  width: 100%;
  height: 600px;
  min-width: 1200px;
}

.distributed {
  width: 100%;
  padding-bottom: 50px;
  height: 600px;
  min-width: 1200px;
}

.mapsuo {
  position: absolute;
  top: 35%;
  left: 9rem;
  z-index: 9997;
  background: white;
  width: 23rem;
  height: 11rem;
}

.mapsuo div {
  width: 16rem;
  height: 1.8rem;
  line-height: 1.8rem;
  margin: 0.625rem auto;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  position: relative;
  padding: 0 0.625rem;
  border: 0.0625rem solid #4c413d;
}

.mapsuo div button {
  cursor: pointer;

}

.mapsuo div:nth-child(3) {
  cursor: pointer;
}

.mapsuo div input {
  width: 100%;
  height: 100%;
  background: none;
  border: 0;

}

.mapsuo div span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  right: 2px;
  top: 0;

}

.mapsuo div span img {
  width: 20px;
  height: 20px;


}

.mapsuo div button {
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  color: rgb(255, 255, 255);
}

.weizhimap {
  position: absolute;
  top: -5px;
  left: 0;
  width: 32.25rem;
  height: 616px;
  /* background: #9e9996; */

  background: white;
  z-index: 9998;
  overflow: hidden;
}

.weizhilist {
  width: 395px;
  height: 500px;
  margin-left: 125px;
  text-align: left;
}

.weiziinput {
  position: relative;
  width: 21.25rem;
  height: 40px;
  background: #ffffff;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 20px;

}

.weiziinput input {
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  padding-left: 10px;
  border: 0.0625rem solid rgb(155, 149, 147);
}

.weizhimap .weiziinput span img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 10px;
}

.map-for {
  cursor: pointer;
}

.map-for h1 {
  /* color: rgb(253, 148, 44); */
  color: rgb(244, 132, 34);
  margin-bottom: 10px;
}

.map-for p {
  /* color: rgb(255, 255, 255); */
  color: rgb(155, 149, 147);
  margin-bottom: 20px;
  padding-right: 20px;
  line-height: 20px;
}

.scrollable {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 1.2rem;
}</style>