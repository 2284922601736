<template>
  <div class="mfooter">
    <div class="foottop pbl">
      <div class="footerLogo">
        <img src="../common/image/footerLogo1.png" alt="" />
             
      </div>
      <!-- <ul>
                <li>
                    <div class="blanklist" @click="mehu">应用门户</div>
                    <div class="blanklinklist" v-show="isblanklinklist">
                        <div @click="mehu1">智能云设计</div>
                        <div @click="mehu2">vifa云学习</div>
                        <div @click="mehu4">vifa图库</div>
                        <div @click="mehu3">vifa供应链</div>
                        <div @click="mehu5">vifa客户订单系统</div>
                    </div>
                </li>
                <li>
                    <router-link to="/mcontact"><div class="blanklist">联系方式</div></router-link>
                    
                </li>
                <li>
                    <div class="blanklist">通知声明</div>
                </li>
            </ul> -->
    </div>
    <!-- <div class="foottop">
        </div> -->
 
    <div v-if="language == 1">
      <p>全国统一服务热线：{{ contactus.hotLine }}</p>
      <p>{{ contactus.companyName }}</p>
      <!-- <p>运营中心地址：{{contactus.companyAddress}}</p>
        <p>生产基地：{{contactus.producebaseAddress}}</p> -->
      <!-- <div class="footerqrcon">
            <ul>
                <li v-for="(item,index) in qrcodeData" :key="index" @click="bigqrcon(index)">
                    <img :src=item.qrcodeicon alt="">
                </li>
            </ul>
        </div> -->
      <!-- <p>From Denmark，Since 1933</p> -->
      <p>
        <span @click="beian">粤ICP备16027141号</span>, All Rights Reserved
        {{ contactus.companyName }}
      </p>
    </div>
    <div v-if="language == 2">
      <p>National uniform service hotline: {{ contactus.hotLine }}</p>
      <p>{{ contactus.companyNameEng }}</p>
      <!-- <p>运营中心地址：{{contactus.companyAddress}}</p>
        <p>生产基地：{{contactus.producebaseAddress}}</p> -->
      <!-- <div class="footerqrcon">
            <ul>
                <li v-for="(item,index) in qrcodeData" :key="index" @click="bigqrcon(index)">
                    <img :src=item.qrcodeicon alt="">
                </li>
            </ul>
        </div> -->
      <!-- <p>From Denmark，Since 1933</p> -->
      <p>
        <span @click="beian">Y. ICP. B No. 16027141</span>, All Rights Reserved
        {{ contactus.companyNameEng }}
      </p>
    </div>
    <div v-if="language == 3">
      <p>Servizio unificato nazionale{{ contactus.hotLine }}</p>
      <p>{{ contactus.companyNameIta }}</p>
      <!-- <p>运营中心地址：{{contactus.companyAddress}}</p>
        <p>生产基地：{{contactus.producebaseAddress}}</p> -->
      <!-- <div class="footerqrcon">
            <ul>
                <li v-for="(item,index) in qrcodeData" :key="index" @click="bigqrcon(index)">
                    <img :src=item.qrcodeicon alt="">
                </li>
            </ul>
        </div> -->
      <!-- <p>From Denmark，Since 1933</p> -->
      <p>
        <span @click="beian"> All Rights Reserved</span>, All Rights Reserved
        {{ contactus.companyNameIta }}
      </p>
    </div>
    <div class="isshowqrcon" v-show="isshowqrcon" @click="close">
      <div
        v-for="(item, index) in qrcodeData"
        :key="index"
        v-show="isbigqrconimg == index"
      >
        <img @click.stop="" :src="'https://www.vifa.cn' + item.qrcode" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import weixin from "../common/image/weixin.png";
import xiaochengxu from "../common/image/xiaochengxu.png";
import douyin from "../common/image/douyin.png";
import xiaohongshu from "../common/image/xiaohongshu.png";
import { getHead } from "../api/api";
export default {
  props: ["language"],
  data() {
    return {
      isblanklinklist: false,
      url1: "https://wx.vifa.cn/design.html",
      url2: "http://v.vifa.cn/login.html",
      url3: "https://console.xiekeyun.com/login",
      url4: "https://serve.vifa.cn/gallery/",
      url5: "http://crm.vifa.cn/",
      qrcodeData: [],
      contactus: "",
      isshowqrcon: false,
      isbigqrconimg: 0,
    };
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    getPageData() {
      getHead()
        .then((res) => {
          this.contactus = res.contactus;
          this.qrcodeData = [
            {
              qrcode: res.contactus.gzhImage,
              qrcodeicon: weixin,
            },
            {
              qrcode: res.contactus.xcxImage,
              qrcodeicon: xiaochengxu,
            },
            {
              qrcode: res.contactus.dyImage,
              qrcodeicon: douyin,
            },
            {
              qrcode: res.contactus.xhsImage,
              qrcodeicon: xiaohongshu,
            },
          ];
        })
        .catch((res) => {
          console.log(res);
        });
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    mehu() {
      this.isblanklinklist = !this.isblanklinklist;
    },
    mehu1() {
      window.open(this.url1, "_blank");
    },
    mehu2() {
      window.open(this.url2, "_blank");
    },
    mehu3() {
      window.open(this.url3, "_blank");
    },
    mehu4() {
      window.open(this.url4, "_blank");
    },
    mehu5() {
      window.open(this.url5, "_blank");
    },
    bigqrcon(index) {
      if (index == 3) {
        window.open(
          "https://www.xiaohongshu.com/user/profile/5dd4e7690000000001006037",
          "_blank"
        );
      } else if (index == 2) {
        window.open("https://v.douyin.com/89h1cdj/", "_blank");
      } else {
        this.isshowqrcon = true;
        this.isbigqrconimg = index;
        document.body.addEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.addEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      }
    },
    close() {
      this.isshowqrcon = false;
      document.body.removeEventListener("touchmove", this.defferScroll, {
        passive: false,
      });
      document.body.removeEventListener("wheel", this.defferScroll, {
        passive: false,
      });
    },
    beian() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
  },
};
</script>

<style scoped>
.mfooter {
  width: 670px;
  padding: 40px;
  background: #8d847f;
  font-size: 26px;
  color: #fff;
  line-height: 2;
  text-align: left;
  padding-bottom: 100px;
}
.foottop {
  width: 100%;
  display: flex;
  /* padding-bottom: 60px; */
  padding-bottom: 20px;
}
.foottop .footerLogo {
  flex: 1;
  text-align: left;
}
.foottop .footerLogo img {
  height: 70px;
}
.foottop ul {
  flex: 2;
  display: flex;
}
.foottop ul li {
  flex: 1;
  line-height: 18px;
  padding-top: 52px;
  /* padding-top: 20px; */
  position: relative;
}
.footerqrcon {
  padding: 50px 0;
}
.footerqrcon ul {
  width: 400px;
  display: flex;
}
.footerqrcon ul li {
  flex: 1 25%;
  text-align: center;
}
.footerqrcon ul li img {
  width: 45px;
  height: 45px;
}
.footerqrcon ul li:nth-child(1) img {
  width: 56px;
}
.blanklinklist {
  /* position: absolute; */
  line-height: 2;
  border-left: 1px solid #fff;
  padding-left: 10px;
  left: -15px;
  top: 85px;
  margin-top: 20px;
}
.blanklinklist div {
  width: 150px;
}
.foottop ul li div {
  color: #fff;
  background: #8d847f;
}
.isshowqrcon {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999999999;
}
.isshowqrcon div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
.isshowqrcon div img {
  margin: 0 auto;
  max-width: 100%;
}
</style>
