<template>
    <div class="product christophBrand">
        <div  :class="{'jianbianbanner':iscaseBaner}" ref="caseBaner">
        <div><img src="@/common/image/christopbanner.png" alt=""></div>
        </div>
        
        <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
        <div class="founder spaceCustomization">
            <div class="yishu marginTX"></div>
            <div class="beijing"><img src="@/common/image/christophlogo.png" alt=""></div>
        </div>
        <div class="djjmaidian">
            <div class="shoutu" :class="{'shoutuani':nomaidain}"><img src="@/common/image/maidian1.png" alt=""></div>
            <ul :class="{'djjmaidianani':hmover == index}" v-for="(item,index) in djjmaidianData" :key='index'>
                <li>
                    <div><img :src=item.maidian alt=""></div>
                    <div class="mdainp">
                        <p>所有CL的壁灯和吊灯都具有一个用于手势控制的集成红外感应器</p>
                        <p>无需接触，优雅自如</p>
                        <p>可方便的用于开灯/关灯，调亮/调暗 ，比例上出光/下出光</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="djjmaidianicon">
            <ul>
                <li :class="{'hmover':hmover == index}" v-for="(item,index) in djjmaidianData" :key='index' @mouseenter="maidianenter(index)" @mouseleave="maidianleave">
                    <img class="maidianicon" :src=item.maidianicon alt="">
                    <img class="maidianicona" :src=item.maidianicona alt="">
                    <p>{{item.p}}</p>
                </li>
            </ul>
        </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">

        <div class="spaceCustomization_img homeDecoration hsct" ref="homeDecoration" @mouseenter="homeDM" @mouseleave="homeDMout">
            <img class="h42" src="@/common/image/fjjkjdw.png" alt="">
            <div class="dingwei" :class="{'dingweishow':isdingwei}">
                <div class="dingweia" @click="hsctdingweia">+</div>
                <div class="dingweib" @click="hsctdingweib">+</div>
                <div class="dingweic" @click="hsctdingweic">+</div>
                <div class="dingweid" @click="hsctdingweid">+</div>
                <div class="dingweie" @click="hsctdingweie">+</div>
            </div>
            <div class="dwzhankai dajiajuxq" v-show="isdwzhankai == index" v-for="(item,index) in dwzhankaiData" :key='index' @click="dwguanbi">
                <ul>
                    <li>
                        <img class="" :src=item.img alt="">
                        <!-- <div>{{item.name}}</div>
                        <p>{{item.text}}</p> -->
                    </li>
                    <li>
                        <img class="" :src=item.img2 alt="">
                        <!-- <div>{{item.name}}</div>
                        <p>{{item.text}}</p> -->
                    </li>
                </ul>
            </div>
        </div>
        </div>
        <div class="founder dengnavcion">
            <ul>
                <li v-for="(item, index) in founderUl" :key="index" :class="{'active':hoverdeng==index}" @mouseover="founderUlMouseover(index)">
                    <div class="cursor" @click="taber(index)">
                        <!-- <p class="cursorNum">{{item.num}}</p> -->
                        <div class="dengiconimg"><img :src=item.image alt=""></div>
                        <p>{{item.name}}</p>
                    </div>
                </li>
            </ul>
            <transition-group name="yishuani">
                <div class="yishu yishuani" :style="{marginLeft:yishuani + 'px'}"></div>
            </transition-group>
            <div class="jiaolian"></div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':iskjmx}" ref="kjmx">
        <div class="founder spaceCustomization">
            <!-- <div class="yishu marginTX"></div> -->
            <div class="beijing">World first</div>
            <p>The first circular,lens-based,</p>
            <p>multi-functional lighting system with glarefree, </p>
            <p>comfortable light and a cariety of interchangeable optics.</p>
        </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':iskjmxaa}" ref="kjmxaa">
            <div class="dengbanner" v-if="ispaceList"><img :src="'https://www.vifa.cn' + productLight.paceImage" alt=""></div>
            <div class="dengbanner" v-if="isjetList"><img :src="'https://www.vifa.cn' + productLight.jetImage" alt=""></div>
            <div class="dengbanner" v-if="isspaceList"><img :src="'https://www.vifa.cn' + productLight.spaceImage" alt=""></div>
            <div class="dengbanner" v-if="isflapList"><img :src="'https://www.vifa.cn' + productLight.flapImage" alt=""></div>
        </div>
        <div class="jianbianq pb80"  :class="{'jianbianh':iskjmxbb}" ref="kjmxbb">
            <div v-if="ispaceList">
                <div class="founder spaceCustomization" v-for="(item,index) in paceList" :key='index'>
                    <div class="yishu marginTX"></div>
                    <div class="beijing">{{item.title}}</div>
                    <p>{{item.subtitle}}</p>                      
                    <div class="moreBut margintopt" @click="moredeng(item.id)">了解更多</div>
                    <div class="pddingt2"><img :src="'https://www.vifa.cn' + item.image" alt=""></div>
                </div>
            </div>
            <div v-if="isjetList">
                <div class="founder spaceCustomization" v-for="(item,index) in jetList" :key='index'>
                    <div class="yishu marginTX"></div>
                    <div class="beijing">{{item.title}}</div>
                    <p>{{item.subtitle}}</p>                      
                    <div class="moreBut margintopt" @click="moredeng(item.id)">了解更多</div>
                    <div class="pddingt2"><img :src="'https://www.vifa.cn' + item.image" alt=""></div>
                </div>
            </div>
            <div v-if="isspaceList">
                <div class="founder spaceCustomization" v-for="(item,index) in spaceList" :key='index'>
                    <div class="yishu marginTX"></div>
                    <div class="beijing">{{item.title}}</div>
                    <p>{{item.subtitle}}</p>                      
                    <div class="moreBut margintopt" @click="moredeng(item.id)">了解更多</div>
                    <div class="pddingt2"><img :src="'https://www.vifa.cn' + item.image" alt=""></div>
                </div>
            </div>
            <div v-if="isflapList">
                <div class="founder spaceCustomization" v-for="(item,index) in flapList" :key='index'>
                    <div class="yishu marginTX"></div>
                    <div class="beijing">{{item.title}}</div>
                    <p>{{item.subtitle}}</p>                      
                    <div class="moreBut margintopt" @click="moredeng(item.id)">了解更多</div>
                    <div class="pddingt2"><img :src="'https://www.vifa.cn' + item.image" alt=""></div>
                </div>
            </div>
        </div>

        


    </div>
</template>

<script>
import maidian from '@/common/image/maidian2.png'
// import maidian from '@/common/image/dajjkjdw.png'
import maidianicon1 from '@/common/image/maidianicon1.png'
import maidianicon2 from '@/common/image/maidianicon2.png'
import maidianicon3 from '@/common/image/maidianicon3.png'
import maidianicon4 from '@/common/image/maidianicon4.png'
import maidianicona1 from '@/common/image/maidianicon5.png'
import maidianicona2 from '@/common/image/maidianicon6.png'
import maidianicona3 from '@/common/image/maidianicon7.png'
import maidianicona4 from '@/common/image/maidianicon8.png'
import dengnavicon1 from '@/common/image/dengnavicon1.png'
import dengnavicon2 from '@/common/image/dengnavicon2.png'
import dengnavicon3 from '@/common/image/dengnavicon3.png'
import dengnavicon4 from '@/common/image/dengnavicon4.png'
import zhipin from '@/common/image/hsctzk.jpg'
import { getProductLight } from "@/api/api";


export default {
  data(){
      return{
        paceList:[],
        spaceList:[],
        jetList:[],
        flapList:[],
        founderUl:[
            {name:'c.Pace系列',image:dengnavicon1},
            {name:'c.Jet系列',image:dengnavicon2},
            {name:'c.Space系列',image:dengnavicon3},
            {name:'c.Flap系列',image:dengnavicon4},
        ],
        dwzhankaiData:[
            {
                name:'木桃木皮盒0',
                text:'材质和工艺的周致考量，融入智能化主题设计，根据生活场景智能切换显示模式，营造舒适主题氛围。',
                img:zhipin,
                img2:zhipin
            },
            {
                name:'木桃木皮盒1',
                text:'材质和工艺的周致考量，融入智能化主题设计，根据生活场景智能切换显示模式，营造舒适主题氛围。',
                img:zhipin,
                img2:zhipin
            },
            {
                name:'木桃木皮盒2',
                text:'材质和工艺的周致考量，融入智能化主题设计，根据生活场景智能切换显示模式，营造舒适主题氛围。',
                img:zhipin,
                img2:zhipin
            },
            {
                name:'木桃木皮盒3',
                text:'材质和工艺的周致考量，融入智能化主题设计，根据生活场景智能切换显示模式，营造舒适主题氛围。',
                img:zhipin,
                img2:zhipin
            },
            {
                name:'木桃木皮盒4',
                text:'材质和工艺的周致考量，融入智能化主题设计，根据生活场景智能切换显示模式，营造舒适主题氛围。',
                img:zhipin,
                img2:zhipin
            },
        ],
        isdwzhankai:999,
        isdingwei:false,
          hmover:999,
          nomaidain:true,
          djjmaidianData:[
              {
                  maidian:maidian,
                  maidianicon:maidianicon1,
                  maidianicona:maidianicona1,
                  p:'城墙按钮'
              },
              {
                  maidian:maidian,
                  maidianicon:maidianicon2,
                  maidianicona:maidianicona2,
                  p:'手势控制'
              },
              {
                  maidian:maidian,
                  maidianicon:maidianicon3,
                  maidianicona:maidianicona3,
                  p:'应用程序'
              },
              {
                  maidian:maidian,
                  maidianicon:maidianicon4,
                  maidianicona:maidianicona4,
                  p:'语音控制'
              },
          ],
            iscaseBaner:false,
            isbrandConcepttitle:false,
            issjmx:false,
            iskjmx:false,
            iskjmxaa:false,
            iskjmxbb:false,
            hoverdeng:0,
            yishuani:150,
            ispaceList:true,
            isjetList:false,
            isspaceList:false,
            isflapList:false,
            productLight:"",
      }
  },
    mounted() {
        this.getProductLightData()
       window.addEventListener("scroll", this.handleScroll); 
        this.iscaseBaner = true
        console.log(this.$refs)
    },
    methods:{
        handleScroll(){
         this.$nextTick(()=>{
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmx = true
                }
                if (this.$refs.kjmxaa.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmxaa = true
                }
                if (this.$refs.kjmxbb.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmxbb = true
                }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmx = false
                }
                if (this.$refs.kjmxaa.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmxaa = false
                }
                if (this.$refs.kjmxbb.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmxbb = false
                }
         })
            // }
        },
        getProductLightData(){
            getProductLight()
            .then(res => {
                console.log(res)
                this.paceList = res.paceList
                this.spaceList = res.spaceList
                this.jetList = res.jetList
                this.flapList = res.flapList
                this.productLight = res.productLight
            })
            .catch(res => {
                console.log(res,'catch')
            });
        },
        maidianenter(index){
            console.log(index)
            this.hmover = index
            this.nomaidain = false
        },
        maidianleave(){
            this.hmover=999
            this.nomaidain=true
        },
        homeDM(){
            this.isdingwei = true
        },
        homeDMout(){
            this.isdingwei = false
        },
        dwguanbi(){
            this.isdwzhankai = 999
        },
        hsctdingweia(){
            this.isdwzhankai = 0
        },
        hsctdingweib(){
            this.isdwzhankai = 1
        },
        hsctdingweic(){
            this.isdwzhankai = 2
        },
        hsctdingweid(){
            this.isdwzhankai = 3
        },
        hsctdingweie(){
            this.isdwzhankai = 4
        },
        moredeng(id){
            console.log(id)
            this.$router.push({path:'/spaceDetails',query:{id:id}})
        },
        founderUlMouseover(index){
            this.yishuani = 150 + 1200/this.founderUl.length*index
        },
        taber(index){
            this.hoverdeng = index
            if (index == 0) {
                this.ispaceList=true
                this.isjetList=false
                this.isspaceList=false
                this.isflapList=false
            }else if(index==1){
                this.ispaceList=false
                this.isjetList=true
                this.isspaceList=false
                this.isflapList=false
            }else if(index==2){
                this.ispaceList=false
                this.isjetList=false
                this.isspaceList=true
                this.isflapList=false
            }else if(index==3){
                this.ispaceList=false
                this.isjetList=false
                this.isspaceList=false
                this.isflapList=true
            }
        }
    },
    unmounted(){
        window.removeEventListener("scroll", this.handleScroll); 
    }
}
</script>

<style>
@import '../../../common/styles/productCenter.css';
.djjmaidian{
    width: 650px;
    margin: 0 auto;
    height: 500px;
    position: relative;
}
.djjmaidian ul{
    position: absolute;
    left: 0;
    top: 0;
    animation:homebrand 2s;
    -moz-animation:homebrand 2s; 
    -webkit-animation:homebrand 2s; 
    -o-animation:homebrand 2s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
.djjmaidian ul.djjmaidianani{
    animation:homebrandani 2s;
    -moz-animation:homebrandani 2s; 
    -webkit-animation:homebrandani 2s; 
    -o-animation:homebrandani 2s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
.djjmaidianicon{
    width: 650px;
    margin: 0 auto;
}
.djjmaidian img{
    width: 100%;
}
.djjmaidian .shoutu{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    animation:homebrand 2s;
    -moz-animation:homebrand 2s; 
    -webkit-animation:homebrand 2s; 
    -o-animation:homebrand 2s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
    
}
.djjmaidian .shoutuani{
    animation:homebrandani 2s;
    -moz-animation:homebrandani 2s; 
    -webkit-animation:homebrandani 2s; 
    -o-animation:homebrandani 2s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
    
}
.djjmaidian .shoutu img{
    height: 500px;
    width: auto;
}
.mdainp{
    padding: 20px 0;
}
.mdainp p{
    line-height: 1.5;
}
.djjmaidianicon ul{
    display: flex;
    padding: 50px 0;
}
.djjmaidianicon ul li{
    flex: 1 25%;
}
.djjmaidianicon ul li img.maidianicona{
    display: none;
}
.djjmaidianicon ul li.hmover img{
    display: none;
}
.djjmaidianicon ul li.hmover img.maidianicona{
    display: initial;
}
.djjmaidianicon ul li p{
    line-height: 3;
}
.kitchenWap .spaceCustomization p{
    /* width: 740px; */
    margin: 0 auto;white-space: pre-wrap;
    /* text-align: left; */
}
.christophBrand .dingwei{
    height:980px
}
.hsct .dingwei .dingweia{
        left: 30.5%;
    top: 10%;
}
.hsct .dingwei .dingweib{
    left: 32%;
    top: 53%;
}
.hsct .dingwei .dingweic{
    left: 51%;
    top: 56.5%;
}
.hsct .dingwei .dingweid{
    left: 70%;
    top: 56%;
}
.hsct .dingwei .dingweie{
    left: 83%;
    top: 48%;
}
.christophBrand .dwzhankai ul{
    width: 875px;
    height: 710px;
}
.christophBrand .dwzhankai ul li .cfimg{
    width: 400px;
    height: 352.5;
    margin:25px 0 25px 355px;
}
.christophBrand .dwzhankai ul li div{
    font-size: 30px;
    padding-top: 0;
}
.christophBrand .dwzhankai ul li p{
    padding-top: 15px;
    font-size: 16px;
}
.hscttitle{
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.h42{
    height: 885px;
}
.dengbanner{
    padding-top: 50px;
}
.christophBrand .founder img{
    transform: none;
    margin-top: 2%;
}
.pddingt2{
    padding-top: 20px;
}
.dajiajuxq ul li{
    background: none;
}
.christophBrand .dajiajuxq ul{
    width: 100%;
    align-items: center;
    height: 885px;
}
.dajiajuxq ul li img{
    height: auto;
}
.home_header{
    top: 0;
}
.christophBrand .dengnavcion .yishuani{
        height: 32px;
    margin-top: 10px;
}
.christophBrand .dengnavcion .dengiconimg{
    width: 100%;
    height: 80px;
    padding: 25px 0;display: flex;
    align-items: center;
}
.pb80{
    padding-bottom: 80px;
}
</style>
 