<template>
  <div class="tongzhi">
    <div class="tongzhibeijin" @click="hidexiazai"></div>
    <div class="tongzhitanchuan">
        <img src="../../xiazai/img/tongzhi.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  props:['tongZhiobj'],
  data(){
    return {
      crystal:{}
    }
  },
  mounted(){
  },
  methods:{
    hidexiazai(){
      let hide=false
      this.$emit('hidetongzhi',hide)
    },
  }
};
</script>

<style>

.tongzhi {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.tongzhibeijin {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  background: rgb(0, 0, 0, 0.5);
}
.tongzhitanchuan {
  top: 1%;
  width: 720px;
  position: absolute;
  left: 27%;
  z-index: 10002;
  background: rgb(0, 0, 0, 0.5);
  background: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.tongzhitanchuan img{

  width: 90%;
  margin: 0px auto;

}

</style>