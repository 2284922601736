<template>
  <div class="case alllist">
    <div class="storeShowImg">
      <img src="@/common/image/allListbanner.png" alt="" />
    </div>
    <div class="enterpriseDynamics newlist">
      <div class="newlix">
        <div class="enterpriseDynamics_box">
          <div class="enterpriseDynamics_news tran">
            <ul>
              <li v-for="(item, index) in newListData" :key="index">
                <div class="newImg">
                  <img :src="'https://www.vifa.cn' + item.image" alt="" />
                </div>
                <p>{{ item.topicIta }}</p>
                <div class="newName">{{ item.titleIta}}</div>
                <div class="newMore">
                  <span>{{ item.publishDate }}</span>
                  <button>
                    <router-link
                      :to="{
                        path: '/newDetails',
                        query: {
                          id: item.id,
                          isnew: this.$route.query.listbanner,
                        },
                      }"
                      > Più informazioni</router-link
                    >
                  </button>
                </div>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutVifaall, saveNewCaChe, saveDynamicCaChe } from "@/api/api";

import usePoint from "@/hooks/usePoint.js";

export default {
  data() {
    return {
      newListData: [],
    };
  },
  mounted() {
    usePoint.getcewfw("你好","en")
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(
        paths + "/mnewsList?listbanner=" + this.$route.query.listbanner
      );
    } else {
      this.$router.replace(
        paths + "/allList?listbanner=" + this.$route.query.listbanner
      );
    }
    this.getnewsData(this.$route.query.listbanner);
    console.log(this.$route.query.listbanner);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    async getFanyi(arr, key) {
      // let arr1 = [];
      // let arr2 = [];

      for (let index = 0; index < arr.length; index++) {
        if (arr[index].titleIta && arr[index].titleIta != "") {
          arr[index].titleIta = await arr[index].titleIta;
          arr[index].topicIta = await arr[index].topicIta;


          arr[index].titleIta = arr[index].titleIta.slice(0, 1).toUpperCase() + arr[index].titleIta.slice(1).toLowerCase();
          arr[index].topicIta = arr[index].topicIta.slice(0, 1).toUpperCase() + arr[index].topicIta.slice(1).toLowerCase();

          arr[index].titleIta = arr[index].titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicIta = arr[index].topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")
          arr[index].topicIta = arr[index].topicIta.replace(/&quot;/g, " ")

          arr[index].titleIta = arr[index].titleIta.replace(/&quot;/g, " ")

        } else {
          arr[index].titleIta = (
            await usePoint.getTranslation(arr[index].title, "it")
          ).data.trans_result[0].dst;
          arr[index].topicIta = (
            await usePoint.getTranslation(arr[index].topic, "it")
          ).data.trans_result[0].dst;


          arr[index].titleIta = arr[index].titleIta.slice(0, 1).toUpperCase() + arr[index].titleEng.slice(1).toLowerCase();
          arr[index].titleIta = arr[index].titleIta.slice(0, 1).toUpperCase() + arr[index].topicEng.slice(1).toLowerCase();

          arr[index].titleIta = arr[index].titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicIta = arr[index].topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")
          arr[index].topicIta = arr[index].topicIta.replace(/&quot; /g, " ")
          arr[index].titleIta = arr[index].titleIta.replace(/&quot;/g, " ")
          if (key == "enterpriseNews") {
            await saveNewCaChe({
              objId: arr[index].id,
              titleEng: arr[index].titleEng,
              topicEng: arr[index].topicEng,
              titleIta: arr[index].titleIta,
              topicIta: arr[index].topicIta,
            });
          } else {
            await saveDynamicCaChe({
              objId: arr[index].id,
              topicEng: arr[index].topicEng,
              topicIta: arr[index].topicIta,
              titleEng: arr[index].titleEng,
              titleIta: arr[index].titleIta,
            });
          }
        }
      }

      // arr.forEach(async (item, index) => {
      //   arr1.push(item.title);
      //   arr2.push(item.topic);
      // });
      // let str1 = arr1.join("@。12&&@");
      // let str2 = arr2.join("@。12&&@");

      // if (
      //   localStorage.getItem(key) &&
      //   localStorage.getItem(key) != null &&
      //   localStorage.getItem(key) != undefined
      // ) {
      //   let obj = JSON.parse(localStorage.getItem(key));
      //   if (
      //     obj.it &&
      //     obj.it != undefined &&
      //     str1 == obj.ch.str1 &&
      //     str2 == obj.ch.str2
      //   ) {
      //     // console.log(obj.en)
      //     str1 = obj.it.str1;
      //     str2 = obj.it.str2;
      //   } else {

      //     str2 = (await usePoint.getTranslation(str2, "it")).data
      //       .trans_result[0].dst;
      //       obj.ch={ str1: arr1.join("@。12&&@"), str2: arr2.join("@。12&&@") }
      //       obj.it={ str1: str1, str2: str2 }
      //     localStorage.setItem(
      //       key,
      //       JSON.stringify(obj)
      //     );
      //   }
      // } else {
      //   str1 = (await usePoint.getTranslation(str1, "it")).data.trans_result[0]
      //     .dst;
      //   str2 = (await usePoint.getTranslation(str2, "it")).data.trans_result[0]
      //     .dst;

      //   localStorage.setItem(
      //     key,
      //     JSON.stringify({
      //       ch: { str1: arr1.join("@。12&&@"), str2: arr2.join("@。12&&@") },
      //       it: { str1: str1, str2: str2 },
      //     })
      //   );
      // }
      // console.log("lalal");
      // // str1 = str1.replace(
      // //   /@ 12&&&@|@12 &&@|12&&@|@ 12&@|@ 12&&@|@12&&@/g,
      // //   "@12&&@"
      // // );
      // // str2 = str2.replace(
      // //   /@ 12&&&@|@12 &&@|12&&@|@ 12&@|@ 12&&@|@12&&@/g,
      // //   "@12&&@"
      // // );
      // // str1 = str1.replace(
      // //   /The way of Chinese craftsman/g,
      // //   "The way of Chinese craftsman@12&&@"
      // // );
      // arr1 = str1.split("@. 12&&@");
      // arr2 = str2.split("@. 12&&@");
      // arr.forEach(async (item, index) => {
      //   item.title = arr1[index];
      //   item.topic = arr2[index];
      // });
      return arr;
    },
    async getnewsData(listbanner) {
      let res = await getAboutVifaall();
      if (listbanner == 0) {
        this.newListData = await this.getFanyi(res.enterpriseNews,"enterpriseNews");
      } else {
        this.newListData = await this.getFanyi(res.enterpriseDynamic,"enterpriseDynamic");
      }
    },
  },
};
</script>

<style>
@import "../../../common/styles/about.css";
.case .storeShowImg,
.case .storeShowImg img {
  height: 720px;
}
.newlist .newlix .enterpriseDynamics_news ul,
.newlist .newlix .enterpriseDynamics_box {
  width: 1200px;
}
.newlist .enterpriseDynamics_news {
  width: 1200px;
}
.case .newlist .enterpriseDynamics_news ul li {
  flex: 1 32%;
  width: 32%;
  margin-bottom: 2%;
}
.alllist .enterpriseDynamics_news ul li > p {
  height: 60px;
}
.case .newlist .enterpriseDynamics_news ul li img {
  width: 100%;
}
</style>
