<template>
  <div>
    <div class="genera-al-list">
      <div class="genera-al-lists">
        <ul>
          <!-- <li v-for="(item, index) in porjectlist" :key="index"> -->
          <li v-for="(item, index) in porjectlist" :key="index">
            <div class="cleft" @mouseover="listover">
              <div class="cleft-list">
                <img :src="'https://www.vifa.cn' + item.image" />
                <p>{{ item.caseNameEng }}</p>
                <div class="cmouseshow" @click="showimglist(item.id)">
                  <a src="">
                    <div>Learn More</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="ctext">{{ item.contentEng }}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="pages c-p-w">
      <div class="prev" @click="prev" v-show="isprev">
        <p></p>
      </div>
      <ul>
        <!-- <li v-for="(item,index) in listData.slice(pagestart, pageend)" :key="index" class="cur" @click="getpages(index+1)" :class="{'active':isactive==index}">{{index+1}}</li> -->
        <li v-for="index in (pagestart, pageend)" :key="index" class="cur" @click="getpages(index)"
          :class="{ active: isactive == index }">
          <span class="gaoliang"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span><span class="nogl"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span>
        </li>
      </ul>
      <div class="next" @click="next" v-show="isnext">
        <p></p>
      </div>
    </div>

    <!-- <div class="newswi" >
                <div class="left" ><p></p></div>
                <div class="center">
                    <ul>
                        <li v-for="(item,index) in jiajiancount" :key="index" @click="bianjia(index)"><span class="no" v-show="index!=jiajianindex">一</span><span class="active" v-show="index==jiajianindex">十</span></li>
                        
                            
                    </ul>
                </div>
                <div class="right" ><p></p></div>
                <p class="iamgetitle"></p>
            </div> -->

    <div class="founder spaceCustomization">
      <div class="yishu"></div>
    </div>

    <div class="c-project-jf">
      <h1>Project management delivery process </h1>
    </div>

    <div class="c-project-list" ref="nameproject">
      <div class="c-project-lc">
        <div class="c-project-left">
          <div class="c-projecttextnb">
            <h1>1</h1>
          </div>
          <div class="c-projecttext-lc">Project start-up phase </div>
        </div>
        <div class="c-project-right">
          <ul>
            <li>
              <p>Design details</p>
              <div></div>
            </li>
            <li>
              <p>Construction disclosure</p>
              <div></div>
            </li>
            <li>
              <p>Establishment of </p>
              <p>site management system</p>
              <div></div>
            </li>
            <li>
              <p>Preparation of project </p>
              <p>installation delivery plan </p>
              <div></div>
            </li>
          </ul>
        </div>

        <div class="c-project-left2 c-project-topy">
          <div class="c-projecttextnb">
            <h1>2</h1>
          </div>
          <div class="c-projecttext-lc">Delivery sample phase</div>
        </div>
        <div class="c-project-right2 c-project-topy">
          <ul>
            <li>
              <p>Delivery sample materials for preparatio</p>
              <div></div>
            </li>
            <li>
              <p>Gauging and ordering of delivery sample </p>
              <div></div>
            </li>
            <li>
              <p>Installation of the delivery sample</p>
              <div></div>
            </li>
          </ul>
          <div class="c-project-right-shu"></div>
          <ul class="c-ul-2">
            <li>
              <p>Materials in bulk for preparatio<br />Gauging and ordering of materials in bulk</p>
              <div></div>
            </li>
            <li>
              <p> Process, quality, working face of delivery sample<br />eview and post-review improvement of installation
              </p>
              <div></div>
            </li>

            <!-- <li>
                 <p> 现场管理体系建立</p>
                 <div></div>
               </li>
               <li>
                  <p>项目安装交付计划的编制 </p>
                 <div></div>
               </li> -->
          </ul>
        </div>

        <div class="c-project-left2">
          <div class="c-projecttextnb">
            <h1>3</h1>
          </div>
          <div class="c-projecttext-lc">Site installation phase</div>
        </div>
        <div class="c-project-right2">
          <ul>
            <li>
              <p>Check of working face on site</p>
              <div></div>
            </li>
            <li>
              <p>Bulk delivery arrangement</p>
              <div></div>
            </li>
            <li>
              <p>Organization and arrangement </p>
              <p>of bulk installation</p>
              <div></div>
            </li>
          </ul>
          <div class="c-project-right-shu"></div>
          <ul class="c-ul-2">
            <li class="c-danghen">
              <p>Bulk installation quality and schedule control</p>
              <div></div>
            </li>
            <li class="c-danghen">
              <p>Bulk installation technology disclosure </p>
              <p>and safety civilization construction publicity</p>
              <div></div>
            </li>
            <!-- <li>
                 <p> 现场管理体系建立</p>
                 <div></div>
               </li>
               <li>
                  <p>项目安装交付计划的编制 </p>
                 <div></div>
               </li> -->
          </ul>
        </div>

        <div class="c-project-left">
          <div class="c-projecttextnb">
            <h1>4</h1>
          </div>
          <div class="c-projecttext-lc">Completion settlement phase</div>
        </div>
        <div class="c-project-right">
          <ul>
            <li>
              <p>Self-inspection and
                <br />
                self-correction of
                <br />
                installation completion
              </p>
              <div></div>
            </li>
            <li>
              <p>Finished product protection</p>
              <div></div>
            </li>
            <li>
              <p>Completion acceptance</p>
              <div></div>
            </li>
            <li>
              <p>Delivery to the owner</p>
              <div></div>
            </li>
            <li>
              <p>Settle accounts</p>
              <div></div>
            </li>
          </ul>
        </div>
        <div class="c-project-left c-project-topy">
          <div class="c-projecttextnb">
            <h1>5</h1>
          </div>
          <div class="c-projecttext-lc">After-sales service phase</div>
        </div>
        <div class="c-project-right c-project-right5 c-project-topy">
          <ul>
            <li>
              <p>24-hour service of
                <br />
                after-sales specialist
              </p>
              <div></div>
            </li>
            <li>
              <p>After-sale maintenance value-added service </p>
              <div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-shuxian"></div>
    <div class="c-Aftersale">
      <h1>Project after-sales service</h1>
      <div class="c-Aftersale-list">
        <div>
          <img src="@/common/image/24page.png" alt="" />
          <h2>Warranty service </h2>
        </div>
        <p>
          During the warranty period, it is equipped with a full-time after-sales specialist to receive customer’s
          problems, provide service within 24 hours,
          <br />
          make detailed records of after-sales problems and customer suggestions,
          <br />
          organize designers and project managers to do analysis and research on problems and suggestions,
          <br />
          and constantly improve product quality and meet customer needs.
        </p>
      </div>
      <div class="c-Aftersale-list">
        <div>
          <img src="@/common/image/25page.png" alt="" />
          <h2>After-sale maintenance value-added service</h2>
        </div>
        <p>
          After-sale maintenance value-added service: after the project warranty expires,
          <br />
          vifa products can provide regular on-site maintenance services at the cost price according to the needs of
          engineering customers,
          <br />
          to help customers maintain the cabinet/door/table hardware, so as to maintain the quality of product.
        </p>
      </div>
    </div>

    <!-- <div class='genera-name'>
                    <h1>{{deliverTitle}}</h1>
                    <div class='genera-name-p'>
                        <div class="leftp" v-html="deliverContent"></div> 
                    </div>
          </div> -->
    <div class="jiakuang"></div>
    <!-- 下面显示 -->
    <div class="brandConcepts quality shipei" v-show="isimgtoshow" @click="downshow">
      <div class="tuji ptone">
        <div class="diangji"></div>

        <div class="tujiswiper" @click.stop>
          <ul :style="{
            width: tujiswiperWideh + 'px',
            marginLeft: tujiswipermLeft + 'px',
          }" :class="{ tran: istran }">
            <li v-show="isimgshow">
              <div><img :src="'http://www.vifa.cn' + lastiamge" alt="" /></div>
            </li>
            <li v-show="isimgshow" v-for="(item, index) in tujiData" :key="index" :ref="'tujili' + index">
              <div><img :src="'http://www.vifa.cn' + item" alt="" /></div>
            </li>
            <li v-show="isimgshow">
              <div><img :src="'http://www.vifa.cn' + firstiamge" alt="" /></div>
            </li>
          </ul>
        </div>
        <div class="newswi" @click.stop>
          <div class="left" @click="tujiswiperLeft">
            <p></p>
          </div>
          <div class="center">
            <ul>
              <li v-for="(item, index) in tujilength" :key="index" :class="{ active: isactivea == index }"
                @click="newzhiindex(index)">
                <span class="no"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span><span class="yes"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span>
              </li>
            </ul>
          </div>
          <div class="right" @click="tujiswiperRight">
            <p></p>
          </div>
          <p class="iamgetitle" v-for="(item, index) in tujiData" :key="index" v-show="isactivea == index">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getProjectCasePage,
  getProjectCaseDetails,
} from "@/api/api";
export default {
  name: "encase",
  props: {
    deliverContent: {
      type: String,
    },
    deliverTitle: {
      type: String,
    },
  },
  data() {
    return {
      mydatas: {
        pageNo: 1,
        pageSize: 3,
      },
      pagestart: 0,
      pageend: 10,
      isactive: 1,
      isprev: true,
      isnext: true,
      porjectlist: [],
      //chiak
      tujiswiperWideh: 99999,
      tujiswipermLeft: -document.body.clientWidth / 4,
      tujiData: [],
      isactivea: 0,
      isqualityBaner: false,
      tujilastindex: 5,
      qualityService: {},
      firstiamge: "",
      lastiamge: "",
      firstiamgea: "",
      lastiamgea: "",
      istran: true,
      isimgtoshow: false,
      count: 0,
      jiajiancount: 0,
      jiajianindex: 0,
      // 图集的长度
      tujilength: 0,
      // 当只有一张图时有个
      pwjactive: 0,
      // 请求前
      isimgshow: false
    };
  },
  mounted() {
    this.getdataProjectCaseDisplaye(this.mydatas.pageNo);

    var ts = this;

    window.onscroll = function () {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      var a =
        document.documentElement.clientHeight || document.body.clientHeight;
      var a1 =
        document.documentElement.offsetHeight || document.body.offsetHeight;
      if (ts.$refs.nameproject.offsetTop - t <= a - a / 3) {
        ts.$refs.nameproject.classList.add("c-project-show");
      } else {
        ts.$refs.nameproject.className = "c-project-list";
      }
    };
  },

  methods: {
    downshow() {
      this.isimgtoshow = false;

      this.tujilength = 0;
      // this.firstiamge = "";
      // this.lastiamge = "";
      // this.firstiamgea = "";
      // this.lastiamgea = "";
      // this.tujiData = [];
      this.tujiswiperWideh = 99999;
      // this.tujilastindex = 0;
      this.tujiswipermLeft = -document.body.clientWidth / 4;
    },

    showimglist(id) {
      console.log("打开id", id);
      var caseId = {
        caseId: id,
      };

      this.isimgtoshow = true;
      // this.getDataimgProjectCaseDetails(caseId)
      this.getDataimg(caseId);
      //     setTimeout(()=>{
      //     this.$nextTick(()=>{

      //     })
      // },)
    },
    getdataProjectCaseDisplaye(index) {
      console.log("11112");
      if (index) {
        var data = {
          pageNo: index,
          pageSize: 3,
        };
      } else {
        var data = {
          pageNo: 1,
          pageSize: 3,
        };
      }
      this.isimgshow = false
      console.log(index, "datamy")
      getProjectCasePage(data)
        .then((res) => {
          console.log("getProjectCaseDisplaye", res);
          this.isimgshow = true
          this.porjectlist = res.page.list;
          this.pageend = Math.ceil(res.page.count / 3);
          //    this.count=res.page.count
          //     this.jiajiancount=  parseInt(res.page.count/3)
          //         if(!res.page.count%3==0){
          //             this.jiajiancount=this.jiajiancount+1
          //         }
          //         console.log(" this.jiajiancount",this.jiajiancount)
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    getDataimg(datas) {
      getProjectCaseDetails(datas)
        .then((res) => {
          this.isactivea = 0;
          this.pwjactive = 1;
          console.log("this.isactivea", this.isactivea);
          //this.qualityService = res.qualityService
          this.tujiData = res.projectCaseDetails.photoAlbumImages;
          console.log("console.log( this.tujiData )", this.isactive);
          if (this.tujiData.length == 1) {
            this.tujilength = this.tujiData.length;
            this.tujiData.push(this.tujiData[0], this.tujiData[0]);
            this.firstiamge = this.tujiData[0];

            this.lastiamge = this.tujiData[0];
            this.firstiamgea = this.tujiData[0];
            this.lastiamgea = this.tujiData[0];
          } else if (this.tujiData.length == 2) {



            // this.firstiamge = this.tujiData[0];
            // let tujilength = this.tujiData.length - 1;
            // this.tujilength = this.tujiData.length;
            // this.lastiamge = this.tujiData[tujilength];
            // this.firstiamgea = this.tujiData[1];
            // this.lastiamgea = this.tujiData[tujilength - 1];


            this.tujilength = this.tujiData.length;
            let tujilength = this.tujiData.length - 1;
            this.tujiData.push(this.tujiData[0]);
            this.tujiData.unshift(this.tujiData[1]);
            this.firstiamge = this.tujiData[0];
            this.lastiamge = this.tujiData[1];
            this.firstiamgea = this.tujiData[0];
            this.lastiamgea = this.tujiData[tujilength];





          } else {




            console.log("this.tujiData", this.tujiData);
            this.tujilength = this.tujiData.length;
            let tujilength = this.tujiData.length - 1;
            this.tujiData.push(this.tujiData[0]);
            this.tujiData.unshift(this.tujiData[tujilength]);
            this.firstiamge = this.tujiData[2];
            this.lastiamge = this.tujiData[tujilength];
            this.firstiamgea = this.tujiData[2];
            this.lastiamgea = this.tujiData[tujilength];


          }
          // this.tujiswiperWideh = (this.$refs.tujisli0.offsetWidth+1) * this.tujiData.length
          // console.log(this.tujiData[0].image)
          console.log(this.lastiamge);
          console.log("11111", this.$refs.tujili0);
          this.$nextTick(() => {
            this.tujiswiperWideh =
              (this.$refs.tujili0.clientWidth + 10) *
              (this.tujiData.length + 4);
            this.tujilastindex = this.tujiData.length - 1;
            this.tujiswipermLeft =
              -document.body.clientWidth / 4 -
              (this.$refs.tujili0.clientWidth + 1);
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    bianjia(index) {
      this.jiajianindex = index;
      this.mydatas.pageNo = index + 1;
      console.log(this.mydatas, 111);
      this.getdataProjectCaseDisplaye(this.mydatas);
    },
    //工程案例详情
    //  getDataimgProjectCaseDetails(data){
    //     getProjectCaseDetails(data)
    //     .then(res => {
    //         console.log(res)
    //         //this.qualityService = res.qualityService
    //          this.tujiData = res.projectCaseDetails.photoAlbumImages
    //         this.firstiamge = this.tujiData[0]
    //         let tujilength = this.tujiData.length-1
    //         this.lastiamge = this.tujiData[tujilength]
    //         this.firstiamgea = this.tujiData[1]
    //         this.lastiamgea = this.tujiData[tujilength-1]

    //         // this.tujiswiperWideh = (this.$refs.tujisli0.offsetWidth+1) * this.tujiData.length
    //         // console.log(this.tujiData[0].image)
    //         console.log(this.lastiamge)
    //     })
    //     .catch(res => {
    //         console.log(res)
    //     });
    // },

    tujiswiperLeft() {
      if (this.tujilength == 1) {
        this.istran = false;
        this.tujiswipermLeft =
          -document.body.clientWidth / 4 -
          (this.$refs.tujili0.clientWidth + 1) * 2;

        console.log(" this.tujiswipermLeft", this.tujiswipermLeft);
        setTimeout(() => {
          this.istran = true;
          this.isactivea = 0;
          this.tujiswipermLeft =
            this.tujiswipermLeft + (this.$refs.tujili0.clientWidth + 1);
          // transitionend
          console.log(" this.tujiswipermLeft", this.tujiswipermLeft);
        }, 5);
        return;
      }

      if (this.isactivea > 0) {
        this.istran = true;
        this.tujiswipermLeft =
          this.tujiswipermLeft + (this.$refs.tujili0.clientWidth + 1);
        this.isactivea--;
      } else {
        this.istran = false;
        this.isactivea = this.tujilength - 1;
        this.tujiswipermLeft =
          -document.body.clientWidth / 4 -
          (this.tujiData.length - 1) * (this.$refs.tujili0.clientWidth + 1);
        setTimeout(() => {
          this.istran = true;
          this.tujiswipermLeft = this.tujiswipermLeft + (this.$refs.tujili0.clientWidth + 1);
        }, 5);
      }
    },
    tujiswiperRight() {
      console.log("document.body.clientWidth", this.tujilength);

      if (this.tujilength == 1) {
        this.istran = false;
        this.tujiswipermLeft =
          -document.body.clientWidth / 4 - (this.$refs.tujili0.clientWidth + 1);

        console.log(" this.tujiswipermLeft", this.tujiswipermLeft);
        setTimeout(() => {
          this.istran = true;
          this.isactivea = 0;
          this.tujiswipermLeft =
            this.tujiswipermLeft - (this.$refs.tujili0.clientWidth + 1);
          // transitionend
          console.log(" this.tujiswipermLeft", this.tujiswipermLeft);
        }, 5);
        return;
      }

      if (this.isactivea < this.tujilength - 1) {
        this.istran = true;
        this.tujiswipermLeft =
          this.tujiswipermLeft - (this.$refs.tujili0.clientWidth + 1);
        this.isactivea++;
      } else {
        this.istran = false;
        this.isactivea = -1;
        this.tujiswipermLeft = -document.body.clientWidth / 4;
        setTimeout(() => {
          this.istran = true;
          this.isactivea++;
          this.tujiswipermLeft =
            -document.body.clientWidth / 4 -
            (this.$refs.tujili0.clientWidth + 1);
        }, 5);
      }
      // console.log(this.isactive)
    },
    prev() {
      if (this.pagestart + this.isactive > 1) {
        this.isactive--;
        this.mydatas.pageNo = this.isactive + this.pagestart;
        // this.getCasePages(this.pages)
        // this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
        this.getdataProjectCaseDisplaye(this.isactive + this.pagestart);
      }
      // else if(this.isactive==1){
      //     if (this.pagestart>0) {
      //         this.isactive = 8
      //         this.pagestart = this.pagestart - 8
      //         this.pages.pageNo=this.isactive+this.pagestart
      //         this.getCasePages(this.pages)
      //         window.scroll(0,0)
      //     }
      // }
    },
    getpages(index) {
      //this.$router.replace({path:'/mCase',query:{page:index+this.pagestart}})

      this.isactive = index;
      this.mydatas.pageNo = index + this.pagestart;
      //this.getCasePages(this.pages)
      this.getdataProjectCaseDisplaye(index);
    },
    next() {
      if (this.isactive < this.pageend) {
        this.isactive++;
        this.mydatas.pageNo = this.isactive + this.pagestart;
        // this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
        this.getdataProjectCaseDisplaye(this.isactive + this.pagestart);
        // this.getCasePages(this.pages)
      }
    },

    newzhiindex(index) {
      // console.log("index",isactivea)
      this.isactivea = index;
      this.istran = true;
      this.tujiswipermLeft =
        -document.body.clientWidth / 4 -
        (this.$refs.tujili0.clientWidth + 1) * (this.isactivea + 1);
    },
  },
};
</script>
<style scoped>
.c-p-w {
  max-width: 75rem;
  margin: 5rem auto 8.4375rem;
}

.diangji {
  width: 100%;
  height: 100%;
}

.brandConcepts {
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #151515;
}

.tuji {
  padding-top: 75px;
}

.quality .ptone {
  padding-top: 50px;
}

.tujiswiper {
  width: 100%;
  overflow: hidden;
}

.tujiswiper ul {
  overflow: auto;
}

.shipei .tujiswiper ul li {
  float: left;
  width: 960px;
  height: 560px;
  padding-right: 25px;
}

.shipei .tujiswiper ul li img {
  width: 960px;
  height: 560px;
  cursor: pointer;
}

.quality .spaceCustomization p {
  text-align: center;
  padding-bottom: 0;
  line-height: 2;
}

.quality .founder .yishu {
  margin-bottom: 20px;
}

.cangchu {
  padding-top: 100px;
}

.quality .qzh {
  padding-top: 50px;
  padding-bottom: 0;
}

.iamgetitle {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  top: -50px;
}

.pvhtml {
  line-height: 1.5;
  white-space: pre-wrap;
}

.brandConcepts .tuji .newswi .center {
  color: #ffffff;
}

.brandConcepts .tuji .newswi .center ul {
  background: #151515;
}

.brandConcepts .tuji .newswi .center ul li {
  color: white;
}

.brandConcepts .tuji .newswi {
  background: #fff;
}

.brandConcepts .tuji .newswi .left p {
  background: #fff;
}

.brandConcepts .tuji .newswi .right p {
  background: #fff;
}

.showimgactive {
  display: block;
}

.ctext {
  line-height: 2rem;
  text-align: left;
}

.genera-name .genera-name-p .leftp p {
  text-align: left;
}

.jiakuang {
  width: 100%;
  height: 80px;
}

.encase_newswi .center {
  color: #ffffff !important;
}

.encase_newswi .center ul {
  background: #151515 !important;
}

.encase_newswi .center ul li {
  color: white !important;
}

.encase_newswi {
  background: #fff !important;
}

.encase_newswi .left p {
  background: #fff !important;
}

.encase_newswi .right p {
  background: #fff !important;
}

.iamgetitle {
  position: absolute !important;
  text-align: center !important;
  width: 100% !important;
  left: 0 !important;
  top: -50px !important;
}

.active {
  color: #f08158;
}

/* chiak */

.c-project-list {
  max-width: 75rem;
  margin: 5rem auto 8.4375rem;
  transform: translateY(2em);
  -moz-transform: translateY(2em);
  -o-transform: translateY(2em);
  -webkit-transform: translateY(2em);
  opacity: 0;
  transition: transform 1s linear, opacity 1s linear;
  -webkit-transition: transform 1s linear, opacity 1s linear;
  -moz-transition: transform 1s linear, opacity 1s linear;
  -o-transition: transform 1s linear, opacity 1s linear;
}

.c-project-show {
  max-width: 75rem;
  transform: translateY(0em);
  -o-transform: translateY(0em);
  -moz-transform: translateY(0em);
  -webkit-transform: translateY(0em);
  opacity: 1;
}

.c-project-lc {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #797777;
}

.c-project-left {
  width: 9%;
  position: relative;
}

.c-project-left2 {
  width: 9%;
  position: relative;
}

.c-projecttextnb h1 {
  margin-top: 0px;
  font-size: 5.2rem;
  font-weight: 550;
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 2px #000000;
}

.c-projecttext-lc {
  width: 100%;
  position: absolute;
  top: 2.5rem;
  left: 0;
  height: 1.8rem;
  line-height: 1.8rem;
  background: #8d847f;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

.c-project-right {
  width: 91%;
}

.c-project-right ul {
  list-style: none;
  position: relative;
  margin-left: 1.8rem;
  height: 1px;
  background-color: #8d847f;
  margin-top: 3.4rem;
  right: 0rem;
  display: flex;
  justify-content: center;
}

.c-project-right ul::before {
  content: "";
  width: 1rem;
  height: 1px;
  background: #8d847f;
  position: absolute;
  top: 0.38rem;
  right: -0.1rem;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.c-project-right ul::after {
  content: "";
  width: 1rem;
  height: 1px;
  background: #8d847f;
  position: absolute;
  top: -0.37rem;
  right: -0.1rem;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.c-project-right ul li {
  width: 25%;
  text-align: center;
  position: relative;
  top: -4.4rem;
  left: -2rem;
}

.c-project-right ul li div {
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 3.8rem;
  left: 50%;
}

.c-project-right2 {
  position: relative;
  width: 91%;
}

.c-project-right2 ul {
  list-style: none;
  position: relative;
  margin-left: 1.8rem;
  height: 1px;
  background-color: #8d847f;
  margin-top: 3.4rem;
  right: 0rem;
  display: flex;
  justify-content: center;
}

.c-project-right5 ul {
  list-style: none;
  position: relative;
  margin-left: 1.8rem;
  height: 1px;
  background-color: #8d847f;
  margin-top: 3.4rem;
  right: 0rem;
  display: flex;
  justify-content: left;
}

.c-project-right2 ul li {
  width: 40%;
  text-align: center;
  position: relative;
  top: -4.4rem;
  left: -2rem;
}

.c-project-right-shu {
  position: absolute;
  width: 1px;
  height: 12.1rem;
  top: 3.4rem;
  right: 0;
  background: #8d847f;
}

.c-ul-2::before {
  content: "";
  width: 1rem;
  height: 1px;
  background: #8d847f;
  position: absolute;
  top: -0.37rem;
  left: -0.1rem;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.c-ul-2::after {
  content: "";
  width: 1rem;
  height: 1px;
  background: #8d847f;
  position: absolute;
  top: 0.38rem;
  left: -0.1rem;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.c-project-right2 .c-ul-2 {
  margin-top: 12rem;
  margin-bottom: 6rem;
}

.c-project-right2 .c-ul-2 li {
  width: 50%;
  text-align: center;
  position: relative;
  top: -7.4rem;
  left: -2rem;
  line-height: 2rem;
}

.c-project-right2 ul li div {
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 3.8rem;
  left: 50%;
}

.c-project-right2 .c-ul-2 .c-danghen {
  width: 50%;
  text-align: center;
  position: relative;
  top: -4.4rem;
  left: -2rem;
}

.c-project-right2 .c-ul-2 .c-danghen div {
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 3.8rem;
  left: 50%;
}

.c-project-right2 .c-ul-2 li div {
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 6.8rem;
  left: 50%;
}

.c-project-left,
.c-project-left2,
.c-project-right,
.c-project-right2 {
  margin-top: 2rem;
}

.c-shuxian {
  width: 1px;
  height: 3rem;
  background: #f08618;
  margin: 0 auto;
}

.c-Aftersale {
  max-width: 75rem;
  margin: 5rem auto 8.4375rem;
}

.c-w-pages {
  max-width: 75rem;
  margin: 5rem auto 8.4375rem;
}

.c-Aftersale h1 {
  font-size: 2.5rem;
  font-weight: 400;
  color: #4c413d;
}

.c-project-jf h1 {
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: #4c413d;
  text-align: center;
}

.c-Aftersale-list div {
  display: flex;
  justify-content: center;
}

.c-Aftersale-list img {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  top: -0.2rem;
  padding-right: 0.8rem;
}

.c-Aftersale-list h2 {
  font-size: 1.9rem;
  margin: 0;
  font-weight: 300;
  color: #797777;
}

.c-Aftersale-list {
  margin-top: 5rem;
}

.c-Aftersale-list p {
  margin-top: 3rem;
  line-height: 2rem;
  color: #797777;
}

.c-project-topy {
  margin-top: 5rem;
}
</style>