<template>
  <div>
    <div class="home_header" v-show="headerShow" :style="{ top: headerTop + 'px' }" ref="home_header"
      @mouseleave="headnavtwoleave">
      <!-- <div class="header" :class="{headerA:isheaderA}" ref="header" :style="{height:headerHeight + 'px'}">
                <img src="../common/image/logo.png" alt="" ref="headerLogo" :style="{left:imgLeft + 'px'}">
                <div class="nav" :style="{right:navRight + 'px'}"> -->
      <div class="header" :class="{ headerA: isheaderA, isheadershow: isheadershow }">
        <div @click="shouye" class="dianji" :class="{ adianji: isheaderA, isadianji: isheadershow }"></div>
        <img src="../common/image/logo1234yi.jpg" alt="" @click="shouye" />
        <div class="nav">
          <ul v-if="language == 1" class="chiUl">
            <li v-for="(item, index) in navData" :key="index" :class="{
              navActive: isnv == index,
              active: ishaadactive == index,
            }" @mouseover="navOver(index)" @mouseout="navOut" @click="headnav(index)">
              <span></span>{{ item.age }}
            </li>
          </ul>
          <ul v-if="language == 2" class="engUl">
            <li v-for="(item, index) in navDataE" :key="index" :class="{
              navActive: isnv == index,
              active: ishaadactive == index,
            }" @mouseover="navOver(index)" @mouseout="navOut" @click="headnav(index)">
              <span></span>{{ item.age }}
            </li>
          </ul>
          <ul v-if="language == 3" class="itaUl">
            <li v-for="(item, index) in navDataI" :key="index" :class="{
              navActive: isnv == index,
              active: ishaadactive == index,
              
            }" @mouseover="navOver(index)" @mouseout="navOut" @click="headnav(index)">
              <span></span>{{ item.age }}
            </li>
          </ul>
        </div>
        <div class="language" @mouseover="bindLangageEnt(true)">
          <div class="activeLanguage">
            <strong :class="isActiveLangeecf ? 'isActiveLangeecf' : ''"></strong>
            <span v-if="language == 1">language/中文</span>
            <span v-if="language == 2">language/English</span>
            <span v-if="language == 3">language/Italiano</span>
          </div>
          <!-- <button @click="bindLanguage(1)">中文</button>
        <button @click="bindLanguage(2)">English</button>
        <button @click="bindLanguage(3)">Italiano</button> -->
        </div>
      </div>









      <template v-if="language == 1">
        <div class="headertwonav" v-for="(item, index) in headernavtwoData" :key="index" v-show="headnavtwo == index">
          <ul>
            <li v-for="(twonav, twonavindex) in item.nav" :key="twonavindex" @mouseover="tnavOver(twonavindex)"
              @mouseout="tnavOut" :class="{ tnavActive: istnavActive == twonavindex }">
              <a @click="gotabChirend($el,twonav.link,index)">
                <div class="twonavaimg">
                  <img class="iamg" :src="'https://www.vifa.cn' + twonav.iamg" alt="" />
                  <img class="iamgc" :src="'https://www.vifa.cn' + twonav.iamgc" alt="" />
                  <div class="twonavmask">{{ twonav.title }}</div>
                </div>
              </a>
              <!-- <router-link :to="" >

              </router-link> -->
            </li>
          </ul>
        </div>
      </template>




      <template v-if="language == 2">
        <div class="headertwonav" v-for="(item, index) in headernavtwoDataE" :key="index" v-show="headnavtwo == index">
          <ul>
            <li v-for="(twonav, twonavindex) in item.nav" :key="twonavindex" @mouseover="tnavOver(twonavindex)"
              @mouseout="tnavOut" :class="{ tnavActive: istnavActive == twonavindex }">
           <a  @click="gotabChirend($el,twonav.link,index)">
            <div class="twonavaimg">
                  <img class="iamg" :src="'https://www.vifa.cn' + twonav.iamg" alt="" />
                  <img class="iamgc" :src="'https://www.vifa.cn' + twonav.iamgc" alt="" />
                  <div class="twonavmask">{{ twonav.title }}</div>
                </div>
           </a>
            </li>
          </ul>
        </div>
      </template>



      <template v-if="language == 3">
        <div class="headertwonav" v-for="(item, index) in headernavtwoDataI" :key="index" v-show="headnavtwo == index">
          <ul>
            <li v-for="(twonav, twonavindex) in item.nav" :key="twonavindex" @mouseover="tnavOver(twonavindex)"
              @mouseout="tnavOut" :class="{ tnavActive: istnavActive == twonavindex }">
             <a @click="gotabChirend($el,twonav.link,index)">
              <div class="twonavaimg">
                  <img class="iamg" :src="'https://www.vifa.cn' + twonav.iamg" alt="" />
                  <img class="iamgc" :src="'https://www.vifa.cn' + twonav.iamgc" alt="" />
                  <div class="twonavmask">{{ twonav.title }}</div>
                </div>
             </a>
            </li>
          </ul>
        </div>
      </template>















      <!-- v-show="" -->
      <div class="languageBack" v-show="isActiveLangeecf">
        <ol>

          <li @mouseover="bindActibeItem(1, true)" @mouseout="bindActibeItem(100, false)" @click="bindLanguage(1)"
            v-if="language != 1" :class="isActive == 1 ? 'active' : ''">
            <strong></strong>
            <span>中文</span>
          </li>

          <li @mouseover="bindActibeItem(2, true)" @mouseout="bindActibeItem(100, false)" @click="bindLanguage(2)"
            v-if="language != 2" :class="isActive == 2 ? 'active' : ''">
            <strong></strong>
            <span>English</span>
          </li>

          <li @mouseover="bindActibeItem(3, true)" @mouseout="bindActibeItem(100, false)" @click="bindLanguage(3)"
            v-if="language != 3" :class="isActive == 3 ? 'active' : ''">
            <strong></strong>
            <span>Italiano</span>
          </li>
        </ol>
      </div>
    </div>

    <transition-group name="hh159">
      <div class="h159" :class="{ h159a: ish159a }"></div>
    </transition-group>
    <!-- <div class="c-guazai" @click="guangaoshows" v-if="ishdgj"><img src="../common/image/PCback.png" alt=""></div> -->
    <div class="c-showhd" v-if="ishdtk">
      <div class="c-show-tk">
        <img class="c-show-pcnew" src="../common/image/PCnew.png" alt="" />
        <div class="c-choos" @click="chossgg">
          <img src="../common/image/pcchoos.png" alt="" />
        </div>
        <!-- <div class="c-intime-s" v-if="intime>0">{{intime}}S</div> -->
        <div class="c-ljzx" @click="gocontact">
          <img src="../common/image/pcljzx.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHead } from "../api/api";
export default {
  name: "header",
  props: ["language"],
  data() {
    return {
      show: true,
      isheaderA: false,
      navData: [
        { age: "关于vifa" },
        { age: "产品中心" },
        { age: "品质和服务" },
        { age: "案例展示" },
        { age: "联系我们" },
      ],
      navDataE: [
        { age: "About vifa" },
        { age: "Product Center" },
        { age: "Quality and Service" },
        { age: "Case Show" },
        { age: " Contact Us" },
      ],
      navDataI: [
        { age: "SRORIE DI VIFA" },
        { age: " PRODOTTI" },
        { age: "QUALITÀ  E SERVIZI" },
        { age: "PROGETTI" },
        { age: "CONTATTI" },
      ],
      headernavtwoData: [
        {
          nav: [
            { title: "门墙系统", link: "/doorwallsystem",iamg:'',iamgc:'' },
            { title: "空间定制", link: "/productCenter",iamg:'',iamgc:'' },
            { title: "大家居", link: "/christophBrand",iamg:'',iamgc:'' },
          ],
        },
        {
          nav: [
            { title: "品质", link: "/quality",iamg:'',iamgc:'' },
            { title: "服务", link: "/andService",iamg:'',iamgc:'' },
          ],
        },
        {
          nav: [
            { title: "家装案例", link: "/case",iamg:'',iamgc:'' },
            {
              title: "门店展示",
              link: { path: "/case", query: { bannertype: 2 } },iamg:'',iamgc:''
            },
          ],
        },
      ],
      headernavtwoDataE: [
        {
          nav: [
            { title: "Door and wall system", link: "/doorwallsystem",iamg:'',iamgc:'' },
            { title: "Space customization", link: "/productCenter",iamg:'',iamgc:'' },
            { title: "Household product", link: "/christophBrand",iamg:'',iamgc:'' },
          ],
        },
        {
          nav: [
            { title: "Quality", link: "/quality",iamg:'',iamgc:'' },
            { title: "service", link: "/andService",iamg:'',iamgc:'' },
          ],
        },
        {
          nav: [
            { title: "Home decoration case", link: "/case" ,iamg:'',iamgc:''},
            {
              title: "Store display",
              link: { path: "/case", query: { bannertype: 2 } },iamg:'',iamgc:''
            },
          ],
        },
      ],
      headernavtwoDataI: [
        {
          nav: [
            { title: "Parete e Porta", link: "/doorwallsystem" ,iamg:'',iamgc:''},
            { title: "Personalizzazione dello spazio", link: "/productCenter",iamg:'',iamgc:'' },
            { title: "Illuminazione e Mobili", link: "/christophBrand",iamg:'',iamgc:'' },
          ],
        },
        {
          nav: [
            { title: "QUALITÀ", link: "/quality" ,iamg:'',iamgc:''},
            { title: "SERVIZI", link: "/andService",iamg:'' ,iamgc:''},
          ],
        },
        {
          nav: [
            { title: "Esempi di arredamento domestico", link: "/case" ,iamg:'',iamgc:''},
            {
              title: "Esposizione del negozio",
              link: { path: "/case", query: { bannertype: 2 } },iamg:'',iamgc:''
            },
          ],
        },
      ],
      isnv: 99999,
      headerShow: true,
      isheadershow: false,
      headerTop: 0,
      headerkh: 159,
      ish159a: false,
      ishaadactive: 999,
      once: false,
      headnavtwo: 999,
      istnavActive: 999,
      ishdgj: false,
      ishdtk: false,
      intime: 10,
      issettime: null,
      isTimeout: null,
      isActiveLangeecf: false,
      isActive: 100,
    };
  },
  mounted() {
    window.addEventListener("mousewheel", this.handleScroll);
    window.addEventListener("scroll", this.headerscroll);
    document.body.addEventListener("touchmove", this.defferScroll, {
      passive: false,
    });
    document.body.addEventListener("wheel", this.defferScroll, {
      passive: false,
    });
    this.navbian();
    this.getPageData();
    this.advertisement();
    // this.settimecontact()
  },
  methods: {
    gotabChirend($el,link,index){
      // $el.preventDefault()
      this.$router.push(link)
   
      console.log(index)
      if(index==0){
        this.ishaadactive=1
      }else if(index==1){
        this.ishaadactive=2
      }else{
        this.ishaadactive=3
      }
    },
    bindActibeItem(index) {
      this.isActive = index
    },
    bindLangageEnt(isshow) {
      this.headnavtwo = 999;
      this.isActiveLangeecf = isshow;
    },
    bindLanguage(num) {
      sessionStorage.setItem("language", num);
      this.$emit("bindLanguage", num);

      let fullPath = this.$route.fullPath;

      fullPath = fullPath.replace("/En", "");
      fullPath = fullPath.replace("/It", "");
      console.log(fullPath);

      if (num == 1) {
        this.$router.replace(fullPath);
      } else if (num == 2) {
        this.$router.replace("/En" + fullPath);
      } else {
        this.$router.replace("/It" + fullPath);
      }
    },
    getPageData() {
      getHead()
        .then((res) => {
          this.$emit("smartHome", res.smartHome);
          let paths;
          if (sessionStorage.getItem("language") == 1) {
            paths = "";
          } else if (sessionStorage.getItem("language") == 2) {
            paths = "/En";
          } else {
            paths = "/It";
          }
          this.headernavtwoData = [
            {
              nav: [
                {
                  title: "门墙系统",
                  iamg: res.navigationImage.mqImage,
                  link: paths + "/doorwallsystem",
                  iamgc: res.navigationImage.mqImageColor,
                },
                {
                  title: "空间定制",
                  iamg: res.navigationImage.kjdzImage,
                  link: paths + "/productCenter",
                  iamgc: res.navigationImage.kjdzImageColor,
                },
                {
                  title: "大家居",
                  iamg: res.navigationImage.djjImage,
                  link: paths + "/christophBrand",
                  iamgc: res.navigationImage.djjImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "品质",
                  iamg: res.navigationImage.pzImage,
                  link: paths + "/quality",
                  iamgc: res.navigationImage.pzImageColor,
                },
                {
                  title: "服务",
                  iamg: res.navigationImage.fwImage,
                  link: paths + "/andService",
                  iamgc: res.navigationImage.fwImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "家装案例",
                  iamg: res.navigationImage.jzalImage,
                  link: { path: paths + "/case", query: { bannertype: 1 } },
                  iamgc: res.navigationImage.jzalImageColor,
                },
                {
                  title: "门店展示",
                  iamg: res.navigationImage.mdalImage,
                  link: { path: paths + "/case", query: { bannertype: 2 } },
                  iamgc: res.navigationImage.mdalImageColor,
                },
              ],
            },
          ];
          this.headernavtwoDataE = [
            {
              nav: [
                {
                  title: "Door and wall system",
                  iamg: res.navigationImage.mqImage,
                  link: paths + "/doorwallsystem",
                  iamgc: res.navigationImage.mqImageColor,
                },
                {
                  title: "Space customization",
                  iamg: res.navigationImage.kjdzImage,
                  link: paths + "/productCenter",
                  iamgc: res.navigationImage.kjdzImageColor,
                },
                {
                  title: "Household product",
                  iamg: res.navigationImage.djjImage,
                  link: paths + "/christophBrand",
                  iamgc: res.navigationImage.djjImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "Quality",
                  iamg: res.navigationImage.pzImage,
                  link: paths + "/quality",
                  iamgc: res.navigationImage.pzImageColor,
                },
                {
                  title: "service",
                  iamg: res.navigationImage.fwImage,
                  link: paths + "/andService",
                  iamgc: res.navigationImage.fwImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "Home decoration case",
                  iamg: res.navigationImage.jzalImage,
                  link: { path: paths + "/case", query: { bannertype: 1 } },
                  iamgc: res.navigationImage.jzalImageColor,
                },
                {
                  title: "Store display",
                  iamg: res.navigationImage.mdalImage,
                  link: { path: paths + "/case", query: { bannertype: 2 } },
                  iamgc: res.navigationImage.mdalImageColor,
                },
              ],
            },
          ];
          this.headernavtwoDataI = [
            {
              nav: [
                {
                  title: "Parete e Porta",
                  iamg: res.navigationImage.mqImage,
                  link: paths + "/doorwallsystem",
                  iamgc: res.navigationImage.mqImageColor,
                },
                {
                  title: "Personalizzazione dello spazio",
                  iamg: res.navigationImage.kjdzImage,
                  link: paths + "/productCenter",
                  iamgc: res.navigationImage.kjdzImageColor,
                },
                {
                  title: "Illuminazione e Mobili",
                  iamg: res.navigationImage.djjImage,
                  link: paths + "/christophBrand",
                  iamgc: res.navigationImage.djjImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "QUALITÀ",
                  iamg: res.navigationImage.pzImage,
                  link: paths + "/quality",
                  iamgc: res.navigationImage.pzImageColor,
                },
                {
                  title: "SERVIZI",
                  iamg: res.navigationImage.fwImage,
                  link: paths + "/andService",
                  iamgc: res.navigationImage.fwImageColor,
                },
              ],
            },
            {
              nav: [
                {
                  title: "Esempi di arredamento domestico",
                  iamg: res.navigationImage.jzalImage,
                  link: { path: paths + "/case", query: { bannertype: 1 } },
                  iamgc: res.navigationImage.jzalImageColor,
                },
                {
                  title: "Esposizione del negozio",
                  iamg: res.navigationImage.mdalImage,
                  link: { path: paths + "/case", query: { bannertype: 2 } },
                  iamgc: res.navigationImage.mdalImageColor,
                },
              ],
            },
          ];
          // console.log(res.)
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    headerscroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0 && this.once == false) {
        this.isheaderA = true;
        this.ish159a = true;
        this.once = true;
      }
    },
    navbian() {
      let navhash = window.location.hash;
      console.log(navhash);
      if (navhash == "#/about") {
        this.ishaadactive = 0;
      } else if (navhash == "#/productCenter") {
        this.ishaadactive = 1;
      } else if (navhash == "#/quality") {
        this.ishaadactive = 2;
      } else if (navhash == "#/case") {
        this.ishaadactive = 3;
      } else if (navhash == "#/contact") {
        this.ishaadactive = 4;
      } else {
        this.ishaadactive = 999;
      }
      if (navhash != "#/") {
        document.body.removeEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.removeEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      }
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    handleScroll(e) {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;

        if (this.scroll > 0 && localStorage.getItem("homeani") == 1) {
          this.isheaderA = true;
          this.ish159a = true;
          document.body.removeEventListener("touchmove", this.defferScroll, {
            passive: false,
          });
          document.body.removeEventListener("wheel", this.defferScroll, {
            passive: false,
          });

          if (e.deltaY > 0) {
            this.headerTop = -103;
            this.isheaderA = false;
            this.isheadershow = true;
          } else {
            this.isheaderA = false;
            this.isheadershow = true;
            this.headerTop = 0;
          }
        } else {
          if (
            e.deltaY > 0 &&
            this.once == false &&
            localStorage.getItem("homeani") == 1
          ) {
            this.isheaderA = true;
            this.ish159a = true;
            this.once = true;
            //定时器
            setTimeout(() => {
              document.body.removeEventListener(
                "touchmove",
                this.defferScroll,
                {
                  passive: false,
                }
              );
              document.body.removeEventListener("wheel", this.defferScroll, {
                passive: false,
              });
            }, 1000);
          }
        }
      });
    },
    navOver(index) {
      this.isnv = index;
      this.headnavtwo = index - 1;
      this.isActiveLangeecf = false;
    },
    navOut() {
      this.isnv = 99999;
    },
    dianjil() {
      this.headnavtwo = 999;
    },
    headnavtwoleave() {
      this.headnavtwo = 999;
      this.isActiveLangeecf = false;
    },
    headnav(index) {
      this.ishaadactive = index;
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      if (index == 0) {
        this.$router.push(paths + "/about");
      } else if (index == 1) {
        this.$router.push(paths + "/productCenter");
      } else if (index == 2) {
        this.$router.push(paths + "/quality");
      } else if (index == 3) {
        this.$router.push(paths + "/case");
      } else if (index == 4) {
        this.$router.push(paths + "/contact");
      }
      this.headnavtwo = 999;
    },
    shouye() {
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      this.$router.push(paths + "/");
      this.ishaadactive = 999;
    },
    tnavOver(index) {
      this.istnavActive = index;
    },
    tnavOut() {
      this.istnavActive = 999;
    },
    guangaoshows() {
      this.ishdtk = true;
      this.ishdgj = false;
    },
    gocontact() {
      this.ishdtk = false;
      this.ishdgj = true;

      console.log("contact");
      this.$router.push({ path: "/contact", query: { id: 1 } });
      console.log(this.$route.path);
      if (this.$route.path.indexOf("/contact") != -1) {
        console.log(" 包含contact");
      }
      this.intime = 0;
      clearInterval(this.issettime);
      clearTimeout(this.isTimeout);
    },
    settimecontact() {
      var t = this;
      t.issettime = setInterval(function () {
        t.intime = t.intime - 1;
        console.log(t.intime);
        if (t.intime <= 0) {
          clearInterval(t.issettime);
        }
      }, 1000);
      t.isTimeout = setTimeout(function () {
        t.ishdtk = false;
        t.ishdgj = true;
        t.$router.push({ path: "/contact", query: { id: 1 } });
      }, 10000);
    },
    chossgg() {
      this.ishdtk = false;
      this.ishdgj = true;
      this.intime = 0;
      clearInterval(this.issettime);
      clearTimeout(this.isTimeout);
    },
    advertisement() {
      let startdata = new Date("2022/08/12 00:00:01").getTime();
      let enddata = new Date("2022/10/10 00:00:01").getTime();
      let nowdata = new Date().getTime();
      if (startdata <= nowdata && nowdata <= enddata) {
        // console.log('显示')
        this.ishdgj = false;
        this.ishdtk = true;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.headertwonav {
  width: 1200px;
  margin: 0 auto;
}

.dianji {
  width: 113px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 543.5px;
  transform: scale(0.8);
  cursor: pointer;
  z-index: 9999;
}

.adianji {
  animation: headerLogo 0.7s;
  -moz-animation: headerLogo 0.7s;
  -webkit-animation: headerLogo 0.7s;
  -o-animation: headerLogo 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.isadianji {
  transform: scale(0.8);
  left: 0;
}

.h159 {
  width: 100%;
  height: 139px;
}

.h159a {
  animation: headerHeight159 0.8s 0.7s;
  -moz-animation: headerHeight159 0.8s 0.7s;
  -webkit-animation: headerHeight159 0.8s 0.7s;
  -o-animation: headerHeight159 0.8s 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.home_header {
  width: 100%;
  background: #fff;
  position: fixed;
  /* top: 0; */
  left: 0;
  z-index: 9999;
  transition: all 0.8s 0.5s;
  box-shadow: 1px 1px 7px #88888821;
}

.header {
  width: 1200px;
  margin: 10px auto 10px;
  position: relative;
  height: 119px;
}

.header img {
  position: absolute;
  top: 0;
  left: 543.5px;
  /* transform: scale(.8); */
  width: 5.15rem;
  /* height: 3.15rem; */
}

.header .nav {
  position: absolute;
  bottom: 0;
  right: 175px;
  /* display: flex;
  align-items: center; */
}

.headerA {
  animation: headerHeight 0.8s 0.7s;
  -moz-animation: headerHeight 0.8s 0.7s;
  -webkit-animation: headerHeight 0.8s 0.7s;
  -o-animation: headerHeight 0.8s 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.headerA img {
  animation: headerLogo 0.7s;
  -moz-animation: headerLogo 0.7s;
  -webkit-animation: headerLogo 0.7s;
  -o-animation: headerLogo 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.headerA .nav {
  animation: headerLogo_right 0.7s;
  -moz-animation: headerLogo_right 0.7s;
  -webkit-animation: headerLogo_right 0.7s;
  -o-animation: headerLogo_right 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.headerA .nav ul li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.nav ul li.active {
  color: #f08518;
}

.isheadershow {
  height: 63px;
}

.isheadershow img {
  /* transform: scale(.8); */
  cursor: pointer;
  left: 0;
}

.isheadershow .nav {
  right: 0;
}

.isheadershow .nav .chiUl {
  margin: 20px auto 20px;
}

.isheadershow .nav .chiUl li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.nav .chiUl {
  width: 850px;
  display: flex;
  margin: 20px auto 20px;
}

.nav .chiUl li {
  flex: 1;
  text-align: center;
  color: #948d8b;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-size: 16px;
}


.nav .chiUl li span {
  display: none;
}

.nav .chiUl li.navActive span {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 35px;
  top: -16px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes h159ani {
  from {
    height: 159px;
  }

  to {
    height: 103px;
  }
}

@-webkit-keyframes h159ani {
  from {
    height: 159px;
  }

  to {
    height: 103px;
  }
}

@-moz-keyframes h159ani {
  from {
    height: 159px;
  }

  to {
    height: 103px;
  }
}

@-o-keyframes h159ani {
  from {
    height: 159px;
  }

  to {
    height: 103px;
  }
}

@keyframes headerLogo {
  from {
    left: 543.5px;
  }

  to {
    left: 0;
  }
}

@-webkit-keyframes headerLogo {
  from {
    left: 543.5px;
  }

  to {
    left: 0;
  }
}

@-moz-keyframes headerLogo {
  from {
    left: 543.5px;
  }

  to {
    left: 0;
  }
}

@-o-keyframes headerLogo {
  from {
    left: 543.5px;
  }

  to {
    left: 0;
  }
}

@keyframes headerLogo_right {
  from {
    right: 175px;
  }

  to {
    right: 0;
  }
}

@-webkit-keyframes headerLogo_right {
  from {
    right: 175px;
  }

  to {
    right: 0;
  }
}

@-moz-keyframes headerLogo_right {
  from {
    right: 175px;
  }

  to {
    right: 0;
  }
}

@-o-keyframes headerLogo_right {
  from {
    right: 175px;
  }

  to {
    right: 0;
  }
}

@keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-webkit-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-moz-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@-o-keyframes headerSpan {
  from {
    top: -16px;
  }

  to {
    top: 0;
  }
}

@keyframes headerHeight {
  from {
    height: 119px;
  }

  to {
    height: 63px;
  }
}

@-webkit-keyframes headerHeight {
  from {
    height: 119px;
  }

  to {
    height: 63px;
  }
}

@-moz-keyframes headerHeight {
  from {
    height: 119px;
  }

  to {
    height: 63px;
  }
}

@-o-keyframes headerHeight {
  from {
    height: 119px;
  }

  to {
    height: 63px;
  }
}

@keyframes headerHeight159 {
  from {
    height: 139px;
  }

  to {
    height: 83px;
  }
}

@-webkit-keyframes headerHeight159 {
  from {
    height: 139px;
  }

  to {
    height: 83px;
  }
}

@-moz-keyframes headerHeight159 {
  from {
    height: 139px;
  }

  to {
    height: 83px;
  }
}

@-o-keyframes headerHeight159 {
  from {
    height: 139px;
  }

  to {
    height: 83px;
  }
}

.hh159-move {
  transition: all 2s;
}

.headertwonav {}

.headertwonav ul {
  display: flex;
  align-items: center;
}

.headertwonav ul li {
  flex: 1;
  padding: 2%;
  text-align: center;
}

.headertwonav ul li img {
  width: 272px;
  height: 182px;
}

.headertwonav ul li .twonavaimg {
  width: 272px;
  height: 182px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.headertwonav ul li .twonavmask {
  position: absolute;
  width: 272px;
  background: #0006;
  left: 0;
  top: 0;
  height: 182px;
  color: #fff;
  line-height: 182px;
}

.headertwonav ul li img.iamg {
  display: block;
}

.headertwonav ul li img.iamgc {
  display: none;
}

.headertwonav ul li.tnavActive img.iamg {
  display: none;
}

.headertwonav ul li.tnavActive img.iamgc {
  display: block;
}

.c-guazai {
  position: fixed;
  bottom: 120px;
  right: 30px;
  z-index: 100000;
  cursor: pointer;
}

.c-guazai img {
  width: 250px;
  height: auto;
}

.c-showhd {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-show-tk {
  width: 1218px;
  height: 823px;
  position: relative;
}

.c-show-pcnew {
  width: 1218px;
  height: 823px;
}

.c-choos {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.c-choos img {
  width: 30px;
  height: 30px;
}

.c-ljzx {
  position: absolute;
  width: 200px;
  height: auto;
  top: 0px;
  /* margin: 0 auto; */
  margin-top: 600px;
  margin-left: 50%;
  left: -100px;
  cursor: pointer;
}

.c-ljzx img {
  width: 200px;
  height: auto;
}

.c-intime-s {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  text-align: center;
  line-height: 30px;
  /* cursor: pointer; */
  color: white;
}

.home_header .language {
  /* margin-left:156px ; */
  position: absolute;
  right: -230px;
  bottom: 20px;
  color: #948d8b;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
}

.home_header .language .isActiveLangeecf {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.activeLanguage {
  position: relative;
  height: 18px;
}

.home_header .language span {
  /* margin: 0 31px; */
  width: 155px;
  /* background: red; */
  display: inline-block;
  /* color: #f08518; */
  text-align: left;
  box-sizing: border-box;
  padding-left: 19px;
}

.languageBack {
  /* width: 100vw; */
  height: 230px;
  /* background-color: #fff; */
  z-index: 100;

  box-shadow: 1px 1px 7px white;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  /* display: flex;
  flex-direction: column;

  align-items: end; */
}

.languageBack ol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.languageBack ol .active {
  color: #f08518;
}

.languageBack ol .active strong {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.languageBack ol li {
  position: relative;
  cursor: pointer;
  width: 155px;
  height: 40px;
  /* margin: ; */
  overflow: hidden;
  margin-right: 122px;
  /* background: red; */
}

.languageBack ol li span {
  display: inline-block;
  width: 113px;
  text-align: left;
  /* margin-left: 40px; */
}

.isheadershow .nav .engUl {
  margin: 25px 0 25px;
}

.nav .engUl {
  width: 850px;
  display: flex;
  padding-left: 34px;
  box-sizing: border-box;
  margin: 25px auto 25px;
}

.nav .engUl li {
  /* flex: 1; */
  text-align: center;
  color: #948d8b;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
  padding-right: 40px;
}

.isheadershow .nav .engUl li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.nav .engUl li.navActive span {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: -16px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}




.isheadershow .nav .itaUl {
  margin: 1.25rem 0 1.25rem;
}

.nav .itaUl {
  width: 850px;
  display: flex;

  margin: 1.25rem auto 1.25rem;
}

.nav .itaUl li {
  /* flex: 1; */
  text-align: center;
  color: #948d8b;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
  padding-right: 48px;
}

.isheadershow .nav .itaUl li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.nav .itaUl li.navActive span {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: -16px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}


.isheadershow .nav .itaUl {
  margin: 1.25rem 0 1.25rem;
}

.nav .itaUl {
  width: 850px;
  display: flex;
  padding-left: 34px;
  box-sizing: border-box;
  margin: 25px auto 25px;
}

.nav .itaUl li {
  /* flex: 1; */
  text-align: center;
  color: #948d8b;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
  padding-right: 40px;
}

.isheadershow .nav .itaUl li.navActive span {
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.nav .itaUl li.navActive span {
  width: 1px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: -16px;
  background: #f08518;
  display: block;
  animation: headerSpan 0.7s;
  -moz-animation: headerSpan 0.7s;
  -webkit-animation: headerSpan 0.7s;
  -o-animation: headerSpan 0.7s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}
</style>
