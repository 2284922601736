<template>
    <div class="product christophBrand">
        <div :class="{ 'jianbianbanner': iscaseBaner }" ref="caseBaner">
            <div><img :src="'https://www.vifa.cn' + lightProduce.banner" alt=""></div>
        </div>

        <div class="jianbianq" :class="{ 'jianbianh': isbrandConcepttitle }" ref="brandConcepttitle">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">{{ lightProduce.titleIta }}</div>
                <p>{{ lightProduce.subtitleIta }}</p>
                <div class="pddingt2"><img :src="'https://www.vifa.cn' + lightProduce.image" alt=""></div>
            </div>
        </div>
        <div class="jianbianq" :class="{ 'jianbianh': issjmx }" ref="sjmx">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">活出格调 引领潮流</div>
                <p>Living in style trendsetting finishes</p>
            </div>
            <div class="spacelun">
                <div class="spacelunclick">
                    <ul>
                        <li v-for="(item, index) in produceColor" :key='index' @click="spacelun(index)"
                            :class="{ 'active': isspacelun == index }">
                            <img :src="'https://www.vifa.cn' + item.smallImage" alt="">
                            <p>{{ item.titleIta }}</p>
                        </li>
                    </ul>
                </div>
                <div class="spacelunimg">
                    <img v-show="isspacelun == index" :src="'https://www.vifa.cn' + item.bigImage" alt=""
                        v-for="(item, index) in produceColor" :key='index'>
                </div>
            </div>
        </div>
        <div class="jianbianq" :class="{ 'jianbianh': iskjmx }" ref="kjmx">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">与更多CL产品一起，打造周致空间</div>
                <p>Different Spaces with Christoph Lights</p>
            </div>
            <div class="tuji">
                <div class="tujiswiper">
                    <ul :style="{ 'width': tujiswiperWideh + 'px', 'marginLeft': tujiswipermLeft + 'px' }" class="tran">
                        <li v-for="(item, index) in produceCompose" :key="index">
                            <div><img :src="'https://www.vifa.cn' + item.image" alt=""></div>
                        </li>
                    </ul>
                </div>
                <div class="newswi">
                    <div class="left" @click="tujiswiperLeft">
                        <p></p>
                    </div>
                    <div class="center">
                        <ul>
                            <li v-for="(item, index) in produceCompose" :key="index" :class="{ 'active': isactive == index }"
                                @click="newzhiindex(index)"><span class="no"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian">
</span><span class="yes"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span></li>
                        </ul>
                    </div>
                    <div class="right" @click="tujiswiperRight">
                        <p></p>
                    </div>
                    <p class="iamgetitle" v-for="(item, index) in produceCompose" :key="index" v-show="isactive == index">
                        {{ item.compose }}</p>
                </div>
            </div>
        </div>
        <div class="jianbianq" :class="{ 'jianbianh': iskjmxaa }" ref="kjmxaa">

            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">智能家居</div>
                <p>Smart Home</p>
            </div>
            <div class="kankan">
                <ul>
                    <li>
                        <img src="@/common/image/maidianicon1.png" alt="">
                        <p>墙壁按钮</p>
                    </li>
                    <li>
                        <img src="@/common/image/maidianicon2.png" alt="">
                        <p>手势控制</p>
                    </li>
                    <li>
                        <img src="@/common/image/maidianicon3.png" alt="">
                        <p>应用程序</p>
                    </li>
                    <li>
                        <img src="@/common/image/maidianicon4.png" alt="">
                        <p>语音控制</p>
                    </li>
                </ul>
            </div>
            <div class="founder spaceCustomization spacexf">
                <p>所有CL设备均配有集成的WIFI/蓝牙模块</p>
                <p>所有CL灯光均配有无线智能照明系统，无需其他附加硬件</p>
                <p>CL提供一个智能墙壁控制按钮，可以对房间或者照明设备进行切换和调光</p>
                <p>所有CL的壁灯还有吊灯都具有一个用于手势控制的集成红外感应器</p>
                <p>智能家居可以由用户自己配搭</p>
                <p>CL智能家居是完全兼容各种类型的声音控制系统</p>
            </div>
        </div>
        <div class="jianbianq" :class="{ 'jianbianh': iskjmxbb }" ref="kjmxbb">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">感受灵动灯光</div>
                <p>Sensual Light</p>
            </div>
            <div class="founder spaceCustomization spacexf">
                <p>在白色LED的可选色温范围内，我们为CL灯选择了3000K，因为该CCT适合住宅和酒店应用。</p>
                <p>显色指数92，高 CRI确保连续光谱/饱和色/自然色/健康肤色</p>
                <p>统一眩光等级（UGR），不适程度：UGR 10=“不易察觉”</p>
                <p>CL-LED驱动技术保证在任何调光水平下无闪烁操作。</p>
            </div>
        </div>




    </div>
</template>

<script>
import spacelun from '@/common/image/spacelun.png'
import spacelunc1 from '@/common/image/spacelunc1.png'
import spacelunc2 from '@/common/image/spacelunc2.png'
import spacelunc3 from '@/common/image/spacelunc3.png'
import tuji from '@/common/image/quality4.png'
import { getProductLightDetails } from "@/api/api";


export default {
    data() {
        return {
            hediaoData: [
                {
                    imgcli: spacelunc1,
                    p: '哑光绒面白',
                    img: spacelun,
                },
                {
                    imgcli: spacelunc2,
                    p: '哑光绒面白',
                    img: spacelun,
                },
                {
                    imgcli: spacelunc3,
                    p: '哑光绒面白',
                    img: spacelun,
                },
            ],
            isspacelun: 0,

            tujiswiperWideh: 925 * 5,
            tujiswipermLeft: -420,
            tujiData: [
                { img: tuji, title: 'c.Jet壁灯+c.Flap射灯' },
                { img: tuji, title: 'c.Jet壁灯+c.Flap射灯' },
                { img: tuji, title: 'c.Jet壁灯+c.Flap射灯' },
                { img: tuji, title: 'c.Jet壁灯+c.Flap射灯' },
            ],
            isactive: 1,
            lightProduce: '',
            produceColor: [],
            produceCompose: [],
            iscaseBaner: false,
            isbrandConcepttitle: false,
            issjmx: false,
            iskjmx: false,
            iskjmxaa: false,
            iskjmxbb: false,
        }
    },
    mounted() {
        this.getProductLightDetailsData()
        window.addEventListener("scroll", this.handleScroll);
        this.iscaseBaner = true
    },
    methods: {
        handleScroll() {
            this.$nextTick(() => {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.iskjmx = true
                }
                if (this.$refs.kjmxaa.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.iskjmxaa = true
                }
                if (this.$refs.kjmxbb.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.iskjmxbb = true
                }

                // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.iskjmx = false
                }
                if (this.$refs.kjmxaa.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.iskjmxaa = false
                }
                if (this.$refs.kjmxbb.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.iskjmxbb = false
                }
            })
            // }
        },
        getProductLightDetailsData() {
            let data = {
                produceId: this.$route.query.id
            }
            getProductLightDetails(data)
                .then(res => {
                    this.lightProduce = res.lightProduce
                    this.produceColor = res.produceColor
                    this.produceCompose = res.produceCompose
                    this.tujiswiperWideh = 925 * this.produceCompose.length
                })
                .catch(res => {
                    console.log(res, 'catch')
                });
        },
        tujiswiperLeft() {
            if (this.tujiswipermLeft <= -420) {
                this.tujiswipermLeft = this.tujiswipermLeft + 925
                this.isactive--
            }
        },
        tujiswiperRight() {
            if (this.tujiswipermLeft >= -this.tujiswiperWideh - this.tujiswipermLeft + 520) {
                this.tujiswipermLeft = this.tujiswipermLeft - 925
                this.isactive++
            }
        },

        newzhiindex(index) {
            this.isactive = index
            this.tujiswipermLeft = 420 - 925 * index
            console.log(index)
        },
        spacelun(index) {
            console.log(index)
            this.isspacelun = index
        }
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll)
    }
}
</script>

<style>
@import '../../../common/styles/productCenter.css';

.pddingt2 img {
    width: 100%;
    margin-top: 0;
}

.spacelunclick {
    width: 600px;
    margin: 90px auto;
}

.spacelunclick ul {
    display: flex;
}

.spacelunclick ul li {
    flex: 1;
}

.spacelunclick ul li.active p {
    color: #f80;
}

.spacelunclick ul li p {
    line-height: 3;
}

.tuji {
    padding-top: 75px;
}

.tujiswiper {
    width: 100%;
    overflow: hidden;
}

.tujiswiper ul {
    overflow: auto;
}

.tujiswiper ul li {
    float: left;
    width: 900px;
    height: 540px;
    padding-right: 25px
}

.tujiswiper ul li img {
    width: 100%;
    height: 540px;
    cursor: pointer;
}

.quality .spaceCustomization p {
    text-align: center;
    padding-bottom: 0;
    line-height: 2;
}

.quality .founder .yishu {
    margin-bottom: 20px;
}

.cangchu {
    padding-top: 100px;
}

.qzh {
    padding-bottom: 50px;
}

.iamgetitle {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: -50px;
}

.kankan {
    width: 600px;
    margin: 50px auto;
}

.kankan ul {
    display: flex;
}

.kankan ul li {
    flex: 1;
}

.kankan ul li p {
    line-height: 3;
}

.spacexf {
    padding: 25px 0;
}</style>
 