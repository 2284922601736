<template>
    <div class="mhome">
        <div class="homelogo homelogohead">
            <!-- <img :src="'https://www.vifa.cn' + productSeries.banner" alt=""> -->
            <img v-for="(item,index) in productSpecific" :key="index" v-show="isqiehuanbt == index" :src="'https://www.vifa.cn' + item.image" alt="">
        </div>


        
        <div v-if="iscpxj">
            <div class="hometopswipt">
                <div class="video" style="padding-bottom:0" :style="{width:xqkd + 'px'}">
                    <div class="homelogos"  v-for="(item,index) in productSpecific" :key="index" @click="qiehuanbt(index)">
                        <img :src="'https://www.vifa.cn' + item.image" alt="">
                    </div>
                </div>
                <!-- <div class="pinpailishi">
                    <div class="pplstext">
                        <div class="title">{{item.title}}</div>
                        <p v-html="item.introduce"></p>
                    </div>
                </div> -->
            </div>
            <div class="pdtitle pwjstrone">{{this.$route.query.ittitle}}</div>
            <div class="shuoming hometopswipt">
                <div class="pinpailishi">
                    <div class="pplstext">
                        <div class="title">{{productSpecifictitle}}</div>
                        <p>{{productSpecificintroduce}}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="hometopswipt">
            <!-- <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">{{this.$route.query.title}}</div>
                </div>
            </div> -->
            <div class="cpxjbut">
                <ul>
                    <!-- <li class="qtiele">{{this.$route.query.title}}</li> -->
                    <li :class="{'avtive':iscxiavtive}" v-show="isshowcxiavtive" @click="qirhuan">Materiale e colore del pannello della porta</li>
                </ul>
            </div>
            <!-- <div class="xiaotop"></div> -->
        </div>


        
        <div v-if="isyanse">
            <div class="hometopswipt">
                <div class="pinpailishi">
                    <div class="pplstext">
                        <div class="title pwjstrone">Più pannelli, più scelte.</div>
                    </div>
                </div>
            </div>
            <div class="yanse">
                <div v-for="(item,index) in productMaterial" :key='index' >
                    <div v-if="item.materialList !==undefined && item.materialList != null  && item.materialList.length > 0">
                        <div class="cfkjystitle">
                            <p></p>
                            <div class="myp">{{item.titleIta}}</div>
                        </div>
                        <div class="cfkjcont">
                            <ul>
                                <li v-for="(colorList,colorListIndex) in item.materialList" :key='colorListIndex' @click="cfkjyscont(index,colorListIndex)" :class="{'active':colorListIndex == productMaterial[index].showItem}">
                                    <img :src="'https://www.vifa.cn' + colorList.image" alt="">
                                    <p class="myp">{{colorList.titleIta}}</p>
                                </li>
                            </ul>
                            <div class="cfkjysbigimg" >
                                <img v-for="(colorList,colorListIndex) in item.materialList" :key='colorListIndex' :src="'https://www.vifa.cn' + colorList.image" alt="" v-show="colorListIndex ==  productMaterial[index].showItem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="subnav" >
            <!-- <transition-group name="pinpailishiani"> -->
                <div @click="morekjdz" :class="{isshow:isshow}">{{morekj}}</div>
                <ul v-show="isshowsubnav">
                    <li v-for="(item,index) in kjdzallData" :key="index" :class="{'avtive':isclick == index}" @click="subnavqiehuan(index)">
                        <img class="icon" :src=item.icon alt="">
                        <img class="icon_active" :src=item.icon_active alt="">
                        <p>{{item.name}}</p>
                    </li>
                    <li></li>
                </ul>
            <!-- </transition-group> -->
        </div>
        <div class="mask" @click="morekjdz"  v-show="isshowsubnav"></div>
    </div>
</template>
 
<script>
import sofa from '@/common/image/sofa.png'
import bed from '@/common/image/bed.png'
import kitchen from '@/common/image/kitchen.png'
import dinner from '@/common/image/dinner.png'
import clothes from '@/common/image/clothes.png'
import bath from '@/common/image/bath.png'
import book from '@/common/image/book.png'
import sofa_active from '@/common/image/sofa_active.png'
import bed_active from '@/common/image/bed_active.png'
import kitchen_active from '@/common/image/kitchen_active.png'
import dinner_active from '@/common/image/dinner_active.png'
import clothes_active from '@/common/image/clothes_active.png'
import bath_active from '@/common/image/bath_active.png'
import book_active from '@/common/image/book_active.png'
import {getProductDetails} from '@/api/api'
export default {
    data(){
        return{
            productSeries:'',
            productSpecific:[],
            productMaterial:[],
            iscpxj:true,
            isyanse:false,
            lastclick:'',
            xijienav:[
                {nav:'产品细节'},
                {nav:'门板材质及颜色'},
            ],
            iscxiavtive:false,
            kjdzallData:[
                {
                    icon:sofa,
                    icon_active:sofa_active,
                    name:'Soggiorno',
                },
                {
                    icon:bed,
                    icon_active:bed_active,
                    name:'Camera da letto',
                },
                {
                    icon:kitchen,
                    icon_active:kitchen_active,
                    name:'Cucina',
                },
                {
                    icon:dinner,
                    icon_active:dinner_active,
                    name:'Sala da pranzo',
                },
                {
                    icon:clothes,
                    icon_active:clothes_active,
                    name:'Guardaroba',
                },
                {
                    icon:bath,
                    icon_active:bath_active,
                    name:'Bagno',
                },
                {
                    icon:book,
                    icon_active:book_active,
                    name:'Libreria',
                }
            ],
            morekj:'Più spazio',
            isshowsubnav:false,
            isshow:false,
            isclick:1,
            xqkd:0,
            productSpecifictitle:'',
            productSpecificintroduce:'',
            isqiehuanbt:0,
            isshowcxiavtive:true,
            biashi:0
        }
    },
    mounted(){
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mproductDtails?title='+this.$route.query.title+"&navtype="+this.$route.query.navtype+"&entitle="+this.$route.query.entitle+"&ittitle="+this.$route.query.ittitle);
        } else {
            this.$router.replace(paths+'/productDtails?title='+this.$route.query.title+'&SpaceActiv'+this.$route.query.SpaceActiv);
        }
        this.getHomePageData()
        // this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
        if (this.$route.query.navtype) {
            this.isclick = this.$route.query.navtype
        }
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getHomePageData(){
            let data = {
                productName:encodeURIComponent(this.$route.query.title)
            }
            getProductDetails(data)
            .then(res => {
                this.productSeries = res.productSeries
                this.productSpecific =res.productSpecific
                this.productMaterial = res.productMaterial
                this.xqkd = res.productSpecific.length * (document.body.clientWidth/3.5)
                this.productSpecifictitle = res.productSpecific[0].titleIta
                this.productSpecificintroduce = res.productSpecific[0].introduceIta
                if (this.productMaterial == undefined ||this.productMaterial == null || this.productMaterial.length <= 0) {
                    this.isshowcxiavtive = false
                }else{
                    this.isshowcxiavtive = true
                }
                for (let i of this.productMaterial) {
                    i.showItem = 999
                    console.log(i)
                    if (i.materialList == undefined ||i.materialList == null || i.materialList.length <= 0) {
                        if (this.biashi == 0) {
                            this.isshowcxiavtive = false
                        }
                    }else{
                        this.isshowcxiavtive = true
                        this.biashi = 1
                    }
                }
            })
            .catch(res => {
                console.log(res)
            });
        },
        cfkjyscont(preindex,index){
            if (this.lastclick != preindex) {
                this.productMaterial[this.lastclick].showItem = 9999
            }
            this.lastclick = preindex
            this.productMaterial[preindex].showItem = index
        },
        qirhuan(){
            this.iscxiavtive = true
            // if (index==0) {
            //     this.iscpxj = true
            //     this.isyanse = false
            // }else if (index==1) {
            //     this.iscpxj = false
                this.isyanse = true
            // }
        },
        qiehuanbt(index){
            this.isqiehuanbt = index
            this.productSpecifictitle = this.productSpecific[index].title
            this.productSpecificintroduce = this.productSpecific[index].introduceIta
        },


        
        // getHomePageData(){
        //     getProductCenter()
        //     .then(res => {
        //         this.productCenterBanner = [
        //             {image:res.productCenterBanner.mqImage,title:'门墙系统',subtitle:'Door & Wall'},
        //             {image:res.productCenterBanner.kjdzImage,title:'空间定制',subtitle:'Space Customization'},
        //             {image:res.productCenterBanner.djjImage,title:'大家居',subtitle:'Lighting & Furniture'},
        //         ]
        //         this.seriesList = res.seriesList
        //         this.spacelist = res.seriesList[0].room_space
        //         this.seriesIntroduce = res.seriesIntroduce
        //         this.introduce = res.seriesIntroduce.roomSpace
        //     })
        //     .catch(res => {
        //         console.log(res)
        //     });
        // },
        // kjshow(index){
        //     this.kjchangex = this.kjstartx
        //     this.kjmarginLeft = -document.body.clientWidth*(index)
        //     console.log(this.kjmarginLeft,index)
        // },
        // kjtouchstart(e){
        //     this.kjstartx = e.touches[0].clientX
        // },
        // kjtouchmove(e){
        //     if(this.kjchangex == this.kjstartx){
        //         return
        //     }
        //     this.kjmovex = e.touches[0].clientX
        //     let kjleftslide = this.kjstartx - this.kjmovex
        //     console.log(this.iskjshow)
        //     if(kjleftslide > 50 && this.iskjshow < this.productCenterBanner.length - 1){
        //         this.kjshow(++this.iskjshow)
        //     }else if(kjleftslide < -50 && this.iskjshow > 0){
        //         this.kjshow(--this.iskjshow)
        //     }
        // },
        subnavqiehuan(index){
            this.isclick = index
            this.producttiel = this.kjdzallData[index].name
            this.isshowsubnav = false
            this.isshow = false
            this.morekj='更多空间'
            window.scroll(0,0)
            this.$router.push({path:'/mProductCenter',query:{navid:index}})
        },
        morekjdz(){
            if (this.isshowsubnav) {
                this.isshowsubnav = false
                this.morekj='更多空间'
                this.isshow = false
            }else{
                this.isshowsubnav = true
                this.morekj=''
                this.isshow = true
            }
        }
        
    },
}
</script>

<style scoped>
.homelogo{
    width: 560px;
    margin: 0 auto 18px;
}
.homelogo img{
    width: 100%;
}
.homelogohead{
    width: 750px;
}
.mhome{
    padding-bottom: 40px;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}
.pinpailishi{
    position: relative;
}
.pinpailishi .title{
    font-size: 45px;
    padding: 30px 0 0px 0;
}
.shuoming .title{
    font-size: 26px;
    /* padding: 30px 0 0px 0; */
    font-weight: bold;
    text-align: left;
    padding-top: 0;

}
.cpxjbut{
    margin-top: 30px;
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 10px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    height: 492px;
    /* padding: 20px 0; */
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -247.5px; 
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px; 
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}
.bottomtitle .showtitle p{
    text-align: center;
}
.bottomtitle .showtitle div{
    font-size: 45px;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #fff;
}
.quanpin .pplsimg{
    height: 447px;
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.producttiel{
    background: #d2cdca;
    padding-top: 1px;
    padding-bottom: 40px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    /* background: #fff; */
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav ul{
    width: 100%;
    display: flex;
    /* padding-top: 20px; */
    flex-wrap: wrap;
    background: #000000cc;
    color: #fff;
}
.subnav>div{
    background: #000000cc;
    color: #fff;
}
.subnav>div.isshow{
    width: 350px;
    height: 0;
    margin: 0  auto;
}
.subnav ul li{
    flex: 1 187.5px;
    text-align: center;
    font-size: 26px;
    /* color: #fff; */
    line-height: 2;
    margin-bottom: 20px;
    /* border-top: 1px solid #fff; */
    padding-top: 30px;
}
.subnav ul li.avtive{
    color: #f08518;
}
.subnav ul li p{}
.subnav ul li img{
    width: 71px;
    height: 55px;
    margin: 0 auto;
}
.subnav ul li.avtive .icon{
    display: none;
}
.subnav ul li.avtive .icon_active{
    display: block;
}
.subnav ul li .icon_active{
    display: none;
}
.subnav ul li .icon{
    display: block;
}
.productcont{
    padding-bottom: 40px;
    line-height: 2;
    width: 750px;
    text-align: center;
}
.productcont img{
    width: 100%;
}
.productcont p{
    font-size: 26px;    
    line-height: 88px;
}
.productcont div{
    font-size: 26px;
    width: 225px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    /* color: #fff; */
    /* background: #f08518; */
    line-height: 65px;
}
.avtive{
    color: #f08518;
    border-color: #f08518;
}
.cpxjbut{}
.cpxjbut ul{
    /* display: flex; */
    width: 650px;
    margin: 0 auto;
    justify-content: space-between;
    overflow:hidden;
}
.cpxjbut ul li{
    line-height: 70px;
    border: 1px solid;
    text-align: center;
    width: 550px;
    font-size: 26px;
    float: left;
}
.cpxjbut ul li.qtiele{
    border: none;
    font-size: 40px;
    text-align: left;
    float: left;
    width: 342px;
}
.cpxitu{
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
}
.cpxitu img{
    width: 100%;
    margin-top: 20px;
}
.cfkjystitle{
    width: 100%;
    position: relative;
    line-height: 41px;
}
.cfkjystitle p{
    width: 100%;
    border-bottom: 1px solid #000;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -.5px;
    z-index: -22;
}
.cfkjystitle div{
    background: #fff;
    margin: 0 auto;
    display: inline-block;
    padding: 0 50px;
}
.yanse>ul{
    padding: 20px 0;
    font-size: 18px;
}
.yanse{}
.yanse ul{
    width: 750px;
    display: flex;
    flex-wrap: wrap;
}
.yanse ul li{
    width: 130px;
    padding: 10px;
}
.yanse ul li img{
    width: 105px;
    height: 105px;
}
.yanse ul li p{
    padding: 10px 0;
    font-size: 22px;
}
.cfkjcont ul li{
    flex: none;
}
.cfkjcont{
    width: 750px;
}
.mask{
    width: 100%;
    height: 100%;
    background: #00000069;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}
.hometopswipt{
    padding-bottom: 30px;
    width: 100%;
    overflow: scroll;
}
::-webkit-scrollbar{      display:none    }
.hometopswipt .homelogo{
    margin-bottom: 0;
}
.yanse>div{
    padding-bottom: 100px;
}
.homelogos{
    width: 200px;
    padding-right: 18px;
}
.homelogos img{
    width: 100%;
}
.video{
    display: flex;
    overflow: scroll;
    padding-left: 18px;
}
.pdtitle{
    font-size: 45px;
    text-align: left;
    line-height: 1.8;
    width: 670px;
    margin: 50px auto 50px;
}
.cfkjcont ul li  .myp{
    line-height: 35px;
}
</style> 