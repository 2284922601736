<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + qualityService.mobileBanner" alt="" />
      <div class="pwjstrone">{{ qualityService.titleIta }}</div>
    </div>
    <div class="hometopswipt">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div
          class="pplstext textleft"
          v-html="qualityService.qualityTextIta"
        ></div>
      </div>
      <div class="video">
        <video
          poster="@/common/image/poter4.jpg"
          :src="'https://www.vifa.cn' + qualityService.qualityVideo"
          controls="controls"
          preload="preload"
          loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
    <div class="hometopswipt">
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title pbl pwjstrone">Famosi fornitori europei<br />Fornire materiali domestici di alta qualità</div>
          <div class="subtitle">BRAND RARTNER</div>
        </div>
      </div>
      <div class="video">
        <video
          poster="@/common/image/poter5.jpg"
          :src="'https://www.vifa.cn' + qualityService.supplierVideo"
          controls="controls"
          preload="preload"
          loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
    <div class="hometopswipt">
      <div class="pinpailishi">
        <div
          class="pplstext textleft"
          v-html="qualityService.baseTextOneIta"
        ></div>
      </div>
      <div class="video" style="padding-bottom: 0">
        <div class="homelogo mbl pwjstrone">
          <img :src="'https://www.vifa.cn' + qualityService.baseImage" alt="" />
        </div>
      </div>
    </div>

    <div class="hometopswipt">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div
          class="pplstext textleft"
          v-html="qualityService.baseTextTwoIta"
        ></div>
      </div>
      <div class="pinpailishi">
        <div class="pplsimg">
          <transition-group name="pinpailishiani">
            <ul
              :style="{
                marginLeft: pplsmarginLeft + 'px',
                width: pplswidth + 'px',
              }"
              ref="pplsul"
            >
              <li
                :ref="'pplsli' + index"
                v-for="(item, index) in vifaBase"
                :key="index"
                @touchstart="pplstouchstart"
                @touchmove="pplstouchmove"
              >
                <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
              </li>
            </ul>
          </transition-group>
        </div>
        <div class="bottomtitle pzbtit">
          <div
            class="showtitle"
            v-for="(item, index) in vifaBase"
            :key="index"
            v-show="ishbishow == index"
          >
            <p>{{ item.titleIta }}</p>
          </div>
          <div class="toleft" @click="pzdbl">
            <span v-show="ispzdbl"></span>
          </div>
          <div class="toright" @click="pzdbr">
            <span v-show="ispzdbr"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="subnav">
      <router-link to="" class="active">QUALITÀ</router-link>
      <router-link to="/It/mAndService">SERVIZI</router-link>
    </div>
  </div>
</template>

<script>
import { getQualityService } from "@/api/api";
export default {
  data() {
    return {
      // aboutVifaBanner:{},
      vifaBase: [],
      shmxiamgData: [],
      ishbishow: 1,
      pplsstartx: 0,
      pplsmovex: 0,
      pplschangex: 0,
      pplsmarginLeft: "",
      pplswidth: 0,
      pplsliwidth: 0,
      pplsuloffsetLeft: 0,
      qualityService: {},
      ispzdbl: true,
      ispzdbr: true,
    };
  },
  components: {
    // Mzixun
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mQuality");
    } else {
      this.$router.replace(paths + "/quality");
    }
    setTimeout(() => {
      this.$nextTick(() => {
        this.pplsliwidth = this.$refs.pplsli0.clientWidth;
        this.pplswidth =
          (this.$refs.pplsli0.clientWidth + 1) * this.vifaBase.length;
        this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft;
        this.pplsmarginLeft = this.pplsuloffsetLeft;
        this.kjwidth =
          document.body.clientWidth * this.productCenterBanner.length;
      });
    }, 1000);
    this.getPageData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPageData() {
      getQualityService()
        .then((res) => {
          this.qualityService = res.qualityService;
          this.vifaBase = res.vifaBase;
        })
        .catch((res) => {
          console.log(res);
        });
    },

    show(index) {
      this.pplschangex = this.pplsstartx;
      this.pplsmarginLeft =
        this.pplsuloffsetLeft - this.pplsliwidth * (index - 1);
      if (this.ishbishow == 0) {
        this.ispzdbl = false;
        this.ispzdbr = true;
      } else if (this.ishbishow == this.vifaBase.length - 1) {
        this.ispzdbl = true;
        this.ispzdbr = false;
      } else {
        this.ispzdbl = true;
        this.ispzdbr = true;
      }
    },
    pplstouchstart(e) {
      this.pplsstartx = e.touches[0].clientX;
    },
    pplstouchmove(e) {
      if (this.pplschangex == this.pplsstartx) {
        return;
      }
      this.pplsmovex = e.touches[0].clientX;
      let leftslide = this.pplsstartx - this.pplsmovex;
      console.log(this.ishbishow);
      if (leftslide > 30 && this.ishbishow < this.vifaBase.length - 1) {
        this.show(++this.ishbishow);
      } else if (leftslide < -30 && this.ishbishow > 0) {
        this.show(--this.ishbishow);
      }
    },
    pzdbl() {
      if (this.ishbishow > 0) {
        this.show(--this.ishbishow);
      }
    },
    pzdbr() {
      if (this.ishbishow < this.vifaBase.length - 1) {
        this.show(++this.ishbishow);
      }
    },
  },
};
</script>

<style scoped>
.mhome {
  font-size: 26px;
}
.homelogo {
  width: 750px;
  position: relative;
  margin-bottom: 100px;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 35px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 35px;
}
.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto;
}
.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
}
.textleft {
  text-align: left;
}
.pinpailishi .subtitle {
  font-size: 30px;
}
.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: justify;
}
.pplsimg {
  width: 100%;
  height: 357px;
  padding: 20px 0;
  overflow: hidden;
}
.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -642px;
}
.pinpailishi ul li {
  width: 635px;
  height: 357px;
  flex: 1;
  padding: 0 20px;
}
.pinpailishi ul li img {
  width: 635px;
  height: 357px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  /* color: #f08518; */
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}
.bottomtitle .showtitle {
  margin: 0 auto;
  width: 470px;
 

}
.bottomtitle .showtitle p {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: 0;
  top: 26px;
}
.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: 0;
  top: 26px;
}
.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}
.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}
.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #4c413d;
}
.quanpin .pplsimg {
  height: 447px;
}
.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}
.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}
.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 40px auto;
}
.input input {
  width: 100%;
  line-height: 2;
  border: 1px solid #4c413d;
  text-align: center;
}

.bannertop,
.ppfzlist {
  width: 670px;
  font-size: 26px;
  line-height: 2;
  text-align: justify;
  margin: 40px auto;
}
.ppfzlist img {
  width: 100%;
}
.ppfzlist div {
  font-size: 32px;
  font-weight: bold;
  padding-top: 26px;
}
.video {
  padding: 40px 0;
}
.ppjjsjmx {
  background: url("../../../common/image/sjmxbg.png") 0 0 no-repeat;
  background-size: 100% 100%;
  padding-top: 10px;
  padding-bottom: 60px;
}
.shmx {
  background: #d2cdca;
  margin-top: 0;
  padding: 10px 0;
}
.subnav {
  width: 750px;
  font-size: 26px;
  line-height: 100px;
  /* background: #fff; */
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #000000cc;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav a.active {
  color: #f08518;
}
.pzbtit .showtitle p {
  line-height: 2;
  padding-bottom: 40px;
}
.pinpailishi .pbl {
  padding-bottom: 0;
}
.mbl {
  margin-bottom: 0;
}
.hometopswipt {
  padding-bottom: 150px;
}
</style>
