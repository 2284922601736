<template>
    <div class="mhome">
        <div class="hometopswipt quanpin">
            <div class="pinpailishi">
                <div class="pplsimg">
                    <transition-group name="pinpailishiani">
                        <ul :style="{marginLeft:kjmarginLeft + 'px',width:kjwidth+'px'}">
                            <li v-for="(item, index) in productCenterBanner" :key="index" ><img :src="'https://www.vifa.cn' + item.image" alt=""></li>
                        </ul>
                    </transition-group>
                </div>
                <div class="bottomtitle">
                    <div class="showtitle" v-for="(item, index) in productCenterBanner" :key="index" v-show="iskjshow ==index">
                        <router-link :to=item.link>
                            <div class="pwjstrone">{{item.subtitle}}</div>
                            <!-- <p>{{item.subtitle}}</p> -->
                        </router-link>
                    </div>
                    <!-- <div class="toleft"><span></span></div>
                    <div class="toright"><span></span></div> -->
                </div>
            </div>
        </div>
        <!-- <div class="twonav">
            <ul>
                <li class="active"><router-link to="">门墙系统</router-link></li>
                <li><router-link to="mProductCenter">空间系统</router-link></li>
                <li><router-link to="mchristophBrand">大家居</router-link></li>
            </ul>
        </div> -->
        
        
        <div class="hometopswipt" v-for="(item,index) in productDoorwall" :key="index">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title pwjstrone">{{item.titleEng}}</div>
                    <p v-html="item.doorwallTextEng"></p>
                </div>
            </div>
            <div class="video" style="padding-bottom:0">
                <div class="homelogo">
                    <img :src="'https://www.vifa.cn' + item.mobileImage" alt="">
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import {getProductCenter} from '@/api/api'
export default {
    data(){
        return{
            productCenterBanner:[],
            kjmarginLeft:-document.body.clientWidth,
            kjwidth:0,
            iskjshow:1,
            kjstartx:0,
            kjmovex:0,
            kjchangex:0,
            productDoorwall:[],
        }
    },
    mounted(){
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mdoorwallsystem');
        } else {
            this.$router.replace(paths+'/doorwallsystem');
        }
        this.getHomePageData()
        this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
        
    },

    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getHomePageData(){
            getProductCenter()
            .then(res => {
                this.productCenterBanner = [
                    {image:res.productCenterBanner.mobileDjjImage,title:'大家居',subtitle:'Lighting & Furniture',link:'/mchristophBrand'},
                    {image:res.productCenterBanner.mobileMqImage,title:'门墙系统',subtitle:'Door & Wall',link:'/mdoorwallsystem'},
                    {image:res.productCenterBanner.mobileKjdzImage,title:'空间定制',subtitle:'Space Customization',link:'/mProductCenter'},
                ]
                this.productDoorwall = res.productDoorwall
            })
            .catch(res => {
                console.log(res)
            });
        },
        kjshow(index){
            this.kjchangex = this.kjstartx
            this.kjmarginLeft = -document.body.clientWidth*(index)
            console.log(this.kjmarginLeft,index)
        },
        kjtouchstart(e){
            this.kjstartx = e.touches[0].clientX
        },
        kjtouchmove(e){
            if(this.kjchangex == this.kjstartx){
                return
            }
            this.kjmovex = e.touches[0].clientX
            let kjleftslide = this.kjstartx - this.kjmovex
            console.log(this.iskjshow)
            if(kjleftslide > 30 && this.iskjshow < this.productCenterBanner.length - 1){
                this.kjshow(++this.iskjshow)
            }else if(kjleftslide < -30 && this.iskjshow > 0){
                this.kjshow(--this.iskjshow)
            }
        },
        
    },
}
</script>

<style scoped>
.mhome{
    padding-bottom: 40px;
}
.homelogo{
    width: 670px;
    margin: 0 auto;

}
.homelogo img{
    width: 100%;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}
.pinpailishi{
    position: relative;
}
.pinpailishi .title{
    font-size: 45px;
    padding: 20px 0;
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    height: 492px;
    /* padding: 20px 0; */
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -247.5px; 
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px; 
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}
.bottomtitle .showtitle p{
    text-align: center;
    color: #fff;
}
.bottomtitle .showtitle div{
    font-size: 45px;
    color: #fff;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #fff;
}
.quanpin .pplsimg{
    height: 447px;
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.producttiel{
    background: #d2cdca;
    padding-top: 1px;
    padding-bottom: 40px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #d2cdca;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav ul{
    width: 100%;
    display: flex;
    padding-top: 20px;
    flex-wrap: wrap;
}
.subnav ul li{
    flex: 1 187.5px;
    text-align: center;
    font-size: 26px;
    color: #fff;
    line-height: 2;
    margin-bottom: 20px;
}
.subnav ul li.avtive{
    color: #f08518;
}
.subnav ul li p{}
.subnav ul li img{
    width: 71px;
    height: 55px;
    margin: 0 auto;
}
.subnav ul li.avtive .icon{
    display: none;
}
.subnav ul li.avtive .icon_active{
    display: block;
}
.subnav ul li .icon_active{
    display: none;
}
.subnav ul li .icon{
    display: block;
}
.productcont{
    padding-bottom: 40px;
    line-height: 2;
    width: 750px;
    text-align: center;
}
.productcont img{
    width: 100%;
}
.productcont p{
    font-size: 26px;    
    line-height: 88px;
}
.productcont div{
    font-size: 26px;
    width: 225px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    /* color: #fff; */
    /* background: #f08518; */
    line-height: 65px;
}.twonav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
}
.twonav ul{
    width: 100%;
    display: flex;
}
.twonav ul li{
    flex: 1;
}
.twonav ul li.active a{
    color: #f08518;
}
.hometopswipt{
    padding-bottom: 150px;
}
/* .video{
    padding: 60px 0;
} */
</style> 