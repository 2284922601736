<template>
  <div>
    <div class="newdetail">
      <div class="aboutBaner">
        <img src="@/common/image/aboutbanner.jpg" alt="" />
        <p class="pwjstrone">VIFA Personalizzazione, Grande casa al minimalismo</p>
      </div>
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing pwjstrone">{{ object.titleIta }}</div>
        <div class="newdetailtime">{{ object.publishDate }}</div>
      </div>
      <div class="newcont">
        <div v-html="object.contentIta" class="newcontnei" ref="object"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnterpriseNews } from "@/api/api";
import {
  getEnterpriseDynamic,
  saveNewCaCheContent,
  saveDynamicCaCheContent,
} from "@/api/api";

import usePoint from "@/hooks/usePoint.js";
export default {
  name: "",
  data() {
    return {
      object: {},
    };
  },
  components: {},
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(
        paths +
        "/mnewDetails?id=" +
        this.$route.query.id +
        "&isnew=" +
        this.$route.query.isnew
      );
    } else {
      this.$router.replace(
        paths +
        "/newDetails?id=" +
        this.$route.query.id +
        "&isnew=" +
        this.$route.query.isnew
      );
    }
    let data = {
      id: this.$route.query.id,
    };
    if (this.$route.query.isnew == 0) {
      this.getnewsData(data);
    } else {
      this.getEnterprissData(data);
    }
  },
  methods: {
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    getText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "\n")
        .split("\n")
        .filter((v) => v.trim());
      let rgx = /&nbsp;/;
      words.forEach((item, index) => {
        // words[index]=item.replace(/&nbsp;/gi, " ")
      });
      console.log(words.join())
      return words;
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    async getFanyiArr(arr) {
      let newArr = [];
      for (let index = 0; index < arr.length; index++) {
        newArr.push(
          (await usePoint.getTranslation(arr[index], "it")).data
            .trans_result[0].dst
        );
      }

      return newArr;
    },

    async getnewsData(data) {
      let res = await getEnterpriseNews(data);


      if (res.object.contentIta && res.object.contentIta != "") {
        res.object.contentIta = res.object.contentIta;
        res.object.contentIta = res.object.contentIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");
        res.object.titleIta = res.object.titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");
        res.object.topicIta = res.object.topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");
        res.object.topicIta = res.object.topicIta.replace(/&quot;/g, " ")
        res.object.titleIta = res.object.titleIta.replace(/&quot;/g, " ")

        res.object.contentIta = res.object.contentIta.replace(/&quot;/g, " ")
      
      } else {
        // 正文数组
        let arr = this.getText(res.object.content);

        let newArr = await this.getFanyiArr(arr);

        for (let index = 0; index < arr.length; index++) {
          res.object.content = res.object.content.replace(
            arr[index],
            newArr[index]
          );
        }
        res.object.titleIta = (
          await usePoint.getTranslation(res.object.title, "it")
        ).data.trans_result[0].dst;





        res.object.topicIta = (
          await usePoint.getTranslation(res.object.topic, "it")
        ).data.trans_result[0].dst;
        res.object.content = res.object.content.replace(
          /& nbsp;|&nbsp;|切割/g,
          "&nbsp;"
        );





        res.object.titleIta = res.object.titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");

        res.object.topicIta = res.object.topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");

        res.object.topicIta = res.object.topicIta.replace(/&quot;/g, " ")
        res.object.titleIta = res.object.titleIta.replace(/&quot;/g, " ")


        
        res.object.content = res.object.content.replace(/"/g, "'");


        res.object.content = res.object.content.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");
        res.object.content = res.object.content.replace(/&quot;/g, " ")
        res.object.contentIta = res.object.content;
        await saveNewCaCheContent({
          objId: res.object.id,
          titleEng: res.object.titleEng,
          topicEng: res.object.topicEng,
          titleIta: res.object.titleIta,
          topicIta: res.object.topicIta,
          contentEng: encodeURIComponent(res.object.contentEng),
          contentIta: encodeURIComponent(res.object.content),
        });
      }
      this.object = res.object;

      let str = "video";
      let indext = res.object.content.indexOf(str);
      if (indext != -1) {
        res.object.content =
          res.object.content.slice(0, indext - 0 + 5) +
          " autoplay" +
          res.object.content.slice(indext - 0 + 5);
        this.object = res.object;
      }
    },

    async getEnterprissData(data) {
      let res = await getEnterpriseDynamic(data);


      if (res.object.contentIta && res.object.contentIta != "") {
        res.object.contentIta = res.object.contentIta;
        res.object.contentIta = res.object.contentIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");

        res.object.titleIta = res.object.titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");

        res.object.topicIta = res.object.topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");
        res.object.topicIta =res.object.topicIta.replace(/&quot;/g, " ")
        res.object.titleIta =res.object.titleIta.replace(/&quot;/g, " ")
        res.object.contentIta =res.object.contentIta.replace(/&quot;/g, " ")
      } else {
        // 正文数组
        let arr = this.getText(res.object.content);

        let newArr = await this.getFanyiArr(arr);

        for (let index = 0; index < arr.length; index++) {
          res.object.content = res.object.content.replace(
            arr[index],
            newArr[index]
          );
        }
        res.object.titleIta = (
          await usePoint.getTranslation(res.object.title, "it")
        ).data.trans_result[0].dst;
        res.object.topicIta = (
          await usePoint.getTranslation(res.object.topic, "it")
        ).data.trans_result[0].dst;
        res.object.content = res.object.content.replace(
          /& nbsp;|&nbsp;|切割/g,
          "&nbsp;"
        );


        res.object.titleIta = res.object.titleIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");

        res.object.topicIta = res.object.topicIta.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa");



        res.object.topicIta = res.object.topicIta.replace(/&quot;/g, " ")

        res.object.titleIta = res.object.titleIta.replace(/&quot;/g, " ")

        res.object.content = res.object.content.replace(/"/g, "'");
        res.object.content =res.object.content.replace(/&quot;/g, " ")
        res.object.contentIta = res.object.content;
   
        await saveDynamicCaCheContent({
          objId: res.object.id,
          titleEng: res.object.titleEng,
          topicEng: res.object.topicEng,
          titleIta: res.object.titleIta,
          topicIta: res.object.topicIta,
          contentEng:encodeURIComponent(res.object.contentEng) ,
          contentIta: encodeURIComponent(res.object.content),
        });
      }

      this.object = res.object;
    },
  },
};
</script>

<style>
.newdetail .founder .yishu {
  margin-top: 50px;
  height: 35px;
}

.newdetail .founder .beijing {
  font-size: 30px;
  padding-top: 35px;
  line-height: 40px;
}

.newdetailtime {
  color: #b2aeac;
  padding-bottom: 35px;
}

.newcont {
  text-align: justify;
}

.newcontnei {
  width: 1200px;
  margin: 0 auto;
}

.newcontnei p {
  padding-bottom: 30px;
  line-height: 2;
}

.newcont img {
  width: 100%;
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.newcontnei p.navtit {
  font-size: 25px;
}
</style>
