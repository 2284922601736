<template>
    <div class="mhome">
        <div class="hometopswipt quanpin">
            <div class="pinpailishi">
                <div class="pplsimg">
                    <transition-group name="pinpailishiani">
                        <ul :style="{ marginLeft: kjmarginLeft + 'px', width: kjwidth + 'px' }">
                            <li v-for="(item, index) in productCenterBanner" :key="index"><img
                                    :src="'https://www.vifa.cn' + item.image" alt=""></li>
                        </ul>
                    </transition-group>
                </div>
                <div class="bottomtitle">
                    <div class="showtitle" v-for="(item, index) in productCenterBanner" :key="index"
                        v-show="iskjshow == index">
                        <router-link :to=item.link>
                            <div>{{ item.title }}</div>
                            <p>{{ item.subtitle }}</p>
                        </router-link>
                    </div>
                    <!-- <div class="toleft"><span></span></div>
                    <div class="toright"><span></span></div> -->
                </div>
            </div>
        </div>
        <!-- <div class="twonav">
            <ul>
                <li><router-link to="mdoorwallsystem">门墙系统</router-link></li>
                <li class="active"><router-link to="">空间系统</router-link></li>
                <li><router-link to="mchristophBrand">大家居</router-link></li>
            </ul>
        </div> -->
        <div class="hometopswipt producttiel">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">{{ producttiel }}</div>
                    <p v-html="introduce"></p>
                </div>
            </div>
        </div>
        <div class="productcont" v-for="(item, index) in spacelist" :key="index">
            <img :src="'https://www.vifa.cn' + item.mobileImage" alt="">
            <p>{{ item.productName }}</p>
            <!-- <div @click="todtails(item.productName)" style="border: 1px solid #4c413d">了解更多</div> -->
            <div @click="todtails(item.productName, item.productNameEng, item.productNameIta)"
                style="border:1px solid #4c413d4d">了解更多</div>
        </div>

        <div class="subnav">
            <!-- <transition-group name="pinpailishiani"> -->
            <div @click="morekjdz" :class="{ isshow: isshow }">{{ morekj }}</div>
            <ul v-show="isshowsubnav">
                <li v-for="(item, index) in kjdzallData" :key="index" :class="{ 'avtive': isclick == index }"
                    @click="subnavqiehuan(index)">
                    <img class="icon" :src=item.icon alt="">
                    <img class="icon_active" :src=item.icon_active alt="">
                    <p>{{ item.name }}</p>
                </li>
                <li></li>
            </ul>
            <!-- </transition-group> -->
        </div>
        <div class="mask" @click="morekjdz" v-show="isshowsubnav"></div>
    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import bed from '@/common/image/bed.png'
import kitchen from '@/common/image/kitchen.png'
import dinner from '@/common/image/dinner.png'
import clothes from '@/common/image/clothes.png'
import bath from '@/common/image/bath.png'
import book from '@/common/image/book.png'
import sofa_active from '@/common/image/sofa_active.png'
import bed_active from '@/common/image/bed_active.png'
import kitchen_active from '@/common/image/kitchen_active.png'
import dinner_active from '@/common/image/dinner_active.png'
import clothes_active from '@/common/image/clothes_active.png'
import bath_active from '@/common/image/bath_active.png'
import book_active from '@/common/image/book_active.png'
import { getProductCenter } from '@/api/api'
export default {
    data() {
        return {
            kjdzallData: [
                {
                    icon: sofa,
                    icon_active: sofa_active,
                    name: '客厅空间',
                },
                {
                    icon: bed,
                    icon_active: bed_active,
                    name: '卧室空间',
                },
                {
                    icon: kitchen,
                    icon_active: kitchen_active,
                    name: '厨房空间',
                },
                {
                    icon: dinner,
                    icon_active: dinner_active,
                    name: '餐厅空间',
                },
                {
                    icon: clothes,
                    icon_active: clothes_active,
                    name: '衣帽空间',
                },
                {
                    icon: bath,
                    icon_active: bath_active,
                    name: '卫浴空间',
                },
                {
                    icon: book,
                    icon_active: book_active,
                    name: '书柜空间',
                }
            ],
            productCenterBanner: [],
            kjmarginLeft: -document.body.clientWidth,
            kjwidth: 0,
            iskjshow: 1,
            kjstartx: 0,
            kjmovex: 0,
            kjchangex: 0,
            producttiel: '客厅空间',
            introduce: 'vifa的客厅空间秉承外观功能一体化的设计理念。\n在追求极简、轻奢的外观设计同时，承载着智能科技，隐藏了收纳功能。\n从此，让您的客厅空间，变得独树一帜，又能众望所归......',
            isclick: 0,
            seriesIntroduce: {},
            seriesList: [],
            spacelist: [],
            morekj: '更多空间',
            isshowsubnav: false,
            isshow: false
        }
    },
    mounted() {
        let paths;
        if (sessionStorage.getItem("language") == 1) {
            paths = "";
        } else if (sessionStorage.getItem("language") == 2) {
            paths = "/En";
        } else {
            paths = "/It";
        }
        if (this._isMobile()) {
            console.log(this.$route.query.navid)
            if (this.$route.query.navid) {
                this.$router.replace(paths + '/mProductCenter?navid=' + this.$route.query.navid);
            } else {
                this.$router.replace(paths + '/mProductCenter');
            }
        } else {
            this.$router.replace(paths + '/productCenter');
        }
        this.getHomePageData()
        this.kjwidth = document.body.clientWidth * this.productCenterBanner.length

    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getHomePageData() {
            getProductCenter()
                .then(res => {
                    this.productCenterBanner = [
                        { image: res.productCenterBanner.mobileMqImage, title: '门墙系统', subtitle: 'Door & Wall', link: '/mdoorwallsystem' },
                        { image: res.productCenterBanner.mobileKjdzImage, title: '空间定制', subtitle: 'Space Customization', link: '/mProductCenter' },
                        { image: res.productCenterBanner.mobileDjjImage, title: '大家居', subtitle: 'Lighting & Furniture', link: '/mchristophBrand' },
                    ]
                    this.seriesList = res.seriesList
                    this.spacelist = res.seriesList[0].room_space
                    this.seriesIntroduce = res.seriesIntroduce
                    this.introduce = res.seriesIntroduce.roomSpace
                    if (this.$route.query.navid) {
                        this.subnavqiehuan(this.$route.query.navid)
                    }

                    // console.log(this.$route.query.navid)
                })
                .catch(res => {
                    console.log(res)
                });
        },
        kjshow(index) {
            this.kjchangex = this.kjstartx
            this.kjmarginLeft = -document.body.clientWidth * (index)
            console.log(this.kjmarginLeft, index)
        },
        kjtouchstart(e) {
            this.kjstartx = e.touches[0].clientX
        },
        kjtouchmove(e) {
            if (this.kjchangex == this.kjstartx) {
                return
            }
            this.kjmovex = e.touches[0].clientX
            let kjleftslide = this.kjstartx - this.kjmovex
            console.log(this.iskjshow)
            if (kjleftslide > 30 && this.iskjshow < this.productCenterBanner.length - 1) {
                this.kjshow(++this.iskjshow)
            } else if (kjleftslide < -30 && this.iskjshow > 0) {
                this.kjshow(--this.iskjshow)
            }
        },
        todtails(title, entitle, ittitle) {
            let paths;
            if (sessionStorage.getItem("language") == 1) {
                paths = "";
            } else if (sessionStorage.getItem("language") == 2) {
                paths = "/En";
            } else {
                paths = "/It";
            }

            this.$router.push({ path: paths + '/mproductDtails', query: { title: title, entitle: entitle, ittitle: ittitle, navtype: this.isclick } })
        },
        subnavqiehuan(index) {
            this.isclick = index
            this.producttiel = this.kjdzallData[index].name
            this.isshowsubnav = false
            this.isshow = false
            this.morekj = '更多空间'
            this.$router.replace({ path: '/mProductCenter', query: { navid: index } })
            window.scroll(0, 0)
            if (index == 0) {
                this.introduce = this.seriesIntroduce.roomSpace
                this.spacelist = this.seriesList[0].room_space
            } else if (index == 1) {
                this.introduce = this.seriesIntroduce.bedroomSpace
                this.spacelist = this.seriesList[1].bedroom_space
            } else if (index == 2) {
                this.introduce = this.seriesIntroduce.kitchenSpace
                this.spacelist = this.seriesList[2].kitchen_space
            } else if (index == 3) {
                this.introduce = this.seriesIntroduce.restaurantSpace
                this.spacelist = this.seriesList[3].restaurant_space
            } else if (index == 4) {
                this.introduce = this.seriesIntroduce.cloakroomSpace
                this.spacelist = this.seriesList[4].cloakroom_space
            } else if (index == 5) {
                this.introduce = this.seriesIntroduce.bathroomSpace
                this.spacelist = this.seriesList[5].bathroom_space
            } else if (index == 6) {
                this.introduce = this.seriesIntroduce.bookcaseSpace
                this.spacelist = this.seriesList[6].bookcase_space
            }
        },
        morekjdz() {
            if (this.isshowsubnav) {
                this.isshowsubnav = false
                this.morekj = '更多空间'
                this.isshow = false
            } else {
                this.isshowsubnav = true
                this.morekj = ''
                this.isshow = true
            }
        }
    },



}
</script>

<style scoped>
.homelogo {
    width: 100%;
}

.xiaotop {
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}

.pplstext {
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}

.pinpailishi {
    position: relative;
}

.pinpailishi .title {
    font-size: 45px;
    padding: 10px 0;
}

.pinpailishi .subtitle {
    font-size: 30px;
}

.pinpailishi p {
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
}

.pplsimg {
    width: 100%;
    height: 492px;
    /* padding: 20px 0; */
    overflow: hidden;
}

.pinpailishi ul {
    /* width: 2490px; */
    display: flex;
    margin-left: -247.5px;
}

.pinpailishi ul li {
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}

.pinpailishi ul li img {
    width: 375px;
    height: 492px;
}

.bottomtitle {
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}

.bottomtitle .showtitle p {
    text-align: center;
    color: #fff;
}

.bottomtitle .showtitle div {
    font-size: 45px;
    color: #fff;
}

.bottomtitle .toleft {
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}

.bottomtitle .toright {
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}

.bottomtitle .toleft span {
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}

.bottomtitle .toright span {
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}

.pinpailishiani-move {
    transition: all .3s;
}




.quanpin .bottomtitle {
    color: #fff;
}

.quanpin .pplsimg {
    height: 447px;
}

.quanpin .pinpailishi ul {
    width: 2250px;
    margin: 0;
}

.quanpin .pinpailishi ul li {
    width: 750px;
    height: 447px;
    padding: 0;
}

.quanpin .pinpailishi ul li img {
    width: 750px;
    height: 447px;
}


.input {
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}

.input input {
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}

.producttiel {
    /* background: #d2cdca; */
    padding-top: 1px;
    /* padding-bottom: 40px; */
}

.subnav {
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    /* background: #fff; */
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}

.subnav ul {
    width: 100%;
    display: flex;
    /* padding-top: 20px; */
    flex-wrap: wrap;
    background: #000000cc;
    color: #fff;
}

.subnav>div {
    background: #000000cc;
    color: #fff;
}

.subnav>div.isshow {
    width: 350px;
    height: 0;
    margin: 0 auto;
}

.subnav ul li {
    flex: 1 187.5px;
    text-align: center;
    font-size: 26px;
    /* color: #fff; */
    line-height: 2;
    margin-bottom: 20px;
    /* border-top: 1px solid #fff; */
    padding-top: 30px;
}

.subnav ul li.avtive {
    color: #f08518;
}

.subnav ul li p {}

.subnav ul li img {
    width: 71px;
    height: 55px;
    margin: 0 auto;
}

.subnav ul li.avtive .icon {
    display: none;
}

.subnav ul li.avtive .icon_active {
    display: block;
}

.subnav ul li .icon_active {
    display: none;
}

.subnav ul li .icon {
    display: block;
}

.productcont {
    padding-bottom: 180px;
    line-height: 2;
    width: 750px;
    text-align: center;
}

.productcont img {
    width: 100%;
}

.productcont p {
    font-size: 26px;
    line-height: 88px;
}

.productcont div {
    font-size: 26px;
    width: 225px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    /* color: #fff; */
    /* background: #f08518; */
    line-height: 65px;
}

.twonav {
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
}

.twonav ul {
    width: 100%;
    display: flex;
}

.twonav ul li {
    flex: 1;
}

.twonav ul li.active a {
    color: #f08518;
}

.mask {
    width: 100%;
    height: 100%;
    background: #00000069;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}

.pinpailishi p>>>p:nth-child(1) {
    display: none;
}

.pinpailishi p>>>p {
    display: inline;
}

.hometopswipt {
    padding-bottom: 120px;
}</style> 