import request from "./http";

//首页
export const getHomePage = (data) => {
  return request({
    url: "/webapi/yeahc/getHomePage",
    method: "post",
    data: data,
  });
};

// 产品中心首页
export const getProductCenter = (data) => {
  return request({
    url: "/webapi/yeahc/getProductCenter",
    method: "post",
    data: data,
  });
};

// 产品详情
export const getProductDetails = (data) => {
  console.log(data);
  return request({
    url: "/webapi/yeahc/getProductDetails?productName=" + data.productName,
    method: "post",
    // data: data
  });
};

// 关于vifa
export const getAboutVifa = (data) => {
  return request({
    url: "/webapi/yeahc/getAboutVifa",
    method: "post",
    data: data,
  });
};
// 新闻列表
export const getAboutVifaall = (data) => {
  return request({
    url: "/webapi/yeahc/getAboutVifa?type=all",
    method: "post",
    data: data,
  });
};
// 大家居
export const getProductLight = (data) => {
  return request({
    url: "/webapi/yeahc/getProductLight",
    method: "post",
    data: data,
  });
};
// 大家居详情
export const getProductLightDetails = (data) => {
  return request({
    url: "/webapi/yeahc/getProductLightDetails?produceId=" + data.produceId,
    method: "post",
    // data: data
  });
};
// 案例展示
export const getCaseDisplaye = (data) => {
  return request({
    url: "/webapi/yeahc/getCaseDisplaye",
    method: "post",
    data: data,
  });
};
// 品质和服务
export const getQualityService = (data) => {
  return request({
    url: "/webapi/yeahc/getQualityService",
    method: "post",
    data: data,
  });
};

// 关键字查询门店
export const getShopByKeyword = (data) => {
  return request({
    url: "/webapi/yeahc/getShopByKeyword?keyword=" + data.keyword,
    method: "post",
    // data: data
  });
};

// 保存咨询记录
export const saveConsultRecord = (data) => {
  return request({
    url:
      "/webapi/yeahc/saveConsultRecord?name=" +
      data.name +
      "&phone=" +
      data.phone +
      "&email=" +
      data.email +
      "&city=" +
      data.city +
      "&message=" +
      data.message,
    method: "post",
    // data: data
  });
};

// 联系我们页数据
export const getContactus = (data) => {
  return request({
    url: "/webapi/yeahc/getContactus",
    method: "post",
    data: data,
  });
};

// 新闻详情
export const getEnterpriseNews = (data) => {
  return request({
    url: "/webapi/yeahc/getEnterpriseNews?objId=" + data.id,
    method: "post",
    // data: data
  });
};

// 企业详情
export const getEnterpriseDynamic = (data) => {
  return request({
    url: "/webapi/yeahc/getEnterpriseDynamic?objId=" + data.id,
    method: "post",
    // data: data
  });
};

// 案例详情
export const getCaseDetails = (data) => {
  return request({
    url: "/webapi/yeahc/getCaseDetails?caseId=" + data.caseId,
    method: "post",
    // data: data
  });
};

// 头部接口
export const getHead = (data) => {
  return request({
    url: "/webapi/yeahc/getHead",
    method: "post",
    data: data,
  });
};

// 案例列表（分页）
export const getCasePage = (data) => {
  return request({
    url:
      "/webapi/yeahc/getCasePage?pageNo=" +
      data.pageNo +
      "&pageSize=" +
      data.pageSize,
    method: "post",
    // data: data
  });
};

export const getProjectInfo = (data) => {
  return request({
    url: "/webapi/yeahc/getProjectInfo",
    method: "post",
    data: data,
  });
};
export const getProjectCasePage = (data) => {
  return request({
    url:
      "/webapi/yeahc/getProjectCasePage?pageNo=" +
      data.pageNo +
      "&pageSize=" +
      data.pageSize,
    method: "post",
    // data: data,
  });
};
export const getAgentSelect = (data) => {
  return request({
    url: "/webapi/yeahc/getAgentSelect?shopAddress=" + data.shopAddress,
    method: "post",
    data: data,
  });
};
export const getProjectCaseDetails = (data) => {
  return request({
    url: "/webapi/yeahc/getProjectCaseDetails?caseId=" + data.caseId,
    method: "post",
    data: data,
  });
};

export const saveNewCaChe = (data) => {
  return request({
    url:
      "/webapi/yeahc/saveNewCaChe?objId=" +
      data.objId +
      "&&topicEng=" +
      data.topicEng +
      "&&topicIta=" +
      data.topicIta +
      "&&titleEng=" +
      data.titleEng +
      "&&titleIta=" +
      data.titleIta,
    method: "post",
    data: data,
  });
};
export const saveDynamicCaChe = (data) => {
  return request({
    url:
      "/webapi/yeahc/saveDynamicCaChe?objId=" +
      data.objId +
      "&&topicEng=" +
      data.topicEng +
      "&&topicIta=" +
      data.topicIta +
      "&&titleEng=" +
      data.titleEng +
      "&&titleIta=" +
      data.titleIta,
    method: "post",
    data: data,
  });
};

export const saveNewCaCheContent = (data) => {
  return request({
    url:
      "/webapi/yeahc/saveNewCaChe?objId=" +
      data.objId +
      "&&topicEng=" +
      data.topicEng +
      "&&topicIta=" +
      data.topicIta +
      "&&titleEng=" +
      data.titleEng +
      "&&titleIta=" +
      data.titleIta,
    method: "post",
    data: "contentEng=" + data.contentEng + "&&contentIta=" + data.contentIta,
  });
};
export const saveDynamicCaCheContent = (data) => {
  return request({
    url:
      "/webapi/yeahc/saveDynamicCaChe?objId=" +
      data.objId +
      "&&topicEng=" +
      data.topicEng +
      "&&topicIta=" +
      data.topicIta +
      "&&titleEng=" +
      data.titleEng +
      "&&titleIta=" +
      data.titleIta ,
    method: "post",
    data: "contentEng=" + data.contentEng + "&&contentIta=" + data.contentIta,
  });
};
