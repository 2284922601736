<template>
    <div>
        <div
            :class="['founder', 'spaceCustomization', { zixun: language == 1, yinyu: language == 2, yidali: language == 3, }]">
            <div class="yishu"></div>
            <div class="beijing pwjwenzhi" v-if="language == 1">更多详情 专属定制 欢迎咨询</div>
            <div class="beijing pwjwenzhi pwjbeijing pwjstrone" v-if="language == 2">

                <span>More details</span>
                <span>Exclusive customization</span>
                <span>Welcome to consult</span>
            </div>
            <div class="beijing pwjwenzhi pwjstrone " v-if="language == 3">
                <span>Maggiori dettagli</span>
                <span>Personalizzazione</span>
               <span> Non esitate a contattarci</span>
            </div>
            <p class="pwjwenzhiyida">{{ homeConsultText }}</p>
        </div>
        <div class="consult" v-if="language == 1">
            <div>
                <span>城市：</span><input type="text" placeholder="*请输入您所在的城市City" v-model="city">
            </div>
            <div>
                <span>姓名：</span><input type="text" placeholder="*请输入您的姓名Name" v-model="name">
            </div>
            <div>
                <span>电话：</span><input type="text" placeholder="*请输入您的电话Phone" v-model="phone">
            </div>
            <div>
                <span>邮箱：</span><input type="text" placeholder="请输入您的邮箱E-mail" v-model="email">
            </div>

            <div>
                <span>问题：</span><input type="text" placeholder="*请输入您希望咨询的问题Message" v-model="message">
            </div>
            <button @click="tijiao">立即提交</button>
        </div>
        <div class="consult " v-if="language == 2">
            <div>
                <span>City:</span><input type="text" placeholder="*Please enter your city" v-model="city">
            </div>
            <div>
                <span>Name:</span><input type="text" placeholder="*Please enter your name" v-model="name">
            </div>
            <div>
                <span>Tel:</span><input type="text" placeholder="*Please enter your phone number" v-model="phone">
            </div>
            <div>
                <span>E-mail:</span><input type="text" placeholder="Please enter your E-mail" v-model="email">
            </div>

            <div>
                <span>Message: </span><input type="text" placeholder="* Please enter the message you wish to consult"
                    v-model="message">
            </div>
            <button @click="tijiao">Submit</button>
        </div>
        <div class="consult itFlex pwjconsults" v-if="language == 3">
            <div>
                <span>Città:</span><input type="text" placeholder="*Inserisce la sua città" v-model="city">
            </div>
            <div>
                <span>Nome e Cognome: </span><input type="text" placeholder="*Inserisce il suo nome e cognome"
                    v-model="name">
            </div>
            <div>
                <span>Tel:</span><input type="text" placeholder="*Inserisce il suo numero di telefono" v-model="phone">
            </div>
            <div>
                <span>Email:</span><input type="text" placeholder="Inserisce la sua Email" v-model="email">
            </div>

            <div>
                <span>Messaggio:</span><input type="text" placeholder="* Ha una domanda specifica?" v-model="message">
            </div>
            <button @click="tijiao">Invia immediatamente</button>
        </div>
        <div class="tishi" v-if="istishi">
            <div>{{ tishi }}</div>
        </div>
    </div>
</template>

<script>
import { saveConsultRecord } from '../api/api'
import { getHomePage } from '../api/api'
export default {
    name: 'zixun',
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            message: '',
            tishi: '',
            homeConsultText: '',
            istishi: false,
            language: 1,
        }
    },
    mounted() {
        this.getHomePageData()
        this.language = sessionStorage.getItem("language")
    },
    methods: {
        getPageData(data) {
            saveConsultRecord(data)
                .then(res => {
                    if (res.result == 0) {
                        this.istishi = true
                        if (sessionStorage.getItem("language") == 1) {
                            this.tishi = '提交成功'
                        } else if (sessionStorage.getItem("language") == 2) {
                            this.tishi = 'Submit successfully'
                        } else {
                            this.tishi = 'Inviato con successo'
                        }
                        setTimeout(() => {
                            this.istishi = false
                        }, 3000)
                    } else {
                        this.istishi = true
                        this.tishi = res.message
                    }
                    this.name = ''
                    this.phone = ''
                    this.email = ''
                    this.message = ''
                    this.city = ''
                })
                .catch(res => {
                    console.log(res,)
                });
        },
        getHomePageData() {
            getHomePage()
                .then(res => {
                    if (sessionStorage.getItem("language") == 1) {
                        this.homeConsultText = res.homeConsultText
                    } else if (sessionStorage.getItem("language") == 2) {
                        this.homeConsultText = res.homeConsultTextEng
                    } else {
                        this.homeConsultText = res.homeConsultTextIta
                    }
                })
                .catch(res => {
                    console.log(res,)
                });
        },
        tijiao() {

            // let emailreg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
            if (this.language == 1) {
                let phonereg = /^[1][3,4,5,7,8,6][0-9]{9}$/
                if (this.name == '') {
                    this.istishi = true
                    this.tishi = '请输入您的姓名'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.phone == '') {
                    this.istishi = true
                    this.tishi = '请输入您的电话'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.message == '') {
                    this.istishi = true
                    this.tishi = '请输入您希望咨询的问题'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if ((!phonereg.test(this.phone)) && this.phone != '') {
                    this.istishi = true
                    this.tishi = '请输入正确电话号码'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else if (this.city == '') {
                    this.istishi = true
                    this.tishi = '请输入您所在的城市'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else {
                    let data = {
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        message: this.message,
                        city: this.city
                    }
                    this.getPageData(data)
                }
            } else if (this.language == 2) {
                // let phonereg = /^(\+?44|0)7\d{9}$/
                let phonereg = /^[1][3,4,5,7,8,6][0-9]{9}$/
                if (this.name == '') {
                    this.istishi = true
                    this.tishi = 'Please enter your name'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.phone == '') {
                    this.istishi = true
                    this.tishi = 'Please enter your phone number'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.message == '') {
                    this.istishi = true
                    this.tishi = 'Please enter the message you wish to consult'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if ((!phonereg.test(this.phone)) && this.phone != '') {
                    this.istishi = true
                    this.tishi = 'Please enter your phone number'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else if (this.city == '') {
                    this.istishi = true
                    this.tishi = 'Please enter your city'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else {
                    let data = {
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        message: this.message,
                        city: this.city
                    }
                    this.getPageData(data)
                }
            } else if (this.language == 3) {
                // let phonereg = /^(\+?39)?\s?3\d{2} ?\d{6,7}$/
                let phonereg = /^[1][3,4,5,7,8,6][0-9]{9}$/
                if (this.name == '') {
                    this.istishi = true
                    this.tishi = 'Inserisce il suo nome e cognome'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.phone == '') {
                    this.istishi = true
                    this.tishi = 'Inserisce il suo numero di telefono'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if (this.message == '') {
                    this.istishi = true
                    this.tishi = 'Inserisce la domanda che desideri consultare'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                }
                else if ((!phonereg.test(this.phone)) && this.phone != '') {
                    this.istishi = true
                    this.tishi = 'Inserisce il numero di telefono corretto'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else if (this.city == '') {
                    this.istishi = true
                    this.tishi = 'Inserisce la sua città'
                    setTimeout(() => {
                        this.istishi = false
                    }, 3000)
                } else {
                    let data = {
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        message: this.message,
                        city: this.city
                    }
                    this.getPageData(data)
                }
            }
        }
    },
}
</script>

<style>
.consult>div {
    display: flex;
}

.consult>div span {
    display: block;
    flex: 1;
    line-height: 2;
    text-align-last: justify;
}

.consult>div input {
    flex: 8;
}

.tishi {
    width: 100%;
    color: #fff;
    position: fixed;
    top: 50%;
    margin-top: -30px;
    left: 0;
}

.tishi div {
    display: inline-block;
    padding: 20px 50px;
    background: #000;
    border-radius: 10px;
}

.yinyu {
    /* width: 740px;
    margin: 0 auto; */
}

.yinyu .beijing {
    font-size: 50px;
    width: 776px;
    margin: 0 auto;
}

#app .yinyu p {
    text-align: justify;
    width: 740px;
    padding-bottom: 80px;
    margin: 0 auto;
}

.yinyu .pwjbeijing {
    font-size: 50px;
    width: 776px;
    margin: 0 auto;
}

.itFlex>div input {
    flex: 7 !important;
}
.pwjwenzhi span{
    display: block;
}
.pwjconsults>div span{
white-space: nowrap;
flex: 1.5 !important;
text-align: center;
text-align-last: inherit !important;
}
.pwjwenzhiyida{
    width: 886px;
    margin: 0 auto;
}
</style>