<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + qualityService.mobileBanner" alt="" />
      <div>{{ qualityService.titleEng }}</div>
    </div>
    <div class="hometopswipt">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title pwjstrone">Five-star service process</div>
          <div class="subtitle">Five-Star Services</div>
        </div>
      </div>
      <div class="fuwushunxu">
        <div><img src="@/common/image/fuwu1.png" alt="" /></div>
        <div><img src="@/common/image/fuwu2.png" alt="" /></div>
        <div><img src="@/common/image/fuwu3.png" alt="" /></div>
        <div><img src="@/common/image/fuwu4.png" alt="" /></div>
      </div>
    </div>
    <div class="hometopswipt pbl">
      <div class="pinpailishi">
        <div
          class="pplstext textleft"
          v-html="qualityService.serviceTextEng"
        ></div>
      </div>
      <div class="wufuimg">
        <img src="@/common/image/fuwu5.png" alt="" />
      </div>
      <div class="video">
        <div class="homelogo pbl pwjstrone">
          <img src="@/common/image/fuwubuzhoueng.png" alt="" />
        </div>
      </div>
    </div>
    <div class="hometopswipt">
      <div class="fuwushunxu wufudi">
        <div><img src="@/common/image/fuwu6.png" alt="" /></div>
        <div class="alignright">
          <img src="@/common/image/fuwu7.png" alt="" />
        </div>
      </div>
    </div>
    <div class="subnav">
      <router-link to="/En/mQuality">Quality</router-link>
      <router-link to="" class="active"> service</router-link>
    </div>
  </div>
</template>

<script>
import { getQualityService } from "@/api/api";
export default {
  data() {
    return {
      qualityService: {},
    };
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mAndService");
    } else {
      this.$router.replace(paths + "/andService");
    }
    this.getPageData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPageData() {
      getQualityService()
        .then((res) => {
          this.qualityService = res.qualityService;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style scoped>
.mhome {
  font-size: 26px;
}
.homelogo {
  width: 750px;
  position: relative;
  margin-bottom: 100px;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 35px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 35px;
}
.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto;
}
.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
}
.pinpailishi .subtitle {
  font-size: 29px;
}
.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: justify;
}
.pplsimg {
  width: 100%;
  height: 357px;
  padding: 20px 0;
  overflow: hidden;
}
.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -642px;
}
.pinpailishi ul li {
  width: 635px;
  height: 357px;
  flex: 1;
  padding: 0 20px;
}
.pinpailishi ul li img {
  width: 635px;
  height: 357px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  color: #f08518;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}
.bottomtitle .showtitle {
}
.bottomtitle .showtitle p {
  text-align: center;
}
.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: 0;
  top: 26px;
}
.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: 0;
  top: 26px;
}
.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}
.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}
.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #4c413d;
}
.quanpin .pplsimg {
  height: 447px;
}
.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}
.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}
.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 40px auto;
}
.input input {
  width: 100%;
  line-height: 2;
  border: 1px solid #4c413d;
  text-align: center;
}

.bannertop,
.ppfzlist {
  width: 670px;
  font-size: 26px;
  line-height: 2;
  text-align: justify;
  margin: 40px auto;
}
.ppfzlist img {
  width: 100%;
}
.ppfzlist div {
  font-size: 32px;
  font-weight: bold;
  padding-top: 26px;
}
.shmx {
  background: #d2cdca;
  margin-top: 0;
  padding: 10px 0;
}
.subnav {
  width: 750px;
  font-size: 26px;
  line-height: 100px;
  background: #fff;
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #000000cc;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav a.active {
  color: #f08518;
}
.pzbtit .showtitle p {
  line-height: 2;
  padding-bottom: 40px;
}
.fuwushunxu {
  width: 690px;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
}
.fuwushunxu div {
  width: 335px;
  padding: 5px;
  flex: 1 335px;
}
.fuwushunxu img {
  width: 335px;
}
.wufuimg {
  width: 670px;
  margin: 40px auto 150px;
}
.wufuimg img {
  width: 100%;
}
.video {
  padding-top: 40px;
}
.wufudi {
  width: 750px;
}
.wufudi div {
  width: 370px;
  padding: 0;
  flex: 1 370px;
  text-align: left;
}
.wufudi .alignright {
  text-align: right;
}
.wufudi div img {
  width: 370px;
}
.textleft {
  text-align: left;
}
.hometopswipt {
  padding-bottom: 150px;
}
.pbl {
  padding-bottom: 0;
}
</style>
