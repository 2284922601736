<template>
<div class="black">
     <div>
        <div class="wxtip" id="JweixinTip"  v-if="iswxtip">
            <span class="wxtip-icon"><img src="./img/jiantou.png" alt=""></span>
            <p class="wxtip-txt" >点击右上角<br />选择在浏览器中打开</p>
        </div>
    </div>
</div>
  
</template>

<script>
export default {
  data() {
    return {
      iswxtip: false,

    };
  },
  mounted() {
    var ua = navigator.userAgent;
    var isWeixin = !!/MicroMessenger/i.test(ua);

    if(isWeixin){
      this.iswxtip=true
    }else{
      this.iswxtip=false
      let url=this.$route.query.url
      console.log(url)
      let index=url.indexOf('&')
      let nesStr
   
      if(index==-1){
        nesStr=url
      }else{
        nesStr=url.substring(0,index)
      }
      console.log(nesStr)
      // return
     if(this.$route.query.model==1){
      console.log(111,nesStr)
       window.location.href =url;
     
     }else{
       console.log(2222,"https://www.vifa.cn"+ nesStr)
        window.location.href ="https://www.vifa.cn"+ nesStr;
     }  
       
       
    }
    // this.iswxtip=this.$route.params

  },
};
</script>

<style>
.black{
    background: rgba(51,51,51);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;

}
.wxtip {
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    /* display: none; */
  }
  .wxtip-icon img {
    width: 148px;
    height: 99px;
    font-size: 50px;
    color: #fff;
    /* background:url('/img/jiantou.png') no-repeat; */
    display: block;
    position: absolute;
    right: 56px;
    top: 36px;
  }
  .wxtip-txt {
    width: 460px;
    margin: auto;
    line-height: 30px;
    padding-top: 52px;
    height: 164px;
    margin-top:300px;
    color: #fff;
    font-size: 20px;
    /* line-height: 1.5; */
    background: url('./img/biankuan.png') no-repeat;
    background-size: 100% 164px;
  }
</style>