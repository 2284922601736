<template>
    <div class="case details" @contextmenu.prevent="youjian()">
        <div class="cdbanner">
            <img :src="'https://www.vifa.cn' + caseDetails.banner" alt="">
        </div>
        <!-- <div class="spaceCustomization_img homeDecoration storeDisplay mtn" ref="homeDecoration">
            <div class="storeShowImg tran" :style="{'width':storeWidth + 'px'}">
                    <div class="ssiamge" :style="{'width':storeWidth + 'px','left':ssiamgeleft + 'px'}" :class="{'tran': noLast}">
                        <img :src=item.storeImg alt="" v-for="(item, index) in storeDisplayData" :key="index" :style="{'width':storeImgWidth + 'px'}">
                    </div>
            </div>
            <div class="switeName">
                <div class="switeNameBox">
                    <div class="left" @click="swiperLeft"><p></p><span></span></div>
                    <div class="midle" v-for="(item, index) in storeDisplayData" :key="index" v-show="isStoerDisplay == index">
                        <p>{{item.addrName}}</p>
                        <div>{{item.storeName}}</div>
                    </div>
                    <div class="right"  @click="swiperRight"><p></p><span></span></div>
                </div>
            </div>
        </div> -->

        
            <div class="founder spaceCustomization">
                <div class="yishu" style="margin-bottom:55px"></div>
                <div v-html="caseDetails.synopsis" class="detailsCenters"></div>            
                <!-- <p>巧妙运用光学原理，</p>            
                <p>让光设计，</p>            
                <p>把空间交给自然，</p>            
                <p>使得整体空间充满着生命的张力，</p>            
                <p>让人不自觉地去感受、去想象……</p>             -->
            </div>

            <div class="tuji">
                <div class="tujiswiper tujiHa">
                    <ul :style="{'width':tujiswiperWideh + 'px','marginLeft':tujiswipermLeft + 'px'}" :class="{tran:istran}" ref="tujiul">
                        
                        <li :class="{'scale':istujiimg ==index}" @click="tujiimg(tujilastindex)">
                            <div><img :src="'https://www.vifa.cn' + tujiData[tujilastindex]" alt=""></div>
                        </li>
                        <li v-for="(item, index) in tujiData" :key="index" :class="{'scale':istujiimg ==index}" @click="tujiimg(index)" :ref="'tujili'+ index">
                            <div><img :src="'https://www.vifa.cn' + item" alt=""></div>
                        </li>
                        <li :class="{'scale':istujiimg ==index}" @click="tujiimg(0)">
                            <div><img :src="'https://www.vifa.cn' + tujiData[0]" alt=""></div>
                        </li>
                    </ul>
                </div>
                <div class="switeName">
                    <div class="switeNameBox">
                        <div class="left" @click="tujiswiperLeft"><p v-show="isleftp"></p><span></span></div>
                        <div class="midle" @click="clickalltuji">
                            查看完整图集
                        </div>
                        <div class="right"  @click="tujiswiperRight"><p v-show="isrightp"></p><span></span></div>
                    </div>
                </div>
            </div>

            <div v-html="caseDetails.content" class="detailsCenter" >
            </div>

            <!-- <div class="detailsName">
                <p><i>项目地址｜<span>{{caseDetails.projectAddress}}</span></i><i v-if="caseDetails.projectCategory">项目类别｜<span>{{caseDetails.projectCategory}}</span></i><i>项目名称｜<span>{{caseDetails.projectName}}</span></i><i>项目面积｜<span>{{caseDetails.projectSize}}</span></i></p>
                <p><i>项目设计｜<span>{{caseDetails.projectDesign}}</span></i><i v-if="caseDetails.designOffice">设计机构｜<span>{{caseDetails.designOffice}}</span></i></p>
            </div> -->

            <div class="alltuji" v-show="isalltujibox" @click="alltjclo">
                <ul ref="tujibigimageul">
                    <li v-for="(item, index) in tujiData" :key="index" v-show="isalltujishow ==index" class="tran">
                        <img :src="'https://www.vifa.cn' + item" alt="" @click="alltjclo" :class="{'iswidthman':iswidthman}">
                    </li>
                </ul>
                <div class="alltujileft" @click.stop="alltjleft"><div></div><p></p></div>
                <div class="alltujiright" @click.stop="alltjright"><div></div><p></p></div>
            </div>
            <!-- <div class="img_list">
                <ul>
                    <li v-for="(item, index) in tujiData" :key="index">
                        <img :src=item.img alt="">
                    </li>
                </ul>
            </div>

            <div class="bigImg">
                <div class="bigImg_left">向左</div>
                <div class="bigImg_img">
                    <div v-for="(item, index) in tujiData" :key="index">
                        <img :src=item.img alt="">
                    </div>
                </div>
                <div class="bigImg_right">向右</div>
            </div> -->
    </div>
</template>

<script>
import storeShow from '@/common/image/jiazhuangcase.png'
import jiax1 from '@/common/image/jiax1.png'
import jiax2 from '@/common/image/jiax2.png'
import jiax3 from '@/common/image/jiax3.png'
import jiax4 from '@/common/image/jiax4.png'
import jiax5 from '@/common/image/jiax5.png'
import shopbanner from '@/common/image/shopbanner.png'
import tuji from '@/common/image/tuji.png'
import {getCaseDetails} from '@/api/api'


export default {
    name: 'caseDetails',
    data(){
        return{
            issdMask:true,
            // hdmHeight:710,
            storeWidth:document.body.clientWidth,
            ssiamgeleft:0,
            noLast:true,
            storeDisplayData:[
                {
                    addrName:'重庆 在高数1',
                    storeName:'周子健1',
                    storeImg:storeShow,
                },
                {
                    addrName:'重庆 在高数2',
                    storeName:'周子健2',
                    storeImg:storeShow,
                },
                {
                    addrName:'重庆 在高数3',
                    storeName:'周子健3',
                    storeImg:storeShow,
                },
                {
                    addrName:'重庆 在高数4',
                    storeName:'周子健4',
                    storeImg:storeShow,
                }
            ],
            jiaxiangData:[
                {
                    jiaxData:[
                        {
                            img:jiax1,
                            addr:'上海某某1'
                        },
                        {
                            img:jiax2,
                            addr:'上海某某2'
                        },
                        {
                            img:jiax3,
                            addr:'上海某某3'
                        },
                        {
                            img:jiax4,
                            addr:'上海某某4'
                        },
                        {
                            img:jiax5,
                            addr:'上海某某5'
                        },
                    ],
                },
                {
                    jiaxData:[
                        {
                            img:jiax1,
                            addr:'北京某某1'
                        },
                        {
                            img:jiax2,
                            addr:'北京某某2'
                        },
                        {
                            img:jiax3,
                            addr:'北京某某3'
                        },
                        {
                            img:jiax4,
                            addr:'北京某某4'
                        },
                        {
                            img:jiax5,
                            addr:'北京某某5'
                        },
                    ],
                },
                {
                    jiaxData:[
                        {
                            img:jiax1,
                            addr:'广州某某1'
                        },
                        {
                            img:jiax2,
                            addr:'广州某某2'
                        },
                        {
                            img:jiax3,
                            addr:'广州某某3'
                        },
                        {
                            img:jiax4,
                            addr:'广州某某4'
                        },
                        {
                            img:jiax5,
                            addr:'广州某某5'
                        },
                    ],
                },
                {
                    jiaxData:[
                        {
                            img:jiax1,
                            addr:'天津某某1'
                        },
                        {
                            img:jiax2,
                            addr:'天津某某2'
                        },
                        {
                            img:jiax3,
                            addr:'天津某某3'
                        },
                        {
                            img:jiax4,
                            addr:'天津某某4'
                        },
                        {
                            img:jiax5,
                            addr:'天津某某5'
                        },
                    ],
                },
            ],
            caseNav:[
                {nav:'家装实例'},
                {nav:'门店展示'},
            ],
            shopbannerData:[
                {
                    img:shopbanner,
                    city:'佛山'
                },
                {
                    img:jiax2,
                    city:'上海'
                },
                {
                    img:shopbanner,
                    city:'兰州'
                },
                {
                    img:jiax4,
                    city:'南通'
                },
                {
                    img:shopbanner,
                    city:'大连'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                },
                {
                    img:jiax1,
                    city:'宁波'
                }

            ],
            storeImgWidth:document.body.clientWidth,
            isStoerDisplay:0,
            isactive:0,
            newLfet:0,
            faw:0,
            moreJiaxmouse:999999999,
            caseNavActive:0,
            ishomeDecoration:true,
            isshopDecoration:false,
            shopImgWidth:document.body.clientWidth,
            shopWidth:0,
            shopiamgeleft:0,
            isShopDisplay:0,
            tujiData:[
                {img:tuji},
                {img:tuji},
                {img:tuji},
                {img:tuji},
                {img:tuji},
            ],
            tujiswiperWideh:9999999999,
            tujiswipermLeft:'',
            istujiimg:999,
            isleftp:true,
            isrightp:true,
            caseDetails:{},
            tujiulleft:'',
            isdiyizhangtu:0,
            tujilastindex:'',
            istran:true,
            isalltujishow:0,
            isalltujibox:false,
            iswidthman:false
        }
    },
    components: {
    },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mCdetail?caseId='+this.$route.query.caseId);
        } else {
            this.$router.replace(paths+'/caseDetails?caseId='+this.$route.query.caseId);
        }
        // setInterval(() => {
        //     this.maskHeight()
        // }, 1000)
        // addclissshiying
        setTimeout(()=>{
            this.$nextTick(()=>{
                this.maskHeight()
                // this.pplsliwidth = this.$refs.pplsli0.clientWidth
                // this.pplswidth = (this.$refs.pplsli0.clientWidth+1)*this.homeBrandIntroduce.length
                // this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft
                // this.pplsmarginLeft = this.pplsuloffsetLeft
                // this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
                this.tujiswiperWideh = (this.$refs.tujili0.clientWidth+1)*(this.tujiData.length + 4)
                this.tujiulleft = this.$refs.tujiul.offsetLeft
                this.tujiswipermLeft = this.tujiulleft
                this.tujilastindex = this.tujiData.length -1
                console.log("贾娃成",this.$refs.tujiul.children[0].children[0].children[0].clientWidth,this.$refs.tujili0.children[0].clientWidth)
                // let tjimgheight = this.$refs.tujibigimageul.children[index].children[0].naturalHeight/this.$refs.tujili0.children[0].clientWidth
                // let tjimgwidth = this.$refs.tujibigimageul.children[index].children[0].naturalWidth/tjimgheight
                // if(this.$refs.tujiul.children[0].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                //     this.$refs.tujiul.children[0].children[0].children[0].className = 'addclissshiying'
                // }
                if(this.$refs.tujiul.children[1].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                    this.$refs.tujiul.children[1].children[0].children[0].className = 'addclissshiying'
                }
                if(this.$refs.tujiul.children[2].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                    this.$refs.tujiul.children[2].children[0].children[0].className = 'addclissshiying'
                }
            })
        },1000)
        this.getpageData()
        document.onkeydown = function(e){
        if(e && e.keyCode === 123) {
            e.keyCode = 0;
            e.returnValue = false;
            return false
        }
       }
    },
    methods:{
        youjian(){
            console.log('点击了右键')
        },
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        
        getpageData(){
            let data = {
                caseId:this.$route.query.caseId
            }
            getCaseDetails(data)
            .then(res => {
                console.log(res)
                this.caseDetails = res.caseDetails
                this.tujiData = res.caseDetails.photoAlbumImages
            })
            .catch(res => {
                console.log(res,'catch')
            });
        },
        maskHeight(){
            this.storeWidth = this.storeImgWidth * this.storeDisplayData.length
            this.shopWidth = this.shopImgWidth*this.shopbannerData.length
        },
        tujiswiperLeft(){
            if(this.isdiyizhangtu>0){
                this.isdiyizhangtu--
                this.tujiswipermLeft = this.tujiswipermLeft + (this.$refs.tujili0.clientWidth+1)
                console.log(this.isdiyizhangtu)
            }else{
                this.istran = false
                this.isdiyizhangtu = this.tujiData.length
                this.tujiswipermLeft = this.tujiulleft - (this.$refs.tujili0.clientWidth+1)*this.isdiyizhangtu
                setTimeout(()=>{
                this.istran = true
                this.isdiyizhangtu--
                this.tujiswipermLeft = this.tujiswipermLeft + (this.$refs.tujili0.clientWidth+1)
                },10)
            }
            console.log(this.isdiyizhangtu)
            if(this.$refs.tujiul.children[this.isdiyizhangtu+1].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu+1].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu - 1].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu - 1].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu - 2].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu - 2].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu - 3].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu - 3].children[0].children[0].className = 'addclissshiying'
            }
        },
        tujiswiperRight(){
            if(this.isdiyizhangtu < this.tujiData.length - 1){
                this.isdiyizhangtu++
                this.tujiswipermLeft = this.tujiswipermLeft - (this.$refs.tujili0.clientWidth+1)
            }
            else{
                console.log(this.isdiyizhangtu)
                this.istran = false
                this.isdiyizhangtu = 0
                this.tujiswipermLeft = this.tujiulleft + (this.$refs.tujili0.clientWidth+1)
                setTimeout(()=>{
                this.istran = true
                this.isdiyizhangtu++
                this.tujiswipermLeft = this.tujiswipermLeft - (this.$refs.tujili0.clientWidth+1)
                },10)
            }
            console.log(this.isdiyizhangtu)
            if(this.$refs.tujiul.children[this.isdiyizhangtu+1].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu+1].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu - 1].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu - 1].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu + 2].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu + 2].children[0].children[0].className = 'addclissshiying'
            }
            if(this.$refs.tujiul.children[this.isdiyizhangtu + 3].children[0].children[0].clientWidth<this.$refs.tujili0.children[0].clientWidth){
                this.$refs.tujiul.children[this.isdiyizhangtu + 3].children[0].children[0].className = 'addclissshiying'
            }
        },
        storeSwiper(){
            setInterval(() => {
                this.noLast = true
                if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
                    this.isStoerDisplay++
                    this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
                    this.noLast = true
                }else{
                    this.isStoerDisplay = 0
                    this.noLast = true
                }
                
                if (this.jiaxiangData.length - 1 > this.isactive) {
                    this.isactive++
                    this.newLfet = -1200 * this.isactive
                }else{
                    this.isactive = 0
                    this.newLfet = 0
                }

                if (this.shopbannerData.length - 1 > this.isShopDisplay) {
                    this.isShopDisplay++
                    this.shopiamgeleft = -this.shopImgWidth * this.isShopDisplay
                }else{
                    this.isShopDisplay = 0
                    this.shopiamgeleft = 0
                }
            }, 5000)
        },
        swiperLeft(){
            console.log(this.isStoerDisplay)
            if (this.isStoerDisplay>0) {
                this.isStoerDisplay = this.isStoerDisplay - 1
                this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
            }
        },
        swiperRight(){
            console.log(this.storeDisplayData.length)
            if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
                this.isStoerDisplay = this.isStoerDisplay + 1
                this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
            }
            
        },
        newswiLfet(){
            if (this.isactive>0) {
                this.isactive = this.isactive-1
                this.newLfet = -1200 * this.isactive
            }
        },
        newswiRight(){
            if (this.isactive < this.jiaxiangData.length - 1) {
                this.isactive = this.isactive+1
                this.newLfet = -1200 * this.isactive
            }
        },
        jiaxenter(index){
            this.moreJiaxmouse = index
        },
        jiaxleave(){
            this.moreJiaxmouse = 9999999
        },
        nav(index){
            this.caseNavActive = index
            if (index == 0) {
                this.ishomeDecoration=true
                this.isshopDecoration=false
            }else if (index == 1) {
                this.ishomeDecoration=false
                this.isshopDecoration=true
            }
        },
        shopCity(index){
            this.noLast = false
            this.isShopDisplay = index
            this.shopiamgeleft = -this.shopImgWidth * this.isShopDisplay
        },

        defferScroll: function (event) {
            event.preventDefault()
        },
        huanclassname(index){
            let tjimgheight = this.$refs.tujibigimageul.children[index].children[0].naturalHeight/document.documentElement.clientHeight
            let tjimgwidth = this.$refs.tujibigimageul.children[index].children[0].naturalWidth/tjimgheight
            if(tjimgwidth>document.documentElement.clientWidth){
                this.$refs.tujibigimageul.children[index].children[0].className = 'iswidthman'
            }
        },
        tujiimg(index){
            // if (this.istujiimg == 999) {
            //     this.istujiimg = index
            //     document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
            //     document.body.addEventListener("wheel", this.defferScroll, {passive: false});
            // }else{
            //     this.istujiimg = 999
            //     document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
            //     document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
            // }
            this.isalltujibox = true
            this.isalltujishow = index
            this.huanclassname(index)
        },
        alltjclo(){
            this.isalltujibox = false
        },
        alltjleft(){
            if(this.isalltujishow>0){
                this.isalltujishow--
            }else{
                this.isalltujishow = this.tujiData.length - 1
            }
            this.huanclassname(this.isalltujishow)
        },
        alltjright(){
            if(this.isalltujishow < this.tujiData.length - 1){
                this.isalltujishow++
            }else{
                this.isalltujishow = 0
            }
            this.huanclassname(this.isalltujishow)
        },
        clickalltuji(){
            this.isalltujibox = true
            this.isalltujishow = this.isdiyizhangtu
        }
    },
}
</script>

<style>
@import '../../../common/styles/about.css';
.tuji{
    padding-top:50px ;
}
.cdbanner img{
    width: 100%;
}
.details .tuji .switeName{
    position: static;
    color: #867f7d;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 50px;
    background: none;
}
.details .tuji .switeName .switeNameBox div{
    /* border-bottom: 1px solid #000; */
    cursor: pointer;
}
.details .tuji .switeName .switeNameBox div p{
    background: #000;
}
.details .tuji .switeName .switeNameBox div.midle{
    border-bottom: none;
}
.tujiswiper{
    width: 100%;
    overflow: hidden;
}
.tujiswiper ul{
    overflow: auto;
    margin-left: -420px;
}
.tujiswiper ul li{
    float: left;
    width: 900px;
    height: 540px;
    padding-right:25px
}
.tuji .tujiHa ul li>div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 540px;
    overflow: hidden;
}
.tujiswiper ul li img{
    width: 100%;
    height: 540px;
    cursor: pointer;
}
.tuji .tujiHa ul li img{
    /* height: auto; */
    width: auto;
    height: 100%;
}
.tuji .tujiHa ul li img.addclissshiying{
    width: 100%;
    height: auto;
}
.detailsCenter,.detailsName{
    width: 900px ;
    margin: 0 auto;
    text-align: justify;
    line-height: 2;
}
.detailsCenters{
    width: 900px ;
    margin: 0 auto;
    text-align: justify;
    line-height: 2;
    white-space: pre-wrap;
}

.detailsCenter p{
    padding-bottom:30px;
}
.detailsName{
    padding:75px 0;
}
.detailsName p{
    color: #b5b5b5;
    line-height: 1.5;
}
.detailsName p span{
    font-weight: bold;
    padding: 0 5px;
}



.bigImg{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    background: #0000005c;
    display: none;
}
.bigImg_left{
    width: 100px;
    height: 100px;
    background: #fff;
    text-align: center;
    line-height: 100px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -50px;
    cursor: pointer;
}
.bigImg_right{
    width: 100px;
    height: 100px;
    background: #fff;
    text-align: center;
    line-height: 100px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -50px;
    cursor: pointer;
}
.bigImg_img{
    width: 9600px;
    overflow: auto;
}
.bigImg_img div{
    width: 1920px;
    /* vertical-align: middle; */
    float: left;
}
.bigImg_img div img{
    width: 100%;
    /* height: 1030px; */
}



.img_list{
    width: 100%;height: 100%;position: fixed;left: 0;top: 0;z-index: 99999;background: #0000005c;display: none;
}
.img_list ul{
    width: 74%;
    margin: 0 auto;
    background: #fff;
    /* display: flex; */
    overflow: hidden;
    overflow-y: auto;
    height: 84%;
    margin-top: 2%;
    /* flex-wrap: wrap; */
    padding: 3%;
}
.img_list ul li{
    width: 31.33%;
    flex: 1 31.33%;
    display: table-cell;
    vertical-align: middle;
    padding: 1%;
    display: flex;
    align-items:center;
    float:left;
}
.img_list ul li img{
     width: 100%;
}
.case .switeName{
    bottom: 0;
    background: #0009;
}
.case .switeName .switeNameBox div.midle p{
        font-size: 35.5px;
}
.case .switeName .switeNameBox div.midle div{
    border-bottom: none;
    font-size: 16px;
    padding-top: 10px;
}


.details .tuji .switeName .switeNameBox div span{
    background: #000;
}

.case .tujiswiper ul li.scale{
    transition: all 2s;
}
.case .tujiswiper ul li.scale div{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999999999;
    background: #00000099;
    height: 100%;
    display: flex;
}
.case .tujiswiper ul li.scale img{
    transform: scale(0.9);
    height: auto;
    align-items: center;
}

.alltuji{
        width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #000;
    z-index: 9999999999999999;
}
.alltuji ul{
        width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
}
.alltuji ul li{
    width: 100%;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.alltuji ul li img{
    width: auto;
    height: 100%;
}
.alltuji ul li img.iswidthman{
    width: 100%;
    height: auto;
}
.alltujileft{
        /* background: #00000052;
    position: absolute;
    left: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-right: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
    width: 150px;
    position: absolute;
    left: 2.5%;
    top: 50%;
    cursor: pointer;
    height:25px;
    margin-top: -12.5px;
}
.alltujileft div{
    width: 150px;
    height: 1px;
    background: #fff;
    margin-top: 25px;
}
.alltujileft p{
    height: 25px;
    width: 1px;
    background: #fff;
    position: absolute;
    left: 25px;
    top: 12.5px;
}
.alltujiright{
        /* background: #00000052;
    position: absolute;
    right: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-left: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
    width: 150px;
    position: absolute;
    right: 2.5%;
    top: 50%;
    cursor: pointer;
    height:25px;
    margin-top: -12.5px;

}
.alltujiright div{
    width: 150px;
    height: 1px;
    background: #fff;
    margin-top: 25px;
}
.alltujiright p{
    height: 25px;
    width: 1px;
    background: #fff;
    position: absolute;
    right: 25px;
    top: 12.5px;
}
.detailsCenter{
    padding-top: 10px;
    padding-bottom: 80px;
}
</style>
