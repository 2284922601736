<template>
  <div class="mheader">
    <!-- <div class="language">
        <button @click="bindLanguage(1)">中文</button>
        <button @click="bindLanguage(2)">English</button>
        <button @click="bindLanguage(3)">Italiano</button>
      </div> -->

    <transition-group name="mheaddaoh">
      <div class="mhead" :style="{ top: mheadtop + 'px' }" ref="mheadheight">
        <div class="routerbox">
          <!-- <router-link  to="/m_home"  
            >
          
            </router-link> -->
          <!-- <img class="mlogo" src="../common/image/logo11.png" alt="" @click="bindHome" /> -->
          <span class="routerlink myspan" @click="bindHome">
            <img class="mlogo" src="../common/image/logo11.png" alt="" />
          </span>
        </div>

        <!-- <transition-group name="mheaddaoh"> -->
        <div class="mnav" @click="zhuandong">
          <!-- <img :class="{'mnavxuan':ismnavxuan,'mnavshow':isshidao,'shidaoimg':isshidaoimg}" src="../common/image/mnav.png" alt="">
                        <img class="shidaoimg cuo" :class="{'shidao':isshidao,'mnavshow':ismnavxuan}" src="../common/image/shidao.png" alt=""> -->
          <transition-group name="mheaddaoh">
            <p :class="{
              rotateaani: isrotateaani,
              rotateaanion: isrotateaanion,
            }">
              <span></span>
            </p>
            <p :class="{
              rotatebani: isrotatebani,
              rotatebanion: isrotatebanion,
            }">
              <span></span>
            </p>
            <p :class="{
              rotatebani: isrotatebani,
              rotatebanion: isrotatebanion,
            }">
              <span></span>
            </p>
          </transition-group>
        </div>
        <!-- </transition-group> -->
      </div>
      <div class="height136" :style="{ height: isheighta + 'px' }"></div>
    </transition-group>






    <transition-group name="mheaddaoh">
      <div class="mheadmuse" :style="{ left: mheadmuseleft + 'px' }">
        <ul>
          <li @click="toabout">
            <div class="title" v-if="language == 1">关于vifa</div>
            <div class="title" v-if="language == 2">About vifa</div>
            <div class="title" v-if="language == 3">SRORIE DI VIFA</div>
          </li>
          <li>
            <div class="title">
              <router-link to="/mProductCenter" @click="tohome" v-if="language == 1">产品中心</router-link>
              <router-link to="/En/mProductCenter" @click="tohome" v-if="language == 2"> Product Center</router-link>
              <router-link to="/It/mProductCenter" @click="tohome" v-if="language == 3">PRODOTTI</router-link>

              <span v-show="!iscpkj" @click="clickcpkj">+</span><span v-show="iscpkj" @click="clickcpkj">-</span>
            </div>
            <!-- <div class="title_list yingcpkj" :class="{'cpkj':iscpkj}" v-show="iscpkj"> -->
            <div class="title_list" v-show="iscpkj">
              <p>
                <router-link to="/mProductCenter" @click="tohome" v-if="language == 1">空间定制</router-link>
                <router-link to="/En/mProductCenter" @click="tohome" v-if="language == 2">Space
                  customization</router-link>
                <router-link to="/It/mProductCenter" @click="tohome" v-if="language == 3">Personalizzazione dello
                  spazio</router-link>
              </p>
              <p>
                <router-link to="/mchristophBrand" @click="tohome" v-if="language == 1">大家居</router-link>
                <router-link to="/En/mchristophBrand" @click="tohome" v-if="language == 2">Household Product</router-link>
                <router-link to="/It/mchristophBrand" @click="tohome" v-if="language == 3">Illuminazione e
                  Mobili</router-link>
              </p>
              <p>
                <router-link to="/mdoorwallsystem" @click="tohome" v-if="language == 1">门墙系统</router-link>
                <router-link to="/En/mdoorwallsystem" @click="tohome" v-if="language == 2"> Door and Wall
                  System</router-link>
                <router-link to="/It/mdoorwallsystem" @click="tohome" v-if="language == 3">Parete e Porta</router-link>
              </p>
            </div>
          </li>
          <li>
            <div class="title">
              <router-link to="/mQuality" @click="tohome" v-if="language == 1">品质和服务</router-link>

              <router-link to="/En/mQuality" @click="tohome" v-if="language == 2">Quality and Service</router-link>
              <router-link to="/It/mQuality" @click="tohome" v-if="language == 3">QUALITÀ E SERVIZI</router-link>
              <span v-show="!ispzhfw" @click="clickpzhfw">+</span><span v-show="ispzhfw" @click="clickpzhfw">-</span>
            </div>
            <div class="title_list" v-show="ispzhfw">
              <p>
                <router-link to="/mQuality" @click="tohome" v-if="language == 1">品质</router-link>
                <router-link to="/En/mQuality" @click="tohome" v-if="language == 2">Quality</router-link>
                <router-link to="/It/mQuality" @click="tohome" v-if="language == 3">QUALITÀ</router-link>
              </p>
              <p>
                <router-link to="/mAndService" @click="tohome" v-if="language == 1">服务</router-link>
                <router-link to="/En/mAndService" @click="tohome" v-if="language == 2">Service</router-link>
                <router-link to="/It/mAndService" @click="tohome" v-if="language == 3">SERVIZI</router-link>
              </p>
            </div>
          </li>
          <li>
            <div class="title">
              <router-link to="/mCase" @click="tohome" v-if="language == 1">案例展示</router-link>
              <router-link to="/En/mCase" @click="tohome" v-if="language == 2"> Case Show</router-link>
              <router-link to="/It/mCase" @click="tohome" v-if="language == 3">PROGETTI</router-link>

              <span v-show="!isalzs" @click="clickalzs">+</span><span v-show="isalzs" @click="clickalzs">-</span>
            </div>
            <div class="title_list" v-show="isalzs">
              <p>
                <router-link to="/mCase" @click="tohome" v-if="language == 1">家装案例</router-link>
                <router-link to="/En/mCase" @click="tohome" v-if="language == 2">Home Furnishing Case</router-link>
                <router-link to="/It/mCase" @click="tohome" v-if="language == 3"> Esempi di arredamento
                  domestico</router-link>
              </p>
              <p>
                <router-link to="/mShopCase" @click="tohome" v-if="language == 1">门店展示</router-link>
                <router-link to="/mShopCase" @click="tohome" v-if="language == 2">Store Display</router-link>
                <router-link to="/mShopCase" @click="tohome" v-if="language == 3">Esposizione del negozio</router-link>
              </p>
            </div>
          </li>

          <li>
            <div class="title">
              <router-link to="/mbrief" @click="tohome" v-if="language == 1">工程项目</router-link>

              <router-link to="/En/mbrief" @click="tohome" v-if="language == 2">Project</router-link>

              <router-link to="/It/mbrief" @click="tohome" v-if="language == 3">Progetto di ingegneria</router-link>
              <span v-show="!isalzx" @click="clickalzx">+</span><span v-show="isalzx" @click="clickalzx">-</span>
            </div>
            <div class="title_list" v-show="isalzx">
              <p>
                <router-link to="/mbrief" @click="tohome" v-if="language == 1">工程案例</router-link>
                <router-link to="/En/mbrief" @click="tohome" v-if="language == 2">Project caseProject </router-link>
                <router-link to="/It/mbrief" @click="tohome" v-if="language == 3">Esempi di progetto</router-link>
              </p>
              <p>
                <router-link to="/mmapserch" @click="tohome" v-if="language == 1">工程经销商查询</router-link>
                <router-link to="/En/mmapserch" @click="tohome" v-if="language == 2">Engineering Dealer <Inquiry>
                  </Inquiry></router-link>
                <router-link to="/It/mmapserch" @click="tohome" v-if="language == 3"> Ricerca del rivenditore di
                  ingegneria</router-link>
              </p>
              <!-- <p>
                <a href="https://qywx.vifa.cn/engineeing/#/engineeingQuery"
                  >工程项目查询</a
                >
               
              </p> -->
            </div>
          </li>

          <li @click="tomcontact">
            <div class="title" v-if="language == 1">联系我们</div>
            <div class="title" v-if="language == 2">Contact Us</div>
            <div class="title" v-if="language == 3">CONTATTI</div>
          </li>

          <li>
            <div class="title">
              <strong @click="clickisshwoLang">语言/Language</strong>
              <span v-show="!isshwoLang" @click="clickisshwoLang">+</span><span v-show="isshwoLang"
                @click="clickisshwoLang">-</span>
            </div>
            <div class="title_list" v-show="isshwoLang">
              <p>
                <strong @click="bindLanguage(1)">中文</strong>

              </p>
              <p>
                <strong @click="bindLanguage(2)">English</strong>

              </p>
              <p>
                <strong @click="bindLanguage(3)">Italiano</strong>

              </p>
              <!-- <p>
                <a href="https://qywx.vifa.cn/engineeing/#/engineeingQuery"
                  >工程项目查询</a
                >
               
              </p> -->
            </div>
          </li>
        </ul>
        <div class="mqrco">
          <div>
            <div v-for="(item, index) in qrcodeData" :key="index" @click="bigqrcon(index)">
              <img :src="item.qrcodeicon" alt="" />
            </div>
            <!-- {{crystal.iosUrl}} -->

            <!-- <div><img src="../common/image/mxiaochengxu.png" alt=""></div>
                        <div><img src="../common/image/mdouyin.png" alt=""></div>
                        <div><img src="../common/image/mxiaohongshu.png" alt=""></div> -->
          </div>
        </div>
        <div @click="xiazai" style="padding: 0px 10px; margin: 3.75rem 21px;padding-bottom: 100px;">
          <img src="./img/btn12.png" alt="" />
          <p v-if="language == 1" style="
                margin-top: 11.8px;
                margin-left: 12px;
                font-weight: bolder;
                font-size: 13.5px;
              "  
              >
            威法智能App下载
          </p>
          <p v-if="language == 2" style="
                margin-top: 11.8px;
                margin-left: 12px;
                font-weight: bolder;
                font-size: 13.5px;
              ">
            vifa App download
          </p>
          <p v-if="language == 3" style="
                margin-top: 11.8px;
                margin-left: 12px;
                font-weight: bolder;
                font-size: 13.5px;
              ">
            VIFA Scarica l’app
          </p>
        </div>

        <!-- <div class="mheadfoot">From Denmark，Since 1933 </div> -->
      </div>
    </transition-group>


















    <div class="isshowqrcon" v-show="isshowqrcon" @click="close">
      <div v-for="(item, index) in qrcodeData" :key="index" v-show="isbigqrconimg == index">
        <img @click.stop="" :src="'https://www.vifa.cn' + item.qrcode" alt="" />
      </div>
    </div>






















    <!-- <div class="cm-guazai" @click="guangaoshows" v-if="ishdgj"><img src="../common/image/shoujiback.png" alt=""></div>
        <div class="cm-showhd" v-if="ishdtk">
            <div class="cm-show-tk"><img class="cm-show-pcnew" src="../common/image/shoujinew.png" alt="">
            <div class="cm-choos" @click="chossgg"><img src="../common/image/shouchoss.png" alt=""></div>
            <div class="cm-ljzx" @click="gocontact"><img src="../common/image/shouljzx.png" alt=""></div>
            </div>
        </div> -->
  </div>
</template>

<script>
import weixin from "../common/image/mwexin.png";
import xiaochengxu from "../common/image/mxiaochengxu.png";
import douyin from "../common/image/mdouyin.png";
import xiaohongshu from "../common/image/mxiaohongshu.png";
import { getHead } from "../api/api";
import { toRaw } from "@vue/reactivity";
export default {
  props: ["smartHomeobj", "touchto", "language"],
  data() {
    return {
      isshwoLang: false,
      iscpkj: false,
      ispzhfw: false,
      isalzs: false,
      isalzx: false,
      mheadmuseleft: document.body.clientWidth,
      ismnavxuan: false,
      isshidao: false,
      isshidaoimg: false,
      qrcodeData: [],
      isshowqrcon: false,
      isbigqrconimg: 0,
      isrotateaani: false,
      isrotateaanion: false,
      isrotatebani: false,
      isrotatebanion: false,
      mheadtop: 0,
      isheighta: 0,
      isaaa: false,
      crystal: {},
      ishdgj: false,
      ishdtk: false,
      intime: 10,
      issettime: null,
      isTimeout: null,

    };
  },

  // props: {
  //   : Number,
  //   language: Number,
  // },
  mounted() {
    this.getPageData();
    //      const crystal = this.smartHomeobj
    //     const ss =toRaw(this.smartHomeobj);
    //      this.crystal=ss
    // console.log(crystal,111)
    // console.log(this.$refs.mheadheight.clientHeight)
    // if (this.touchto == 1) {
    //     this.mheadtop = 0
    //     this.isheighta = this.$refs.mheadheight.clientHeight
    // }else{
    //     this.mheadtop = -this.$refs.mheadheight.clientHeight
    //     this.isheighta = 0
    // }
    this.isheighta = this.$refs.mheadheight.clientHeight;
    this.advertisement();
    // this.settimecontact()
  },
  watch: {
    touchto(newValue, oldValue) {
      this.touchtofun(newValue);
    },
  },
  methods: {
    clickisshwoLang() {
      this.isshwoLang = !this.isshwoLang
    },
    bindHome() {
      console.log("点击");
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      this.$router.push(paths + "/m_home");
    },
    bindLanguage(num) {
      sessionStorage.setItem("language", num);
      this.$emit("bindLanguage", num);

      let fullPath = this.$route.fullPath;

      fullPath = fullPath.replace("/En", "");
      fullPath = fullPath.replace("/It", "");
      console.log(fullPath);

      if (num == 1) {
        this.$router.replace(fullPath);
      } else if (num == 2) {
        this.$router.replace("/En" + fullPath);
      } else {
        this.$router.replace("/It" + fullPath);
      }
      this.tohome()
    },
    xiazai() {
      var browser = {
        versions: (function () {
          var u = navigator.userAgent,
            app = navigator.appVersion;
          return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile:
              !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
          };
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase(),
      };

      if (
        browser.versions.ios ||
        browser.versions.iPhone ||
        browser.versions.iPad
      ) {
        // console.log('ios')//如果是ios系统 就显示APP Store 按钮
        // console.log( this.crystal.iosUrl)
        // alert('jjjjj')
        // return
        this.$router.push({
          path: "/download",
          query: {
            url: this.crystal.iosUrl,
            model: 1,
          },
        });
        // window.open("https://new.vifa.cn" +, "_blank");
      } else if (browser.versions.android) {
        // console.log('是Android系统')//如果是Android系统 就显示APP下载 按钮
        this.$router.push({
          path: "/download",
          query: {
            url: this.crystal.androidUrl,
            model: 2,
          },
        });
        // window.open("https://new.vifa.cn" + this.crystal.androidUrl, "_blank");
      }
      // let crystal = JSON.stringify(this.crystal);
      // if (isWeixin) {
      //   this.iswxtip = true;
      // } else {
      //   this.iswxtip = false;

      //   return;
      // }
    },

    touchtofun(val) {
      if (val == 1) {
        this.mheadtop = 0;
        this.isheighta = this.$refs.mheadheight.clientHeight;
      } else {
        this.mheadtop = -this.$refs.mheadheight.clientHeight;
        this.isheighta = 0;
      }
    },
    getPageData() {
      getHead()
        .then((res) => {
          // console.log(res)
          // res.smartHome
          this.crystal = toRaw(res.smartHome);
          console.log(this.crystal);
          this.qrcodeData = [
            {
              qrcode: res.contactus.gzhImage,
              qrcodeicon: weixin,
            },
            {
              qrcode: res.contactus.xcxImage,
              qrcodeicon: xiaochengxu,
            },
            {
              qrcode: res.contactus.dyImage,
              qrcodeicon: douyin,
            },
            {
              qrcode: res.contactus.xhsImage,
              qrcodeicon: xiaohongshu,
            },
          ];
        })
        .catch((res) => {
          console.log(res);
        });
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    zhuandong() {
      this.isaaa = !this.isaaa;
 
      this.$emit("isaaa", this.isaaa);
      if (this.mheadmuseleft == 0) {
        this.isrotateaani = false;
        this.isrotateaanion = true;
        this.isrotatebani = false;
        this.isrotatebanion = true;
        this.mheadmuseleft = document.body.clientWidth;
        this.ismnavxuan = false;
        this.isshidao = true;
        document.querySelector("video").style.display="block"
      
      } else {
        document.querySelector("video").style.display="none"
        this.isrotateaani = true;
        this.isrotateaanion = false;
        this.isrotatebani = true;
        this.isrotatebanion = false;
        this.mheadmuseleft = 0;
        this.ismnavxuan = true;
        this.isshidao = false;
        this.isshidaoimg = true;

      }
    },
    clickcpkj() {
      this.iscpkj = !this.iscpkj;
    },
    clickpzhfw() {
      this.ispzhfw = !this.ispzhfw;
    },
    clickalzs() {
      this.isalzs = !this.isalzs;
    },
    clickalzx() {
      this.isalzx = !this.isalzx;
    },
    toabout() {
      this.$router.push("/mAbout");
      this.mheadmuseleft = document.body.clientWidth;
      this.ismnavxuan = false;
      this.isshidao = true;
      this.isrotateaani = false;
      this.isrotateaanion = true;
      this.isrotatebani = false;
      this.isrotatebanion = true;
    },
    tohome() {
      this.mheadmuseleft = document.body.clientWidth;
      this.ismnavxuan = false;
      this.isshidao = true;
      this.isrotateaani = false;
      this.isrotateaanion = true;
      this.isrotatebani = false;
      this.isrotatebanion = true;
    },

    //    tohome(){
    //         this.mheadmuseleft = document.body.clientWidth
    //         this.ismnavxuan = false
    //         this.isshidao = true
    //             this.isrotateaani = false
    //             this.isrotateaanion = true
    //             this.isrotatebani = false
    //             this.isrotatebanion = true
    //     },

    tomcontact() {
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      this.$router.push(paths + "/mcontact");
      this.mheadmuseleft = document.body.clientWidth;
      this.ismnavxuan = false;
      this.isshidao = true;
      this.isrotateaani = false;
      this.isrotateaanion = true;
      this.isrotatebani = false;
      this.isrotatebanion = true;
    },
    bigqrcon(index) {
      // if (index == 0) {
      //     window.open('http://weixin.qq.com/r/gHXg73nEEkdth2iMnyCk','_blank')
      // }
      if (index == 3) {
        window.open(
          "https://www.xiaohongshu.com/user/profile/5dd4e7690000000001006037",
          "_blank"
        );
      } else if (index == 2) {
        window.open("https://v.douyin.com/89h1cdj/", "_blank");
      } else {
        this.isshowqrcon = true;
        this.isbigqrconimg = index;
        document.body.addEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.addEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      }
    },
    close() {
      this.isshowqrcon = false;
      document.body.removeEventListener("touchmove", this.defferScroll, {
        passive: false,
      });
      document.body.removeEventListener("wheel", this.defferScroll, {
        passive: false,
      });
    },
    guangaoshows() {
      this.ishdtk = true;
      this.ishdgj = false;
    },
    gocontact() {
      this.ishdtk = false;
      this.ishdgj = true;
      this.$router.push({ path: "/mcontact", query: { id: 1 } });
      if (this.$route.path.indexOf("/mcontact") != -1) {
        console.log(" 包含contact");
        document.documentElement.scrollTop = this.$refs.search.offsetTop - 19;
      }
      this.intime = 0;
      clearInterval(this.issettime);
      clearTimeout(this.isTimeout);
    },
    chossgg() {
      this.ishdtk = false;
      this.ishdgj = true;
      this.intime = 0;
      clearInterval(this.issettime);
      clearTimeout(this.isTimeout);
    },
    advertisement() {
      let startdata = new Date("2022/8/12").getTime();
      let enddata = new Date("2022/10/10").getTime();
      let nowdata = new Date().getTime();
      console.log("时间", new Date("2017/8/10").getTime());
      if (startdata <= nowdata && nowdata <= enddata) {
        // console.log('显示')
        this.ishdgj = false;
        this.ishdtk = true;
      }
    },
    settimecontact() {
      var t = this;
      t.issettime = setInterval(function () {
        t.intime = t.intime - 1;
        console.log(t.intime);
        if (t.intime <= 0) {
          clearInterval(t.issettime);
        }
      }, 1000);
      t.isTimeout = setTimeout(function () {
        t.ishdtk = false;
        t.ishdgj = true;
        t.$router.push({ path: "/mcontact", query: { id: 1 } });
      }, 10000);
    },
  },
};
</script>

<style scoped>
body {
  padding-bottom: env(safe-area-inset-bottom);
}

.mheader {
  width: 750px;
  text-align: left;
  position: relative;
}

.height136 {
  /* height: 136px; */
  width: 100%;
}

.mhead {
  width: 100%;
  height: 136px;
  background: #fff;
  position: fixed;
  left: 0;
  z-index: 99999;
}

.mhead img {
  position: absolute;
}

.mhead .mlogo {
  height: 70px;
  position: absolute;
  left: 40px;
  top: 33px;
}

.routerbox .routerlink {
  height: 136px;
  width: 200px;
}

.mhead .mnav img {
  /* height: 50px; */
  position: absolute;
  right: 40px;
  top: 43px;
  width: 40px;
}

.mhead .mnav img.cuo {
  width: 30px;
}

.shidaoimg {
  opacity: 0;
}

.mheadmuse {
  width: 750px;
  background: #fff;
  z-index: 99999;
  position: fixed;
  top: 136px;
  height: 100%;
  overflow: auto;
}

.mheadmuse ul {
  width: 670px;
  margin: 0 auto;
}

.mheadmuse ul li {
  position: relative;
  line-height: 2;
  /* font-size: 32px; */
  font-size: 38px;
  font-weight: 100;
}

.mheadmuse ul li .title {
  position: relative;
  padding: 10px 0;
}

.mheadmuse ul li .title a {
  display: inline-block;
  font-weight: 100 !important;
}

.mheadmuse ul li .title span {
  font-size: 60px;
  font-weight: 100;
  position: absolute;
  top: -20px;
  right: 0;
}

.mheadmuse ul li .title_list {
  font-size: 30px;
  color: #4c413d;
  border-top: 1px solid #4c413d;
  padding: 10px 0;
}

.mheadmuse ul li .yingcpkj {
  animation: yingcpkjani 0.5s;
  animation-fill-mode: forwards;
}

.mheadmuse ul li .cpkj {
  animation: yingcpkjxani 0.5s;
  animation-fill-mode: forwards;
}

.mheaddaoh-move {
  transition: transform 0.5s;
}

.mnavxuan {
  animation: mnavtransform 0.5s;
  animation-fill-mode: forwards;
}

.shidao {
  animation: mnavtransformd 0.25s;
  animation-fill-mode: forwards;
}

.mnavshow {
  animation: ismnavshow 0.25s 0.25s;
  animation-fill-mode: forwards;
}

.rotateaani {
  animation: isrotateaaniaa 0.5s;
  animation-fill-mode: forwards;
}

.rotateaanion {
  animation: isrotateaanionaa 0.5s;
  animation-fill-mode: forwards;
}

.rotatebani {
  animation: isrotatebaniaa 0.5s;
  animation-fill-mode: forwards;
}

.rotatebanion {
  animation: isrotatebanionaa 0.5s;
  animation-fill-mode: forwards;
}

@keyframes isrotateaaniaa {
  0% {
    transform: rotate(0deg);
    flex: 1;
  }

  50% {
    transform: rotate(0deg);
    flex: none;
  }

  /* 75%{transform:rotate(45deg);flex: none;} */
  100% {
    transform: rotate(45deg);
    flex: none;
  }
}

@keyframes isrotateaanionaa {
  0% {
    transform: rotate(45deg);
    flex: none;
  }

  /* 25%{transform:rotate(45deg);flex: none;} */
  50% {
    transform: rotate(0);
    flex: none;
  }

  100% {
    transform: rotate(0);
    flex: 1;
  }
}

@keyframes isrotatebaniaa {
  0% {
    transform: rotate(0deg);
    flex: 1;
  }

  50% {
    transform: rotate(0deg);
    flex: none;
  }

  /* 75%{transform:rotate(0deg);flex: none;} */
  100% {
    transform: rotate(-45deg);
    flex: none;
  }
}

@keyframes isrotatebanionaa {
  0% {
    transform: rotate(-45deg);
    flex: none;
  }

  /* 25%{transform:rotate(0);flex: none;} */
  50% {
    transform: rotate(0);
    flex: none;
  }

  100% {
    transform: rotate(0);
    flex: 1;
  }
}

@keyframes yingcpkjxani {
  0% {
    height: 0;
  }

  100% {
    height: 98px;
  }
}

@keyframes title_listxani {
  0% {
    height: 0;
  }

  100% {
    height: 72px;
  }
}

@keyframes yingcpkjani {
  0% {
    height: 98px;
  }

  100% {
    height: 0;
  }
}

@keyframes title_listani {
  0% {
    height: 72px;
  }

  100% {
    height: 0;
  }
}

@keyframes mnavtransform {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes mnavtransformd {
  0% {
    transform: rotate(90deg);
    opacity: 1;
  }

  100% {
    transform: rotate(0deg);
    opacity: 0;
  }
}

@keyframes ismnavshow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mqrco {
  width: 670px;
  margin: 0 auto;
  padding: 60px 0;
  border-bottom: 1px solid;
}

.mqrco>div {
  /* width: 37rem; */
  width: 400px;
  display: flex;
}

.mqrco>div>div {
  flex: 1 25%;
  text-align: center;
}

.mqrco>div>div img {
  width: 45px;
  height: 45px;
}

.mqrco>div>div:nth-child(1) img {
  width: 56px;
}

.mheadfoot {
  width: 670px;
  margin: 0 auto;
  padding: 60px 0;
  font-size: 18px;
}

.isshowqrcon {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999999999;
}

.isshowqrcon div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.isshowqrcon div img {
  margin: 0 auto;
  max-width: 100%;
}

.mnav {
  position: absolute;
  right: 40px;
  top: 43px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mnav p {
  /* width: 40px;
    height: 1px;
    background: #000; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mnav p span {
  display: block;
  width: 40px;
  height: 1px;
  background: #000;
}

.cm-guazai {
  width: auto;
  height: auto;
  position: fixed;
  bottom: 150px;
  right: 50px;
  z-index: 100000;
  cursor: pointer;
}

.cm-guazai img {
  z-index: 100000;
  width: 80px;
  height: auto;
}

.cm-showhd {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-show-tk {
  width: 710px;
  height: auto;
  position: relative;
}

.cm-show-pcnew {
  width: 710px;
  height: auto;
}

.cm-choos {
  /* position: absolute; */
  /* top: 15px; */
  /* right: 15px; */
  width: 45px;
  height: 45px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 45px;
}

.cm-choos img {
  width: 45px;
  height: 45px;
}

.cm-ljzx {
  position: absolute;
  width: 200px;
  height: auto;
  top: 0px;
  /* margin: 0 auto; */
  margin-top: 740px;
  margin-left: 50%;
  left: -100px;
  cursor: pointer;
}

.cm-ljzx img {
  width: 200px;
  height: auto;
}

.cm-intime-s {
  position: absolute;
  top: 10px;
  right: 50px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  /* cursor: pointer; */
  color: white;
}

.language {
  position: absolute;
  left: 250px;
  bottom: 30px;
  z-index: 100000;
}

.language button {
  width: 100px;
  margin-right: 30px;
}

.myspan {
  color: #000;
  text-decoration: none;
  display: block;
}

.mheadmuse ul li .title strong {
  display: inline-block;
}

p strong {
  word-wrap: break-word !important;
}

strong {
  color: rgb(70,70,70);
  text-decoration: none;
  display: block;
  font-weight: 100 !important;
  /* font-weight:normal, */
}
</style>
