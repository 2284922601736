<template>
    <div>
        <div class="newdetail">
            <div class="aboutBaner">
                <img src="@/common/image/aboutbanner.jpg" alt="">
                <p>威法定制 大宅至简</p>
            </div>
            <div class="founder spaceCustomization">
                <div class="yishu"></div>
                <div class="beijing">{{object.title}}</div>
                <div class="newdetailtime" >{{object.publishDate}}</div>       
            </div>
            <div class="newcont">
                <div v-html="object.content" class="newcontnei" ref="object">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getEnterpriseNews } from "@/api/api";
import { getEnterpriseDynamic } from "@/api/api";


export default {
  name: '',
  data(){
      return{
          object:{}
      }
  },
  components: {
  },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace('/mnewDetails?id='+this.$route.query.id+'&isnew='+this.$route.query.isnew);
        } else {
            this.$router.replace(paths+'/newDetails?id='+this.$route.query.id+'&isnew='+this.$route.query.isnew);
        }
        let data = {
            id:this.$route.query.id
        }
        if(this.$route.query.isnew == 0){
            this.getnewsData(data)
        }else{
            this.getEnterprissData(data)
        }
  },
  methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getnewsData(data){
            getEnterpriseNews(data)
            .then(res => {
                this.object = res.object
                let str="video"
                let indext=res.object.content.indexOf(str)
                // console.log(this.$refs.object.contains("video"))
                if(indext!=-1){
                 res.object.content=  res.object.content.slice(0,indext-0+5)+" autoplay"+res.object.content.slice(indext-0+5)
                     this.object = res.object
                }
            })
            .catch(res => {
                console.log(res)
            });
        },
        getEnterprissData(data){
            getEnterpriseDynamic(data)
            .then(res => {
                console.log(res)
                this.object = res.object
            })
            .catch(res => {
                console.log(res)
            });
        },
    }
}
</script>

<style>
.newdetail .founder .yishu{
    margin-top: 50px;
    height: 35px;
}
.newdetail .founder .beijing{
    font-size: 30px;
    padding-top: 35px;
    line-height: 40px;
}
.newdetailtime{
    color:#b2aeac;
    padding-bottom: 35px;
}
.newcont{
    text-align: justify;
}
.newcontnei{
    width: 1200px;
    margin: 0 auto;
}
.newcontnei p {
    padding-bottom: 30px;
    line-height: 2;
}
.newcont img{
    width: 100%;
    margin-top: 20px;
    /* margin-bottom: 50px; */
}
.newcontnei p.navtit{
    font-size: 25px;
}
</style>
