<template>
  <div>
    <!-- <Header/> -->
    <div class="about">
      <div :class="{ jianbianbanner: isaboutBaner }" ref="aboutBaner">
        <div class="aboutBaner">
          <img src="@/common/image/aboutbanner.jpg" alt="" />
          <p class="pwjstrone">VIFA Customization  Supreme Simplicity</p>
        </div>
      </div>

      <div class="founder">
        <ul>
          <li
            v-for="(item, index) in founderUl"
            :key="index"
            :class="{ active: hover == index }"
            @mouseover="founderUlMouseover(index)"
          >
            <div class="cursor">
              <!-- <p class="cursorNum">{{item.num}}</p> -->
              <p @click="taber(index)">{{ item.name }}</p>
            </div>
          </li>
        </ul>
        <transition-group name="yishuani">
          <div
            class="yishu yishuani"
            :style="{ marginLeft: yishuani + 'px' }"
          ></div>
        </transition-group>
        <div class="jiaolian" ref="jiaolianref"></div>
      </div>
      <component :is="comName"></component>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import BrandProfile from "./brandProfile";
import BrandConcept from "./brandConcept";
import EnterpriseDynamics from "./enterpriseDynamics";

export default {
  name: "about",
  data() {
    return {
      hover: 0,
      founderUl: [
        { name: "Brand introduction" },
        { name: "Brand concept" },
        { name: "Business information" },
      ],
      yishuani: 200,
      jiaolianrefwidth: 1200,
      yishuanicl: 200,
      comName: "BrandProfile",
      isaboutBaner: false,
    };
  },
  components: {
    // Header,
    // Footer,
    BrandProfile,
    BrandConcept,
    EnterpriseDynamics,
  },
  mounted() {

    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mAbout");
    } else {
      this.$router.replace(paths + "/about");
    }
    this.isaboutBaner = true;
    // console.log(document.title)
    window.exec_main(document.title);
    this.jiaolianrefwidth = this.$refs.jiaolianref.clientWidth;
    this.yishuanicl = this.jiaolianrefwidth / 6;
    this.yishuani = this.jiaolianrefwidth / 6;
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //   exec_main(title){
    //             var text = encodeURIComponent(title);

    //             var url = 'https://www.vifa.cntest/middle.html?v=' + Math.random()+'&title='+text

    //             if(typeof(exec_obj)=='undefined'){
    //             console.log(1111)
    //                 exec_obj = document.createElement('iframe');
    //                 exec_obj.name = 'tmp_frame';
    //                 exec_obj.src = url
    //                 exec_obj.style.display = 'none';
    //                 document.body.appendChild(exec_obj);
    //             }else{
    //             console.log(222222)
    //                 exec_obj.src = url
    //             }
    //             console.log(3333)
    //         }  ,
    founderUlMouseover(index) {
      // this.yishuani = 200 + 1200/this.founderUl.length*index
      this.yishuani =
        this.yishuanicl +
        (this.jiaolianrefwidth / this.founderUl.length) * index;
    },
    taber(index) {
      this.hover = index;
      if (index == 0) {
        this.comName = BrandProfile;
      } else if (index == 1) {
        this.comName = BrandConcept;
      } else if (index == 2) {
        this.comName = EnterpriseDynamics;
      }
    },
  },
};
</script>

<style>
/* .about{
    padding-top: 159px;
} */
.aboutBaner {
  position: relative;
}
.aboutBaner p {
  position: absolute;
  font-size: 40px;
  left: 0;
  top: 40%;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  letter-spacing: 10px;
  font-weight: 100;
}
.about .founder {
  padding-top: 60px;
}
.about .founder .yishuani {
  margin: 18 auto 0;
  height: 25px;
}
.about .jiaolian {
  bottom: 12.5px;
}
.about .brandConcept .brandProfile_introduce {
  padding-top: 50px;
  white-space: pre-wrap;
}
</style>
