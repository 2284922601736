<template>
  <div class="enterpriseDynamics duijie">
    <div class="jianbianq" :class="{ jianbianh: isnewlix }" ref="newlix">
      <div class="newlix" ref="newlistul">
        <div class="title">
          <div>企业新闻</div>
          <router-link :to="{ path: '/allList', query: { listbanner: 0 } }"
            ><p>全部新闻</p></router-link
          >
        </div>
        <div class="enterpriseDynamics_box">
          <div
            class="enterpriseDynamics_news tran"
            :style="{ marginLeft: newLfet + 'px', width: newWidth + 'px' }"
          >
            <ul v-for="(item, index) in enterpriseNews" :key="index" style="">
              <li @mouseenter="newcenterenter" @mouseleave="newcenterleave">
                <div class="newImg">
                  <img :src="'https://www.vifa.cn' + item.image" alt="" />
                </div>
                <p>{{ item.topic }}</p>
                <div class="newName">{{ item.title }}</div>
                <div class="newMore">
                  <span>{{ item.publishDate }}</span>
                  <span class="myspan" @click="goNewDetails(item.id, 0)">
                    <button
                      @mouseleave="moremouseleaved"
                      :class="{ moreleavh: ismoreleavd }"
                    >
                      了解更多
                    </button>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="newswi">
        <div class="left" @click="newswiLfet"><p></p></div>
        <div class="center">
          <ul>
            <li
              v-for="(item, index) in 4"
              :key="index"
              :class="{ active: isactive == index }"
              @click="newzhiindex(index)"
            >
              <span class="no"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span><span class="yes"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span>
            </li>
          </ul>
        </div>
        <div class="right" @click="newswiRight"><p></p></div>
      </div>
    </div>
    <div class="jianbianq" :class="{ jianbianh: ishuodongx }" ref="huodongx">
      <div class="newlix huodongx">
        <div class="enterpriseDynamics_box qiyehuo">
          <div
            class="enterpriseDynamics_news tran"
            :style="{ marginLeft: qhLfet + 'px', width: qhWidth + 'px' }"
          >
            <ul
              v-for="(item, index) in enterpriseDynamic"
              :key="index"
              style=""
            >
              <li @mouseenter="qhcenterenter" @mouseleave="qhcenterleave">
                <div class="newImg">
                  <img :src="'https://www.vifa.cn' + item.image" alt="" />
                </div>
                <p>{{ item.topic }}</p>
                <div class="newName">{{ item.title }}</div>
                <div class="newMore">
                  <span>{{ item.publishDate }}</span>
                  <span class="myspan" @click="goNewDetails(item.id, 1)">
                    <button
                      @mouseleave="moremouseleaved"
                      :class="{ moreleavh: ismoreleavd }"
                    >
                      了解更多
                    </button>
                  </span>
                  <!-- <router-link
                    :to="{
                      path: '/newDetails',
                      query: { id: item.id, isnew: 1 },
                    }"
                    ></router-link
                  > -->
                </div>
              </li>
              <!-- <li class="title">
                                <div>企业活动{{index}}</div>
                                <p>全部活动</p>
                            </li> -->
            </ul>
          </div>
        </div>
        <div class="title">
          <div>企业活动</div>
          <router-link :to="{ path: '/allList', query: { listbanner: 1 } }"
            ><p>全部活动</p></router-link
          >
        </div>
      </div>
      <div class="newswi">
        <div class="left" @click="qhswiLfet"><p></p></div>
        <div class="center">
          <ul>
            <li
              v-for="(item, index) in enterpriseDynamic.slice(
                0,
                enterpriseDynamicnum
              )"
              :key="index"
              :class="{ active: isqhactive == index }"
              @click="qyhdzhiindex(index)"
            >
              <span class="no"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span><span class="yes"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span>
            </li>
          </ul>
        </div>
        <div class="right" @click="qhswiRight"><p></p></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutVifaall } from "@/api/api";
import axios from "axios";
import { MD5 } from "@/hooks/usePoint.js";
export default {
  name: "enterpriseDynamics",
  data() {
    return {
      noLast: true,
      storeImgWidth: document.body.clientWidth,
      isactive: 0,
      isqhactive: 0,
      newLfet: 0,
      qhLfet: 0,
      ismoreleava: false,
      ismoreleavb: false,
      ismoreleavc: false,
      ismoreleavd: false,
      npposition: true,
      qhnoposition: true,
      isnewlix: false,
      ishuodongx: false,
      enterpriseNewsnum: 1,
      enterpriseDynamicnum: 1,
      enterpriseNews: [],
      enterpriseDynamic: [],
      newWidth: 4000,
      qhWidth: 4000,
      pwjisactive: 0,
    };
  },
  components: {},
  mounted() {
    this.storeSwiper();
    window.addEventListener("scroll", this.handleScroll);
    this.isnewlix = true;
    this.getAboutVifaData();
  },
  methods: {
    goNewDetails(id, isnew) {
      let paths;
      if (sessionStorage.getItem("language") == 1) {
        paths = "";
      } else if (sessionStorage.getItem("language") == 2) {
        paths = "/En";
      } else {
        paths = "/It";
      }
      this.$router.push({
        path: paths + "/newDetails",
        query: { id: id, isnew: isnew },
      });
    },
    getAboutVifaData() {
      getAboutVifaall()
        .then(async (res) => {
          // .forEach((item,index)=>{
          //  item.title= getTranslation(item.title)
          //  })
          this.enterpriseNews = res.enterpriseNews;
          this.enterpriseDynamic = res.enterpriseDynamic;

          this.enterpriseNewsnum = Math.ceil(this.enterpriseNews.length / 2);
          this.newWidth =
            (this.$refs.newlistul.offsetWidth / 3 + 1) *
            this.enterpriseNews.length;
          this.enterpriseDynamicnum = Math.ceil(
            this.enterpriseDynamic.length / 2
          );
          this.qhWidth =
            (this.$refs.newlistul.offsetWidth / 3 + 1) *
            this.enterpriseDynamic.length;
        })
        .catch((res) => {
          // console.log(res);
        });
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        if (
          this.$refs.newlix.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isnewlix = false;
        }
        if (
          this.$refs.newlix.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isnewlix = true;
        }
        if (
          this.$refs.huodongx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.ishuodongx = true;
        }

        // }else if (e.deltaY < 0) {
        if (
          this.$refs.newlix.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isnewlix = false;
        }
        if (
          this.$refs.huodongx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.ishuodongx = false;
        }
      });
      // }
    },
    storeSwiper() {
      setInterval(() => {
        if (this.npposition) {
          if (this.enterpriseNewsnum - 1 > this.pwjisactive) {
            this.isactive++;
            this.pwjisactive++;
            if (this.isactive > 3) {
              this.isactive = 3;
            }
            this.newLfet =
              (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.pwjisactive;
          } else {
            this.isactive = 0;
            this.pwjisactive = 0;
            this.newLfet = 0;
          }
        }
        if (this.qhnoposition) {
          if (this.enterpriseDynamicnum - 1 > this.isqhactive) {
            this.isqhactive++;
            this.qhLfet =
              (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.isqhactive;
          } else {
            this.isqhactive = 0;
            this.qhLfet = 0;
          }
        }
        // this.pwjisactive = this.pwjisactive + 1;

        // if (this.pwjisactive >= this.enterpriseNewsnum - 1) {
        //   this.pwjisactive = 0
        //    this.isactive = 0
        // } else {
        //   if (this.pwjisactive > 3) {
        //     this.isactive = 3;
        //   } else {
        //     this.isactive++;
        //   }
        // }

        // this.newLfet =
        //   (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.pwjisactive;
      }, 5000);
    },
    newswiLfet() {
      this.pwjisactive = this.pwjisactive - 1;
      if (this.pwjisactive < 0) {
        this.pwjisactive = 0;
      }

      if (this.isactive <= 0) {
        this.isactive = 0;
      } else {
        this.isactive--;
      }
      // this.isactive ++
      console.log(this.pwjisactive);
      this.newLfet =
        (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.pwjisactive;
      // if (this.isactive>0) {
      //     this.isactive = this.isactive-1
      //     this.newLfet = -this.$refs.newlistul.offsetWidth/3*2 * this.isactive
      // }
    },
    newswiRight() {
      this.pwjisactive = this.pwjisactive + 1;
      if (this.pwjisactive >= this.enterpriseNewsnum - 1) {
        this.pwjisactive = this.enterpriseNewsnum - 1;
      }

      if (this.pwjisactive > 3) {
        this.isactive = 3;
      } else {
        this.isactive++;
      }
      // this.isactive ++
      console.log(this.pwjisactive);
      this.newLfet =
        (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.pwjisactive;
      // if (this.isactive < this.enterpriseNewsnum - 1) {

      // }
    },
    qhswiLfet() {
      if (this.isqhactive > 0) {
        this.isqhactive = this.isqhactive - 1;
        this.qhLfet =
          (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.isqhactive;
      }
    },
    qhswiRight() {
      if (this.isqhactive < this.enterpriseDynamicnum - 1) {
        this.isqhactive = this.isqhactive + 1;
        this.qhLfet =
          (-this.$refs.newlistul.offsetWidth / 3) * 2 * this.isqhactive;
      }
    },
    moremouseleavea() {
      this.ismoreleava = true;
    },
    moremouseleaveb() {
      this.ismoreleavb = true;
    },
    moremouseleavec() {
      this.ismoreleavc = true;
    },
    moremouseleaved() {
      this.ismoreleavd = true;
    },
    newcenterenter() {
      this.npposition = false;
    },
    newcenterleave() {
      this.npposition = true;
    },
    qhcenterenter() {
      this.qhnoposition = false;
    },
    qhcenterleave() {
      this.qhnoposition = true;
    },
    newzhiindex(index) {
      // this.isactive = index
      console.log(this.pwjisactive, this.pwjisactive > index);
      if (this.pwjisactive > index) {
        if (this.pwjisactive < 3) {
          this.isactive = index;
          this.pwjisactive = index;
          this.newLfet = -800 * this.pwjisactive;
          return;
        }

        this.pwjisactive = this.pwjisactive - (3 - index);
        this.isactive = index;
        this.newLfet = -800 * this.pwjisactive;
      } else {
        this.pwjisactive = index;
        this.isactive = index;
        this.newLfet = -800 * this.pwjisactive;
      }
      // this.newLfet = -800 * this.isactive
    },
    qyhdzhiindex(index) {
      this.isqhactive = index;
      this.qhLfet = -800 * this.isqhactive;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../../common/styles/about.css";
.duijie .enterpriseDynamics_news ul {
  width: 400px;
}
.duijie .enterpriseDynamics_box {
  margin-bottom: 0;
}
.duijie .enterpriseDynamics_news ul li > p {
  height: 60px;
}
.duijie .huodongx .title,
.duijie .newlix .title {
  height: 454px;
}
.duijie .enterpriseDynamics_news ul li img {
  width: 374px;
  height: 246px;
}
.newlix .title p {
  color: #f08158;
  border-bottom: 1px solid #f08158;
}
.myspan {
  color: #000;
  text-decoration: none;
  display: block;
}
</style>
