<template>
    <div class="zhichi">
        <ul>
            <li>
                <img src="@/common/image/hzlcicon1.png" alt="">
                <p>双方洽谈</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon2.png" alt="">
                <p>达成协议</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon3.png" alt="">
                <p>店面选址</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon4.png" alt="">
                <p>总部考察认可</p>
            </li>
        </ul>
        <div class="guaiwang"></div>
        <ul>
            <li>
                <img src="@/common/image/hzlcicon5.png" alt="">
                <p>装修及上样</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon6.png" alt="">
                <p>店面设计及样品规划</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon7.png" alt="">
                <p>签订经销合同</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon8.png" alt="">
                <p>缴纳费用及保证金</p>
            </li>
        </ul>
        <div class="guaiwangLeft"></div>
        <ul>
            <li>
                <img src="@/common/image/hzlcicon9.png" alt="">
                <p>员工培训</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon10.png" alt="">
                <p>软装及电器到位</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon11.png" alt="">
                <p>开业策划</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon12.png" alt="">
                <p>店面开业</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name:'hezuoliucheng',

}
</script>

<style>
.zhichi ul{
    text-align: center;
    display: flex;
}
.zhichi ul li{
    flex: 1 150px;
}
.zhichi ul li img{}
.zhichi ul li p{
    padding: 15px 0;
}
.zhichi ul .henggang{
    flex: 1 200px;
    height: 1px;
    background: #000;
    margin-top: 30px;
}
.guaiwang{
    height: 40px;
    border-right: 1px solid #000;
    margin-right: 75px;
    margin-bottom:25px;
}
.guaiwangLeft{
    height: 40px;
    border-left: 1px solid #000;
    margin-left: 75px;
    margin-bottom:25px;
}
</style>