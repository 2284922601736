<template>
  <div>
    <div class="aboutBaner">
      <img :src="'https://www.vifa.cn' + bannerImage" alt="" />
      <!-- bannn1111 -->
      <!-- <img src="./img/bannn111.jpg" alt=""> -->
    </div>
    <!-- <div class="pplstext">
      <div class="title">工程案例简介</div>
      <p v-html="synopsisContent"></p>
    </div> -->
    <div class="projectlist">
      <div class="hometopswipt"></div>
      <div
        class="projectitem"
        v-for="(item, index) in projectCaseList"
        :key="item.id"
      >
        <div class="imgandtitle">
          <img
            :src="'https://www.vifa.cn' + item.image"
            alt=""
            class="projectitem_img"
          />
          <div class="projectitem-title">
            <p class="projectitem-title_left">
              {{ item.caseNameEng }}
            </p>
            <p class="projectitem-title_right" @click="projectitem_show(index)">
              +
            </p>
          </div>
        </div>
        <div class="projectitem-title_center" @click="cktj(item.id)">Complete atlas <span class="xian"></span></div>
        <div
          :class="[
            item.isshowhide
              ? 'projectitem_introduce_show'
              : 'projectitem_introduce',
          ]"
        >
          {{ item.contentEng }}
        </div>
      </div>
    </div>
    <div class=" quanpin tuji" v-if="istujishow" @click="clotuji">
      <div class="pinpailishi">
        <div class="pplsimg">
          <ul class="felx pinpailishiani" :style="{transform:'translateX('+touchindex*-100+'%)'}"   @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend">
                <li v-for="(item,index) in photoAlbumImages" :key="index">
                <img :src="'http://www.vifa.cn'+item" alt="">
                </li>
              </ul>
        </div>
      </div>
    </div>
    <!-- <div></div> -->
    <!-- 图片展示 -->
    <div class="pages">
            <div class="prev" @click="prev" v-show="isprev"><p></p></div>
            <ul>
                <!-- <li v-for="(item,index) in listData.slice(pagestart, pageend)" :key="index" class="cur" @click="getpages(index+1)" :class="{'active':isactive==index}">{{index+1}}</li> -->
                <li v-for="index in (pagestart,pageend)" :key="index" class="cur" @click="getpages(index)" :class="{'active':isactive==index}"><span class="gaoliang">+</span><span class="nogl">-</span></li>
            </ul>
            <div class="next" @click="next" v-show="isnext"><p></p></div>
        </div>
  </div>


<div class="projectlist">
      <div class="hometopswipt"></div>
    </div>
  <div class="c-project-jf">
                <h1>Project management delivery process  </h1>
            </div>
            
        <div class="c-project-list" ref="nameproject">
         <div class="c-project-lc">
           <div class="c-project-left xm-left">
              <div class="c-projecttextnb">
                <h1>1</h1>
              </div>
              <div class="c-projecttext-lc">
                Project start-up phase 
              </div>
           </div>
           <div class="c-project-right">
             <ul>
               <li style="width: 20%;" ><p>
                Design details</p>
                 <div></div>
               </li>
               <li style="width: 20%;">
                 <p>Construction disclosure</p>
                 <div></div>
               </li>
               <li style="width: 30%; ">
                 <p> Establishment of   <br>site management system</p>
                 <div></div>
               </li>
               <li style="width: 25%;">
                  <p>Preparation of project <br>installation delivery plan   </p>
                 <div></div>
               </li>
             </ul>
             
           </div>

            <div class="c-project-left2 c-project-topy xm-left">
              <div class="c-projecttextnb">
                <h1>2</h1>
              </div>
              <div class="c-projecttext-lc">
                Delivery sample phase
              </div>
           </div>
           <div class="c-project-right2 c-project-topy">
             <ul>
               <li><p>
                Delivery sample materials for preparation</p>
                 <div></div>
               </li>
               <li >
                 <p> Gauging and ordering   <br>of delivery sample</p>
                 <div></div>
               </li>
               <li>
                 <p> Installation of the delivery sample </p>
                 <div></div>
               </li>
             </ul>
             <div class="c-project-right-shu"></div>
             <ul class="c-ul-2">
               <li>
                    <p>
                 
                      Materials in bulk for preparation<br>Gauging and ordering of materials in bulk  Process, quality, </p>
                 <div></div>
               </li>
               <li>
                  <p>working face of delivery sample<br> Review and post-review improvement of installation</p>
             
                 <div></div>
               </li>
               
               <!-- <li>
                 <p> 现场管理体系建立</p>
                 <div></div>
               </li>
               <li>
                  <p>项目安装交付计划的编制 </p>
                 <div></div>
               </li> -->
             </ul>
           </div>


           <div class="c-project-left2 xm-left">
              <div class="c-projecttextnb">
                <h1>3</h1>
              </div>
              <div class="c-projecttext-lc">
                Site installation phase
              </div>
           </div>
           <div class="c-project-right2">
             <ul>
               <li><p>
                Check of working face on site</p>
                 <div></div>
               </li>
               <li>
                 <p>Bulk delivery arrangement</p>
                 <div></div>
               </li>
               <li>
                 <p>Organization and <br>arrangement of bulk installation</p>
                 <div></div>
               </li>
             </ul>
             <div class="c-project-right-shu"></div>
             <ul class="c-ul-2">
               <li class="c-danghen"><p>
                Bulk installation quality and schedule control</p>
                 <div></div>
               </li>
               <li class="c-danghen"   style="line-height:1.6rem" >
                 <p>Bulk installation technology disclosure <br>and safety civilization construction publicity</p>
                 <div></div>
               </li>
               <!-- <li>
                 <p> 现场管理体系建立</p>
                 <div></div>
               </li>
               <li>
                  <p>项目安装交付计划的编制 </p>
                 <div></div>
               </li> -->
             </ul>
           </div>

           <div class="c-project-left xm-left">
              <div class="c-projecttextnb">
                <h1>4</h1>
              </div>
              <div class="c-projecttext-lc">
                Completion settlement phase
              </div>
           </div>
           <div class="c-project-right">
             <ul>
               <li style="width: 30%;">
                 <p> Self-inspection</p><p> and self-correction</p>
                 <div></div>
               </li>
               <li style="width: 20%;">
                 <p>Finished product protection</p>
                 <div></div>
               </li>
               <li style="width: 20%;">
                 <p>Completion acceptance</p>
                 <div></div>
               </li>
               <li style="width: 20%;">
                 <p >Delivery to the owner </p>
                 <div></div>
               </li>
               <li style="width: 10%;">
                  <p>Settle accounts</p>
                 <div></div>
               </li>
             </ul>
           </div>
           <div class="c-project-left c-project-topy xm-left">
              <div class="c-projecttextnb">
                <h1>5</h1>
              </div>
              <div class="c-projecttext-lc">
                After-sales service phase
              </div>
           </div>
           <div class="c-project-right  c-project-right5 c-project-topy">
             <ul>
               <li style="width: 40%;" class="defew"><p>
                24-hour service of after-sales specialist   </p>
                 <div></div>
               </li>
               <li style="width: 40%;" class="defew">
                 <p>After-sale maintenance value-added service</p>
                 <div></div>
               </li>
               
             </ul>
           </div>
           
         </div>


         </div>
         <div class="c-shuxian"></div>
         <div class="c-Aftersale">
           <h1>Project after-sales service</h1>
           <div class="c-Aftersale-list">
             <div><img src="@/common/image/24page.png" alt=""><h2>Warranty service </h2></div>
             <p>During the warranty period, it is equipped with a full-time after-sales specialist to receive customer’s problems, provide service within 24 hours,</p> <p>make detailed records of after-sales problems and customer suggestions,</p> <p>organize designers and project managers to do analysis and research on problems and suggestions,</p> <p>and constantly improve product quality and meet customer needs.</p>
               
             
           </div>
           <div class="c-Aftersale-list">
             <div><img src="@/common/image/25page.png" alt=""><h2>After-sale maintenance value-added service</h2></div>
             <p>After-sale maintenance value-added service:  After the project warranty expires, </p>
             <p> vifa products can provide regular on-site maintenance services at the cost price according to the needs of engineering customers,</p> <p> to help customers maintain the cabinet/door/table hardware, so as to maintain the quality of product.</p>
             
             </div>
           
         </div>


</template>

<script>
// import {getProjectCasePage,getQualityService,} from '@/api/api'
// import Encase from './encase'
//工程经销商
// import Distributed from './distributed'
//工程项目
// import Itemquery from './itemquery'
import { getProjectInfo,getProjectCaseDetails, getProjectCasePage } from "@/api/api";
export default {
  name: "mbrief",
  data() {
    return {
      projectInfo: {},
      bannerImage: "",
      synopsisContent: "",
      projectCaseList: [],
      istujishow: false,
      kjmarginLeft: 0,
      kjwidth: 9999999,
      photoAlbumImages:[],
      touchstateX:0,
      istouch:false,
      touchindex:0,
      pages:{
                pageNo:1,
                pageSize:3
            }, 
      pagestart:0,
      pageend:10,
      isactive:1,
      isprev:true,
      isnext:true,
    };

  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getHomePageData() {
      getProjectInfo()
        .then((res) => {
          console.log(res);
          this.bannerImage = res.projectInfo.bannerMobileImageEng;
          this.projectInfo = res.projectInfo;
          this.synopsisContent = res.projectInfo.synopsisContent;
          for (let i = 0; i < res.projectCaseList.length; i++) {
            res.projectCaseList[i].isshowhide = false;
          }
         // this.projectCaseList = res.projectCaseList;
          console.log(projectCaseList);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    projectitem_show(index) {
      this.projectCaseList[index].isshowhide =
        !this.projectCaseList[index].isshowhide;
      console.log(this.projectCaseList[index].isshowhide);
    },
    getProjectCasePagelist(index) {
      if(index){
        var data={
        pageNo:index,
        pageSize:3
       }

      }else{
         var data={
        pageNo:1,
        pageSize:3
       }
      }
      
      getProjectCasePage(data)
        .then((res) => {
          console.log(res);
          // this.bannerImage = res.projectInfo.bannerImage;
          // this.projectInfo = res.projectInfo;
          // this.synopsisContent = res.projectInfo.synopsisContent;
          // for (let i = 0; i < res.projectCaseList.length; i++) {
          //   res.projectCaseList[i].isshowhide = false;
          // }
          this.projectCaseList = res.page.list;
          this.pageend= Math.ceil(res.page.count/3)
          // console.log(projectCaseList);
        })
        .catch((res) => {
          console.log(res);
        });
    },




    cktj(id) {
      this.istujishow = true;
      console.log(id)
      this.getDataimg({caseId:id})
    },
      clotuji() {
      this.istujishow = false;
      this.touchindex=0 
    },
   getDataimg(datas){
            getProjectCaseDetails(datas)
            .then(res => {
                console.log("getProjectCaseDetails",res)

                this.photoAlbumImages=res.projectCaseDetails.photoAlbumImages

                //this.qualityService = res.qualityService
                // this.tujiData = res.projectCaseDetails.photoAlbumImages
                // this.firstiamge = this.tujiData[0]
                // let tujilength = this.tujiData.length-1
                // this.lastiamge = this.tujiData[tujilength]
                // this.firstiamgea = this.tujiData[1]
                // this.lastiamgea = this.tujiData[tujilength-1]
        
                // this.tujiswiperWideh = (this.$refs.tujisli0.offsetWidth+1) * this.tujiData.length
                // console.log(this.tujiData[0].image)
                // console.log(this.lastiamge)
            })
            .catch(res => {
                console.log(res)
            });
        },
    touchstart(e){
      this.touchstateX=e.changedTouches[0].clientX
      this.istouch=true
    },
    touchmove(e){
     
      //  console.log( this.touchstateX,e)
       let touchX=this.touchstateX-e.changedTouches[0].clientX;
      //  console.log("touchX<0",touchX)
      if(this.istouch){
         if(touchX>0){
           console.log(this.touchindex)
           if(this.touchindex+1>=this.photoAlbumImages.length){
             return
           }
         this.touchindex=this.touchindex+1
          this.istouch=false
       }else{
          if(this.touchindex-1<0){
             return
           }
           this.touchindex=this.touchindex-1
          this.istouch=false
       }
      }

    },
      touchend(e){
        // this.istouch=true
      //  console.log( this.touchstateX,e)
      //  let touchX=this.touchstateX-e;

    },
    prev(){
            if (this.pagestart + this.isactive>1) {
                this.isactive--
                this.pages.pageNo=this.isactive+this.pagestart
               // this.getCasePages(this.pages)
                // this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
                this.getProjectCasePagelist(this.isactive+this.pagestart)
            }
            // else if(this.isactive==1){
            //     if (this.pagestart>0) {
            //         this.isactive = 8
            //         this.pagestart = this.pagestart - 8
            //         this.pages.pageNo=this.isactive+this.pagestart
            //         this.getCasePages(this.pages)
            //         window.scroll(0,0)
            //     }
            // }
        },
        getpages(index){
            //this.$router.replace({path:'/mCase',query:{page:index+this.pagestart}})
            
            this.isactive = index
            this.pages.pageNo=index+this.pagestart
            //this.getCasePages(this.pages)
            this.getProjectCasePagelist(index)
        }, 
        next(){
            if (this.isactive<this.pageend) {
                this.isactive++
                this.pages.pageNo=this.isactive+this.pagestart
                // this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
                this.getProjectCasePagelist(this.isactive+this.pagestart)
                // this.getCasePages(this.pages)
            }
            // else if(this.isactive==this.pageend+1){
            //     console.log(this.pagestart,this.pageend)
            //     if (this.pageend<this.zongyeshu) {
            //         this.isactive = 1
            //         this.pagestart = this.pagestart + 8
            //         if (this.zongyeshu - this.pagestart>=8) {
            //             this.pageend = 8
            //         }else{
            //             this.pageend = this.zongyeshu - this.pagestart
            //         }
            //         this.pages.pageNo=this.isactive+this.pagestart
            //         this.getCasePages(this.pages)
            //         window.scroll(0,0)
            //         this.$router.replace({path:'/mCase',query:{page:this.isactive}})
            //     }
            //     console.log(this.pagestart,this.pageend)
            // }
        },

    
  },
  mounted() {
        let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths+"/mbrief");
    } else {
      this.$router.replace(paths+"/general");
    }
    this.getHomePageData();
    this.getProjectCasePagelist()

     
    // document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
    // document.body.addEventListener("wheel", this.defferScroll, {passive: false});
    // setTimeout(()=>{
    //     this.$nextTick(()=>{
    //         this.pplsliwidth = this.$refs.pplsli0.clientWidth
    //         this.pplswidth = (this.$refs.pplsli0.clientWidth+1)*this.homeBrandIntroduce.length
    //         this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft
    //         this.pplsmarginLeft = this.pplsuloffsetLeft
    //         this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
    //     })
    // },1000)

    // setTimeout(() => {
    //     this.mutedautop = 'autoplay'
    //     document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
    //     document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
    //     this.islaipin = false
    //     this.isvideoshow = true
    // }, 6000)

    //     document.addEventListener('DOMContentLoaded', function () {
    //         function audioAutoPlay() {
    //             var musicEle0 = document.getElementById('music_mp3_0');
    //             musicEle0.play();
    //         }
    //         audioAutoPlay();
    //     });
  },
};
</script>
<style scoped>
.aboutBaner img {
  width: 100%;
  height: 517px;
}
.pplstext {
  margin: 4.1rem 5.5rem 4.1rem 5.5rem;
  text-align: center;
}
.title {
  font-size: 3rem;
  line-height: 5rem;
}
.pplstext p {
  line-height: 4rem;
}
.imgandtitle {
  position: relative;
  /* margin-top: 12px; */
}
.projectitem .imgandtitle img {
  width: 100%;
}
.projectitem-title {
}
.projectitem-title_right {
  font-size: 3rem;
  margin-right: 2rem;
}
.projectitem-title_left {
  margin-left: 2rem;
}
.projectitem .imgandtitle .projectitem-title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5.3rem;
  line-height: 5.3rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: rgba(0,0,0,.43137254901960786);
  color: white;
}
.projectitem_introduce {
  height: 14rem;
  font-size: 1.7rem;
  overflow: hidden;
  padding: 2rem 2rem;
  margin: 2rem 0;
  text-align: justify;
  line-height: 1.9;
}
.projectitem_introduce_show {
  /* height: 14rem; */
  font-size: 1.7rem;
  /* overflow: hidden; */
  padding: 2rem 2rem;
  margin: 2rem 0;
  text-align: left;
  line-height: 1.9;
}
.hometopswipt {
  /* margin-top: 8.4375rem; */
  width: 0.0625rem;
  height: 2.125rem;
  background: #f08518;
  margin: 8.4375rem auto 2.1875rem;
}
.projectitem-title_center {
  padding: 0.61rem 0;
}
.tuji {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99999999;
  padding-top: 0;
}
.tuji .pinpailishi {
  height: 100%;
}
.tuji .pplsimg {
  height: 100%;
  padding: 0;
  display: flex;
}
.pinpailishiani{
  transition: all 0.3s;
}
.felx{
  display: flex;
  align-items: center;
}
.felx li{
  /* height: 100%; */
  /* flex-grow: 0; */
  flex-shrink: 0;
  width: 100%;
}
.felx li img{
  width: 100%;
  height: auto;
  /* margin: 50% 0; */
}
.xian{
  display: block;
  width: 6.3rem;
  height: 1px;
  margin: 0 auto;
  color: #000;
  margin-top: 1rem;
  background: #000;
}
/* 分页样式 */
.pages{
    display: flex;
    justify-content: center;
    font-size: 26px;
    padding-bottom: 40px;

    border-bottom: 1px solid #000;
    width: 650px;
    margin: 40px auto;
    position: relative;
    padding: 0;
}
.pages div{
    /* border: 1px solid #f08518; */
}
.pages .prev{
    position: absolute;
    left: 0;
    top: -12px;
    width: 25px;
    height: 25px;
}
.pages .prev p{
    width: 1px;
    background: #000;
    height: 25px;
    margin: 0 auto;
}
.pages .next{
    position: absolute;
    right: 0;
    top: -12px;
    width: 25px;
    height: 25px;
}
.pages .next p{
    width: 1px;
    background: #000;
    height: 25px;
    margin: 0 auto;
}
.pages ul{
    display: flex;


    position: absolute;
    background: #fff;
    top: -15px;
}
.pages ul li{
    flex: 1;
    width: 30px;
    height: 30px;
    /* border: 1px solid #f08518; */
    margin: 0 10px;
    line-height: 30px;
    text-align: center;
}
.pages ul li.active{
    color: #f08518;
}

.pages ul li .gaoliang{
    display: none;
}
.pages ul li .nogl{
    display: block;
}
.pages ul li.active .gaoliang{
    display: block;
}
.pages ul li.active .nogl{
    display: none;
}
/* chiak */

.c-project-list{
    max-width: 75rem;
    margin: 5rem auto 8.4375rem;
    /* transform: translateY(2em);
    -moz-transform: translateY(2em);
    -o-transform: translateY(2em);
    -webkit-transform: translateY(2em);
     opacity: 0;
    transition:transform 1s linear,opacity 1s linear;
	-webkit-transition:transform 1s linear, opacity 1s linear;
  -moz-transition:transform 1s linear,opacity 1s linear;
	-o-transition:transform 1s linear,opacity 1s linear; */
   
    
}
.c-project-list .c-project-lc .c-project-left p, .c-project-list .c-project-lc .c-project-right p ,
.c-project-list .c-project-lc .c-project-left2, .c-project-list .c-project-lc .c-project-right2 p{
  font-size: 1.6rem;
  word-break: break-all;

}
.c-project-show{
  max-width: 75rem;
  transform: translateY(0em);
  -o-transform: translateY(0em);
  -moz-transform: translateY(0em);
  -webkit-transform: translateY(0em);
  opacity: 1;
}
.c-project-lc{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #4c413d;
}
.c-project-left{
  width: 100%;
 position: relative;
}
.c-project-left2{
  width: 100%;
 position: relative;
}
.c-projecttextnb h1{
  margin-top:  0px;
  font-size: 7rem;
  font-weight: 550;
  color: rgb(255, 255, 255);
 text-shadow:0px 0px 2px #000000;
}
.c-projecttext-lc{
  width: 11rem;
  position: absolute;
  top: 3.2rem;
  margin-left: 50%;
  left: -5rem;
  height: 2.5rem;
    line-height: 2.5rem;
  background: #8d847f;
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
}
.c-project-right{
  width: 91%;
}

.c-project-right ul{
 list-style: none;
 position: relative;
 height: 1px;
 background-color: #8d847f;
 margin-top: 8.4rem;
 right: 0rem;
 display: flex;
 justify-content: center;
}
.c-project-right ul::before{
 content: '';
 width: 1rem;
 height: 1px;
 background: #8d847f;
 position: absolute;
 top: 0.4rem;
right: -0.1rem;
 transform:rotate(-45deg);
-ms-transform:rotate(-45deg); 	
-moz-transform:rotate(-45deg); 	
-webkit-transform:rotate(-45deg); 
-o-transform:rotate(-45deg);
}
.c-project-right ul::after{
 content: '';
 width: 1rem;
 height: 1px;
 background: #8d847f;
 position: absolute;
     top: -.35rem;
    right: -0.1rem;
 transform:rotate(45deg);
-ms-transform:rotate(45deg); 	
-moz-transform:rotate(45deg); 	
-webkit-transform:rotate(45deg);
-o-transform:rotate(45deg);
}
.c-project-right ul li{
  width: 25%;
  text-align: center;
  position: relative;
  top:-7.4rem;
}
.c-project-right ul li div{
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 6.8rem;
  left: 50%;
}
.c-project-right2{
  position: relative;
  width: 91%;
}

.c-project-right2 ul{
 list-style: none;
 position: relative;
 height: 1px;
 background-color: #8d847f;
 margin-top: 3.4rem;
 right: 0rem;
 display: flex;
 justify-content: center;
}
.c-project-right5 ul{
 list-style: none;
 position: relative;
 height: 1px;
 background-color: #8d847f;
 margin-top: 3.4rem;
 right: 0rem;
 display: flex;
 justify-content: left;
}

.c-project-right2 ul li{
  width: 40%;
  text-align: center;
  position: relative;
  top: -7.4rem;
  left: -2rem;
}
.c-project-right-shu{
  position: absolute;
  width: 1px;
  height: 17.1rem;
    top: 3.4rem;
  right: 0;
  background: #8d847f;

}
.c-ul-2::before{
 content: '';
 width: 1rem;
 height: 1px;
 background: #8d847f;
 position: absolute;
 top: -.38rem;
 left: -0.1rem;
 transform:rotate(-45deg);
-ms-transform:rotate(-45deg); 
-moz-transform:rotate(-45deg); 	
-webkit-transform:rotate(-45deg);
-o-transform:rotate(-45deg);
}
.c-ul-2::after{
 content: '';
 width: 1rem;
 height: 1px;
 background: #8d847f;
 position: absolute;
 top: 0.32rem;
 left: -0.1rem;
 transform:rotate(45deg);
-ms-transform:rotate(45deg); 	
-moz-transform:rotate(45deg); 
-webkit-transform:rotate(45deg); 
-o-transform:rotate(45deg);
} 

.c-project-right2 .c-ul-2{
    margin-top: 17rem;
    margin-bottom: 6rem;
}
.c-project-right2 .c-ul-2 li{
  width: 50%;
  text-align: center;
  position: relative;
  top:-10.4rem;
  left: -2rem;
  line-height: 2rem;
}
.c-project-right2 ul li div{
  width: 1px;
  height: 2rem;
  background: #f08618;
  position: absolute;
  top: 6.8rem;
  left: 50%;
}
.c-project-right2 .c-ul-2 .c-danghen{
  width: 50%;
  text-align: center;
  position: relative;
  top: -7.4rem;
  left: -2rem;
}
.c-project-right2 .c-ul-2 .c-danghen div{
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 6.8rem;
  left: 50%;
}
.c-project-right2 .c-ul-2 li div{
  width: 1px;
  height: 1.2rem;
  background: #f08618;
  position: absolute;
  top: 9.2rem;
  left: 50%;
}
.c-project-left,.c-project-left2,.c-project-right ,.c-project-right2{
  margin-top: 2rem;
}
.c-shuxian{
  width: 1px;
    height: 3rem;
    background: #f08618;
    margin: 0 auto;
}
.c-Aftersale{
  max-width: 46rem;
    margin: 5rem auto 8.4375rem;
}
.c-Aftersale h1{
  font-size: 2.5rem;
    font-weight: 400;
    color: #4c413d;
}
.c-project-jf h1{
    margin-top: 3rem;
    font-size: 3rem;
    font-weight: 400;
    color: #4c413d;
    text-align: center;
}
.c-Aftersale-list div{
  display: flex;
  justify-content: center;
}
.c-Aftersale-list img{
      width: 2.5rem;
    height: 2.5rem;
    position: relative;
    top: -0.2rem;
    padding-right: 0.8rem;
}
.c-Aftersale-list h2{
      font-size: 1.9rem;
    margin: 0;
        font-weight: 300;
    color: #797777;

}
.c-Aftersale-list{
  margin-top: 5rem;
}
.c-Aftersale-list p{
  margin: 0 auto;
    max-width: 30rem;
    line-height: 4rem;
    color: #797777;

}
.c-project-topy{
    margin-top: 5rem;
}
.xm-left{
  margin-bottom: 2rem;
}
.c-project-right ul  .defew{
  top: -5.4rem
}
.c-project-right ul .defew div{
  top: 4.8rem;
} 
</style>