<template>
    <div class="brandConcept quality andService">
        <div class="aboutBaner">
                <img :src="'https://www.vifa.cn' + qualityService.banner" alt="">
                <p class="pwjaboutBanerp">
                    SELEZIONE GLOBALE DEI PRINCIPALI MATERIALI BUONI, 
                        <br/>
                    GESTIONE DEI MAGAZZINI IN CINA,
                    <br>
                    DESIGN PERSONALIZZATO

                </p>
            </div>
        <div class="founder spaceCustomization">
            <div class="yishu"></div>
            <div class="beijing pwjstrone">Processo di servizio a cinque stelle</div>
            <p class="jijian">Five-Star Services</p>
        </div>
        <div class="xingxing">
            <!-- <ul>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
                <li><img src="@/common/image/xingxing.png" alt=""></li>
            </ul> -->
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu1.png" alt=""></li>
                <li><img src="@/common/image/fuwu2.png" alt=""></li>
                <li><img src="@/common/image/fuwu3.png" alt=""></li>
                <li><img src="@/common/image/fuwu4.png" alt=""></li>
            </ul>
        </div>
        <div class="founder spaceCustomization qzh" v-html="qualityService.serviceTextIta">                    
        </div>

        <div class="cangchu">
            <img src="@/common/image/fuwu5.png" alt="">
        </div>
        <div class="wufuliucheng pwjbig">
            <div class="liuchengcont">
                <ul >
                    <li class="itli">Misura della<br/>casa grezza</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Design e rendering<br/>gratuiti per negozi</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Archiviare lo schema idrico <br/> ed elettrico dell'edificio per future consultazioni</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Vai sul sito per stendere gli schemi idrici<br/>ed elettrici e consegnali al team di costruzione</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli"> Controllo della posizione idraulica<br/>e elettrica prima della posa della piastrella in loco</li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li class="itli">Dopo ogni fase di produzione le <br/>informazioni vengono inviate<br/>attivamente al cliente</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Il cliente può verificare lo stato di avanzamento<br/>in qualsiasi momento <br/>scansionando il codice QR</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">La produzione inizia dopo <br/> che l'ordine
                        <br/>
                        è stato caricato automaticamente</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Modifica il piano <br/> e firma il contratto</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Rimisurazione in loco dopo il<br/>completamento del progetto nascosto</li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li class="itli">Promemoria informativo dopo l'arrivo della merce</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Autenticazione dell' installatore<br/> con nome reale</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Fissa un appuntamento<br/>con l'installatore </li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Installa in loco<br/>Pulizia e protezione</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Montaggio dell'armadio<br/>e registrazione delle foto</li>
                </ul>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                </ul>
                <ul>
                    <li class="itli">Pulizia in loco e<br/>protezione del prodotto</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Esegue un controllo in loco e<br/>scattare foto per caricarle</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Pannelli e ferramenta<br/>
                        per porte
                        <br/>
                        Installazione e messa in servizio</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Installazione in loco e accettazione<br/> dei controsoffitti</li>
                    <li class="noitli"><img src="@/common/image/jiantouright.png" alt=""></li>
                    <li class="itli">Misura dell'anta<br/>dell’armadio</li>
                </ul>
                <ul>
                    <li class="shupai"><img src="@/common/image/jiantouxia.png" alt=""></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <ul>
                    <li class="itli">Fissare un appuntamento<br/>per l'ispezione e l'accettazione del cliente</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Accettazione<br/>e valutazione online<br/>dell'installatore</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Pulizia profonda<br/> e debugging dei prodotti prima del trasloco</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Garanzia online<br/>del cliente post-vendita senza preoccupazioni</li>
                    <li class="noitli"><img src="@/common/image/jiantou.png" alt=""></li>
                    <li class="itli">Il meticoloso servizio di<br/> "assumere un aspetto completamente nuovo" di vifa</li>
                </ul>
            </div>
        </div>
        <div class="fuwu">
            <ul>
                <li><img src="@/common/image/fuwu6.png" alt=""></li>
                <li><img src="@/common/image/fuwu7.png" alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
import {getQualityService} from '@/api/api'

export default {
    data(){
        return{
            qualityService:''
        }
    },
    components: {
    },
    created () {
    },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mAndService');
        } else {
            this.$router.replace(paths+'/andService');
        }
        window.exec_main(document.title)
        this.getData()
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getData(){
            getQualityService()
            .then(res => {
                console.log(res)
                this.qualityService = res.qualityService
            })
            .catch(res => {
                console.log(res)
            });
        },
    },
}
</script>

<style>
.tuji{
    padding-top:75px ;
}
.tujiswiper{
    width: 100%;
    overflow: hidden;
}
.tujiswiper ul{
    overflow: auto;
}
.tujiswiper ul li{
    float: left;
    width: 900px;
    height: 540px;
    padding-right:25px
}
.tujiswiper ul li img{
    width: 100%;
    height: 540px;
    cursor: pointer;
}
.quality .spaceCustomization p{
    text-align: center;
    padding-bottom: 0;
    line-height: 2;
}
.quality .founder .yishu{
    margin-bottom: 20px;
}
.cangchu{
    padding-top: 50px;
}
.andService .qzh{
    padding-top: 0;
    padding-bottom: 0;
    white-space: pre-wrap;
    line-height: 1.5;
}
.iamgetitle{
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: -50px;
}
.andService .founder .beijing{
    padding-bottom: 10px;
}
.xingxing{
    padding:  25px 0;
    padding-top: 10px;
}
.xingxing ul{
    width: 250px;
    margin: 0 auto;
    display: flex;
}
.xingxing ul li{
    flex: 1 40px;
}
.xingxing ul li img{
    width: 35px;
    height: 35px;
}
.fuwu{
    padding-bottom: 80px;
}
.fuwu ul{
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.fuwu ul li{
    flex: 1;
}
.fuwu ul li img{
    width: 99%;
}
.wufuliucheng{
    width: 100%;
    padding: 50px 0;
    background: #d2cdca;
    color: #5e5450;
    margin: 80px 0;
}
.liuchengcont{
    width: 1200px;
    margin: 0 auto;
}
.liuchengcont ul{
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5% 0;
}
.liuchengcont ul li{
    flex: 1 132px;
    text-align: center;
    line-height: 2;
}
.liuchengcont ul li p{}
.liuchengcont ul li img{
    width: 100%;
}
.liuchengcont ul li.shupai img{
    width: auto;
}
/* .liuchengcont ul .itli{
    flex: 4 0px;
}
.liuchengcont ul .noitli{
    flex: 1 100px;
} */
/* .aboutBaner .pwjaboutBanerp{
    width: ;
} */
</style>
