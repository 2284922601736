<template>
  <div class="kitchenWap">
    <div class="livingRoomSpace">
      <div
        class="jianbianq"
        :class="{ jianbianh: isbrandConcepttitle }"
        ref="brandConcepttitle"
      >
        <div class="founder spaceCustomization">
          <div class="yishu marginTX"></div>
          <div class="beijing pwjstrone">Bagno</div>
          <p v-html="seriesIntroduce.bathroomSpaceIta" class="kjjianjie"></p>
          <!-- <div class="moreBut">了解更多</div> -->
        </div>
      </div>
      <div class="jianbianq" :class="{ jianbianh: issjmx }" ref="sjmx">
        <div
          class="spaceCustomization_img homeDecoration hsct"
          ref="homeDecoration"
          @mouseenter="homeDM"
          @mouseleave="homeDMout"
        >
          <img src="@/common/image/bathroomSpace1.png" alt="" />
          <div class="dingwei" :class="{ dingweishow: isdingwei }">
            <div class="dingweia" @click="hsctdingweia">+</div>
            <div class="dingweib" @click="hsctdingweib">+</div>
            <div class="dingweic" @click="hsctdingweic">+</div>
          </div>
          <div
            class="dwzhankai"
            v-show="isdwzhankai == index"
            v-for="(item, index) in dwzhankaiData"
            :key="index"
            @click="dwguanbi"
          >
            <ul>
              <li>
                <img class="cfimg" :src="'https://www.vifa.cn' + item.image" />
                <div>{{ item.titleIta }}</div>
                <p>{{ item.introduceIta }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hscttitle pwjstrone">Mobile bagno sospeso</div>
        <router-link
          :to="{
            path: '/productDtails',
            query: { title: '悬挂浴室柜', SpaceActiv: 5,ittitle:'Mobile bagno sospeso',entitle:'Hanging bathroom cabinet' },
          }"
          ><div class="moreBut">Più informazioni</div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getProductDetails } from "@/api/api";

export default {
  name: "bathroomSpace",
  data() {
    return {
      dwzhankaiData: [],
      isdwzhankai: 999,
      isdingwei: false,
      isbrandConcepttitle: false,
      issjmx: false,
    };
  },
  components: {},
  props: {
    seriesIntroduce: {
      type: String,
    },
  },
  mounted() {
    this.getkalajinyanData();
    window.addEventListener("scroll", this.handleScroll);
    this.isbrandConcepttitle = true;
    this.issjmx = true;
  },
  methods: {
    getkalajinyanData() {
      let data = {
        productName: "悬挂浴室柜",
      };
      getProductDetails(data)
        .then((res) => {
          this.dwzhankaiData = res.productSpecific;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        if (
          this.$refs.brandConcepttitle.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandConcepttitle = false;
        }
        if (
          this.$refs.sjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issjmx = false;
        }
        if (
          this.$refs.brandConcepttitle.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandConcepttitle = true;
        }
        if (
          this.$refs.sjmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issjmx = true;
        }

        // }else if (e.deltaY < 0) {
        if (
          this.$refs.brandConcepttitle.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isbrandConcepttitle = false;
        }
        if (
          this.$refs.sjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.issjmx = false;
        }
      });
      // }
    },
    homeDM() {
      this.isdingwei = true;
    },
    homeDMout() {
      this.isdingwei = false;
    },
    dwguanbi() {
      this.isdwzhankai = 999;
    },
    hsctdingweia() {
      this.isdwzhankai = 0;
    },
    hsctdingweib() {
      this.isdwzhankai = 1;
    },
    hsctdingweic() {
      this.isdwzhankai = 2;
    },
  },
  unmounted(){
    window.removeEventListener("scroll", this.handleScroll);;
    }
  
};
</script>

<style scoped>
.kitchenWap .spaceCustomization p {
  /* width: 740px; */
  margin: 0 auto;
  white-space: pre-wrap;
  /* text-align: left; */
}
.hsct .dingwei .dingweia {
  left: 4%;
  top: 67.5%;
}
.hsct .dingwei .dingweib {
  left: 5.5%;
  top: 84%;
}
.hsct .dingwei .dingweic {
  left: 11.5%;
  top: 30%;
}
.kitchenWap .dwzhankai ul {
  width: 875px;
  height: 710px;
}
.kitchenWap .dwzhankai ul li .cfimg {
  width: 400px;
  height: 352.5px;
  margin: 88px 0 25px 355px;
}
.kitchenWap .dwzhankai ul li div {
  font-size: 30px;
  padding-top: 0;
}
.kitchenWap .dwzhankai ul li p {
  padding-top: 15px;
  font-size: 16px;
}
.hscttitle {
  font-size: 30px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
