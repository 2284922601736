<template>
  <div class="product">
    <div :class="{ jianbianbanner: isproductBaner }" ref="productBaner">
      <div
        class="spaceCustomization_img homeDecoration productkjdz"
        ref="homeDecoration"
      >
        <div class="spaceCustomization">
          <img
            :src="'https://www.vifa.cn' + productCenterBanner.kjdzImage"
            alt=""
          />
          <!-- <div class="productnav " :class="{'productnavTop':isproductnavTop}" ref="productnavTop">
                        <div class="space">
                            <div :class="{spaceActiv:isSpaceActiv == index}" v-for="(item, index) in kjdzallData" :key="index" @click="spance(index)">
                                <img class="spaceimg" :src=item.icon alt="">
                                <img class="spaceimgActive" :src=item.icon_active alt="">
                                <p>{{item.name}}</p>
                            </div>
                        </div>
                    </div> -->
        </div>
        <div
          class="homeDecorationMask"
          v-show="ishdMask"
          :style="{ height: hdmHeight }"
        >
          <div class="homeDecorationCenten">
            <transition-group name="hdcm">
              <ul>
                <li
                  v-for="(item, index) in kjdzData"
                  :key="index"
                  @click="kjdz(index)"
                  :class="{
                    midl: midlIndex == index,
                    left: leftIndex == index,
                    right: rightIndex == index,
                    pwjstrone: midlIndex == index
                  }"
                >
                  <div class="zhoyao">{{ item.eName }}</div>
                  <p>{{ item.eName }}</p>
                </li>
              </ul>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <!-- <div  :class="{'jianbianbanner':isproductBaner}" ref="productBaner"> -->
    <div
      class="productnav"
      :class="{
        productnavTop: isproductnavTop,
        productnavTopaa: isproductnavTopaa,
        productnavTopbb: isproductnavTopbb,
        productnavtopling: isproductnavtopling,
        jianbianbanner: isproductBaner,
      }"
      ref="productnavTop"
    >
      <div class="space">
        <div
          :class="{ spaceActiv: isSpaceActiv == index }"
          v-for="(item, index) in kjdzallData"
          :key="index"
          @click="spance(index)"
        >
          <!--  -->
          <img
            :class="['spaceimg', { zhanheight: item.classt == true }]"
            :src="item.icon"
            alt=""
          />
          <img
            :class="['spaceimgActive', { zhanheight: item.classt == true }]"
            :src="item.icon_active"
            alt=""
          />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="livingRoomSpace" ref="roomSpace">
      <component
        :is="comName"
        style="z-index: -1"
        :seriesIntroduce="seriesIntroduce"
        :seriesList="seriesList"
      ></component>
      <Zixun />
    </div>
  </div>
</template>

<script>
import sofa from "@/common/image/sofa.png";
import bed from "@/common/image/bed.png";
import kitchen1 from "@/common/image/kitchen1.png";
import dinner from "@/common/image/dinner.png";
import clothes1 from "@/common/image/clothes1.png";
import bath from "@/common/image/bath.png";
import book from "@/common/image/book.png";
import sofa_active from "@/common/image/sofa_active.png";
import bed_active from "@/common/image/bed_active.png";
import kitchen_active1 from "@/common/image/kitchen_active1.png";
import dinner_active from "@/common/image/dinner_active.png";
import clothes_active1 from "@/common/image/clothes_active1.png";
import bath_active from "@/common/image/bath_active.png";
import book_active from "@/common/image/book_active.png";
import LivingRoomSpace from "./livingRoomSpace";
import KitchenSpace from "./kitchenSpace";
import RestaurantSpace from "./restaurantSpace";
import ClothesAndHatsSpace from "./clothesAndHatsSpace";
import BedroomSpace from "./bedroomSpace";
import BathroomSpace from "./bathroomSpace";
import BookcaseSpace from "./bookcaseSpace";
import { getProductCenter } from "@/api/api";
import Zixun from "@/components/zixun";

export default {
  name: "productCenter",
  data() {
    return {
      kjdzData: [
        {
          name: "门墙系统",
          eName: "Door & Wall",
        },
        {
          name: "空间定制",
          eName: "Space Customization",
        },
        {
          name: "大家居",
          eName: "Light & Decoration",
        },
      ],
      kjdzallData: [
        {
          icon: sofa,
          icon_active: sofa_active,
          name: "Living room ",
          classt: false,
        },
        {
          icon: bed,
          icon_active: bed_active,
          name: "Bedroom",
          classt: false,
        },
        {
          icon: kitchen1,
          icon_active: kitchen_active1,
          name: "Kitchen",
          classt: false,
        },
        {
          icon: dinner,
          icon_active: dinner_active,
          name: "Dining room",
          classt: false,
        },
        {
          icon: clothes1,
          icon_active: clothes_active1,
          name: "Clothing",
          classt: true,
        },
        {
          icon: bath,
          icon_active: bath_active,
          name: "Bathroom",
          classt: false,
        },
        {
          icon: book,
          icon_active: book_active,
          name: "Bookcase",
          classt: false,
        },
      ],
      ishdMask: true,
      midlIndex: 1,
      leftIndex: 0,
      rightIndex: 2,
      isSpaceCustomization: 1,
      hdmHeight: 920,
      kjdzallIndex: 0,
      isSpaceActiv: 0,
      comName: "LivingRoomSpace",
      productnavTopH: 709,
      isproductBaner: false,
      isproductnavTop: false,
      isproductnavTopaa: false,
      isproductnavTopbb: false,
      isproductnavtopling: false,
      seriesIntroduce: "",
      seriesList: [],
      productCenterBanner: "",
    };
  },
  components: {
    LivingRoomSpace,
    KitchenSpace,
    RestaurantSpace,
    ClothesAndHatsSpace,
    BedroomSpace,
    BathroomSpace,
    BookcaseSpace,
    Zixun,
  },
  mounted() {
    // setInterval(() => {

    //     this.maskHeight()
    // }, 1000)
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mProductCenter");
    } else {
      this.$router.replace(paths + "/productCenter");
    }
    window.addEventListener("scroll", this.productNavScroll);
    window.addEventListener("scroll", this.handleScroll);
    this.getProductCenterData();
    this.isproductBaner = true;
    if (this.$route.query.isindex) {
      this.spance(this.$route.query.isindex);
    }
    window.exec_main(document.title);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // maskHeight(){
    // this.hdmHeight = this.$refs.homeDecoration.clientHeight
    // this.productnavTopH = this.$refs.productnavTop.offsetTop
    // console.log(this.productnavTopH)
    // },
    handleScroll() {
     this.$nextTick(()=>{
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // if (e.deltaY>0 && this.isproductnavTop == true) {
      if (this.isproductnavTop == true) {
        this.isproductnavTopaa = false;
        this.isproductnavTopbb = true;
        // }else if (e.deltaY < 0 && this.isproductnavTop == true) {
      } else if (this.isproductnavTop == true) {
        this.isproductnavTopaa = true;
        this.isproductnavTopbb = false;
      }
     })
    },
    getProductCenterData() {
      getProductCenter()
        .then((res) => {
          console.log(res);
          this.seriesIntroduce = res.seriesIntroduce;
          this.seriesList = res.seriesList;
          this.productCenterBanner = res.productCenterBanner;
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    productNavScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 785) {
        this.isproductnavTop = true;
        this.isproductnavtopling = true;
      } else {
        this.isproductnavTop = false;
        this.isproductnavTopaa = false;
        this.isproductnavTopbb = false;
        this.isproductnavtopling = false;
      }
    },
    homeDM() {
      this.ishdMask = true;
      this.hdcm = 0;
    },
    homeDMout() {
      this.ishdMask = false;
      // this.hdcm = -300
    },
    kjdz(index) {
      if (this.midlIndex != index) {
        if (index == 0) {
          this.$router.push("/doorwallsystem");
        } else if (index == 2) {
          this.$router.push("/christophBrand");
        }
        this.isSpaceCustomization = index;
      }
    },
   spance(index) {
          let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
      this.kjdzallIndex = index;
      this.isSpaceActiv = index;
      document.documentElement.scrollTop = this.$refs.roomSpace.offsetTop - 200;
      this.$router.replace({
        path: paths+"/productCenter",
        query: { isindex: index },
      });
      if (index == 0) {
        this.comName = "LivingRoomSpace";
      } else if (index == 1) {
        this.comName = "BedroomSpace";
      } else if (index == 2) {
        this.comName = "KitchenSpace";
      } else if (index == 3) {
        this.comName = "RestaurantSpace";
      } else if (index == 4) {
        this.comName = "ClothesAndHatsSpace";
      } else if (index == 5) {
        this.comName = "BathroomSpace";
      } else if (index == 6) {
        this.comName = "BookcaseSpace";
      }
    },
  },

  unmounted(){
      window.removeEventListener("scroll", this.productNavScroll);
    window.removeEventListener("scroll", this.handleScroll);
    }
};
</script>

<style>
@import "../../../common/styles/productCenter.css";
.homeDecorationCenten {
  position: relative;
}
.productnav {
  /* position: absolute;
    left: 0;
    top: 675px; */
  width: 100%;
  text-align: center;
  padding: 8px 0;
  background: #8d847f;
}
.productnav .space {
  width: 1200px;
  margin: 0 auto;
  padding-top: 0;
}
.productnav .space div p {
  text-align: center;
  padding-bottom: 0;
}
.productnavTop {
  position: fixed;
  bottom: inherit;
  top: 0;
  left: 0;
  background: #000000e3;
  z-index: 99;
}
/* #app .productnavTopaa{
    animation:productnavTopaa 1.5s;
    -moz-animation:productnavTopaa 1.5s; 
    -webkit-animation:productnavTopaa 1.5s; 
    -o-animation:productnavTopaa 1.5s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
#app .productnavTopbb{
    animation:productnavTopbb 1.5s;
    -moz-animation:productnavTopbb 1.5s; 
    -webkit-animation:productnavTopbb 1.5s; 
    -o-animation:productnavTopbb 1.5s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
} */
@keyframes productnavTopaa {
  0% {
    top: 0;
  }
  100% {
    top: 83px;
  }
}
@-webkit-keyframes productnavTopaa {
  0% {
    top: 0;
  }
  100% {
    top: 83px;
  }
}
@-moz-keyframes productnavTopaa {
  0% {
    top: 0;
  }
  100% {
    top: 83px;
  }
}
@-o-keyframes productnavTopaa {
  0% {
    top: 0;
  }
  100% {
    top: 83px;
  }
}
@keyframes productnavTopbb {
  0% {
    top: 83px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes productnavTopbb {
  0% {
    top: 83px;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes productnavTopbb {
  0% {
    top: 83px;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes productnavTopbb {
  0% {
    top: 83px;
  }
  100% {
    top: 0;
  }
}
.productnavtopling {
  top: 0;
}
.product .productkjdz ul li {
  padding-top: 120px;
  cursor: pointer;
}
.product .productkjdz ul li p {
  display: none;
}
.product .productkjdz ul li.midl p {
  display: block;
  margin-top: 10px;
}
.product .productkjdz ul li.midl {
  border-bottom: none;
  flex: 4;
  font-size: 2.5em;
  padding-top: 60px;
  overflow: initial;
}
.product .productkjdz ul li.midl > div {
  letter-spacing: 10px;
}
.product .productkjdz ul li.left {
  text-align: left;
}
.product .productkjdz ul li.right {
  text-align: right;
}
.product .homeDecorationCenten ul {
  padding-top: 150px;
}
.product .spaceCustomization img.pbanner {
  width: 100%;
  height: 720px;
}
#app .product .kjjianjie p {
  padding-bottom: 0;
}
.zhanheight {
  height: 32px;
}
.midl .zhoyao{
  opacity: 0;
}
</style>
