<template>
  <div class="brandConcept">
    <div
      class="jianbianq"
      :class="{ jianbianh: isbrandConcepttitle }"
      ref="brandConcepttitle"
    >
      <div class="brandProfile_introduce" v-html="aboutVifa.brandConcept"></div>
    </div>
    <div class="jianbianq" :class="{ jianbianh: issjmx }" ref="sjmx">
      <div class="sjmx mtab">
        <div class="founder spaceCustomization">
          <div class="yishu"></div>
          <div class="beijing">设计美学</div>
          <p class="jijian">以极简呈现精致，以极简成就实用，以极简释放兼容</p>
        </div>
        <div class="kjmxvideo mtab">
          <video
            poster="@/common/image/poter3.jpg"
            width="1200"
            height="540"
            :src="'https://www.vifa.cn' + aboutVifa.deviseVideo"
            controls="controls"
            preload="preload"
            loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
          ></video>
        </div>
        <!-- <div class="movies">
                    <ul>
                        <li>
                            <img src="@/common/image/sjmx1.png" alt="">
                        </li>
                        <li>
                            <img src="@/common/image/sjmx2.png" alt="">
                        </li>
                        <li>
                            <img src="@/common/image/sjmx3.png" alt="">
                        </li>
                    </ul>
                    <video width="1200" height="540" src="https://yeahc01.oss-cn-shenzhen.aliyuncs.com/citi/cip1912/images/sjmx.a429a156.mp4" controls='controls' preload='preload' loop='loop'>
                    </video>
                </div> -->
      </div>
    </div>
    <div class="jianbianq" :class="{ jianbianh: iskjmx }" ref="kjmx">
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing">科技美学</div>
        <p class="jijian">以科技应用不断提升产品与服务，创造更高生活品质；</p>
        <p class="jijian">以科技创新不断提升精准对接和服务效率</p>
      </div>
      <div class="kjmxvideo mtab">
        <video
          poster="@/common/image/poter2.jpg"
          width="1200"
          height="540"
          :src="'https://www.vifa.cn' + aboutVifa.scienceVideo"
          controls="controls"
          preload="preload"
          loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
    <!-- <div class="kjmx">
            <div>只能立体仓库</div>
            <img src="@/common/image/kjmxicon.png" alt="">
        </div> -->
    <!-- <div class="tuji">
                <div class="tujiswiper">
                    <ul :style="{'width':tujiswiperWideh + 'px','marginLeft':tujiswipermLeft + 'px'}" class="tran">
                        <li v-for="(item, index) in tujiData" :key="index">
                            <div><img :src=item.img alt=""></div>
                        </li>
                    </ul>
                </div>
                <div class="newswi">
                    <div class="left" @click="tujiswiperLeft"><p></p></div>
                    <div class="center">
                        <ul>
                            <li v-for="(item, index) in tujiData" :key="index" :class="{'active':isactive == index}" @click="newzhiindex(index)"><span class="no">一</span><span class="yes">十</span></li>
                        </ul>
                    </div>
                    <div class="right" @click="tujiswiperRight"><p></p></div>
                </div>
            </div> -->
    <!-- <div class="movies">
            <video poster="@/common/image/vidoeimg2.png" width="1200" height="506" src="@/common/image/aaa.mp4" controls='controls' preload='preload' loop='loop'>
            </video>
        </div> -->
    <div class="jianbianq" :class="{ jianbianh: isshmx }" ref="shmx">
      <div class="shmx">
        <div class="founder spaceCustomization">
          <div class="yishu"></div>
          <div class="beijing">生活美学</div>
          <p class="jijian">奉行极简智慧和精致生活态度，化繁为简，追求卓越，</p>
          <p class="jijian">享受周致和专注的愉悦。</p>
        </div>
        <div class="kjmx">
          <!-- <div class="kjmxtext">2018-11-29 大连皮划艇</div> -->
        </div>
        <div class="kjmxvideo mtab">
          <video
            :poster="'https://www.vifa.cn' + aboutVifa.lifeVideoImage"
            width="1200"
            height="540"
            :src="'https://www.vifa.cn' + aboutVifa.lifeVideo"
            controls="controls"
            preload="preload"
            loop="loop"
          x5-playsinline=""
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen=""
          x5-video-orientation="portraint"
          ></video>
        </div>
        <!-- <div class="shmximg">
                    <div class="shmxbigiamgbox">
                        <img v-for="(item, index) in shmxiamgData" :key="index" :src="'https://www.vifa.cn' + item.image" alt="" :class="{'shmxbigiamg':isshmxbigiamg == index}">
                    </div>
                    <ul>
                        <li v-for="(item, index) in shmxiamgData" :key="index" :class="{'gk':gk == index}" @click="shmxbigiamgqie(index)"><img :src="'https://www.vifa.cn' + item.image" alt="" ></li>
                      
                    </ul>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import tuji from "@/common/image/kjmxlb1.png";
import { getAboutVifa } from "@/api/api";

export default {
  name: "brandConcept",
  data() {
    return {
      shmxiamgData: [],
      videoImg: "@/common/image/aboutbanner.jpg",
      tujiswiperWideh: 925 * 5,
      tujiswipermLeft: -420,
      tujiData: [{ img: tuji }, { img: tuji }, { img: tuji }, { img: tuji }],
      isactive: 1,
      isbrandConcepttitle: false,
      issjmx: false,
      iskjmx: false,
      isshmx: false,
      isshmxbigiamg: 0,
      gk: 1,
      aboutVifa: "",
    };
  },
  components: {},
  created() {
    this.getImgInfo();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isbrandConcepttitle = true;
    this.issjmx = true;
    this.getAboutVifaData();
  },
  methods: {
    getAboutVifaData() {
      getAboutVifa()
        .then((res) => {
          console.log(res);
          this.aboutVifa = res.aboutVifa;
          this.shmxiamgData = res.aboutVifaLife;
          console.log(this.shmxiamgData);
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // if (e.deltaY>0) {
      if (
        this.$refs.brandConcepttitle.offsetTop >
        this.scroll + document.documentElement.clientHeight
      ) {
        this.isbrandConcepttitle = false;
      }
      if (
        this.$refs.sjmx.offsetTop >
        this.scroll + document.documentElement.clientHeight
      ) {
        this.issjmx = false;
      }
      if (
        this.$refs.brandConcepttitle.offsetTop <
        this.scroll + document.documentElement.clientHeight
      ) {
        this.isbrandConcepttitle = true;
      }
      if (
        this.$refs.sjmx.offsetTop <
        this.scroll + document.documentElement.clientHeight
      ) {
        this.issjmx = true;
      }
      if (
        this.$refs.kjmx.offsetTop <
        this.scroll + document.documentElement.clientHeight
      ) {
        this.iskjmx = true;
      }
      if (
        this.$refs.shmx.offsetTop <
        this.scroll + document.documentElement.clientHeight
      ) {
        this.isshmx = true;
      }

      // }else if (e.deltaY < 0) {
      if (
        this.$refs.brandConcepttitle.offsetTop >
        this.scroll + document.documentElement.clientHeight - 200
      ) {
        this.isbrandConcepttitle = false;
      }
      if (
        this.$refs.sjmx.offsetTop >
        this.scroll + document.documentElement.clientHeight - 200
      ) {
        this.issjmx = false;
      }
      if (
        this.$refs.kjmx.offsetTop >
        this.scroll + document.documentElement.clientHeight - 200
      ) {
        this.iskjmx = false;
      }
      if (
        this.$refs.shmx.offsetTop >
        this.scroll + document.documentElement.clientHeight - 200
      ) {
        this.isshmx = false;
      }
      // }
    },
    getImgInfo() {
      this.tujiswiperWideh = 925 * this.tujiData.length;
    },
    // tujiimg(index){
    //     if (this.istujiimg == 999) {
    //         this.istujiimg = index
    //         document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
    //         document.body.addEventListener("wheel", this.defferScroll, {passive: false});
    //     }else{
    //         this.istujiimg = 999
    //         document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
    //         document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
    //     }

    // },
    tujiswiperLeft() {
      if (this.tujiswipermLeft <= -420) {
        this.tujiswipermLeft = this.tujiswipermLeft + 925;
        this.isactive--;
      }
    },
    tujiswiperRight() {
      if (
        this.tujiswipermLeft >=
        -this.tujiswiperWideh - this.tujiswipermLeft
      ) {
        this.tujiswipermLeft = this.tujiswipermLeft - 925;
        this.isactive++;
      }
    },

    newzhiindex(index) {
      this.isactive = index;
      this.tujiswipermLeft = 420 - 925 * index;
      console.log(index);
    },
    shmxbigiamgqie(index) {
      this.isshmxbigiamg = index;
      console.log(index);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.sjmx {
  width: 100%;
  background: url("../../../common/image/sjmxbg.png") 0 0 no-repeat;
  background-size: 100% 100%;
  padding-top: 5px;
  margin-top: 45px;
}
.about .mtab {
  margin-top: 50px;
}
.about .brandConcept .sjmx .founder .yishu {
  margin-top: 45px;
}
.sjmx .movies {
  display: flex;
}
.sjmx .movies ul {
  display: flex;
  width: 335px;
  flex: 1 335px;
  justify-content: space-between;
  flex-direction: column;
}
.sjmx .movies ul li {
  width: 100%;
  height: 177.6px;
}
.sjmx .movies ul li img {
  width: 335px;
  height: 175px;
}
.sjmx .movies video {
  width: 860px;
  height: 530px;
}
.kjmx {
  position: relative;
  /* padding-top: 75px; */
  padding-bottom: 35px;
}
.kjmx .kjmxtext {
  padding: 55px 340px;
  border: 1px solid;
  display: inline-block;
  font-size: 25px;
}
.kjmx .kjmxicon {
  position: absolute;
  width: 75px;
  height: 75px;
  border: 1px solid;
  border-radius: 100%;
  top: 37.5px;
  left: 50%;
  margin-left: -37.5px;
  background: #d2cdca;
  display: flex;
  align-items: center;
  text-align: center;
}
.kjmx .kjmxicon img {
  margin-left: 19%;
}

.tuji {
  padding-top: 75px;
}
.tujiswiper {
  width: 100%;
  overflow: hidden;
}
.tujiswiper ul {
  overflow: auto;
}
.tujiswiper ul li {
  float: left;
  width: 900px;
  height: 540px;
  padding-right: 25px;
}
.tujiswiper ul li img {
  width: 100%;
  height: 540px;
  cursor: pointer;
}
.shmx {
  background: #d2cdca;
  padding-top: 5px;
  margin-top: 80px;
  padding-bottom: 80px;
}
.about .brandConcept .shmx .founder .yishu {
  margin-top: 45px;
}
.shmx .kjmx img {
  background: #d2cdca;
}
.shmximg {
  width: 940px;
  margin: 0 auto;
}
.shmximg ul {
  display: flex;
  width: 100%;
  padding-top: 10px;
}
.shmximg ul li {
  width: 296.6665px;
  flex: 1 296.66665px;
}
.shmximg img {
  width: 100%;
}
.shmximg .gk {
  margin: 0 10px;
}
.kjmxvideo {
  width: 1200px;
  margin: 0 auto;
}
.shmxbigiamgbox {
  position: relative;
  width: 940px;
  height: 630px;
}
.shmxbigiamgbox img {
  width: 940px;
  height: 630px;
  position: absolute;
  left: 0;
  top: 0;
  animation: homebrand 2s;
  -moz-animation: homebrand 2s;
  -webkit-animation: homebrand 2s;
  -o-animation: homebrand 2s;
  animation-fill-mode: forwards;
  /* -webkit-animation-timing-function:ease-in-out; */
}
.shmxbigiamgbox .shmxbigiamg {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  /* -webkit-animation-timing-function:ease-in-out; */
}
</style>
