<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + shopCase" alt="" />
    </div>
    <div class="hometopswipt">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">威法定制 大宅至简</div>
          <p>
            vifa以优质的门店环境，为每一位到店的顾客提供周致的服务体验，以真实的装修实例，为您呈现多种理想家装组合形式。
          </p>
        </div>
      </div>
    </div>
    <div class="cityshop">
      <ul>
        <li
          v-for="(item, index) in homeVifaAround"
          :key="index"
          @click="openttuji(index)"
        >
          <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
          <div>
            <div>{{ item.name }}</div>
            <p>{{ item.cityAddress }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="hometopswipt quanpin tuji" v-if="istujishow" @click="clotuji">
      <div class="pinpailishi">
        <div class="pplsimg">
          <transition-group :name="pinpailishiani">
            <ul
              :style="{
                marginLeft: kjmarginLeft + 'px',
                width: kjwidth + 'px',
              }"
            >
              <li @touchstart="kjtouchstart" @touchmove="kjtouchmove">
                <img
                  :src="'https://www.vifa.cn' + lastImage"
                  alt=""
                  @click.stop="stopclotuji"
                />
              </li>
              <li
                v-for="(item, index) in photoAlbumImages"
                :key="index"
                @touchstart="kjtouchstart"
                @touchmove="kjtouchmove"
              >
                <img
                  @click.stop="stopclotuji"
                  :src="'https://www.vifa.cn' + item"
                  alt=""
                />
              </li>
              <li @touchstart="kjtouchstart" @touchmove="kjtouchmove">
                <img
                  @click.stop="stopclotuji"
                  :src="'https://www.vifa.cn' + firstImage"
                  alt=""
                />
              </li>
            </ul>
          </transition-group>
          <!-- <div class="alltujileft" @click.stop="alltujileft"><div></div><p></p></div>
                    <div class="alltujiright" @click.stop="alltujiright"><div></div><p></p></div> -->
        </div>
      </div>
    </div>
    <div class="subnav">
      <router-link to="mCase">家装案例</router-link>
      <router-link to="" class="active">门店展示</router-link>
    </div>
  </div>
</template>

<script>
import { getCaseDisplaye } from "@/api/api";
export default {
  data() {
    return {
      shopCase: "",
      homeVifaAround: [],
      kjmarginLeft: -document.body.clientWidth,
      mdwidth: 0,
      iskjshow: 1,
      firstImage: "",
      lastImage: "",
      pinpailishiani: "pinpailishiani",
      photoAlbumImages: [],
      kjwidth: 9999999,
      istujishow: false,
    };
  },
  components: {
    // Mzixun
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mShopCase");
    } else {
      this.$router.replace(paths + "/case?bannertype=2");
    }
    this.getPageData();

    setTimeout(() => {
      this.$nextTick(() => {
        this.mdwidth = document.body.clientWidth * this.homeVifaAround.length;
        this.photoAlbumImages = this.homeVifaAround[0].mobilePhotoAlbumImages;
        this.firstImage = this.photoAlbumImages[0];
        this.lastImage =
          this.photoAlbumImages[this.photoAlbumImages.length - 1];
        this.kjwidth =
          document.body.clientWidth * (this.photoAlbumImages.length + 2);
      });
    }, 1000);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPageData() {
      getCaseDisplaye()
        .then((res) => {
          console.log(res);
          this.shopCase = res.caseDisplayBanner[0].mobileShopCase;
          this.homeVifaAround = res.homeVifaAround;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    openttuji(index) {
      this.istujishow = true;
      this.photoAlbumImages = this.homeVifaAround[index].mobilePhotoAlbumImages;
      this.firstImage = this.photoAlbumImages[0];
      this.lastImage = this.photoAlbumImages[this.photoAlbumImages.length - 1];
      document.body.addEventListener("touchmove", this.defferScroll, {
        passive: false,
      });
      document.body.addEventListener("wheel", this.defferScroll, {
        passive: false,
      });
    },
    kjshow(index) {
      console.log(this.kjmarginLeft, index);
      this.kjchangex = this.kjstartx;
      this.kjmarginLeft = -document.body.clientWidth * index;
      console.log(this.kjmarginLeft, index);
    },
    kjtouchstart(e) {
      this.kjstartx = e.touches[0].clientX;
    },
    kjtouchmove(e) {
      if (this.kjchangex == this.kjstartx) {
        return;
      }
      this.kjmovex = e.touches[0].clientX;
      let kjleftslide = this.kjstartx - this.kjmovex;
      console.log(this.iskjshow);
      if (
        kjleftslide > 30 &&
        this.iskjshow < this.photoAlbumImages.length + 2
      ) {
        if (this.iskjshow == this.photoAlbumImages.length + 1) {
          this.pinpailishiani = "";
          this.iskjshow = 0;
          this.kjmarginLeft = 0;
          setTimeout(() => {
            this.pinpailishiani = "pinpailishiani";
            this.kjshow(++this.iskjshow);
          }, 300);
        } else {
          this.kjshow(++this.iskjshow);
        }
      } else if (kjleftslide < -30 && this.iskjshow >= 0) {
        console.log(this.iskjshow);
        if (this.iskjshow == 0) {
          this.pinpailishiani = "";
          this.iskjshow = this.photoAlbumImages.length + 1;
          this.kjmarginLeft = -document.body.clientWidth * this.iskjshow;
          setTimeout(() => {
            this.pinpailishiani = "pinpailishiani";
            this.kjshow(--this.iskjshow);
          }, 300);
        } else {
          this.kjshow(--this.iskjshow);
        }
      }
    },
    alltujileft() {
      if (this.iskjshow >= 0) {
        if (this.iskjshow == 0) {
          this.pinpailishiani = "";
          this.iskjshow = this.photoAlbumImages.length + 1;
          this.kjmarginLeft = -document.body.clientWidth * this.iskjshow;
          setTimeout(() => {
            this.pinpailishiani = "pinpailishiani";
            this.kjshow(--this.iskjshow);
          }, 300);
        } else {
          this.kjshow(--this.iskjshow);
        }
      }
    },
    alltujiright() {
      if (this.iskjshow < this.photoAlbumImages.length + 2) {
        if (this.iskjshow == this.photoAlbumImages.length + 1) {
          this.pinpailishiani = "";
          this.iskjshow = 0;
          this.kjmarginLeft = 0;
          setTimeout(() => {
            this.pinpailishiani = "pinpailishiani";
            this.kjshow(++this.iskjshow);
          }, 300);
        } else {
          this.kjshow(++this.iskjshow);
        }
      }
    },
    clotuji() {
      this.istujishow = false;
      document.body.removeEventListener("touchmove", this.defferScroll, {
        passive: false,
      });
      document.body.removeEventListener("wheel", this.defferScroll, {
        passive: false,
      });
    },
    stopclotuji() {
      this.istujishow = true;
    },
  },
  unmounted(){
           document.body.removeEventListener("touchmove", this.defferScroll, {
        passive: false,
      });
      document.body.removeEventListener("wheel", this.defferScroll, {
        passive: false,
      });
    }
};
</script>

<style scoped>
.homelogo {
  width: 750px;
  position: relative;
  margin-bottom: 100px;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 45px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 50%;
  margin-top: -45px;
}
.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 35px;
}
.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto 100px;
}
.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
}
.pinpailishi .subtitle {
  font-size: 29px;
}
.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: justify;
}
.pplsimg {
  width: 100%;
  height: 357px;
  padding: 20px 0;
  overflow: hidden;
}
.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -642px;
}
.pinpailishi ul li {
  width: 635px;
  height: 357px;
  flex: 1;
  padding: 0 20px;
}
.pinpailishi ul li img {
  width: 635px;
  height: 357px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  color: #f08518;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}
.bottomtitle .showtitle {
}
.bottomtitle .showtitle p {
  text-align: center;
}
.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: 0;
  top: 26px;
}
.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: 0;
  top: 26px;
}
.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}
.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}
.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #000;
}
.quanpin .pplsimg {
  height: 447px;
}
.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}
.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}
.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 40px auto;
}
.input input {
  width: 100%;
  line-height: 2;
  border: 1px solid #4c413d;
  text-align: center;
}

.bannertop,
.ppfzlist {
  width: 670px;
  font-size: 26px;
  line-height: 2;
  text-align: justify;
  margin: 40px auto;
}
.ppfzlist img {
  width: 100%;
}
.ppfzlist div {
  font-size: 32px;
  font-weight: bold;
  padding-top: 26px;
}
.video {
  padding: 40px 0;
}
.ppjjsjmx {
  background: url("../../../common/image/sjmxbg.png") 0 0 no-repeat;
  background-size: 100% 100%;
  padding-top: 10px;
  padding-bottom: 60px;
}
.shmx {
  background: #d2cdca;
  margin-top: 0;
  padding: 10px 0;
}

.subnav {
  width: 750px;
  font-size: 26px;
  line-height: 100px;
  background: #fff;
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #000000cc;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav a.active {
  color: #f08518;
}
.pzbtit .showtitle p {
  line-height: 2;
  padding-bottom: 40px;
}

.mcaselist {
  width: 750px;
  margin: 40px auto;
}
.cityshop ul {
}
.cityshop ul li {
  padding-bottom: 180px;
}
.cityshop ul li img {
  width: 100%;
}
.cityshop ul li > div {
  width: 100%;
  /* background: #9b948e; */
  /* color: #fff; */
  line-height: 2;
  padding: 20px 0;
  text-align: center;
}
.cityshop ul li > div div {
  font-size: 26px;
  font-weight: bold;
  margin: 0 auto;
}
.cityshop ul li > div p {
  font-size: 26px;
  width: 670px;
  margin: 0 auto;
}
.tuji {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99999999;
  padding-top: 0;
}
.tuji .pinpailishi {
  height: 100%;
}
.tuji .pplsimg {
  height: 100%;
  padding: 0;
  display: flex;
}
.tuji .pinpailishi ul li {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.alltujileft {
  /* background: #00000052;
    position: absolute;
    left: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-right: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
  width: 80px;
  position: absolute;
  left: 2.5%;
  top: 50%;
  cursor: pointer;
  height: 25px;
  margin-top: -12.5px;
}
.alltujileft div {
  width: 80px;
  height: 1px;
  background: #fff;
  margin-top: 25px;
}
.alltujileft p {
  height: 12.5px;
  width: 1px;
  background: #fff;
  position: absolute;
  left: 25px;
  top: 12.5px;
}
.alltujiright {
  /* background: #00000052;
    position: absolute;
    right: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-left: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
  width: 80px;
  position: absolute;
  right: 2.5%;
  top: 50%;
  cursor: pointer;
  height: 25px;
  margin-top: -12.5px;
}
.alltujiright div {
  width: 80px;
  height: 1px;
  background: #fff;
  margin-top: 25px;
}
.alltujiright p {
  height: 12.5px;
  width: 1px;
  background: #fff;
  position: absolute;
  right: 25px;
  top: 12.5px;
}
.mhome {
  margin-bottom: 100px;
}
</style>
