<template>
    <div class="kitchenWap">
        <div class="livingRoomSpace">
            <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing pwjstrone">Sala da pranzo</div>
                <p v-html="seriesIntroduce.restaurantSpaceEng"  class="kjjianjie"></p>            
                <!-- <div class="moreBut">了解更多</div> -->
            </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
            <div class="spaceCustomization_img homeDecoration hsct" ref="homeDecoration" @mouseenter="homeDM" @mouseleave="homeDMout">
                <img src="@/common/image/ctkjbanner1.png" alt="">
                <div class="dingwei" :class="{'dingweishow':isdingwei}">
                    <div class="dingweia" @click="hsctdingweia">+</div>
                    <div class="dingweib" @click="hsctdingweib">+</div>
                    <div class="dingweic" @click="hsctdingweic">+</div>
                </div>
                <div class="dwzhankai" v-show="isdwzhankai == index" v-for="(item,index) in dwzhankaiData" :key='index' @click="dwguanbi">
                    <ul>
                        <li>
                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                            <div>{{item.titleIta}}</div>
                            <p>{{item.introduceIta}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hscttitle pwjstrone">Mobile vino da credenza</div>
            <router-link :to="{path:'/productDtails',query:{title:'餐边酒柜',SpaceActiv:3,ittitle:'Mobile vino da credenza',entitle:'Sideboard wine cabinet'}}"><div class="moreBut">Più informazioni</div></router-link>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':iskjmx}" ref="kjmx">
            
            <div class="spaceCustomization_img homeDecoration cfct" ref="homeDecoration" @mouseenter="cfctenter" @mouseleave="cfctleave">
                <img src="@/common/image/ctkjbanner2.png" alt="">
                <div class="dingwei" :class="{'dingweishow':iscfctdingwei}">
                    <div class="dingweia" @click="cfctdingweia">+</div>
                    <div class="dingweib" @click="cfctdingweib">+</div>
                    <div class="dingweic" @click="cfctdingweic">+</div>
                </div>
                <div class="dwzhankai" v-show="iscfctdwzhankai == index" v-for="(item,index) in cfctdwzhankaiData" :key='index' @click="cfctdwguanbi">
                    <ul>
                        <li>
                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                            <div>{{item.titleIta}}</div>
                            <p>{{item.introduceIta}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hscttitle pwjstrone">Mobile decorativo lato cena</div>
            <router-link :to="{path:'/productDtails',query:{title:'餐边装饰柜',SpaceActiv:3,ittitle:'Mobile decorativo lato cena',entitle:'Dinner side decoration cabinet'}}"><div class="moreBut">Più informazioni</div></router-link>
        </div>
        </div>
    </div>
</template>

<script>
import { getProductDetails } from "@/api/api";


export default {
  name: 'restaurantSpace',
  data(){
      return{
        dwzhankaiData:[],
        isdwzhankai:999,
        isdingwei:false,
        cfctdwzhankaiData:[],
        iscfctdwzhankai:999,
        iscfctdingwei:false,
            isbrandConcepttitle:false,
            issjmx:false,
            iskjmx:false,
      }
  },
    components: {
    },
    props:{
        seriesIntroduce: {
        type: String 
        }
    },
    mounted() {
       window.addEventListener("scroll", this.handleScroll);    
       this.isbrandConcepttitle = true    
       this.issjmx = true
       this.getkalajinyanData()
       this.getcfctdwzhankaiData()
    },
    methods:{
        getkalajinyanData(){
            let data = {
                productName:"餐边酒柜"
            }
            getProductDetails(data)
            .then(res =>{
                this.dwzhankaiData = res.productSpecific
            })
            .catch(res=>{
                console.log(res)
            })
        },
        getcfctdwzhankaiData(){
            let data = {
                productName:"餐边装饰柜"
            }
            getProductDetails(data)
            .then(res =>{
                this.cfctdwzhankaiData = res.productSpecific
            })
            .catch(res=>{
                console.log(res)
            })
        },
        handleScroll(){
           this.$nextTick(()=>{
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = false
                }
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmx = true
                }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmx = false
                }
           })
            // }
        },
        homeDM(){
            this.isdingwei = true
        },
        homeDMout(){
            this.isdingwei = false
        },
        dwguanbi(){
            this.isdwzhankai = 999
        },
        hsctdingweia(){
            this.isdwzhankai = 0
        },
        hsctdingweib(){
            this.isdwzhankai = 1
        },
        hsctdingweic(){
            this.isdwzhankai = 2
        },
        cfctenter(){
            this.iscfctdingwei = true
        },
        cfctleave(){
            this.iscfctdingwei = false
        },
        cfctdwguanbi(){
            this.iscfctdwzhankai = 999
        },
        cfctdingweia(){
            this.iscfctdwzhankai = 0
        },
        cfctdingweib(){
            this.iscfctdwzhankai = 1
        },
        cfctdingweic(){
            this.iscfctdwzhankai = 2
        },
  },
  unmounted(){
      window.removeEventListener("scroll",this.handleScroll)  
    }
}
</script>

<style scoped>
.kitchenWap .spaceCustomization p{
    /* width: 740px; */
    margin: 0 auto;white-space: pre-wrap;
    /* text-align: left; */
}
.hsct .dingwei .dingweia{
        left: 42.5%;
    top: 38.5%;
}
.hsct .dingwei .dingweib{
   left: 54.5%;
    top: 16.5%;
}
.hsct .dingwei .dingweic{
left: 68.5%;
    top: 18%;
}
.kitchenWap .dwzhankai ul{
    width: 875px;
    height: 710px;
}
.kitchenWap .dwzhankai ul li .cfimg{
    width: 400px;
    height: 352.5px;
    margin:88px 0 25px 355px;
}
.kitchenWap .dwzhankai ul li div{
    font-size: 30px;
    padding-top: 0;
}
.kitchenWap .dwzhankai ul li p{
    padding-top: 15px;
    font-size: 16px;
}
.hscttitle{
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.cfct .dingwei .dingweia{
        left: 17%;
    top: 41.4%;
}
.cfct .dingwei .dingweib{
    left: 69.6%;
    top: 59.5%;
}
.cfct .dingwei .dingweic{
    left: 78.9%;
    top: 69%;
}
</style>
