<template>
    <div class="kitchenWap">
        <div class="livingRoomSpace">
            <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing pwjstrone">Bedroom</div>
                <p v-html="seriesIntroduce.bedroomSpaceEng"  class="kjjianjie" ></p>            
                <!-- <div class="moreBut">了解更多</div> -->
            </div>
            </div>
            
            <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
            <div class="spaceCustomization_img homeDecoration hsct" ref="homeDecoration" @mouseenter="homeDM" @mouseleave="homeDMout">
                <img src="@/common/image/bedroom1.png" alt="">
                <div class="dingwei" :class="{'dingweishow':isdingwei}">
                    <div class="dingweia" @click="hsctdingweia">+</div>
                    <div class="dingweib" @click="hsctdingweib">+</div>
                    <div class="dingweic" @click="hsctdingweic">+</div>
                    <div class="dingweid" @click="hsctdingweid">+</div>
                </div>
                <div class="dwzhankai" v-show="isdwzhankai == index" v-for="(item,index) in dwzhankaiData" :key='index' @click="dwguanbi">
                    <ul>
                        <li>
                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                            <div>{{item.titleEng}}</div>
                            <p>{{item.introduceEng}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hscttitle pwjstrone">Pearl apricot bedroom cabinet</div>
            <router-link :to="{path:'/productDtails',query:{title:'珍珠杏卧室柜',SpaceActiv:1,ittitle:'Armadio da camera da letto Pearl Albicocca',entitle:'Pearl apricot bedroom cabinet'}}"><div class="moreBut">Learn More</div></router-link>
            </div>
        </div>
            
    </div>
</template>

<script>
import { getProductDetails } from "@/api/api";


export default {
  name: 'bedroomSpace',
  data(){
      return{
        dwzhankaiData:[],
        isdwzhankai:999,
        isdingwei:false,
        isbrandConcepttitle:false,
        issjmx:false,
      }
  },
    components: {
    },
    props:{
        seriesIntroduce: {
        type: String 
        }
    },
    mounted() {
       window.addEventListener("scroll", this.handleScroll);    
       this.isbrandConcepttitle = true    
       this.issjmx = true
        this.getkalajinyanData()
    },
    methods:{
        getkalajinyanData(){
            let data = {
                productName:"珍珠杏卧室柜"
            }
            getProductDetails(data)
            .then(res =>{
                this.dwzhankaiData = res.productSpecific
            })
            .catch(res=>{
                console.log(res)
            })
        },
        handleScroll(){
          this.$nextTick(()=>{
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = false
                }
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
          })
            // }
        },
        homeDM(){
            this.isdingwei = true
        },
        homeDMout(){
            this.isdingwei = false
        },
        dwguanbi(){
            this.isdwzhankai = 999
        },
        hsctdingweia(){
            this.isdwzhankai = 0
        },
        hsctdingweib(){
            this.isdwzhankai = 1
        },
        hsctdingweic(){
            this.isdwzhankai = 2
        },
        hsctdingweid(){
            this.isdwzhankai = 3
        },
  },
  unmounted(){
    window.removeEventListener("scroll", this.handleScroll);  
  } 
}
</script>

<style scoped>
.kitchenWap .spaceCustomization p{
    /* width: 740px; */
    margin: 0 auto;white-space: pre-wrap;
    /* text-align: left; */
}
.hsct .dingwei .dingweia{
    left: 36%;
    top: 47.5%;
}
.hsct .dingwei .dingweib{
    left: 41.5%;
    top: 4%;
}
.hsct .dingwei .dingweic{
    left: 55.5%;
    top: 57%;
}
.hsct .dingwei .dingweid{
        left: 69%;
    top: 60.5%;
}
.kitchenWap .dwzhankai ul{
    width: 875px;
    height: 710px;
}
.kitchenWap .dwzhankai ul li .cfimg{
    width: 400px;
    height: 352.5px;
    margin:88px 0 25px 355px;
}
.kitchenWap .dwzhankai ul li div{
    font-size: 30px;
    padding-top: 0;
}
.kitchenWap .dwzhankai ul li p{
    padding-top: 15px;
    font-size: 16px;
}
.hscttitle{
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
</style>
