<template>
    <div class="zhichi">
        <p v-html="apply"></p>
        <div class="consult pwjconsult">
            <div>
                <span>Città </span><input type="text" placeholder="*Inserisce la sua città" v-model="city">
            </div>
            <div>
                <span>Nome e Cognome: </span><input type="text" placeholder="*Inserisce il suo nome e cognome" v-model="name">
            </div>
            <div>
                <span>Tel.: </span><input type="text" placeholder="* Inserisce il suo numero di telefono" v-model="phone">
            </div>
            <div>
                <span>Email: </span><input type="text" placeholder="Inserisce la sua Email" v-model="email">
            </div>
            <div>
                <span>Messaggio: </span><input type="text" placeholder="*Ha una domanda specifica?" v-model="message">
            </div>
            
            <button @click="tijiao">Invia immediatamente</button>
        </div>
        <div class="tishi" v-if="istishi"><div>{{tishi}}</div></div>
    </div>
</template>

<script>
import { getContactus } from "@/api/api";
import {saveConsultRecord} from '@/api/api'
export default {
    name:'jxsshenqing',
    data(){
        return{
            apply:"",
            name:'',
            phone:'',
            email:'',
            message:'',
            tishi:'',
            istishi:false,
            city:''
        }
    },
    mounted() {
        this.getContactusData()
    },
    methods:{
        getContactusData(){
            getContactus()
            .then(res => {
                console.log(res)
                this.apply = res.agentRecruit.applyIta
            })
            .catch(res => {
                console.log(res,'catch')
            });
        },getPageData(data){
            saveConsultRecord(data)
            .then(res => {
                if(res.result == 0){
                    this.istishi=true
                    this.tishi = 'Inviato con successo'
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
                }else{
                    this.istishi=true
                    this.tishi = res.message
                }
                    this.name = ''
                    this.phone = ''
                    this.email = ''
                    this.message = ''
                    this.city = ''
            })
            .catch(res => {
                console.log(res,)
            });
        },
        tijiao(){
            let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/
            // let emailreg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
            if(this.name == ''){
                this.istishi=true
                this.tishi = 'Inserisce il suo nome e cognome'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.phone == ''){
                this.istishi=true
                this.tishi = 'Inserisce il suo numero di telefono'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.city == ''){
                this.istishi=true
                this.tishi = 'Inserisce la sua città'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.message == ''){
                this.istishi=true
                this.tishi = 'Inserisce la domanda che desideri consultare'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if((!phonereg.test(this.phone)) && this.phone != ''){
                this.istishi=true
                this.tishi = 'Inserisce il numero di telefono corretto'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else{
                let data = {
                    name : this.name,
                    phone : this.phone,
                    email : this.email,
                    message : this.message,
                    city:this.city
                }
                this.getPageData(data)
            }
        }
    },

}
</script>

<style>
.zhichi .consult{
    width: 1200px;
    padding-top: 60px;
    text-align: center;
}
.zhichi .consult button:hover{
    color: #000;
    animation:morehoverh 1s;
    -moz-animation:morehoverh 1s; 
    -webkit-animation:morehoverh 1s; 
    -o-animation:morehoverh 1s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
.consult>div{
    display: flex;
}
.consult>div span{
    display: block;
    flex: 1;
    line-height: 2;
    text-align-last: justify;
}
.consult>div input{
    flex: 8;
}
.tishi{
    width: 100%;
    color: #fff;
    position: fixed;
    top: 50%;
    margin-top: -30px;
    left: 0;
    text-align: center;
}
.tishi div{
    display: inline-block;
    padding: 20px 50px;
    background: #000;
    border-radius: 10px;
}
.pwjconsult>div span{
   white-space: nowrap;
}
</style>