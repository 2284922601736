<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + aboutVifaBanner.mobileImage" alt="" />
      <div>{{ aboutVifaBanner.title }}</div>
    </div>
    <div class="bannertop" v-html="aboutVifa.brandConcept"></div>
    <div class="hometopswipt ppjjsjmx">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">设计美学</div>
          <div class="subtitle">
            以极简呈现精致，以极简成就实用，以极简释放兼容
          </div>
        </div>
      </div>
      <div class="video">
        <video
          poster="@/common/image/poter3.jpg"
          :src="'https://www.vifa.cn' + aboutVifa.deviseVideo"
          controls="controls"
          preload="preload"
          loop="loop"
x5-playsinline="" 
playsinline="true" 
webkit-playsinline="true" 
x-webkit-airplay="true" 
x5-video-player-type="h5" 
x5-video-player-fullscreen="" 
x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
    <div class="hometopswipt">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">科技美学</div>
          <div class="subtitle">
            以科技应用不断提升产品与服务，创造更高生活品质；
          </div>
          <div class="subtitle">以科技创新不断提升精准对接和服务效率</div>
        </div>
      </div>
      <div class="video">
        <video
          poster="@/common/image/poter2.jpg"
          :src="'https://www.vifa.cn' + aboutVifa.scienceVideo"
          controls="controls"
          preload="preload"
          loop="loop"
x5-playsinline="" 
playsinline="true" 
webkit-playsinline="true" 
x-webkit-airplay="true" 
x5-video-player-type="h5" 
x5-video-player-fullscreen="" 
x5-video-orientation="portraint"
        ></video>
      </div>
    </div>
    <div class="hometopswipt ppjjsjmx shmx">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">生活美学</div>
          <div class="subtitle">奉行极简智慧和精致生活态度</div>
          <div class="subtitle">化繁为简，追求卓越，享受周致和专注的愉悦。</div>
        </div>
      </div>
      <div class="shmxbimg" v-show="isshmxbbox" @click="closhmx">
        <transition-group name="pinpailishiani">
          <ul>
            <li
              v-for="(item, index) in shmxiamgData"
              :key="index"
              v-show="isshmxbimg == index"
            >
              <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
            </li>
          </ul>
        </transition-group>
      </div>
      <div class="pinpailishi">
        <div class="pplsimg">
          <transition-group name="pinpailishiani">
            <ul
              :style="{
                marginLeft: pplsmarginLeft + 'px',
                width: pplswidth + 'px',
              }"
              ref="pplsul"
            >
              <li
                :ref="'pplsli' + index"
                v-for="(item, index) in shmxiamgData"
                :key="index"
                @click="fatatu(index)"
                @touchstart="pplstouchstart"
                @touchmove="pplstouchmove"
              >
                <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
              </li>
            </ul>
          </transition-group>
        </div>
      </div>
    </div>
    <div class="subnav">
      <router-link to="/mAbout">品牌简介</router-link>
      <router-link to="" class="active">品牌理念</router-link>
      <router-link to="/mEnterpriseDynamics">企业动态</router-link>
    </div>
  </div>
</template>

<script>
import { getAboutVifa } from "@/api/api";
export default {
  data() {
    return {
      aboutVifaBanner: {},
      aboutBrandDevelop: [],
      shmxiamgData: [],
      ishbishow: 1,
      pplsstartx: 0,
      pplsmovex: 0,
      pplschangex: 0,
      pplsmarginLeft: "",
      pplswidth: 0,
      pplsliwidth: 0,
      pplsuloffsetLeft: 0,
      aboutVifa: "",
      isshmxbimg: 1,
      isshmxbbox: false,
    };
  },
  components: {
    // Mzixun
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mBrandConcept");
    } else {
      this.$router.replace(paths + "/about");
    }

    setTimeout(() => {
      this.$nextTick(() => {
        this.pplsliwidth = this.$refs.pplsli0.clientWidth;
        this.pplswidth =
          (this.$refs.pplsli0.clientWidth + 1) * this.shmxiamgData.length;
        this.pplsuloffsetLeft = this.$refs.pplsul.offsetLeft;
        this.pplsmarginLeft = this.pplsuloffsetLeft;
        this.kjwidth =
          document.body.clientWidth * this.productCenterBanner.length;
      });
    }, 1000);
    this.getPageData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPageData() {
      getAboutVifa()
        .then((res) => {
          this.aboutVifaBanner = res.aboutVifaBanner;
          this.aboutVifa = res.aboutVifa;
          this.shmxiamgData = res.aboutVifaLife;
        })
        .catch((res) => {
          console.log(res);
        });
    },

    show(index) {
      this.pplschangex = this.pplsstartx;
      this.pplsmarginLeft =
        this.pplsuloffsetLeft - this.pplsliwidth * (index - 1);
    },
    pplstouchstart(e) {
      this.pplsstartx = e.touches[0].clientX;
    },
    pplstouchmove(e) {
      if (this.pplschangex == this.pplsstartx) {
        return;
      }
      this.pplsmovex = e.touches[0].clientX;
      let leftslide = this.pplsstartx - this.pplsmovex;
      console.log(this.ishbishow);
      if (leftslide > 30 && this.ishbishow < this.shmxiamgData.length - 1) {
        this.show(++this.ishbishow);
      } else if (leftslide < -30 && this.ishbishow > 0) {
        this.show(--this.ishbishow);
      }
    },
    fatatu(index) {
      this.isshmxbbox = true;
      this.isshmxbimg = index;
    },
    closhmx() {
      this.isshmxbbox = false;
    },
  },
};
</script>

<style scoped>
.homelogo {
  width: 750px;
  position: relative;
  margin-bottom: 100px;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 45px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 50%;
  margin-top: -45px;
}
.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 0;
}
.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto;
}
.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
  font-weight: bold;
}
.pinpailishi .subtitle {
  font-size: 26px;
}
.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: justify;
}
.pplsimg {
  width: 100%;
  height: 357px;
  padding: 20px 0;
  overflow: hidden;
}
.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -642px;
}
.pinpailishi ul li {
  width: 635px;
  height: 357px;
  flex: 1;
  padding: 0 20px;
}
.pinpailishi ul li img {
  width: 635px;
  height: 357px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  color: #f08518;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}
.bottomtitle .showtitle {
}
.bottomtitle .showtitle p {
  text-align: center;
}
.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: 0;
  top: 45px;
}
.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: 0;
  top: 45px;
}
.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}
.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}
.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #4c413d;
}
.quanpin .pplsimg {
  height: 447px;
}
.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}
.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}
.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 40px auto;
}
.input input {
  width: 100%;
  line-height: 2;
  border: 1px solid #4c413d;
  text-align: center;
}

.bannertop,
.ppfzlist {
  width: 670px;
  font-size: 26px;
  line-height: 2;
  text-align: justify;
  margin: 40px auto 100px;
}
.ppfzlist img {
  width: 100%;
}
.ppfzlist div {
  font-size: 32px;
  font-weight: bold;
  padding-top: 26px;
}
.video {
  padding: 40px 0;
}
.ppjjsjmx {
  /* background: url('../../../common/image/sjmxbg.png') 0 0 no-repeat; */
  /* background-size: 100% 100%; */
  /* padding-top: 10px; */
  /* padding-bottom: 60px; */
}
.shmx {
  background: none;
  margin-top: 0;
  /* padding: 10px 0; */
  /* padding-bottom: 40px; */
}
.subnav {
  width: 750px;
  font-size: 26px;
  line-height: 100px;
  background: #000000cc;
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav .active {
  color: #f08518;
}
.shmxbimg {
  /* width: 100%;
    overflow: hidden;
    padding: 20px 0; */
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000f2;
  z-index: 999999;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shmxbimg ul {
  width: 99999999px;
  display: flex;
}
.shmxbimg ul li {
  width: 750px;
}
.shmxbimg ul li img {
  width: 100%;
}
.hometopswipt {
  margin-bottom: 100px;
  /* padding-top: 60px; */
  /* padding-bottom: 100px; */
}
</style>
