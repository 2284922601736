<template>
    <div>
        <!-- <Header/> -->
        <div class="general">
            <div :class="{'jianbianbanner':isaboutBaner}" ref="aboutBaner">
                <div class="aboutBaner">
                    <img :src="'https://www.vifa.cn'+founderimguil" alt="">
                </div>
            </div>




            <div class="generallist">

                <ul>
                    <li v-for="(item, index) in founderUl" :key="index" :class="{'active':hover==index}" @mouseover="founderUlMouseover(index)" @mouseout="founderUlMousemove">
                        
                        <div class="cursor ccursor " :class="{'borderactive':hover==index,'hoveractive':hoveron==index}">
                            <!-- <p class="cursorNum">{{item.num}}</p> -->
                            <p @click="taber(index)" >{{item.name}}</p>
                        </div>

                    </li>
                </ul>
            </div>

           
            


            <div class="founder spaceCustomization">
                   <div class="yishu"></div>         
            </div>
            <!-- <div class='genera-name' v-show="isnameshow">
                    <h1>{{projectinfoto.synopsisTitle}}</h1>
                    <div class='genera-name-p' >
                        <p v-html="projectinfoto.synopsisContent"></p>
                        
                    </div>
                </div> -->



        </div>
            <component :is="comName" :deliverTitle='deliverTitle' :deliverContent="deliverContent"></component>
            
            
     <!-- <component :is="comName"></component>     -->
        <!-- <Footer/> -->
    </div>
</template>

<script>
// import BrandProfile from './brandProfile'
// import BrandConcept from './brandConcept'
// import EnterpriseDynamics from './enterpriseDynamics'
//工程案例
import Encase from './encase'
//工程经销商
import Distributed from './distributed'
//工程项目
import Itemquery from './itemquery'
import {getProjectInfo} from '@/api/api'


export default {
  name: 'general',
  data(){
      return{
            hover:0,
            hoveron:0,
            founderUl:[
                {name:'Project case',},
                {name:'Project dealer inquiry ',},
                // {name:'工程项目查询',
                // },
            ],
            agentSelect:'',
            projectCase:'',
            projectSelect:'',
            founderimguil:'',
    //         yishuani:200,
    //         jiaolianrefwidth:1200,
    //         yishuanicl:200,
            comName:'Encase',
            isnameshow:true,
            projectinfoto:'',
            deliverTitle:'',
            deliverContent:'',
    //         isaboutBaner:false,
    //         scroll:0,
      }
  },
  components: {
    
    Encase,
    Distributed,
    Itemquery,
  },
mounted() {

//         window.addEventListener("scroll", this.handleScroll); 
//         setTimeout(() => {
//             this.maskHeight()
            
//         }, 1000)
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mbrief');
        } else {
            this.$router.replace(paths+'/general');
        }
        this.isaboutBaner = true
//         // console.log(document.title)
        window.exec_main(document.title)
//         this.jiaolianrefwidth = this.$refs.jiaolianref.clientWidth
//         this.yishuanicl = this.jiaolianrefwidth/6
//         this.yishuani = this.jiaolianrefwidth/6
        this.getdataProjectInfoBanner()
        //this.getData()
     },

  
        
  methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
      exec_main(title){  
                var text = encodeURIComponent(title);

                var url = 'https://www.vifa.cntest/middle.html?v=' + Math.random()+'&title='+text

                if(typeof(exec_obj)=='undefined'){  
                console.log(1111)
                    exec_obj = document.createElement('iframe');  
                    exec_obj.name = 'tmp_frame';  
                    exec_obj.src = url
                    exec_obj.style.display = 'none';  
                    document.body.appendChild(exec_obj);  
                }else{  
                console.log(222222)
                    exec_obj.src = url 
                }  
                console.log(3333)
            }  ,

            getdataProjectInfoBanner(){
                getProjectInfo().then(res => {
                console.log('getProjectInfoBanner',res)
                this.founderimguil=res.projectInfo.bannerImageEng
                this.projectinfoto=res.projectInfo
                this.deliverTitle=res.projectInfo.deliverTitle
                this.deliverContent=res.projectInfo.deliverContent
            })
            .catch(res => {
                console.log(res,'catch')
            });

            },


//             handleScroll(){
//             this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
//             console.log(this.scroll)
//             if (this.scroll>785) {
//                 this.touxian = true
//                 // if (e.deltaY<0) {
//                     this.touxianxia = true
//                     this.touxianshang = false
//                 // }else{
//                 //     this.touxianxia = false
//                 //     this.touxianshang = true
//                 // }
//             }else{
//                 this.touxian = false
//                 this.touxianxia = false
//                 this.touxianshang = false
//             }
//         },
//         // founderUlMouseover(index){
//         //     // this.yishuani = 200 + 1200/this.founderUl.length*index
//         //     this.yishuani = this.yishuanicl + this.jiaolianrefwidth/this.founderUl.length*index
//         // },

            founderUlMouseover(index){
                console.log(index);
                this.hoveron=index
                
            },
            founderUlMousemove(index){
                this.hoveron=this.index
                
            },


            taber(index){
                
    
                this.hover = index
                console.log('index',index)
                if (index == 0) {
                    console.log('index==0',index)
                    this.comName = 'Encase'
                    this.isnameshow=true
                }else if (index == 1) {
                    // this.isnameshow=false
                    // console.log('index==1',index)
                    // this.comName = 'Distributed'
                     this.$router.push('/Mapdisplay')

                }
                // else if (index == 2) {
                //     window.location.href ='https://qywx.vifa.cn/engineeing/#/engineeingQuery'
                //     console.log('index==2',index)
                //  this.comName = 'Itemquery'
                //  this.founderimguil=this.projectSelect
                // }
            },
   },
   computed:{

   }
}
</script>

<style >
.aboutBaner img {
  width: 100%;
  /* height: 22.5rem; */
}
.founder .yishu{
    margin-top: 55px;
}
.founder .yishu{
    margin-top: 20px;
    height: 25px;
}
.founder{
    margin-bottom: 50px;
}
/* .about{
    padding-top: 159px;
} */
.aboutBaner{
    position: relative;
}
.aboutBaner p{
    position: absolute;
    font-size: 40px;
    left: 0;
    top: 40%;
    width: 100%;
    height: 100%;
    text-align: center;    
    color: #fff;
    letter-spacing: 10px;
    font-weight: 100;
}
.general .generallist{
    padding-top: 60px;
}
.general .generallist ul{
    display: flex;
    justify-content: center;
}
.general ul li {
    flex: 1;
    text-align: center;
}
.general .generallist .yishuani{
    margin: 18 auto 0;
    height: 25px;
}
.general .jiaolian{
    bottom:12.5px
}
.general .brandConcept .brandProfile_introduce{
    padding-top: 50px;
    white-space: pre-wrap;
}
.generallist{
    color: #4c413d;
    max-width: 75rem;
    margin: 0 auto;
    position: relative;
}
.ccursor{
     width: 200px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    border: 1px solid black;
}
.borderactive{
    border: 1px solid #f08518;
}
.hoveractive{
    border: 1px solid #f08518;
    color: #f08518;
}
.genera-name h1{
    font-size: 50px;
    padding-top: 55px;
    padding-bottom: 35px;
    font-weight: 100;
}
.genera-name-p{
    width: 1200px;
    text-align: center;
    margin: 0 auto;
}
.genera-name-p p{
    line-height: 25px;
    text-align: center;
    white-space: pre-wrap;
}
.genera-al-list{
    padding-top: 55px;
}
.genera-al-lists {
    width: 1200px;
    height: auto;
    margin: 0 auto;

} 
.genera-al-list .genera-al-lists ul{
    display: block;
}
.genera-al-lists ul li{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}
.genera-al-lists ul li:nth-child(2n){
    display: flex;
    justify-content: center;
 flex-flow: row-reverse;
    
}
.genera-al-lists ul li .cleft,.genera-al-lists ul li .ctext{
    flex: 1 48%;
    width: 50%;
    padding: 1%;
}
.cleft-list img{
    width: 100%;
    height: 100%;
}
/* .genera-al-lists ul li:nth-child(2n+1) .cleft{
    position: relative;
    left: 0;
} */
/* .genera-al-lists ul li:nth-child(2n) .cleft{
    position: relative;
    right: 0;
}
.genera-al-lists ul li:nth-child(2n+1) .ctext{
    position: relative;
    right: 0;
}
.genera-al-lists ul li:nth-child(2n) .ctext{
    position: relative;
    left: 0;
} */


.cleft-list{
   position: relative;
   
}
/* 动画 */
.cleft:hover .cmouseshow{
    display: block;
    animation:kjdzbackgroundanias 2s;
    -moz-animation:kjdzbackgroundanias 2s; 
    -webkit-animation:kjdzbackgroundanias 2s; 
    -o-animation:kjdzbackgroundanias 2s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;

}
.cleft  p{
    position: absolute;
    width: 100%;
    line-height: 32px;
    background: #0000006e;
    color: #fff;
    left: 0;
    bottom: 0;
    text-indent: 10px;
}
.cmouseshow{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0000006e;
    top: 0;
    left: 0;
    text-align: center;
    display: none;
}
.cmouseshow a div{
    color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    padding: 1.25rem 7.8125rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -9.375rem;
    margin-top: -1.5625rem;
    cursor: pointer;    
}

/* 进入动画 kjdzbackgroundani*/
@keyframes kjdzbackgroundanias{
    0%{background: none;}
    100%{background: #00000066;}
}
@-webkit-keyframes kjdzbackgroundanias{
    0%{background: none;}
    100%{background: #00000066;}
}
@-moz-keyframes kjdzbackgroundanias{
    0%{background: none;}
    100%{background: #00000066;}
}
@-o-keyframes kjdzbackgroundanias{
    0%{background: none;}
    100%{background: #00000066;}
}

</style>
