<template>
    <div class="livingRoomWap kitchenDetails">
        <div class="livingRoomSpace">
        <div  :class="{'jianbianbanner':iscaseBaner}" ref="caseBaner">
            <div class="chufangxbanner">
                <img :src="'https://www.vifa.cn' + productbanner" alt="">
            </div>
        </div>
        <div class="productnav " :class="{'productnavTop':isproductnavTop,'productnavTopaa':isproductnavTopaa,'productnavTopbb':isproductnavTopbb,'productnavtopling':isproductnavtopling,'jianbianbanner':iscaseBaner}" ref="productnavTop">
            <div class="space">
                <div :class="{spaceActiv:isSpaceActiv == index}" v-for="(item, index) in kjdzallData" :key="index" @click="spance(index)">
                    <img class="spaceimg" :src=item.icon alt="">
                    <img class="spaceimgActive" :src=item.icon_active alt="">
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
            <div class="founder spaceCustomization">
                <div class="beijing pwjstrone">{{productName}}</div>
                <!-- <ul class="kala">
                    <li v-for="(item,index) in ProductDetail.productFunction" :key='index'>
                        <img :src="'https://www.vifa.cn' + item.image" alt="">
                        <p>{{item.title}}</p>
                    </li>
                </ul>   -->
                <div class="kjdetails">
                    <div :class="{'active':iscp}" @click="clicp">Dettagli del prodotto</div>
                    <div class="kjdetailsright" :class="{'active':isys}"  @click="cliys">Materiale e colore della porta</div>
                </div>      
            </div>
        </div>
        <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
            <div class="founder spaceCustomization" v-show="iscp">
                <div class="yishu"></div>
                <ul class="kalajinx">
                    <li  v-for="(item,index) in kalajinxData" :key='index' @mouseenter="kalajinxenter(index)" @mouseleave="kalajinxleave" :class="{kalajinxenter:isdivshop == index}">
                        <img :src="'https://www.vifa.cn' + item.image" alt="">
                        <div>
                            <p>{{item.titleIta}}</p>
                            <p>+</p>
                        </div> 
                    </li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

        </div>
        <div class="jianbianq"  :class="{'jianbianh':iskjmx}" ref="kjmx">
            <div class="cfkjys" v-show="isys">
                <div class="founder spaceCustomization">
                    <div class="yishu"></div>
                    <div class="beijing">Più pannelli, più scelte.</div>
                </div>
                <div v-for="(item,index) in productMaterial" :key='index' >
                    <div v-if="item.materialList !==undefined && item.materialList != null  && item.materialList.length > 0">
                        <div class="cfkjystitle ">
                            <p></p>
                            <div class="itcfkjystitle">{{item.titleIta}}</div>
                        </div>
                        <div class="cfkjcont">
                            <ul>
                                <li v-for="(colorList,colorListIndex) in item.materialList" :key='colorListIndex' @click="cfkjyscont(index,colorListIndex)" :class="{'active':colorListIndex == productMaterial[index].showItem}">
                                    <img :src="'https://www.vifa.cn' + colorList.image" alt="">
                                    <p>{{colorList.titleIta}}</p>
                                </li>
                            </ul>
                            <div class="cfkjysbigimg" >
                                <img v-for="(colorList,colorListIndex) in item.materialList" :key='colorListIndex' :src="'https://www.vifa.cn' + colorList.image" alt="" v-show="colorListIndex ==  productMaterial[index].showItem">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

           
        </div>
    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import bed from '@/common/image/bed.png'
import kitchen from '@/common/image/kitchen.png'
import dinner from '@/common/image/dinner.png'
import clothes from '@/common/image/clothes.png'
import bath from '@/common/image/bath.png'
import book from '@/common/image/book.png'
import sofa_active from '@/common/image/sofa_active.png'
import bed_active from '@/common/image/bed_active.png'
import kitchen_active from '@/common/image/kitchen_active.png'
import dinner_active from '@/common/image/dinner_active.png'
import clothes_active from '@/common/image/clothes_active.png'
import bath_active from '@/common/image/bath_active.png'
import book_active from '@/common/image/book_active.png'
import { getProductDetails } from "@/api/api";





export default {
  data(){
      return{
          lastclick:'',
        kalajinxData:[],
        isdivshop:999,
        kjdetailsnav:[{name:'产品细节'},{name:'门板材质及颜色'}],
        iscp:true,
        isys:false,
        // productMaterial:[],
        productbanner:'',
        ProductDetail:'',
        productName:'',
        iscaseBaner:false,
        isbrandConcepttitle:false,
        issjmx:false,
        iskjmx:false,
        isproductnavTop:false,
        isproductnavTopaa:false,
        isproductnavTopbb:false,
        isproductnavtopling:false,
        isSpaceActiv:0,
        kjdzallData:[
            {
                icon:sofa,
                icon_active:sofa_active,
                name:'Soggiorno',
            },
            {
                icon:bed,
                icon_active:bed_active,
                name:' Camera da letto',
            },
            {
                icon:kitchen,
                icon_active:kitchen_active,
                name:'Cucina',
            },
            {
                icon:dinner,
                icon_active:dinner_active,
                name:'Sala da pranzo',
            },
            {
                icon:clothes,
                icon_active:clothes_active,
                name:' Guardaroba',
            },
            {
                icon:bath,
                icon_active:bath_active,
                name:'Bagno',
            },
            {
                icon:book,
                icon_active:book_active,
                name:'Libreria',
            }
        ],
        productMaterial:[
        ],
        showItemdata:{showItem:0}
      }
  },
    components: {
    },
    mounted() {
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
            this.$router.replace(paths+'/mproductDtails?title='+this.$route.query.title+"&navtype="+this.$route.query.SpaceActiv+"&entitle="+this.$route.query.entitle+"&ittitle="+this.$route.query.ittitle);
            // this.$router.replace(paths+'/mproductDtails?title='+this.$route.query.title);
        } else {
            this.$router.replace(paths+'/productDtails?title='+this.$route.query.title+'&SpaceActiv='+this.$route.query.SpaceActiv+"&entitle="+this.$route.query.entitle+"&ittitle="+this.$route.query.ittitle);
        }
        console.log(this.$route.query.title)
        this.getProductDetailsData()
        window.addEventListener("scroll", this.productNavScroll); 
       window.addEventListener("scroll", this.handleScroll); 
        this.iscaseBaner = true
        this.isSpaceActiv=this.$route.query.SpaceActiv
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        handleScroll(){
           this.$nextTick(()=>{
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.iskjmx = true
                }
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = false
                    this.isproductnavTopbb = true
                    this.isproductnavtopling = false
                }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.iskjmx = false
                }
                if(this.isproductnavTop == true){
                    this.isproductnavTopaa = true
                    this.isproductnavTopbb = false
                    this.isproductnavtopling = false
                }
           })
            // }
        },
        productNavScroll(){
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (this.scroll > 785) {
                this.isproductnavTop =true
                this.isproductnavtopling = true
            }else{
                this.isproductnavTop =false
                this.isproductnavTopaa = false
                this.isproductnavTopbb = false
                this.isproductnavtopling = false
            }
        },
        getProductDetailsData(){
            let data = {
                productName:this.$route.query.title
            }
            getProductDetails(data)
            .then(res =>{
                console.log(res)
                this.ProductDetail = res
                this.kalajinxData = res.productSpecific
                this.productMaterial = res.productMaterial
                for (let i of this.productMaterial) {
                    i.showItem = 999
                }
                this.productbanner = res.productSeries.banner
                this.productName = res.productSeries.productNameIta
            })
            .catch(res=>{
                console.log(res)
            })
        }, 
        kalajinxenter(index){
            this.isdivshop = index
        },
        kalajinxleave(){
            this.isdivshop = 999
        },
        clicp(){
            this.iscp = true
            this.isys = false
        },
        cliys(){
            this.iscp = false
            this.isys = true
        },
        cfkjyscont(preindex,index){
            if (this.lastclick != preindex) {
                this.productMaterial[this.lastclick].showItem = 9999
            }
            this.lastclick = preindex
            this.productMaterial[preindex].showItem = index
            
        },
        spance(index){
            this.isSpaceActiv = index
            
            // document.documentElement.scrollTop = this.$refs.roomSpace.offsetTop - 200
            this.$router.push({path:'/productCenter',query:{isindex:index}})
        }
  },
  unmounted(){
       window.removeEventListener("scroll", this.productNavScroll); 
       window.removeEventListener("scroll", this.handleScroll); 
    }
}
</script>

<style>
.kitchenDetails .founder img{
    width: 50px;
    height: 50px;
    transform: none;
}
.kitchenDetails .founder ul p{
    text-align: center;
    line-height: 2;
    padding-top: 10px;
}
.kitchenDetails .founder .beijing{
    padding-bottom: 100px;
}
.kjdetails{
    width: 625px;
    margin: 0 auto;
    overflow: hidden;
}
.kjdetails>div{
    width: 40%;
    float: left;
    border: 1px solid #000;
    cursor: pointer;
    color: #000;
    padding: 2% 0;
}
.kjdetails>div.active{
    border: 1px solid #f08518;
    color: #f08518;
}
.kjdetails>div.kjdetailsright{
    float: right;
}
.kitchenDetails .founder .yishu{
    margin-top: 3%;
    margin-bottom: 2%;
}
.cfkjystitle{
    font-size: 30px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.cfkjystitle div{
    width: 450px;
    margin: 0 auto;
    background: #fff;
}
.cfkjystitle p{
    border-bottom: 1px solid #000;
    position: absolute;
    left: 0;
    width: 100%;
    top: 52%;
    z-index: -1565;
}
.cfkjcont{
    width: 1200px;
    margin: 35px auto 0;
}
.cfkjcont ul{
        width: 100%;
    display: flex;
    align-content: center;
}
.cfkjcont ul li{
    flex: 1;
    cursor: pointer;
}
.cfkjcont ul li.active img{
    border: 1px solid #f08518;
}
.cfkjcont ul li p{
    font-size: 12px;
    line-height: 2;
}
.cfkjcont ul li img{
    width: 50px;
    height: 50px;
}
.cfkjysbigimg{
    margin: 45px 0;
}
.cfkjysbigimg img{
    width: 100%;
}
.spacelunimg{
    width: 1200px;
    margin: 0 auto;
}
.spacelunimg img{
    width: 100%;
}
.chufangxbanner img{
    width: 100%;
}
.cfkjystitle  .itcfkjystitle{
    width: 520px;
}
</style>
