<template>
  <Header
    v-if="pc"
    @contextmenu.prevent="youjian()"
    @smartHome="smartHome"
    :language="language"
    @bindLanguage="bindLanguage"
  ></Header>
  <Mheader
    v-if="mobile"
    :touchto="touchto"
    @isaaa="isaaas"
    :smartHomeobj="smartHomeobj"
    :language="language"
    @bindLanguage="bindLanguage"
  ></Mheader>
  <router-view
    @touchstart="kjtouchstart"
    @touchmove="kjtouchmove"
    :isaaat="isaaat"
  ></router-view>
  <Footer
    v-if="pc"
    @contextmenu.prevent="youjian()"
    :isxiazai="isxiazai"
    :language="language"
    :istongzhi="istongzhi"
    @footerxiazai="footerxiazai"
    @footertongzhi="footertongzhi"
  ></Footer>
  <Mfooter v-if="mobile"     :language="language"></Mfooter>
  <xi-zai
    v-if="isxiazai"
    :smartHomeobj="smartHomeobj"
    @hide="hide"

  ></xi-zai>
  <tong-zhi v-if="istongzhi" :tongZhiobj="tongZhiobj" @hidetongzhi="hidetongzhi"></tong-zhi>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";
import Mheader from "./components/m_header";
import Mfooter from "./components/m_footer";
import XiZai from "@/page/china/xiazai/xaizai.vue";
import TongZhi from '@/page/china/xiazai/tongzhi.vue'
export default {
  name: "App",
  data() {
    return {
      pc: false,
      mobile: false,
      starty: 0,
      movey: 0,
      touchto: 1,
      isaaat: false,
      // 是否显示下载
      isxiazai: false,
      istongzhi:false,
      smartHomeobj: {},
      tongZhiobj:{},
      language: 1,
    };
  },
  components: {
    Header,
    Footer,
    Mheader,
    Mfooter,
    XiZai,
    TongZhi
  },
  mounted() {
    if (
      sessionStorage.getItem("language") &&
      sessionStorage.getItem("language") != undefined &&
      sessionStorage.getItem("language") != null
    ) {
      this.language = sessionStorage.getItem("language");
    } else {
      sessionStorage.setItem("language", 1);
    }

    window.addEventListener("beforeunload", this.beforeunloada);
    if (this.$route.path == "/mmapsearch") {
      this.mobile = false;
      this.pc = false;
    } else {
      if (this._isMobile()) {
        // this.$router.replace('/mnewDetails');
        this.mobile = true;
        this.pc = false;
        // let mapp = document.getElementById('app')
        // console.log(mapp)
        // mapp.style.color = '#000'
        // this.mobile = false
        // this.pc = true
      } else {
        // this.$router.replace('/');
        this.mobile = false;
        this.pc = true;
      }
    }

    // mounted () {
    document.dispatchEvent(new Event("coston-render-trigger"));
    this.smartHome();
    console.log(this.smartHomeobj);
    // }
  },
  methods: {
    bindLanguage(e) {
      this.language = e;
    },
    hide(e) {
      this.isxiazai = e;
    },
    hidetongzhi(e){
      this.istongzhi=e
    },
    smartHome(e) {

      this.smartHomeobj = e;
    },
    footerxiazai(e) {
      this.isxiazai = e;
    },
    footertongzhi(e){
      this.istongzhi=e
    },
    isaaas(e) {
      // console.log(e,111)
      this.isaaat = e;
    },
    beforeunloada() {
      window.scroll(0, 0);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    kjtouchstart(e) {
      this.starty = e.touches[0].clientY;
      // console.log(e.touches[0].clientY)
    },
    kjtouchmove(e) {
      // console.log(e.touches[0].clientY)
      this.movey = e.touches[0].clientY;
      let kjleftslide = this.starty - this.movey;
      if (kjleftslide > 0) {
        this.touchto = 2;
      } else if (kjleftslide < 0) {
        this.touchto = 1;
      }
    },
  },
  // watch:{
  //     $route:{
  //         handler(news){
  //             console.log(news)
  //         }
  //     }
  // }
};
</script>

<style>
#app {
  text-align: center;
  color: #4c413d;
  padding-top: 0;
}
p a{
    word-wrap: break-word !important;
}
.pwjstrone{
  font-weight: 400 !important;
  text-transform: uppercase;
}
.pwjbig{
  text-transform: uppercase;
}
.pwj_jian{
  width: 15px !important;
  height: 1px;
}
.pwj_jia{
  width: 15px !important;
  height: 15px;
}
</style>
