<template>
    <div class="mhome">
        <div class="homelogo">
            <img :src="'https://www.vifa.cn' + homeCase" alt="">
        </div>
        <div class="hometopswipt">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">威法定制 大宅至简</div>
                    <p ref="mcaselist">vifa携手全球设计师，致力于为中国精英家庭，提供优质的高端家装定制体验。我们想象并打造深入人心、引领未来的家居产品，以真实的案例提供直观的vifa家装效果。</p>       
                </div>
            </div>
        </div>
        <div class="mcaselist">
            <ul>
                <li v-for="(item,index) in caseList" :key="index">
                    <router-link :to="{path:'/mCdetail',query:{caseId:item.id}}">
                        <img :src="'https://www.vifa.cn' + item.mobileImage" alt="">
                        <p>{{item.title}}</p>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="pages">
            <div class="prev" @click="prev" v-show="isprev"><p></p></div>
            <ul>
                <!-- <li v-for="(item,index) in listData.slice(pagestart, pageend)" :key="index" class="cur" @click="getpages(index+1)" :class="{'active':isactive==index}">{{index+1}}</li> -->
                <li v-for="index in (pagestart,pageend)" :key="index" class="cur" @click="getpages(index)" :class="{'active':isactive==index}"><span class="gaoliang">+</span><span class="nogl">-</span></li>
            </ul>
            <div class="next" @click="next" v-show="isnext"><p></p></div>
        </div>
        <div class="subnav" >
            <router-link to="" class="active">家装案例</router-link>
            <router-link to="/mShopCase">门店展示</router-link>
        </div>
    </div>
</template>

<script>
import { getCaseDisplaye ,getCasePage} from "@/api/api";
export default {
    data(){
        return{
            homeCase:'',
            caseList:[],
            listData:[],
            pages:{
                pageNo:1,
                pageSize:10
            }, 
            pagestart:0,
            pageend:8,
            isactive:1,
            zongyeshu:0,
            isprev:true,
            isnext:true
        }
    },
    components: {
        // Mzixun
    },
    mounted(){
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mCase');
        } else {
            this.$router.replace(paths+'/case?bannertype=1&page='+this.$route.query.page);
        }
        this.getPageData()
        if (this.$route.query.page  && this.$route.query.page>0) {
            this.pages.pageNo=this.$route.query.page
        }
        this.getCasePages(this.pages)
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getPageData(){
            getCaseDisplaye()
            .then(res => {
                console.log(res)
                this.homeCase = res.caseDisplayBanner[0].mobileHomeCase
                this.listData = res.caseList
            })
            .catch(res => {
                console.log(res)
            });
        },
        getCasePages(pages){
            getCasePage(pages)
            .then(res => {
                console.log("res",res)
                this.caseList = res.page.list
                this.zongyeshu = parseInt(res.page.last)
                if (this.$route.query.page) {
                    if (this.$route.query.page%8 == 0) {
                        this.isactive = 8
                    }else{
                        this.isactive = this.$route.query.page%8
                    }
                    if (this.$route.query.page/8 == Math.floor(this.$route.query.page/8)) {
                        if (Math.floor(this.$route.query.page/8) == 0) {
                            this.pagestart = 0
                        }else{
                            this.pagestart = (Math.floor(this.$route.query.page/8)-1) * 8
                        }
                        
                    }else{
                        this.pagestart = Math.floor(this.$route.query.page/8) * 8
                    }
                    
                    if (this.zongyeshu - this.pagestart >= 8) {
                        this.pageend = 8
                    }else{
                        this.pageend = this.zongyeshu - this.pagestart
                    }
                    setTimeout(()=>{
                        this.$nextTick(()=>{
                            document.documentElement.scrollTop = this.$refs.mcaselist.offsetTop
                        })
                    },50)
                    
                }else{
                    if (this.zongyeshu>=8) {
                        this.pageend = 8
                    }else{
                        this.pageend = this.zongyeshu
                    }
                }
            })
            .catch(res => {
                console.log(res)
            });
        },
        prev(){
            if (this.pagestart + this.isactive>1) {
                this.isactive--
                this.pages.pageNo=this.isactive+this.pagestart
                this.getCasePages(this.pages)
                this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
            }
            // else if(this.isactive==1){
            //     if (this.pagestart>0) {
            //         this.isactive = 8
            //         this.pagestart = this.pagestart - 8
            //         this.pages.pageNo=this.isactive+this.pagestart
            //         this.getCasePages(this.pages)
            //         window.scroll(0,0)
            //     }
            // }
        },
        getpages(index){
            this.$router.replace({path:'/mCase',query:{page:index+this.pagestart}})
            this.isactive = index
            this.pages.pageNo=index+this.pagestart
            this.getCasePages(this.pages)
        }, 
        next(){
            if (this.isactive<this.pageend) {
                this.isactive++
                this.pages.pageNo=this.isactive+this.pagestart
                this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
                this.getCasePages(this.pages)
            }
            // else if(this.isactive==this.pageend+1){
            //     console.log(this.pagestart,this.pageend)
            //     if (this.pageend<this.zongyeshu) {
            //         this.isactive = 1
            //         this.pagestart = this.pagestart + 8
            //         if (this.zongyeshu - this.pagestart>=8) {
            //             this.pageend = 8
            //         }else{
            //             this.pageend = this.zongyeshu - this.pagestart
            //         }
            //         this.pages.pageNo=this.isactive+this.pagestart
            //         this.getCasePages(this.pages)
            //         window.scroll(0,0)
            //         this.$router.replace({path:'/mCase',query:{page:this.isactive}})
            //     }
            //     console.log(this.pagestart,this.pageend)
            // }
        },
    },

}
</script>

<style scoped>
.homelogo{
    width: 750px;
    position: relative;
    margin-bottom: 100px;
}
.homelogo img,.video{
    width: 750px;
}
.homelogo div{
    width: 750px;
    text-align: center;
    font-size: 45px;
    color: #fff;
    position: absolute;
    left: 0;
    line-height: 90px;
    top:50%;
    margin-top: -45px;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 35px;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto 100px;
}
.pinpailishi .title{
    font-size: 45px;
    padding: 20px 0;
}
.pinpailishi .subtitle{
    font-size: 29px;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    height: 357px;
    padding: 20px 0;
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -642px; 
}
.pinpailishi ul li{
    width: 635px;
    height: 357px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 635px; 
    height: 357px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
}
.bottomtitle .showtitle{}
.bottomtitle .showtitle p{
    text-align: center;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    left: 0;
    top: 26px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    right: 0;
    top: 26px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #4c413d;
}
.quanpin .pplsimg{
    height: 447px;
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}

.bannertop ,.ppfzlist{
    width: 670px;
    font-size: 26px;
    line-height: 2;
    text-align: justify;
    margin: 40px auto;
}
.ppfzlist img{
    width: 100%;
}
.ppfzlist div{
    font-size: 32px;
    font-weight: bold;
    padding-top: 26px;
}
.video{
    padding: 40px 0;
}
.ppjjsjmx{
    background: url('../../../common/image/sjmxbg.png') 0 0 no-repeat;
    background-size: 100% 100%;
    padding-top: 10px;
    padding-bottom: 60px;
}
.shmx{
    background: #d2cdca;
    margin-top: 0;
    padding: 10px 0;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: #000000cc;
}
.subnav a{
    flex: 1;
    display: block;
    color: #fff;
}
.subnav a.active{
    color: #f08518;
}
.pzbtit .showtitle p{
    line-height: 2;
    padding-bottom: 40px;
}

.mcaselist{
    width: 670px;
    margin: 40px auto 10px;
}
.mcaselist ul{}
.mcaselist ul li{
    padding-bottom: 10px;
    position: relative;
}
.mcaselist ul li img{
    width: 100%;
}
.mcaselist ul li p{
    width: 650px;
    line-height: 2;
    padding: 0 10px;
    color: #fff;
    background: #0000006e;
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: left;
}
.pages{
    display: flex;
    justify-content: center;
    font-size: 26px;
    padding-bottom: 40px;

    border-bottom: 1px solid #000;
    width: 650px;
    margin: 40px auto;
    position: relative;
    padding: 0;
}
.pages div{
    /* border: 1px solid #f08518; */
}
.pages .prev{
    position: absolute;
    left: 0;
    top: -12px;
    width: 25px;
    height: 25px;
}
.pages .prev p{
    width: 1px;
    background: #000;
    height: 25px;
    margin: 0 auto;
}
.pages .next{
    position: absolute;
    right: 0;
    top: -12px;
    width: 25px;
    height: 25px;
}
.pages .next p{
    width: 1px;
    background: #000;
    height: 25px;
    margin: 0 auto;
}
.pages ul{
    display: flex;


    position: absolute;
    background: #fff;
    top: -15px;
}
.pages ul li{
    flex: 1;
    width: 30px;
    height: 30px;
    /* border: 1px solid #f08518; */
    margin: 0 10px;
    line-height: 30px;
    text-align: center;
}
.pages ul li.active{
    color: #f08518;
}

.pages ul li .gaoliang{
    display: none;
}
.pages ul li .nogl{
    display: block;
}
.pages ul li.active .gaoliang{
    display: block;
}
.pages ul li.active .nogl{
    display: none;
}
.mhome{
    margin-bottom: 100px;
}
</style> 