<template>
    <div class="kitchenWap">
        <div class="livingRoomSpace">
            <div class="jianbianq"  :class="{'jianbianh':issjmx}" ref="sjmx">
            <div class="founder spaceCustomization">
                <div class="yishu marginTX"></div>
                <div class="beijing">客厅空间</div>
                <p v-html="seriesIntroduce.roomSpace"  class="kjjianjie" ></p>            
                <!-- <div class="moreBut">了解更多</div> -->
            </div>
            </div>
            <div class="jianbianq"  :class="{'jianbianh':isbrandConcepttitle}" ref="brandConcepttitle">
            <div class="spaceCustomization_img homeDecoration hsct" ref="homeDecoration" @mouseenter="homeDM" @mouseleave="homeDMout">
                <img src="@/common/image/kala.png" alt="">
                <div class="dingwei" :class="{'dingweishow':isdingwei}">
                    <div class="dingweia" @click="hsctdingweia">+</div>
                    <div class="dingweib" @click="hsctdingweib">+</div>
                    <div class="dingweic" @click="hsctdingweic">+</div>
                    <div class="dingweid" @click="hsctdingweid">+</div>
                    <div class="dingweie" @click="hsctdingweie">+</div>
                </div>
                <div class="dwzhankai" v-show="isdwzhankai == index" v-for="(item,index) in dwzhankaiData" :key='index' @click="dwguanbi">
                    <ul>
                        <li>
                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                            <div>{{item.title}}</div>
                            <p>{{item.introduce}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hscttitle">卡拉金岩板视听柜</div>
            <router-link :to="{path:'/productDtails',query:{title:'卡拉金岩板视听柜',SpaceActiv:0,ittitle:'Armadio audiovisivo Karajin rock board',entitle:'Karajin rock board audio-visual cabinet'}}"><div class="moreBut">了解更多</div></router-link>
            </div>



<!--            <div class="jianbianq"  :class="{'jianbianh':iskjmx}" ref="kjmx">-->
<!--            <div class="spaceCustomization_img homeDecoration cfct" ref="homeDecoration" @mouseenter="cfctenter" @mouseleave="cfctleave">-->
<!--                <img src="@/common/image/ktroom2.png" alt="">-->
<!--                <div class="dingwei" :class="{'dingweishow':iscfctdingwei}">-->
<!--                    <div class="dingweia" @click="cfctdingweia">+</div>-->
<!--                    <div class="dingweib" @click="cfctdingweib">+</div>-->
<!--                    <div class="dingweic" @click="cfctdingweic">+</div>-->
<!--                </div>-->
<!--                <div class="dwzhankai" v-show="iscfctdwzhankai == index" v-for="(item,index) in cfctdwzhankaiData" :key='index' @click="cfctdwguanbi">-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">-->
<!--                            <div>{{item.title}}</div>-->
<!--                            <p>{{item.introduce}}</p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="hscttitle">金属玻璃边柜</div>-->
<!--            <router-link :to="{path:'/productDtails',query:{title:'金属玻璃边柜',SpaceActiv:0,ittitle:'Armadio della camera da letto in albicocca perla',entitle:'Pearl apricot bedroom cabinet'}}"><div class="moreBut">了解更多</div></router-link>-->
<!--            </div>-->


          <div class="spaceCustomization_img homeDecoration fcscf" ref="homeDecoration" @mouseenter="fcscftenter" @mouseleave="fcscftleave">
                <img :src="'https://www.vifa.cn' + ktroom3image" alt="" />
                <div class="dingwei"  :class="{'dingweishow':isfcscftdingwei}">
                    <div class="dingweia" @click="fcscftdingweia">+</div>
                    <div class="dingweib" @click="fcscftdingweib">+</div>
                    <div class="dingweid" @click="fcscftdingweid">+</div>
                    <div class="dingweie" @click="fcscftdingweie">+</div>
                </div>
                <div class="dwzhankai" v-show="isfcscftdwzhankai == index" v-for="(item,index) in fcscftdwzhankaiData" :key='index' @click="fcscftdwguanbi">
                    <ul>
                        <li>
                            <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                            <div>{{item.title}}</div>
                            <p>{{item.introduce}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hscttitle">爱丽丝客厅空间</div>
            <router-link :to="{path:'/productDtails',query:{title:'爱丽丝客厅空间'}}"><div class="moreBut">了解更多</div></router-link>
        </div>
    </div>
</template>

<script>
import { getProductDetails } from "@/api/api";


export default {
  name: 'livingRoomSpace',
  data(){
      return{
        ktroom3image:null,
        dwzhankaiData:[],
        isdwzhankai:999,
        isdingwei:false,
        cfctdwzhankaiData:[],
        iscfctdwzhankai:999,
        iscfctdingwei:false,
        fcscftdwzhankaiData:[],
        isfcscftdwzhankai:999,
        isfcscftdingwei:false,
        isbrandConcepttitle:false,
        issjmx:false,
        iskjmx:false,
      }
  },
    components: {
    },
    props:{
        seriesIntroduce: {
        type: String 
        },
        seriesList: {
        type: Array 
        },
    },
    mounted() {
       window.addEventListener("scroll", this.handleScroll);    
       this.isbrandConcepttitle = true    
       this.issjmx = true
        this.getkalajinyanData()
       // this.getcfctdwzhankaiData()
        this.getFcscftdwzhankaiData()
    },
    methods:{
        getFcscftdwzhankaiData(){
            let data = {
                productName:"爱丽丝客厅空间"
            }
            getProductDetails(data).then(res =>{
                this.fcscftdwzhankaiData = res.productSpecific;
                this.ktroom3image=res.productSeries.image
            }).catch(res=>{
                console.log(res)
            })
        },
        getkalajinyanData(){
            let data = {
                productName:"卡拉金岩板视听柜"
            }
            getProductDetails(data)
            .then(res =>{
                this.dwzhankaiData = res.productSpecific
            })
            .catch(res=>{
                console.log(res)
            })
        },
        getcfctdwzhankaiData(){
            let data = {
                productName:"金属玻璃边柜"
            }
            getProductDetails(data)
            .then(res =>{
                this.cfctdwzhankaiData = res.productSpecific
            })
            .catch(res=>{
                console.log(res)
            })
        },
        handleScroll(){
           this.$nextTick(()=>{
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = false
                }
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                // if (this.$refs.kjmx.offsetTop < this.scroll +document.documentElement.clientHeight) {
                //     this.iskjmx = true
                // }

            // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                    this.issjmx = false
                }
                // if (this.$refs.kjmx.offsetTop > this.scroll +document.documentElement.clientHeight-200) {
                //     this.iskjmx = false
                // }
           })
            // }
        },
        homeDM(){
            this.isdingwei = true
        },
        homeDMout(){
            this.isdingwei = false
        },
        dwguanbi(){
            this.isdwzhankai = 999
        },
        hsctdingweia(){
            this.isdwzhankai = 0
        },
        hsctdingweib(){
            this.isdwzhankai = 1
        },
        hsctdingweic(){
            this.isdwzhankai = 2
        },
        hsctdingweid(){
            this.isdwzhankai = 3
        },
        hsctdingweie(){
            this.isdwzhankai = 4
        },
        cfctenter(){
            this.iscfctdingwei = true
        },
        cfctleave(){
            this.iscfctdingwei = false
        },
        cfctdwguanbi(){
            this.iscfctdwzhankai = 999
        },
        cfctdingweia(){
            this.iscfctdwzhankai = 0
        },
        cfctdingweib(){
            this.iscfctdwzhankai = 1
        },
        cfctdingweic(){
            this.iscfctdwzhankai = 2
        },
        fcscftenter(){
            this.isfcscftdingwei = true
        },
        fcscftleave(){
            this.isfcscftdingwei = false
        },
        fcscftdwguanbi(){
            this.isfcscftdwzhankai = 999
        },
        fcscftdingweia(){
            this.isfcscftdwzhankai = 0
        },
        fcscftdingweib(){
            this.isfcscftdwzhankai = 1
        },
        fcscftdingweic(){
            this.isfcscftdwzhankai = 2
        },
        fcscftdingweid(){
            this.isfcscftdwzhankai = 3
        },
        fcscftdingweie(){
            this.isfcscftdwzhankai = 4
        },
  },
  unmounted(){
          window.removeEventListener("scroll", this.handleScroll);    
    }
}
</script>

<style scoped>
.kitchenWap .spaceCustomization p{
    /* width: 740px; */
    margin: 0 auto;
    white-space: pre-wrap;
    /* text-align: left; */
}
.hsct .dingwei .dingweia{
        left: 25%;
    top: 51%;
}
.hsct .dingwei .dingweib{
        left: 30%;
    top: 34%;
}
.hsct .dingwei .dingweic{
    left: 48%;
    top: 30%;
}
.hsct .dingwei .dingweid{
        left: 52%;
    top: 45%;
}
.hsct .dingwei .dingweie{
        left: 58%;
    top: 61%;
}
.kitchenWap .dwzhankai ul{
    width: 875px;
    height: 710px;
}
.kitchenWap .dwzhankai ul li .cfimg{
    width: 400px;
    height: 352.5px;
    margin:88px 0 25px 355px;
}
.kitchenWap .dwzhankai ul li div{
    font-size: 30px;
    padding-top: 0;
}
.kitchenWap .dwzhankai ul li p{
    padding-top: 15px;
    font-size: 16px;
}
.hscttitle{
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.cfct .dingwei .dingweia{
    left: 29.5%;
    top: 44%;
}
.cfct .dingwei .dingweib{
    left: 39%;
    top: 24%;
}
.cfct .dingwei .dingweic{
    left: 50%;
    top: 74%;
}

.fcscf .dingwei .dingweia{
  left: 16%;
  top: 57%;
}
.fcscf .dingwei .dingweib{
  left: 24.7%;
  top: 18%;
}
.fcscf .dingwei .dingweic{
  left: 34%;
  top: 73%;
}.fcscf .dingwei .dingweid{
   left: 31.1%;
   top: 33%;
}.fcscf .dingwei .dingweie{
    left: 60%;
    top: 60%;
}
</style>
