<template>
    <div class="mzixun">
        <div class="hometopswipt" v-if="language==1">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">更多详情 专属定制 欢迎垂询</div>
                    <p>{{homeConsultText}}</p>
                </div>
                <div class="zixuntian">
                    <div>
                        <input type="text" placeholder="*请输入您所在的城市 City" v-model="city" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="*请输入您的姓名 Name" v-model="name" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="*请输入您的电话 Phone" v-model="phone" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="请输入您的邮箱 E-mail" v-model="email" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>   
                        <input type="text" placeholder="*请输入您希望咨询的问题 Message" v-model="message" style="border-bottom:1px solid #4c413d4d">
                    </div>
                </div>
                <div class="mzixunbottom" @click="tijiao" style="border:1px solid #4c413d4d">立即提交</div>
            </div>
        </div>
        <div class="hometopswipt" v-if="language==2">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext ">
                    <div class="title pwjstrone">MORE DETAILS</div>
                    <div class="title pwjstrone">EXCLUSIVE CUSTOMIZATION</div>
                    <div class="title pwjstrone">WELCOME TO CONSUIT</div>
                    <p>{{homeConsultText}}</p>
                </div>
                <div class="zixuntian">
                    <div>
                        <input type="text" placeholder="*Please enter your city" v-model="city" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="*Please enter your name" v-model="name" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="* Please enter your phone number" v-model="phone" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder=" Please enter your E-mail" v-model="email" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>   
                        <input type="text" placeholder="* Please enter the message you wish to consult" v-model="message" style="border-bottom:1px solid #4c413d4d">
                    </div>
                </div>
                <div class="mzixunbottom" @click="tijiao" style="border:1px solid #4c413d4d">Submit</div>
            </div>
        </div>
        <div class="hometopswipt" v-if="language==3">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext ">
                    <div class="title pwjstrone">Maggiori dettagli, </div>
                    <div class="title pwjstrone" >Personalizzazione,</div>
                    <div class="title pwjstrone" >Non esitate a contattarci</div>
                    <p>{{homeConsultText}}</p>
                </div>
                <div class="zixuntian">
                    <div>
                        <input type="text" placeholder="*Inserisce la sua città" v-model="city" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="*Inserisce il suo nome e cognome" v-model="name" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="*Inserisce il suo numero di telefono" v-model="phone" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>
                        <input type="text" placeholder="Inserisce la sua Email" v-model="email" style="border-bottom:1px solid #4c413d4d">
                    </div>
                    <div>   
                        <input type="text" placeholder="*Ha una domanda specifica?" v-model="message" style="border-bottom:1px solid #4c413d4d">
                    </div>
                </div>
                <div class="mzixunbottom" @click="tijiao" style="border:1px solid #4c413d4d">Invia immediatamente</div>
            </div>
        </div>
        <div class="tishi" v-if="istishi"><div>{{tishi}}</div></div>
    </div>
</template>

<script>
import { saveConsultRecord } from "../api/api";
import {getHomePage} from '../api/api'
export default {
    name:'mzixun',
    data(){
        return{
            name:'',
            phone:'',
            email:'',
            message:'',
            istishi:false,
            tishi:'',
            homeConsultText:'',
            city:'',
            language:sessionStorage.getItem("language")
        }
    },
    mounted(){
        this.getHomePageData()
        
    },
    methods:{
        getPageData(data){
            saveConsultRecord(data)
            .then(res => {
                if(res.result == 0){
                    this.istishi=true
                   if(language==1){
                    this.tishi = '提交成功'
                   }else if(this.language==2){
                    this.tishi = 'Submit successfully'
                   }else{
                    this.tishi = 'Inviato con successo'
                   }
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
                }else{
                    this.istishi=true
                    this.tishi = res.message
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
                }
                    this.name = ''
                    this.phone = ''
                    this.email = ''
                    this.message = ''
                    this.city=''
            })
            .catch(res => {
                console.log(res,)
            });
        },
        getHomePageData(){
            getHomePage()
            .then(res => {
               if(sessionStorage.getItem("language")==1){
                this.homeConsultText = res.homeConsultText
               }else if(sessionStorage.getItem("language")==2){
                this.homeConsultText = res.homeConsultTextEng
               }else{
                this.homeConsultText = res.homeConsultTextIta
               }
            })
            .catch(res => {
                console.log(res,)
            });
        },
        tijiao(){
            let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/
            if(this.name == ''){
                this.istishi=true
                this.tishi = 'Inserisce il suo nome e cognome'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.phone == ''){
                this.istishi=true
                this.tishi = 'Inserisce il suo numero di telefono'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.city == ''){
                this.istishi=true
                this.tishi = 'Inserisce la sua città'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.message == ''){
                this.istishi=true
                this.tishi = 'Inserisce la domanda che desideri consultare'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if((!phonereg.test(this.phone)) && this.phone != ''){
                this.istishi=true
                this.tishi = 'Inserisce il numero di telefono corretto'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else{
                let data = {
                    name : this.name,
                    phone : this.phone,
                    email : this.email,
                    message : this.message,
                    city:this.city
                }
                this.getPageData(data)
            }
        }
    },
}
</script>

<style scoped>
.homelogo{
    width: 100%;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}
.pinpailishi .title{
    font-size: 45px;
    /* padding: 20px 0; */
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    height: 492px;
    padding: 20px 0;
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -247.5px; 
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px; 
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
}
.bottomtitle .showtitle{}
.bottomtitle .showtitle p{
    text-align: center;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    left: 0;
    top: 45px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    right: 0;
    top: 45px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}

.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d4d;
    text-align: center;
}
.zixuntian{
    width: 670px;
    margin: 40px auto 0;
}
/* .zixuntian>div{
    padding-bottom: 40px;
} */
.zixuntian>div input{
    border-bottom: 1px solid #4c413d;
    font-size: 26px;
    width: 670px;
    margin-bottom: 40px;
    padding: 10px 0;
    text-indent: 20px;
}
.mzixunbottom{
    width: 310px;
    margin: 40px auto 120px;
    line-height: 70px;
    border: 1px solid #4c413d;
    font-size: 26px;
}.tishi{
    width: 100%;
    color: #fff;
    position: fixed;
    top: 50%;
    margin-top: -30px;
    left: 0;
    text-align: center;
}
.tishi div{
    display: inline-block;
    padding: 20px 50px;
    background: #000;
    border-radius: 10px;
}
</style> 