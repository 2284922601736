<template>
    <div class="kitchenWap">
        <div class="livingRoomSpace">
            <div class="jianbianq" :class="{ 'jianbianh': isbrandConcepttitle }" ref="brandConcepttitle">
                <div class="founder spaceCustomization">
                    <div class="yishu marginTX"></div>
                    <div class="beijing pwjstrone">Kitchen</div>
                    <p class="kjjianjie" v-html="seriesIntroduce.kitchenSpaceEng"></p>
                    <!-- <div class="moreBut">了解更多</div> -->
                </div>
            </div>
            <div class="jianbianq" :class="{ 'jianbianh': issjmx }" ref="sjmx">
                <div class="spaceCustomization_img homeDecoration hsct" ref="homeDecoration" @mouseenter="homeDM"
                    @mouseleave="homeDMout">
                    <img src="@/common/image/chufangbanner1.png" alt="">
                    <div class="dingwei" :class="{ 'dingweishow': isdingwei }">
                        <div class="dingweia" @click="hsctdingweia">+</div>
                        <div class="dingweib" @click="hsctdingweib">+</div>
                        <div class="dingweic" @click="hsctdingweic">+</div>
                        <div class="dingweid" @click="hsctdingweid">+</div>
                        <div class="dingweie" @click="hsctdingweie">+</div>
                    </div>
                    <div class="dwzhankai" v-show="isdwzhankai == index" v-for="(item, index) in dwzhankaiData" :key='index'
                        @click="dwguanbi">
                        <ul>
                            <li>
                                <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                                <div>{{ item.titleEng }}</div>
                                <p>{{ item.introduceEng }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hscttitle pwjstrone">Club kitchen hall</div>
                <router-link
                    :to="{ path: '/productDtails', query: { title: '会所厨厅', SpaceActiv: 2, ittitle: 'Cucina del Club', entitle: 'Club kitchen hall' } }">
                    <div class="moreBut">Learn More</div>
                </router-link>
            </div>
            <div class="jianbianq" :class="{ 'jianbianh': iskjmx }" ref="kjmx">

                <div class="spaceCustomization_img homeDecoration cfct" ref="homeDecoration" @mouseenter="cfctenter"
                    @mouseleave="cfctleave">
                    <img src="@/common/image/chufangbanner2.png" alt="">
                    <div class="dingwei" :class="{ 'dingweishow': iscfctdingwei }">
                        <div class="dingweia" @click="cfctdingweia">+</div>
                        <div class="dingweib" @click="cfctdingweib">+</div>
                        <div class="dingweic" @click="cfctdingweic">+</div>
                    </div>
                    <div class="dwzhankai" v-show="iscfctdwzhankai == index" v-for="(item, index) in cfctdwzhankaiData"
                        :key='index' @click="cfctdwguanbi">
                        <ul>
                            <li>
                                <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                                <div>{{ item.titleEng }}</div>
                                <p>{{ item.introduceEng }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hscttitle pwjstrone">Kitchen + dining room </div>
                <router-link
                    :to="{ path: '/En/productDtails', query: { title:'厨房%2B餐厅', SpaceActiv: 2, ittitle: 'Cucina + sala da pranzo', entitle: 'Kitchen + dining room ' } }">
                    <div class="moreBut">Learn More</div>
                </router-link>
            </div>
            <div class="jianbianq" :class="{ 'jianbianh': isshmx }" ref="shmx">
                <div class="spaceCustomization_img homeDecoration fcscf" ref="homeDecoration" @mouseenter="fcscftenter"
                    @mouseleave="fcscftleave">
                    <img src="@/common/image/chufangbanner3.png" alt="">
                    <div class="dingwei" :class="{ 'dingweishow': isfcscftdingwei }">
                        <div class="dingweia" @click="fcscftdingweia">+</div>
                        <div class="dingweib" @click="fcscftdingweib">+</div>
                        <div class="dingweic" @click="fcscftdingweic">+</div>
                    </div>
                    <div class="dwzhankai" v-show="isfcscftdwzhankai == index" v-for="(item, index) in fcscftdwzhankaiData"
                        :key='index' @click="fcscftdwguanbi">
                        <ul>
                            <li>
                                <img class="cfimg" :src="'https://www.vifa.cn' + item.image">
                                <div>{{ item.titleEng }}</div>
                                <p>{{ item.introduceEng }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hscttitle pwjstrone">Cucina aperta</div>
                <router-link
                    :to="{ path: '/productDtails', query: { title: '开放式厨房', SpaceActiv: 2, ittitle: 'Cucina aperta', entitle: 'Cucina aperta' } }">
                    <div class="moreBut">Learn More</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { getProductDetails } from "@/api/api";


export default {
    name: 'kitchenSpace',
    data() {
        return {
            dwzhankaiData: [],
            isdwzhankai: 999,
            isdingwei: false,
            cfctdwzhankaiData: [],
            iscfctdwzhankai: 999,
            iscfctdingwei: false,
            fcscftdwzhankaiData: [],
            isfcscftdwzhankai: 999,
            isfcscftdingwei: false,
            isbrandConcepttitle: false,
            issjmx: false,
            iskjmx: false,
            isshmx: false,
        }
    },
    components: {
    },
    props: {
        seriesIntroduce: {
            type: String
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.isbrandConcepttitle = true
        this.issjmx = true
        this.getkalajinyanData()
        this.getcfctdwzhankaiData()
        this.getfcscftdwzhankaiData()
    },
    methods: {
        getkalajinyanData() {
            let data = {
                productName: "会所厨厅"
            }
            getProductDetails(data)
                .then(res => {
                    this.dwzhankaiData = res.productSpecific
                })
                .catch(res => {
                    console.log(res)
                })
        },
        getcfctdwzhankaiData() {
            let data = {
                productName: "厨房%2B餐厅Ⅱ"
            }
            getProductDetails(data)
                .then(res => {
                    this.cfctdwzhankaiData = res.productSpecific
                })
                .catch(res => {
                    console.log(res)
                })
        },
        getfcscftdwzhankaiData() {
            let data = {
                productName: "开放式厨房"
            }
            getProductDetails(data)
                .then(res => {
                    this.fcscftdwzhankaiData = res.productSpecific
                })
                .catch(res => {
                    console.log(res)
                })
        },
        handleScroll() {
            this.$nextTick(() => {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // if (e.deltaY>0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll + document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll + document.documentElement.clientHeight) {
                    this.issjmx = false
                }
                if (this.$refs.brandConcepttitle.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.isbrandConcepttitle = true
                }
                if (this.$refs.sjmx.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.issjmx = true
                }
                if (this.$refs.kjmx.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.iskjmx = true
                }
                if (this.$refs.shmx.offsetTop < this.scroll + document.documentElement.clientHeight) {
                    this.isshmx = true
                }

                // }else if (e.deltaY < 0) {
                if (this.$refs.brandConcepttitle.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.isbrandConcepttitle = false
                }
                if (this.$refs.sjmx.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.issjmx = false
                }
                if (this.$refs.kjmx.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.iskjmx = false
                }
                if (this.$refs.shmx.offsetTop > this.scroll + document.documentElement.clientHeight - 200) {
                    this.isshmx = false
                }
            })
            // }
        },
        homeDM() {
            this.isdingwei = true
        },
        homeDMout() {
            this.isdingwei = false
        },
        dwguanbi() {
            this.isdwzhankai = 999
        },
        hsctdingweia() {
            this.isdwzhankai = 0
        },
        hsctdingweib() {
            this.isdwzhankai = 1
        },
        hsctdingweic() {
            this.isdwzhankai = 2
        },
        hsctdingweid() {
            this.isdwzhankai = 3
        },
        hsctdingweie() {
            this.isdwzhankai = 4
        },
        cfctenter() {
            this.iscfctdingwei = true
        },
        cfctleave() {
            this.iscfctdingwei = false
        },
        cfctdwguanbi() {
            this.iscfctdwzhankai = 999
        },
        cfctdingweia() {
            this.iscfctdwzhankai = 0
        },
        cfctdingweib() {
            this.iscfctdwzhankai = 1
        },
        cfctdingweic() {
            this.iscfctdwzhankai = 2
        },
        fcscftenter() {
            this.isfcscftdingwei = true
        },
        fcscftleave() {
            this.isfcscftdingwei = false
        },
        fcscftdwguanbi() {
            this.isfcscftdwzhankai = 999
        },
        fcscftdingweia() {
            this.isfcscftdwzhankai = 0
        },
        fcscftdingweib() {
            this.isfcscftdwzhankai = 1
        },
        fcscftdingweic() {
            this.isfcscftdwzhankai = 2
        },
    },
    unmounted(){
   window.removeEventListener("scroll", this.handleScroll);
    }
}
</script>

<style scoped>
.kitchenWap .spaceCustomization p {
    /* width: 740px; */
    margin: 0 auto;
    white-space: pre-wrap;
    /* text-align: left; */
}

.hsct .dingwei .dingweia {
    left: 22.5%;
    top: 61%;
}

.hsct .dingwei .dingweib {
    left: 25%;
    top: 37.5%;
}

.hsct .dingwei .dingweic {
    left: 29.5%;
    top: 69%;
}

.hsct .dingwei .dingweid {
    left: 51%;
    top: 61%;
}

.hsct .dingwei .dingweie {
    left: 69.5%;
    top: 59%;
}

.kitchenWap .dwzhankai ul {
    width: 875px;
    height: 710px;
}

.kitchenWap .dwzhankai ul li .cfimg {
    width: 400px;
    height: 352.5px;
    margin: 88px 0 25px 355px;
}

.kitchenWap .dwzhankai ul li div {
    font-size: 30px;
    padding-top: 0;
}

.kitchenWap .dwzhankai ul li p {
    padding-top: 15px;
    font-size: 16px;
}

.hscttitle {
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.cfct .dingwei .dingweia {
    left: 33.2%;
    top: 71%;
}

.cfct .dingwei .dingweib {
    left: 42.5%;
    top: 65%;
}

.cfct .dingwei .dingweic {
    left: 87%;
    top: 31%;
}

.fcscf .dingwei .dingweia {
    left: 51%;
    top: 58%;
}

.fcscf .dingwei .dingweib {
    left: 60.6%;
    top: 21%;
}

.fcscf .dingwei .dingweic {
    left: 75.6%;
    top: 61%;
}
</style>
