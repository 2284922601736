<template>
    <div class="contact" @click="kongbai">
        <div  :class="{'jianbianbanner':iscontactBaner}" ref="contactBaner">
            <div class="aboutBaner">
                <img :src="'https://www.vifa.cn' + contactusBanner.image" alt="">
                <p class="pwjstrone">We are where you are<span>{{contactus.titleEng}}</span> 
                </p>
            </div>
        </div>
        <div class="founder spaceCustomization">
            <div class="yishu"></div>
            <div class="beijing pwjstrone">Contact Us</div>
            <p class="jijian">National uniform service hotline: 4008388622</p>            
        </div>
        <div class="contactCode">
            <div class="contactCodeLeft">
                <div>
                    <div>Operation center </div>
                    <p>Address:{{contactus.companyAddressEng}}</p>
                    <p>Tel:{{contactus.headquarterPhone}}</p>
                    <p>Fax:{{contactus.headquarterFax}}</p>
                    <p>Postcode:{{contactus.headquarterCode}}</p>
                </div>
                <div>
                    <div>Manufacturing base headquarters</div>
                    <p>Address:{{contactus.producebaseAddressEng}}</p>
                    <!-- <p>电话：{{contactus.producebasePhone}}</p> -->
                    <p>Fax:{{contactus.producebaseFax}}</p>
                    <p>Postcode:{{contactus.producebaseCode}}</p>
                </div>
            </div>
            <div class="contactCodeRiight">
                <ul>
                    <li>
                        <img :src="'https://www.vifa.cn' + contactus.xcxImage" alt="">
                        <p>{{ contactus.xcxTitleEng }}</p>
                    </li>
                    <li>
                        <img :src="'https://www.vifa.cn' + contactus.gzhImage" alt="">
                        <p>{{ contactus.gzhTitleEng }}</p>
                    </li>
                    <li>
                        <img :src="'https://www.vifa.cn' + contactus.xhsImage" alt="">
                        <p>{{ contactus.xhsTitleEng }}</p>
                    </li>
                    <li>
                        <img :src="'https://www.vifa.cn' + contactus.dyImage" alt="">
                        <p>{{ contactus.dyTitleEng }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="aboutBaner">
            <img :src="'https://www.vifa.cn' + contactusBanner.shopImage" alt="">
        </div>
        <div class="founder spaceCustomization">
            <div class="yishu"></div>         
        </div>
        <div class="search" ref="search">
            <div class="searchWap">
                <div class="searchCenten">
                    <div class="founder">
                        <div class="beijing secle pwjstrone">Store inquiry</div>
                    </div>
                    <div class="search_input">
                        <input type="text" placeholder="Please enter your city" v-model="searchcityshop" @keyup.enter="citychashop">
                        <div class="img" @click="citychashop">
                            <img src="@/common/image/searchIco.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="enterpriseDynamics_box" id="kanbaiid" ref="enterpriseDynamics_box">
            <!-- <div class="enterpriseDynamics_news tran" :style="{marginLeft:newLfet + 'px',width:boxWidth + 'px'}"> -->
            <div class="enterpriseDynamics_news tran">
                <!-- <ul v-for="(item, index) in jiaxiangData" :key="index" style=""> -->
                <ul v-if="shoplianxi.length>0">
                    <!-- <li>545454</li> -->
                    <li v-for="(jiax, jiaxindex) in shoplianxi" :key="jiaxindex" @mouseenter="addrenter(jiaxindex)" @mouseleave="addrleave(jiaxindex)">
                        <!-- <div>
                        </div> -->
                        <p class="shopname pwjP">{{jiax.shopNameEng}}</p>
                        <p class="pwjP">{{jiax.shopAddressEng}}</p>
                        <p class="pwjP">Tel:{{jiax.shopPhone}}</p>
                        <!--  -->
                        <div class="addres" v-show="isaddres == jiaxindex">
                            <button @click="cliyuyue" class="pwjbutton">Make an appointment</button>
                            <button @click="chakanditu(jiax.shopLongitude,jiax.shopLatitude)" class="pwjbutton">Map view</button>
                        </div>
                        <div :class="{addrexiaxian:isaddres == jiaxindex}"></div>
                        <!-- <div class="jiax">
                            <img :src=jiax.img alt="">
                            <p>{{jiax.addr}}</p>
                            <div class="moreJiax" :class="{'moreJiaxmouse':moreJiaxmouse == jiaxindex}">
                                <div @mouseleave="moremouseleave" :class="{'moreleav':ismoreleav}">了解更多</div>
                            </div>
                        </div> -->
                    </li>
                    <!-- <li class="whwl"></li>
                    <li class="whwl"></li> -->
                </ul>
                <!-- <div class="wushuju" v-if="shoplianxi.length==0">该城市暂时还没有门店，请搜索其他城市</div> -->
                <div class="wushuju" v-if="shoplianxi.length==0">Please wait</div>
            </div>
            
            <div class="containerbox">
                <div id="container" v-show="ischakanditu" class="map" :class="mapSize"></div>
            </div>
        </div>
        <!-- <div class="newswi" v-if="shoplianxi.length>6">
            <div class="left" @click="newswiLfet"><p></p></div>
            <div class="center">
                <ul>
                    <li v-for="(item, index) in shoplianxi.slice(0,slicenum)" :key="index" :class="{'active':isactive == index}" @click="adderlun(index)"><span class="no">一</span><span class="yes">十</span></li>
                </ul>
            </div>
            <div class="right" @click="newswiRight"><p></p></div>
        </div> -->
        <div class="recruit">
            <div class="founder spaceCustomization">
                <div class="yishu"></div>
                <div class="beijing pwjstrone">Dealer recruitment</div>  
            </div>
            <div class="founder">
                <ul>
                    <li v-for="(item, index) in founderUl" :key="index" :class="{'active':hover==index}" @mouseover="founderUlMouseover(index)">
                        <div class="cursor">
                            <!-- <p class="cursorNum">{{item.num}}</p> -->
                            <p @click="taber(index)">{{item.name}}</p>
                        </div>
                    </li>
                </ul>
                <transition-group name="yishuani">
                    <div class="yishu yishuani" :style="{marginLeft:yishuani + 'px'}"></div>
                </transition-group>
                <div class="jiaolian"></div>
            </div>
            <component :is="comName"></component>
        </div>
        <div class="yuyue" ref="yuyue">
            <Zixun/>
        </div>
    </div>
</template>

<script> 
import AMap from 'AMap'
import Zhichi from './zhichi'
import Jxstiaojian from './jxstiaojian'
import Jxsshenqing from './jxsshenqing'
import Hezuoliucheng from './hezuoliucheng'
import { getContactus,getShopByKeyword} from "@/api/api";
import arricon from '@/common/image/arricon.png'
import Zixun from '@/components/zixun'
  


export default {
    name: 'contact',
    data(){
        return{
            shoplianxi:[],
            newLfet:0,
            isactive:0,
            boxWidth:9999999999999,
            isaddres:999,
            hover:0,
            yishuani:150,
            founderUl:[
                {name:'Dealer support'},
                {name:'Cooperation process'},
                {name:'Requirements for being a dealer'},
                {name:'Dealer application'},
            ],
            comName:'Zhichi',
            iscontactBaner:false,
            ischakanditu:false,
            slicenum:1,
            arriveCoor:[116.481181, 39.989792],
            searchcityshop:'',
            contactus:{
              xcxImage:'',
              xcxTitleEng:'',
              gzhTitleEng:'',
              xhsTitleEng:'',
              dyTitleEng:'',
              gzhImage:'',
              xhsImage:'',
              dyImage:'',
              shopImage:''
            },
            contactusBanner:{
              shopImage:'',
              image:''
            },
        }
    },
    components: {
        Zhichi,
        Jxstiaojian,
        Jxsshenqing,
        Hezuoliucheng,Zixun
    },
    mounted() {
        // if(this.$router.currentRoute.params.operation && this.$router.currentRoute.params.operation==1){
        //       console.log('路由2',this.$router)
        // }
        // if(this.$route.query.id==1){
        //     this.goDealerinquiry()
        // }
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mcontact?suosuov='+this.$route.query.suosuov);
        } else {
            // this.$router.replace('/contact?suosuov='+this.$route.query.suosuov);
        }
        // this.init(this.arriveCoor)
        
        this.iscontactBaner = true
        this.getContactusData()
        window.exec_main(document.title)
        if(this.$route.query.suosuov){
            this.searchcityshop = this.$route.query.suosuov
            let data = {
                keyword:this.$route.query.suosuov
            }
            this.getShopByKeywordData(data)
            document.documentElement.scrollTop = this.$refs.search.offsetTop
        }
         setTimeout(()=>{
        this.$nextTick(()=>{
             if(this.$route.query.id==1){
            this.goDealerinquiry()
        }
   })},500)
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },


        goDealerinquiry(){
          document.documentElement.scrollTop = this.$refs.search.offsetTop-150
        },
        getContactusData(){
            getContactus()
            .then(res => {
                console.log(res)
                this.contactData = res
                this.contactus = res.contactus
                this.contactusBanner =res.contactusBanner
                if(!this.$route.query.suosuov){
                    this.shoplianxi = res.shopList
                }
                
                this.arriveCoor = [res.shopList[0].shopLongitude,res.shopList[0].shopLatitude]
                this.init(this.arriveCoor)
                if(this.shoplianxi.length>6){
                    this.slicenum =  Math.ceil(this.shoplianxi.length/6)
                }else{
                    this.slicenum = 1
                }
            })
        },
        init (arriveCoor) {
            new AMap.Marker({
                icon: new AMap.Icon({            
                    image: arricon,
                    imageSize: new AMap.Size(30,30)
                }),
                position: arriveCoor,
                offset: new AMap.Pixel(-13, -30),
                // 设置是否可以拖拽
                draggable: false,
                // cursor: 'move',
                // 设置拖拽效果
                // raiseOnDrag: true
            }).setMap(
                new AMap.Map('container', {
                center: arriveCoor,
                zoom: 16,
                mapStyle: 'amap://styles/whitesmoke',
                resizeEnable: true,
            })
            )
        },
        newswiLfet(){
            if (this.isactive>0) {
                this.isactive = this.isactive-1
                this.newLfet = -1200 * this.isactive
            }
        },
        newswiRight(){
            if (this.isactive < this.jiaxiangData.length - 1) {
                this.isactive = this.isactive+1
                this.newLfet = -1200 * this.isactive
            }
        },
        addrenter(index){
            this.isaddres = index
        },
        addrleave(){
            this.isaddres = 999
        },
        adderlun(index){
            this.isactive = index
            this.newLfet = -1200 * this.isactive
        },
        founderUlMouseover(index){
            this.yishuani = 150 + 1200/this.founderUl.length*index
        },
        taber(index){
            this.hover = index
            if (index == 0) {
                this.comName = 'Zhichi'
            }else if(index==1){
                this.comName = 'Hezuoliucheng'
            }else if(index==2){
                this.comName = 'Jxstiaojian'
            }else if(index==3){
                this.comName = 'Jxsshenqing'
            }
        },
        chakanditu(shopLongitude,shopLatitude){
            this.ischakanditu = true    
            this.arriveCoor = [shopLongitude,shopLatitude]
            this.init(this.arriveCoor)
        },
        kongbai(){
            let kanbaiid = document.getElementById("kanbaiid")
            if(!kanbaiid.contains(event.target)){      
                this.ischakanditu = false;
            }
        },
        getShopByKeywordData(data){
            getShopByKeyword(data).then( res => {
                this.contactData = res
                this.shoplianxi = res.shopList
                this.arriveCoor = [res.shopList[0].shopLongitude,res.shopList[0].shopLatitude]
                this.init(this.arriveCoor)
                if(this.shoplianxi.length>6){
                    this.slicenum =  Math.ceil(this.shoplianxi.length/6)
                }else{
                    this.slicenum = 1
                }
            }).catch(res => {
                console.log(res)
            })
        },
        citychashop(){
            let data = {
                keyword:this.searchcityshop
            }
            this.getShopByKeywordData(data)
        },
        cliyuyue(){
            document.documentElement.scrollTop = this.$refs.yuyue.offsetTop-20
        }
    },
    
}
</script>

<style>
.contact .aboutBaner p{
    font-size: 40px;
}
.contact .aboutBaner p span{
    display: block;
    padding-top: 25px;
    letter-spacing: 0;
    
}
.contact .founder .yishu{
    margin-top: 55px;
}
.contactCode{
    width: 1200px;
    margin: 80px auto 135px;
    display: flex;
}
.contactCode .contactCodeLeft{
    width: 600px;
    flex: 1 600px;
    text-align: left;
    line-height: 2;
    border-right: 1px solid #000;
}
.contactCode .contactCodeLeft>div{
    padding-bottom: 20px;
}
.contactCode .contactCodeLeft>div div{
    font-weight: bold;
}
.contactCode .contactCodeRiight{
    width: 600px;
    flex: 1 600px;
}
.contactCode .contactCodeRiight ul{
    display: flex;
    flex-wrap: wrap;
}
.contactCode .contactCodeRiight ul li{
    width: 50%;
    flex: 1 50%;
}
.contactCode .contactCodeRiight ul li img{
    width: 140px;
    height: 140px;
}
.contactCode .contactCodeRiight ul li p{
    line-height: 3;
}
.contact .enterpriseDynamics_box{
    margin-top: 0;
    position: relative;
    margin-bottom: 80px;
}
.contact .enterpriseDynamics_news{
    width: 100%;
    left: 0;
}
.contact .enterpriseDynamics_news ul{
    flex-wrap: wrap;
    color: #605653;
    /* height: 348px; */
    /* flex-direction:column */
}
.wushuju{
    color: #605653;
    height: 348px;
    line-height: 348px;
    width: 1200px;
    text-align: center;
}
.contact .enterpriseDynamics_news ul li{
    /* flex: 1 96px; */
    width: 588px;
    padding: 0;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    padding-left: 12px;
    /* overflow: hidden; */
    /* height: 96px; */
}
.contact .enterpriseDynamics_news ul li>p{
    line-height: 2;
    padding-left: 0;
    width: 490px;
}
.contact .enterpriseDynamics_news ul li>p.shopname{
    font-weight: bold;
}
.contact .enterpriseDynamics_news ul li .addres{
    position: absolute;
    right: 0;
    top: 8px;
}
.contact .enterpriseDynamics_news ul li .addres button{
    display: block;
    background: #fff;
    border: 1px solid;
    margin-bottom: 7.5px;
    padding: 0 12.5px;
    cursor: pointer;
    width: 100%;
}
.contact .enterpriseDynamics_news ul li .addres button:hover{
    color: #fff;
    border-color: #0000006e;
    
    animation:morehoverh 1s;
    -moz-animation:morehoverh 1s; 
    -webkit-animation:morehoverh 1s; 
    -o-animation:morehovehr 1s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
.addrexiaxian{
    width: 1px;
    height: 60px;
    position: absolute;
    left: 0;
    top: 45px;
    background: #f08518;
    animation:addrexiaxianani 1s;
    -moz-animation:addrexiaxianani 1s; 
    -webkit-animation:addrexiaxianani 1s; 
    -o-animation:addrexiaxianani 1s; 
    animation-fill-mode: forwards;
    -webkit-animation-timing-function:ease-in-out;
}
@keyframes addrexiaxianani{
    0%{top: -60px;}
    100%{top: 45px;}
}
@-webkit-keyframes addrexiaxianani{
    0%{top: -60px;}
    100%{top: 45px;}
}
@-moz-keyframes addrexiaxianani{
    0%{top: -60px;}
    100%{top: 45px;}
}
@-o-keyframes addrexiaxianani{
    0%{top: -60px;}
    100%{top: 45px;}
}
.recruit{
    background: #e5e2e0;
    padding-top:50px;
    padding-bottom:50px;
}
.recruit .founder .beijing{
    padding-top:40px;
}

.contact .recruit .founder .yishu{
    margin-top: 20px;
    height: 25px;
}
.recruit .jiaolian{
    bottom: 12.5px;
}
.contact .founder{
    margin-bottom: 50px;
}
#container{
    position: absolute;
    background: rgb(242, 242, 242);
    left: 0;
    top: 0;
    width: 100%;
    height: 465px;
}
.zhichi{
    color: #605653;
    line-height: 2;
    text-align: justify;
    max-width: 1200px;
    margin: 0 auto;
}
.zhichi div{
    padding-top: 10px;
    white-space: pre-wrap;
}
.colorop{
    color: #a7a09e;
}
.containerbox{
    /* position: absolute; */
    left: 0;
    top: 0 ;
    
}
.contact .searchCenten .secle{
    padding: 0;
}
.searchCenten .founder{
    margin-bottom: 0;
}
.contact .search{
    padding-bottom: 0;
}
.contact .enterpriseDynamics_news ul li.whwl{
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}
.contact .newswi{
    margin-top: 0;
}
.contact .enterpriseDynamics_news ul li .addres .pwjbutton{
    width: 110px;
    height: 50px;
}
/* .contact .enterpriseDynamics_news ul li:last-child{
  
  flex: 0.5 1px !important;

} */
.contact .enterpriseDynamics_news ul li> .pwjP{
    width: 480px;
}
</style>
