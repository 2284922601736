<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + aboutVifaBanner.mobileImage" alt="" />
      <div>威法定制 大宅至简</div>
    </div>
    <div class="bannertop" v-html="aboutVifa.brandProfile"></div>
    <div class="hometopswipt quanpin">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">品牌发展</div>
        </div>
        <div class="ppfz">
          <div
            class="ppfzlist"
            v-for="(item, index) in aboutBrandDevelop"
            :key="index"
          >
            <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
            <div>{{ item.year }}</div>
            <p>{{ item.synopsis }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hometopswipt quanpin">
      <div class="xiaotop"></div>
      <div class="pinpailishi">
        <div class="pplstext">
          <div class="title">产品理念</div>
        </div>
      </div>
    </div>
    <div class="bannertop bannertopmbl" v-html="aboutVifa.productConcept"></div>
    <div class="video">
      <video
        poster="@/common/image/poter1.jpg"
        controls="controls"
        preload="none"
        :src="'https://www.vifa.cn' + aboutVifa.productConceptVideo"
        type="video/mp4"
x5-playsinline="" 
playsinline="true" 
webkit-playsinline="true" 
x-webkit-airplay="true" 
x5-video-player-type="h5" 
x5-video-player-fullscreen="" 
x5-video-orientation="portraint"
      ></video>
    </div>
    <div class="subnav">
      <router-link to="" class="active">品牌简介</router-link>
      <router-link to="/mBrandConcept">品牌理念</router-link>
      <router-link to="/mEnterpriseDynamics">企业动态</router-link>
    </div>
  </div>
</template>

<script>
import { getAboutVifa } from "@/api/api";
export default {
  data() {
    return {
      aboutVifaBanner: {},
      aboutBrandDevelop: [],
      aboutVifa: "",
    };
  },
  components: {
    // Mzixun
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mAbout");
    } else {
      this.$router.replace(paths + "/about");
    }
    this.getPageData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPageData() {
      getAboutVifa()
        .then((res) => {
          this.aboutVifaBanner = res.aboutVifaBanner;
          this.aboutBrandDevelop = res.aboutBrandDevelop;
          this.aboutVifa = res.aboutVifa;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style scoped>
.homelogo {
  width: 750px;
  position: relative;
  margin-bottom: 100px;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 45px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 50%;
  margin-top: -45px;
}
.xiaotop {
  width: 1px;
  height: 34px;
  background: #f08518;
  margin: 35px auto 0;
}
.pplstext {
  line-height: 1.8;
  width: 670px;
  margin: 0 auto;
}
.pinpailishi .title {
  font-size: 45px;
  padding: 20px 0;
}
.pinpailishi .subtitle {
  font-size: 30px;
}
.pinpailishi p {
  font-size: 26px;
  padding-bottom: 20px;
  padding-bottom: 20px;
  text-align: justify;
}
.pplsimg {
  width: 100%;
  height: 492px;
  padding: 20px 0;
  overflow: hidden;
}
.pinpailishi ul {
  /* width: 2490px; */
  display: flex;
  margin-left: -247.5px;
}
.pinpailishi ul li {
  width: 375px;
  height: 492px;
  flex: 1;
  padding: 0 20px;
}
.pinpailishi ul li img {
  width: 375px;
  height: 492px;
}

.bottomtitle {
  width: 670px;
  margin: 0 auto;
  color: #f08518;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
}
.bottomtitle .showtitle {
}
.bottomtitle .showtitle p {
  text-align: center;
}
.bottomtitle .toleft {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  left: 0;
  top: 45px;
}
.bottomtitle .toright {
  width: 80px;
  height: 1px;
  background: #918b88;
  position: absolute;
  right: 0;
  top: 45px;
}
.bottomtitle .toleft span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  left: 10px;
  top: -9px;
}
.bottomtitle .toright span {
  width: 1px;
  height: 20px;
  background: #918b88;
  display: block;
  position: absolute;
  right: 10px;
  top: -9px;
}
.pinpailishiani-move {
  transition: all 0.3s;
}

.quanpin .bottomtitle {
  color: #4c413d;
}
.quanpin .pplsimg {
  height: 447px;
}
.quanpin .pinpailishi ul {
  width: 2250px;
  margin: 0;
}
.quanpin .pinpailishi ul li {
  width: 750px;
  height: 447px;
  padding: 0;
}
.quanpin .pinpailishi ul li img {
  width: 750px;
  height: 447px;
}

.input {
  font-size: 26px;
  width: 670px;
  margin: 40px auto;
}
.input input {
  width: 100%;
  line-height: 2;
  border: 1px solid #4c413d;
  text-align: center;
}

.bannertop,
.ppfzlist {
  width: 670px;
  font-size: 26px;
  line-height: 2;
  text-align: justify;
  margin: 40px auto 100px;
}
.ppfzlist img {
  width: 100%;
}
.ppfzlist div {
  font-size: 32px;
  font-weight: bold;
  padding-top: 26px;
}
.video {
  padding: 40px 0;
  padding-bottom: 100px;
}
.subnav {
  width: 750px;
  font-size: 26px;
  line-height: 100px;
  background: #000000cc;
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav .active {
  color: #f08518;
}
.hometopswipt {
  padding-bottom: 20px;
}
.bannertopmbl {
  margin-bottom: 0;
}
</style>
