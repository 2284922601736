<template>
  <div class="kitchenWap">
    <div class="livingRoomSpace">
      <div
        class="jianbianq"
        :class="{ jianbianh: isbrandConcepttitle }"
        ref="brandConcepttitle"
      >
        <div class="founder spaceCustomization">
          <div class="yishu marginTX"></div>
          <div class="beijing pwjstrone"> Clothing</div>
          <p v-html="seriesIntroduce.cloakroomSpaceEng" class="kjjianjie"></p>
          <!-- <div class="moreBut">了解更多</div> -->
        </div>
      </div>
           <div class="jianbianq" :class="{ jianbianh: iskjmx }" ref="kjmx">
        <div
          class="spaceCustomization_img homeDecoration cfct"
          ref="homeDecoration"
          @mouseenter="cfctenter"
          @mouseleave="cfctleave"
        >
          <img src="@/common/image/ymkjbanner2.png" alt="" />
          <div class="dingwei" :class="{ dingweishow: iscfctdingwei }">
            <div class="dingweia" @click="cfctdingweia">+</div>
            <div class="dingweib" @click="cfctdingweib">+</div>
            <div class="dingweic" @click="cfctdingweic">+</div>
            <div class="dingweid" @click="cfctdingweid">+</div>
          </div>
          <div
            class="dwzhankai"
            v-show="iscfctdwzhankai == index"
            v-for="(item, index) in cfctdwzhankaiData"
            :key="index"
            @click="cfctdwguanbi"
          >
            <ul>
              <li>
                <img class="cfimg" :src="'https://www.vifa.cn' + item.image" />
                <div>{{ item.titleEng }}</div>
                <p>{{ item.introduceEng }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hscttitle pwjstrone">Partition cloakroom</div>
        <router-link
          :to="{
            path: '/productDtails',
            query: {
              title: '隔断式衣帽间',
              SpaceActiv: 4,
              ittitle: 'Guardaroba diviso',
              entitle: 'Partition cloakroom',
            },
          }"
          ><div class="moreBut">Learn More</div></router-link
        >
      </div>
      <div class="jianbianq" :class="{ jianbianh: issjmx }" ref="sjmx">
        <div
          class="spaceCustomization_img homeDecoration hsct"
          ref="homeDecoration"
          @mouseenter="homeDM"
          @mouseleave="homeDMout"
        >
          <img src="@/common/image/ymkjbanner1.png" alt="" />
          <div class="dingwei" :class="{ dingweishow: isdingwei }">
            <div class="dingweia" @click="hsctdingweia">+</div>
            <div class="dingweib" @click="hsctdingweib">+</div>
            <div class="dingweic" @click="hsctdingweic">+</div>
            <div class="dingweid" @click="hsctdingweid">+</div>
            <!-- <div class="dingweie" @click="hsctdingweie">+</div>
                    <div class="dingweif" @click="hsctdingweif">+</div> -->
            <!-- <div class="dingweig" @click="hsctdingweig">+</div> -->
          </div>
          <div
            class="dwzhankai"
            v-show="isdwzhankai == index"
            v-for="(item, index) in dwzhankaiData"
            :key="index"
            @click="dwguanbi"
          >
            <ul>
              <li>
                <img class="cfimg" :src="'https://www.vifa.cn' + item.image" />
                <div>{{ item.titleEng }}</div>
                <p>{{ item.introduceEng }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hscttitle pwjstrone">Separate cloakroom I</div>
        <router-link
          :to="{
            path: '/productDtails',
            query: {
              title: '独立衣帽间Ⅰ',
              SpaceActiv: 4,
              ittitle: 'Cabina ArmadioⅠ',
              entitle: 'Separate cloakroom I',
            },
          }"
          ><div class="moreBut">Learn More</div></router-link
        >
      </div>
 
      <div class="jianbianq" :class="{ jianbianh: isshmx }" ref="shmx">
        <div
          class="spaceCustomization_img homeDecoration fcscf"
          ref="homeDecoration"
          @mouseenter="fcscftenter"
          @mouseleave="fcscftleave"
        >
          <img src="@/common/image/ymkjbanner3.png" alt="" />
          <div class="dingwei" :class="{ dingweishow: isfcscftdingwei }">
            <div class="dingweia" @click="fcscftdingweia">+</div>
            <div class="dingweib" @click="fcscftdingweib">+</div>
          </div>
          <div
            class="dwzhankai"
            v-show="isfcscftdwzhankai == index"
            v-for="(item, index) in fcscftdwzhankaiData"
            :key="index"
            @click="fcscftdwguanbi"
          >
            <ul>
              <li>
                <img class="cfimg" :src="'https://www.vifa.cn' + item.image" />
                <div>{{ item.titleEng }}</div>
                <p>{{ item.introduceEng }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hscttitle pwjstrone">Separate cloakroom Ⅱ</div>
        <router-link
          :to="{
            path: '/productDtails',
            query: {
              title: '独立衣帽间Ⅱ',
              SpaceActiv: 4,
              ittitle: 'Cabina ArmadioⅡ',
              entitle: 'Separate cloakroom Ⅱ',
            },
          }"
          ><div class="moreBut"> Learn More</div></router-link
        >
      </div>
      <div class="jianbianq" :class="{ jianbianh: isyimaokj }" ref="yimaokj">
        <div
          class="spaceCustomization_img homeDecoration fcscfa"
          ref="homeDecoration"
          @mouseenter="fcscftentera"
          @mouseleave="fcscftleavea"
        >
          <img src="@/common/image/ymkjbanner4.png" alt="" />
          <div class="dingwei" :class="{ dingweishow: isfcscftdingweia }">
            <div class="dingweia" @click="fcscftdingweiaa">+</div>
            <div class="dingweib" @click="fcscftdingweiba">+</div>
            <div class="dingweic" @click="fcscftdingweica">+</div>
          </div>
          <transition-group name="tran">
            <div
              class="dwzhankai"
              v-show="isfcscftdwzhankaia == index"
              v-for="(item, index) in fcscftdwzhankaiDataa"
              :key="index"
              @click="fcscftdwguanbia"
            >
              <ul>
                <li>
                  <img
                    class="cfimg"
                    :src="'https://www.vifa.cn' + item.image"
                  />
                  <div>{{ item.titleEng }}</div>
                  <p>{{ item.introduceEng }}</p>
                </li>
              </ul>
            </div>
          </transition-group>
        </div>
        <div class="hscttitle pwjstrone">Separate cloakroom III</div>
        <router-link
          :to="{
            path: '/productDtails',
            query: {
              title: '独立衣帽间Ⅲ',
              SpaceActiv: 4,
              ittitle: 'Cabina Armadio Ⅲ',
              entitle: 'Separate cloakroom III',
            },
          }"
          ><div class="moreBut">Learn More</div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getProductDetails } from "@/api/api";

export default {
  name: "clothesAndHatsSpace",
  data() {
    return {
      dwzhankaiData: [],
      isdwzhankai: 999,
      isdingwei: false,
      cfctdwzhankaiData: [],
      iscfctdwzhankai: 999,
      iscfctdingwei: false,
      fcscftdwzhankaiData: [],
      isfcscftdwzhankai: 999,
      isfcscftdingwei: false,
      fcscftdwzhankaiDataa: [],
      isfcscftdwzhankaia: 999,
      isfcscftdingweia: false,
      isbrandConcepttitle: false,
      issjmx: false,
      iskjmx: false,
      isshmx: false,
      isyimaokj: false,
    };
  },
  components: {},
  props: {
    seriesIntroduce: {
      type: String,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isbrandConcepttitle = true;
    this.issjmx = true;
    this.getkalajinyanData();
    this.getcfctdwzhankaiData();
    this.getfcscftdwzhankaiData();
    this.getfcscftdwzhankaiDataa();
  },
  methods: {
    getkalajinyanData() {
      let data = {
        productName: "独立衣帽间Ⅰ",
      };
      getProductDetails(data)
        .then((res) => {
          this.dwzhankaiData = res.productSpecific;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getcfctdwzhankaiData() {
      let data = {
        productName: "隔断式衣帽间",
      };
      getProductDetails(data)
        .then((res) => {
          this.cfctdwzhankaiData = res.productSpecific;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getfcscftdwzhankaiData() {
      let data = {
        productName: "独立衣帽间Ⅱ",
      };
      getProductDetails(data)
        .then((res) => {
          this.fcscftdwzhankaiData = res.productSpecific;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getfcscftdwzhankaiDataa() {
      let data = {
        productName: "独立衣帽间Ⅲ",
      };
      getProductDetails(data)
        .then((res) => {
          this.fcscftdwzhankaiDataa = res.productSpecific;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        // if (e.deltaY>0) {
        if (
          this.$refs.brandConcepttitle.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandConcepttitle = false;
        }
        if (
          this.$refs.sjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issjmx = false;
        }
        if (
          this.$refs.brandConcepttitle.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isbrandConcepttitle = true;
        }
        if (
          this.$refs.sjmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.issjmx = true;
        }
        if (
          this.$refs.kjmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.iskjmx = true;
        }
        if (
          this.$refs.shmx.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isshmx = true;
        }
        if (
          this.$refs.yimaokj.offsetTop <
          this.scroll + document.documentElement.clientHeight
        ) {
          this.isyimaokj = true;
        }

        // }else if (e.deltaY < 0) {
        if (
          this.$refs.brandConcepttitle.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isbrandConcepttitle = false;
        }
        if (
          this.$refs.sjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.issjmx = false;
        }
        if (
          this.$refs.kjmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.iskjmx = false;
        }
        if (
          this.$refs.shmx.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isshmx = false;
        }
        if (
          this.$refs.yimaokj.offsetTop >
          this.scroll + document.documentElement.clientHeight - 200
        ) {
          this.isyimaokj = false;
        }
      });
      // }
    },
    homeDM() {
      this.isdingwei = true;
    },
    homeDMout() {
      this.isdingwei = false;
    },
    dwguanbi() {
      this.isdwzhankai = 999;
    },
    hsctdingweia() {
      this.isdwzhankai = 0;
    },
    hsctdingweib() {
      this.isdwzhankai = 1;
    },
    hsctdingweic() {
      this.isdwzhankai = 2;
    },
    hsctdingweid() {
      this.isdwzhankai = 3;
    },
    hsctdingweie() {
      this.isdwzhankai = 4;
    },
    hsctdingweif() {
      this.isdwzhankai = 5;
    },
    // hsctdingweig(){
    //     this.isdwzhankai = 6
    // },
    cfctenter() {
      this.iscfctdingwei = true;
    },
    cfctleave() {
      this.iscfctdingwei = false;
    },
    cfctdwguanbi() {
      this.iscfctdwzhankai = 999;
    },
    cfctdingweia() {
      this.iscfctdwzhankai = 0;
    },
    cfctdingweib() {
      this.iscfctdwzhankai = 1;
    },
    cfctdingweic() {
      this.iscfctdwzhankai = 2;
    },
    cfctdingweid() {
      this.iscfctdwzhankai = 3;
    },
    fcscftenter() {
      this.isfcscftdingwei = true;
    },
    fcscftleave() {
      this.isfcscftdingwei = false;
    },
    fcscftdwguanbi() {
      this.isfcscftdwzhankai = 999;
    },
    fcscftdingweia() {
      this.isfcscftdwzhankai = 0;
    },
    fcscftdingweib() {
      this.isfcscftdwzhankai = 1;
    },
    fcscftentera() {
      this.isfcscftdingweia = true;
    },
    fcscftleavea() {
      this.isfcscftdingweia = false;
    },
    fcscftdwguanbia() {
      this.isfcscftdwzhankaia = 999;
    },
    fcscftdingweiaa() {
      this.isfcscftdwzhankaia = 0;
    },
    fcscftdingweiba() {
      this.isfcscftdwzhankaia = 1;
    },
    fcscftdingweica() {
      this.isfcscftdwzhankaia = 2;
    },
  },
  unmounted(){
    window.removeEventListener("scroll", this.handleScroll);  
  } 
};
</script>

<style scoped>
.kitchenWap .spaceCustomization p {
  /* width: 740px; */
  margin: 0 auto;
  white-space: pre-wrap;
  /* text-align: left; */
}
.hsct .dingwei .dingweia {
  left: 15.5%;
  top: 49.5%;
}
.hsct .dingwei .dingweib {
  left: 31.5%;
  top: 33%;
}
.hsct .dingwei .dingweic {
  left: 46%;
  top: 37%;
}
.hsct .dingwei .dingweid {
  left: 62%;
  top: 67%;
}
/* .hsct .dingwei .dingweie{
    left: 40%;
    top: 49%;
}
.hsct .dingwei .dingweif{
    left: 9%;
    top: 22%;
}
.hsct .dingwei .dingweig{
    left: 64.5%;
    top: 36%;
} */
.kitchenWap .dwzhankai ul {
  width: 875px;
  height: 710px;
}
.kitchenWap .dwzhankai ul li .cfimg {
  width: 400px;
  height: 352.5px;
  margin: 88px 0 25px 355px;
}
.kitchenWap .dwzhankai ul li div {
  font-size: 30px;
  padding-top: 0;
}
.kitchenWap .dwzhankai ul li p {
  padding-top: 15px;
  font-size: 16px;
}
.hscttitle {
  font-size: 30px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.cfct .dingwei .dingweia {
  left: 16%;
  top: 14%;
}
.cfct .dingwei .dingweib {
  left: 27.5%;
  top: 49%;
}
.cfct .dingwei .dingweic {
  left: 39%;
  top: 52%;
}
.cfct .dingwei .dingweid {
  left: 55%;
  top: 45%;
}
.cfct .dingwei .dingweie {
  left: 63.5%;
  top: 30%;
}
.fcscf .dingwei .dingweia {
  left: 17.5%;
  top: 16%;
}
.fcscf .dingwei .dingweib {
  left: 49%;
  top: 44%;
}
.fcscfa .dingwei .dingweia {
  left: 28%;
  top: 47%;
}
.fcscfa .dingwei .dingweib {
  left: 62%;
  top: 43%;
}
.fcscfa .dingwei .dingweic {
  left: 81%;
  top: 67%;
}
</style>
