<template>
    <div class="mhome">
        <div class="hometopswipt quanpin">
            <div class="pinpailishi">
                <div class="pplsimg">
                    <transition-group name="pinpailishiani">
                        <ul :style="{marginLeft:kjmarginLeft + 'px',width:kjwidth+'px'}">
                            <li v-for="(item, index) in productCenterBanner" :key="index" ><img :src="'https://www.vifa.cn' + item.image" alt=""></li>
                        </ul>
                    </transition-group>
                </div>
                <div class="bottomtitle">
                    <div class="showtitle" v-for="(item, index) in productCenterBanner" :key="index" v-show="iskjshow ==index">
                        <router-link :to=item.link>
                            <div>{{item.title}}</div>
                            <p>{{item.subtitle}}</p>
                        </router-link>
                    </div>
                    <!-- <div class="toleft"><span></span></div>
                    <div class="toright"><span></span></div> -->
                </div>
            </div>
        </div>
        <!-- <div class="twonav">
            <ul>
                <li><router-link to="mdoorwallsystem">门墙系统</router-link></li>
                <li><router-link to="mProductCenter">空间系统</router-link></li>
                <li class="active"><router-link to="">大家居</router-link></li>
            </ul>
        </div> -->
        
        <div class="hometopswipt">
            <div class="xiaotop"></div>
            <div class="wpz"><img src="@/common/image/christophlogo.png" alt=""></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <!-- <p v-html="qualityService.qualityText"></p> -->
                    <p>CHRISTOPH 灯以设计师、物理学家克里斯托夫•库格勒 (Christoph Kügler) 的名字命名，从积极的意义上说，他对光着迷。这些灯结合了对细节的热爱和材料的质量以及新的智能技术。是一款符合高专业标准的灯，同时适合您的个人应用。CHRISTOPH灯光系统，德国设计，德国工艺。艺术、奢华，能运用于饭厅、客厅、厨房、睡房等家居中每一个角落。平衡光影效果与空间美学，线条流畅，既能自然地融入功能空间内，又能够起到装点的作用，提升了空间层次感。灯饰明朗的色彩搭配清爽淡雅的线条设计，简单的线条勾勒出光与影的独白，将生活空间更多地留白与思考，诠释了高端生活的质感，打造出来的是一种精致、唯美、极具沉浸感的视觉效果。</p>
                </div>
            </div>
        </div>
        <div class="productcont">
            <div @click="todajiju" style="border:1px solid #4c413d4d">了解更多</div>
        </div>
        <div class="hometopswipt">
            <div class="wpq"><img src="@/common/image/cbrand1.png" alt=""></div>
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">不同空间演绎</div>
                    <p style="text-align:center">Different Spaces，Same Spectacular</p>    
                </div>
            </div>
        </div>
        <div class="productcont">
            <div @click="todajiju" style="border:1px solid #4c413d4d">了解更多</div>
        </div>
        <!-- <div class="quantu"><img src="@/common/image/dajiajuhome1.png" alt=""></div> -->
        <div class="hometopswipt quanpin quantu">
            <div class="pinpailishi">
                <div class="pplsimg">
                    <transition-group name="pinpailishiani">
                        <ul :style="{marginLeft:kjmarginLeft + 'px',width:kjwidth+'px'}">
                            <li v-for="(item, index) in productCenterBanners" :key="index"   @touchstart="kjtouchstart" @touchmove='kjtouchmove'><img :src=item.image alt=""></li>
                        </ul>
                    </transition-group>
                </div>
            </div>
        </div>
        
        <div class="subnav" >
            <ul>
                <li v-for="(item,index) in kjdzallData" :key="index" :class="{'avtive':isclick == index}" @click="subnavqiehuan(index)">
                    <img class="icon" :src=item.icon alt="">
                    <img class="icon_active" :src=item.icon_active alt="">
                    <p>{{item.name}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import sofa from '@/common/image/sofa.png'
import kitchen from '@/common/image/kitchen.png'
import sofa_active from '@/common/image/sofa_active.png'
import kitchen_active from '@/common/image/kitchen_active.png'
import dajiajuhome1 from '@/common/image/dajiajuhome1.png'
import dajiajuhome2 from '@/common/image/dajiajuhome2.png'
import dajiajuhome3 from '@/common/image/dajiajuhome3.png'
import {getProductCenter} from '@/api/api'
export default {
    data(){
        return{
            kjdzallData:[{
                    icon:kitchen,
                    icon_active:kitchen_active,
                    name:'灯光',
                },
                {
                    icon:sofa,
                    icon_active:sofa_active,
                    name:'软装',
                },
            ],
            productCenterBanner:[],
            kjmarginLeft:-document.body.clientWidth,
            kjwidth:0,
            iskjshow:1,
            kjstartx:0,
            kjmovex:0,
            kjchangex:0,
            isclick:0,
            dajijuyrl:'http://christoph.cn',
            productCenterBanners:[
                {
                    image:dajiajuhome1
                },
                {
                    image:dajiajuhome2
                },
                {
                    image:dajiajuhome3
                },
            ]
        }
    },
    mounted(){
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
            this.$router.replace(paths+'/mchristophBrand');
        } else {
            this.$router.replace(paths+'/christophBrand');
        }
        this.getHomePageData()
        this.kjwidth = document.body.clientWidth * this.productCenterBanner.length
        
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getHomePageData(){
            getProductCenter()
            .then(res => {
                this.productCenterBanner = [
                    {image:res.productCenterBanner.mobileKjdzImage,title:'空间定制',subtitle:'Space Customization',link:'/mProductCenter'},
                    {image:res.productCenterBanner.mobileDjjImage,title:'大家居',subtitle:'Lighting & Furniture',link:'/mchristophBrand'},
                    {image:res.productCenterBanner.mobileMqImage,title:'门墙系统',subtitle:'Door & Wall',link:'/mdoorwallsystem'},
                ]
                this.seriesList = res.seriesList
                this.spacelist = res.seriesList[0].room_space
                this.seriesIntroduce = res.seriesIntroduce
                this.introduce = res.seriesIntroduce.roomSpace
            })
            .catch(res => {
                console.log(res)
            });
        },
        kjshow(index){
            this.kjchangex = this.kjstartx
            this.kjmarginLeft = -document.body.clientWidth*(index)
            console.log(this.kjmarginLeft,index)
        },
        kjtouchstart(e){
            this.kjstartx = e.touches[0].clientX
        },
        kjtouchmove(e){
            if(this.kjchangex == this.kjstartx){
                return
            }
            this.kjmovex = e.touches[0].clientX
            let kjleftslide = this.kjstartx - this.kjmovex
            console.log(this.iskjshow)
            if(kjleftslide > 30 && this.iskjshow < this.productCenterBanner.length - 1){
                this.kjshow(++this.iskjshow)
            }else if(kjleftslide < -30 && this.iskjshow > 0){
                this.kjshow(--this.iskjshow)
            }
        },
        todajiju(){
            window.open(this.dajijuyrl,'_blank')
        },
    },
}
</script>

<style scoped>
.homelogo{
    width: 100%;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 0;
}
.wpz{
    width: 670px;
    margin: 35px auto 35px;
}
.wpz img{
    width: 50%;
}
.wpq{
    width: 670px;
    margin: 35px auto 10px;
}
.wpq img,.quantu img{
    width: 100%;
}
.quantu{
    width: 750px;
    padding: 40px 0;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 0 auto;
}
.pinpailishi{
    position: relative;
}
.pinpailishi .title{
    font-size: 45px;
    padding: 20px 0;
}
.pinpailishi .subtitle{
    font-size: 30px;
}
.pinpailishi p{
    font-size: 26px;
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    height: 492px;
    /* padding: 20px 0; */
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -247.5px; 
}
.pinpailishi ul li{
    width: 375px;
    height: 492px;
    flex: 1;
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 375px; 
    height: 492px;
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -56.25px;
}
.bottomtitle .showtitle p{
    text-align: center;
    color: #fff;
}
.bottomtitle .showtitle div{
    font-size: 45px;
    color: #fff;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 56.25px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 56.25px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #fff;
}
.quanpin .pplsimg{
    height: 447px;
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    height: 447px;
    padding: 0;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 40px auto;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.producttiel{
    background: #d2cdca;
    padding-top: 1px;
    padding-bottom: 40px;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    /* background: #d2cdca; */
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav ul{
    width: 100%;
    display: flex;
    padding-top: 20px;
    flex-wrap: wrap;
    background: #000000cc;
    color: #fff;
}
.subnav ul li{
    flex: 1 187.5px;
    text-align: center;
    font-size: 26px;
    /* color: #fff; */
    line-height: 2;
    margin-bottom: 20px;
}
.subnav ul li.avtive{
    color: #f08518;
}
.subnav ul li p{}
.subnav ul li img{
    width: 71px;
    height: 55px;
    margin: 0 auto;
}
.subnav ul li.avtive .icon{
    display: none;
}
.subnav ul li.avtive .icon_active{
    display: block;
}
.subnav ul li .icon_active{
    display: none;
}
.subnav ul li .icon{
    display: block;
}
.productcont{
    padding: 40px 0;
    line-height: 2;
    width: 750px;
    text-align: center;
}
.productcont img{
    width: 100%;
}
.productcont p{
    font-size: 26px;    
    line-height: 88px;
}
.productcont div{
    font-size: 26px;
    width: 225px;
    margin: 0 auto;
    line-height: 1;
    border: 1px solid #4c413d;
    /* color: #fff; */
    /* background: #f08518; */
    line-height: 65px;
}
.twonav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
}
.twonav ul{
    width: 100%;
    display: flex;
}
.twonav ul li{
    flex: 1;
}
.twonav ul li.active a{
    color: #f08518;
}
.hometopswipt{
    padding-bottom: 100px;
}
</style> 