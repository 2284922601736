<template>
    <div class="mhome mcontact">
        <div class="homelogo">
            <img :src="'https://www.vifa.cn' + contactusBanner.mobileImage" alt="">
            <div>{{contactusBanner.title}}</div>
        </div>
        <div class="hometopswipt">
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title">联系我们</div>
                    <p>全国统一服务热线：{{contactus.hotLine}}</p>
                </div>
            </div>
        </div>
        <div class="hometopswipt">
            <div class="pinpailishi">
                <div class="pplstext">
                    <p class="blod">运营中心</p>
                    <p>地址：{{contactus.companyAddress}}</p>
                    <p>电话：{{contactus.headquarterPhone}}</p>
                    <p>传真：{{contactus.headquarterFax}}</p>
                    <p>邮编：{{contactus.headquarterCode}}</p>
                </div>
            </div>
        </div>
        <div class="hometopswipt">
            <div class="pinpailishi">
                <div class="pplstext">
                    <p class="blod">制造基地总部</p>
                    <p>地址：{{contactus.producebaseAddress}}</p>
                    <!-- <p>电话：{{contactus.producebasePhone}}</p> -->
                    <p>传真：{{contactus.producebaseFax}}</p>
                    <p>邮编：{{contactus.producebaseCode}}</p>
                </div>
            </div>
        </div>
        <!-- <div class="mqrco">
            <div>
                <div v-for="(item,index) in qrcodeData" :key="index" @click="bigqrcon(index)"><img :src=item.qrcodeicon alt=""></div>
            </div>
        </div> -->
                
        
        <!-- <div class="weweima">
            <ul>
                <li>
                    <img src="@/common/image/mwexin.png" alt="">
                </li>
                <li>
                    <img src="@/common/image/mxiaochengxu.png" alt="">
                </li>
                <li>
                    <img src="@/common/image/mdouyin.png" alt="">
                </li>
                <li>
                    <img src="@/common/image/mxiaohongshu.png" alt="">
                </li>
            </ul>
        </div> -->
        <div class="imgqp">
            <img :src="'https://www.vifa.cn' + contactusBanner.mobileShopImage" alt="">
        </div>
        <div class="hometopswipt quanpin" ref="search">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title" style="text-align:center">门店查询</div>
                    <div class="input">
                        <input type="text" placeholder="请输入您所在的城市" v-model="searchcityshop" @keyup.enter="citychashop"  style="border:1px solid #4c413d4d">
                        <div class="img" @click="citychashop">
                            <img src="@/common/image/searchIco.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="pplsimg">
                    <transition-group name="pinpailishiani">
                        <!-- <ul @touchstart="mdtouchstart" @touchmove='mdtouchmove' :style="{marginLeft:mdmarginLeft + 'px',width:mdwidth+'px'}" ref="pplsul" v-if="shopList.length>0"> -->
                        <ul @touchstart="mdtouchstart" @touchmove='mdtouchmove'  ref="pplsul" v-if="shopList.length>0">
                            <!-- <li v-for="(item, index) in shopList" :key="index" @click="addrenter(index)"> -->
                            <li v-for="(item, index) in shopList" :key="index" @click="tonaxtpage(index)">
                                <div>{{item.shopName}}</div>
                                <p>{{item.shopAddress}}</p>
                                <div class="addres" v-show="isaddres == index">
                                    <button @click.stop="cliyuyue">预约</button>
                                    <button @click="chakanditu(jiax.shopLongitude,jiax.shopLatitude)">地图</button>
                                </div>
                            </li>
                            <!-- <li style="height:0"></li>
                            <li style="height:0"></li> -->
                        </ul>
                    </transition-group>
                    <!-- <div class="citynoshop" v-if="shopList.length == 0">该城市暂时还没有门店，请搜索其他城市</div> -->
                    <div class="citynoshop" v-if="shopList.length == 0">敬请期待</div>
                </div>
            </div>
            <!-- <div class="shopcity" v-if="hangshu>1">
                <div>
                    <ul>
                        <li v-for="(item, index) in shopList.slice(0,hangshu)" :key="index" :class="{'active':ismdshow == index}"><span class="no">一</span><span class="yes">十</span></li>
                    </ul>
                </div>
                <p class="shopcity_left" @click="forleft" v-show="isshopcity_left"></p>
                <p class="shopcity_right" @click="forright" v-show="isshopcity_right"></p>
            </div> -->
        </div>
        <div class="hometopswipt backb">
            <div class="xiaotop"></div>
            <div class="pinpailishi">
                <div class="pplstext">
                    <div class="title" style="text-align:center">经销商招募</div>
                </div>
            </div>
            <div class="zhaomu">
                <transition-group name="pinpailishiani">
                    <ul>
                        <li v-for="(item,index) in zhaomunavData" :key="index" :class="{'active':zhixinagisactive == index}" @click="clickjingxiaos(index)">{{item.name}}</li>
                    </ul>
                    <p></p>
                    <div class="zhizheng" :style="{left:zhizhengleft + 'px'}"></div>
                </transition-group>
            </div>
            <div class="jxscont" v-if="support">
                <div v-html="agentRecruit.support"></div>
            </div>
            <div class="jxscont" v-if="procedure">
                <!-- <div v-html="agentRecruit.procedure"></div> -->
                <div class="img"><img :src="'https://www.vifa.cn'+agentRecruit.procedure" alt=""></div>
            </div>
            <div class="jxscont" v-if="condition">
                <div v-html="agentRecruit.condition"></div>
            </div>
            <div class="jxscont" v-if="apply">
                <div v-html="agentRecruit.apply"></div>
                <div class="hometopswipt">
                    <div class="pinpailishi">
                        <div class="zixuntian">
                            <div>
                                <input type="text" placeholder="*请输入您所在的城市 City" v-model="city">
                            </div>
                            <div>
                                <input type="text" placeholder="*请输入您的姓名 Name" v-model="name">
                            </div>
                            <div>
                                <input type="text" placeholder="*请输入您的电话 Phone" v-model="phone">
                            </div>
                            <div>
                                <input type="text" placeholder="请输入您的邮箱 E-mail" v-model="email">
                            </div>
                            <div>
                                <input type="text" placeholder="*请输入您希望咨询的问题 Message" v-model="message">
                            </div>
                        </div>
                        <div class="mzixunbottom" @click="tijiao">立即提交</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tishi" v-if="istishi"><div>{{tishi}}</div></div>
        <div class="isshowqrcon" v-show="isshowqrcon" @click="close">
            <div v-for="(item,index) in qrcodeData" :key="index" v-show="isbigqrconimg == index"><img @click.stop="" :src="'https://www.vifa.cn'+item.qrcode" alt=""></div>
        </div>
        <div class="mzixunbox" ref="mzixunbox">
            <Mzixun/>
        </div>
    </div>
</template>

<script>
import weixin from '@/common/image/mwexin.png'
import xiaochengxu from '@/common/image/mxiaochengxu.png'
import douyin from '@/common/image/mdouyin.png'
import xiaohongshu from '@/common/image/mxiaohongshu.png'
import { getContactus ,getShopByKeyword,saveConsultRecord } from "@/api/api";
import Mzixun from '@/components/mzixun'
export default {
    data(){
        return{
            contactus:'',
            contactusBanner:'',
            shopList:[],
            mdmarginLeft:0,
            mdwidth:0,
            ismdshow:0,
            mdstartx:0,
            mdmovex:0,
            mdchangex:0,
            pplsulli:[],
            zongheight:0,
            hangshu:0,
            zhaomunavData:[
                {name:'经销商支持'},
                {name:'合作流程'},
                {name:'成为经销商条件'},
                {name:'经销商申请'},
            ],
            zhizhengleft:0,
            zhixinagisactive:0,
            agentRecruit:'',
            support:true,
            procedure:false,
            condition:false,
            apply:false,
            searchcityshop:'',
            iscityhasshop:false,
            name:'',
            phone:'',
            email:'',
            message:'',
            istishi:false,
            tishi:'',
            qrcodeData:[
            ],
            isshowqrcon:false,
            isbigqrconimg:0,
            isshopcity_left:false,
            isshopcity_right:true,
            isaddres:999,
            city:'',
        }
    },
    components: {
        Mzixun
    },
    mounted(){
            let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
        if (this._isMobile()) {
           if(this.$route.query.suosuov){    
             this.$router.replace(paths+'/mcontact?suosuov='+this.$route.query.suosuov);
           }else{
               this.$router.replace(paths+'/mcontact?');
           }
        } else {
            this.$router.replace(paths+'/contact?suosuov='+this.$route.query.suosuov);
        }

        
       
        this.getContactusData()
        if (this.$route.query.suosuov) {
            document.documentElement.scrollTop = this.$refs.search.offsetTop-19
            this.searchcityshop = this.$route.query.suosuov
            let data = {
                    keyword:this.$route.query.suosuov
                }
            this.citychashop()
        }

        
        else{
            setTimeout(()=>{
                this.$nextTick(()=>{
                    this.pplsulli = this.$refs.pplsul.children
                    for (let i = 0; i < this.pplsulli.length-2; i++) {
                        const element = this.pplsulli[i];
                        this.zongheight = this.zongheight+element.clientHeight
                    }
                    this.hangshu = Math.ceil(this.zongheight/this.$refs.pplsul.clientHeight)
                    console.log(this.hangshu)
                    this.zhizhengleft = document.body.clientWidth/(this.zhaomunavData.length*2)
                })
            },1000)
        }
         setTimeout(()=>{
        this.$nextTick(()=>{
             if(this.$route.query.id==1){
            console.log('我进来了')
            // this.searchcityshop=''
            this.goDealerinquiry()
        }
   })},1000)
        
    },
    methods:{
        defferScroll: function (event) {
            event.preventDefault()
        },
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        getContactusData(){
            getContactus()
            .then(res => {
                this.contactus = res.contactus
                this.contactusBanner = res.contactusBanner
                this.agentRecruit = res.agentRecruit
                if (!this.$route.query.suosuov) {
                this.shopList = res.shopList
                }
                this.qrcodeData=[
                    {
                        qrcode:res.contactus.gzhImage,
                        qrcodeicon:weixin
                    },
                    {
                        qrcode:res.contactus.xcxImage,
                        qrcodeicon:xiaochengxu
                    },
                    {
                        qrcode:res.contactus.dyImage,
                        qrcodeicon:douyin
                    },
                    {
                        qrcode:res.contactus.xhsImage,
                        qrcodeicon:xiaohongshu
                    },
                ]
            })
        },
        getShopByKeywordData(data){
            getShopByKeyword(data).then( res => {
                // this.contactData = res
                this.shopList = res.shopList
                this.ismdshow = 0
                this.zongheight = 0
                setTimeout(()=>{
                    this.$nextTick(()=>{
                        this.pplsulli = this.$refs.pplsul.children
                        for (let i = 0; i < this.pplsulli.length-2; i++) {
                            const element = this.pplsulli[i];
                            this.zongheight = this.zongheight+element.clientHeight
                        }
                        this.hangshu = Math.ceil(this.zongheight/this.$refs.pplsul.clientHeight)
                        console.log(this.hangshu)
                    })
                },100)
                // if(this.shoplianxi.length>6){
                //     this.slicenum =  Math.ceil(this.shoplianxi.length/6)
                // }else{
                //     this.slicenum = 1
                // }
            }).catch(res => {
                console.log(res)
            })
        },
         goDealerinquiry(){

        //      let scrollTops = window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop

        // scrollTops = this.$refs.search.offsetTop-19
        // var t =  document.documentElement.scrollTop || document.body.scrollTop;
        // t= this.$refs.search.offsetTop-19

        document.documentElement.scrollTop = this.$refs.search.offsetTop-19
        },
        mdshow(index){
            if (this.ismdshow == 0) {
                this.isshopcity_left = false
                this.isshopcity_right = true
            }else if (this.ismdshow == this.hangshu-1) {
                this.isshopcity_left = true
                this.isshopcity_right = false
            }
            this.mdchangex = this.mdstartx
            this.mdmarginLeft = -document.body.clientWidth*(index)
        },
        mdtouchstart(e){
            this.mdstartx = e.touches[0].clientX
        },
        mdtouchmove(e){
            if(this.mdchangex == this.mdstartx){
                return
            }
            this.mdmovex = e.touches[0].clientX
            let mdleftslide = this.mdstartx - this.mdmovex
            console.log(this.ismdshow)
            if(mdleftslide > 30 && this.ismdshow < this.hangshu-1){
                this.mdshow(++this.ismdshow)
            }else if(mdleftslide < -30 && this.ismdshow > 0){
                this.mdshow(--this.ismdshow)
            }
        },
        clickjingxiaos(index){
            this.zhixinagisactive = index
            this.zhizhengleft = document.body.clientWidth/(this.zhaomunavData.length*2)+(document.body.clientWidth/(this.zhaomunavData.length*2)*2*index)
            if (index==0) {
                this.support = true
                this.procedure = false
                this.condition = false
                this.apply = false
            }else if (index==1) {
                this.support = false
                this.procedure = true
                this.condition = false
                this.apply = false
            }else if (index==2) {
                this.support = false
                this.procedure = false
                this.condition = true
                this.apply = false
            }else if (index==3) {
                this.support = false
                this.procedure = false
                this.condition = false
                this.apply = true
            }
        },
        citychashop(){
            if (this.searchcityshop == '') {
                this.istishi=true
                this.tishi = '请输入您需要搜索的城市'
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
            }else{
                this.mdmarginLeft = 0
                let data = {
                    keyword:this.searchcityshop
                }
                this.getShopByKeywordData(data)
            }
        },
        getPageData(data){
            saveConsultRecord(data)
            .then(res => {
                if(res.result == 0){
                    this.istishi=true
                    this.tishi = '提交成功'
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
                }else{
                    this.istishi=true
                    this.tishi = res.message
                    setTimeout(() => {
                        this.istishi = false
                    },3000)
                }
                    this.name = ''
                    this.phone = ''
                    this.email = ''
                    this.message = ''
                    this.city=''
            })
            .catch(res => {
                console.log(res,)
            });
        },
        addrenter(index){
            if (this.isaddres == 999) {
                this.isaddres = index
            }else{
                this.isaddres = 999
            }
            
        },
        tijiao(){
            let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/
            if(this.name == ''){
                this.istishi=true
                this.tishi = '请输入您的姓名'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.phone == ''){
                this.istishi=true
                this.tishi = '请输入您的电话'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.city == ''){
                this.istishi=true
                this.tishi = '请输入您所在的地址'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if(this.message == ''){
                this.istishi=true
                this.tishi = '请输入您希望咨询的问题'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else if((!phonereg.test(this.phone)) && this.phone != ''){
                this.istishi=true
                this.tishi = '请输入正确电话号码'
                setTimeout(() => {
                    this.istishi = false
                },3000)
            }
            else{
                let data = {
                    name : this.name,
                    phone : this.phone,
                    email : this.email,
                    message : this.message,
                    city:this.city,
                }
                this.getPageData(data)
            }
        },
        
        bigqrcon(index){
            if (index == 3) {
                window.open('https://www.xiaohongshu.com/user/profile/5dd4e7690000000001006037','_blank')
            }else if (index == 2) {
                window.open('https://v.douyin.com/89h1cdj/','_blank')
            }else{
                this.isshowqrcon = true
                this.isbigqrconimg = index
                document.body.addEventListener("touchmove", this.defferScroll, {passive: false});
                document.body.addEventListener("wheel", this.defferScroll, {passive: false});
            }
        },
        close(){
            this.isshowqrcon = false
            document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
            document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
        },
        forleft(){
            if(this.ismdshow > 0){

                this.mdshow(--this.ismdshow)
            }
        },
        forright(){
            if(this.ismdshow < this.hangshu-1){
                this.mdshow(++this.ismdshow)
            }
        },
        cliyuyue(){
            console.log(this.$refs.mzixunbox.offsetTop)
            document.documentElement.scrollTop = this.$refs.mzixunbox.offsetTop
        },

       
        tonaxtpage(index){
          let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
            this.$router.push({path:paths+'/mmapsearch',query:{searchcityshop:this.searchcityshop,clickindex:index}})
        },
    },
    unmounted(){
       document.body.removeEventListener("touchmove", this.defferScroll, {passive: false});
                document.body.removeEventListener("wheel", this.defferScroll, {passive: false});
    }
}
</script>

<style scoped>
.mhome{
    font-size: 26px;
}
.homelogo{
    width: 750px;
    position: relative;
    margin-bottom: 100px;
}
.homelogo img,.video{
    width: 750px;
}
.homelogo div{
    width: 750px;
    text-align: center;
    font-size: 45px;
    color: #fff;
    position: absolute;
    left: 0;
    line-height: 90px;
    top:50%;
    margin-top: -45px;
}
.xiaotop{
    width: 1px;
    height: 34px;
    background: #f08518;
    margin: 35px auto 35px;
}
.pplstext{
    line-height: 1.8;
    width: 670px;
    margin: 40px auto 0;
    text-align: left;
}
.pinpailishi .title{
    font-size: 45px;
    padding: 20px 0;
    font-weight: bold;
}
.pinpailishi .subtitle{
    font-size: 29px;
}
.pinpailishi p{
    padding-bottom: 20px;
    text-align: justify;
}
.pplsimg{
    width: 100%;
    /* height: 357px; */
    padding: 20px 0;
    overflow: hidden;
}
.pinpailishi ul{
    /* width: 2490px; */
    display: flex;
    margin-left: -642px; 
}
.pinpailishi ul li{
    width: 635px;
    /* height: 357px; */
    /* flex: 1; */
    padding: 0 20px;
}
.pinpailishi ul li img{
    width: 635px; 
    height: 357px;
    
}

.bottomtitle{
    width: 670px;
    margin: 0 auto;
    color: #f08518;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
}
.bottomtitle .showtitle{}
.bottomtitle .showtitle p{
    text-align: center;
}
.bottomtitle .toleft{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    left: 0;
    top: 26px;
}
.bottomtitle .toright{
    width: 80px;
    height: 1px;
    background: #918b88;
    position: absolute;
    right: 0;
    top: 26px;
}
.bottomtitle .toleft span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
}
.bottomtitle .toright span{
    width: 1px;
    height: 20px;
    background: #918b88;
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;
}
.pinpailishiani-move{
    transition: all .3s;
}




.quanpin .bottomtitle{
    color: #4c413d;
}
.quanpin .pplsimg{
    /* height: 450px; */
}
.quanpin .pinpailishi ul{
    width: 2250px;
    margin: 0;
    /* height: 450px;     */
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.quanpin .pinpailishi ul li{
    width: 750px;
    /* height: 450px; */
    padding: 0;
    font-size: 26px;
}
.quanpin .pinpailishi ul li img{
    width: 750px;
    height: 447px;
}


.input{
    font-size: 26px;
    width: 670px;
    margin: 0 auto 40px;
}
.input input{
    width: 100%;
    line-height: 67px;
    border: 1px solid #4c413d;
    text-align: center;
    color: #4c413d;
}

.bannertop ,.ppfzlist{
    width: 670px;
    font-size: 26px;
    line-height: 2;
    text-align: justify;
    margin: 40px auto;
}
.ppfzlist img{
    width: 100%;
}
.ppfzlist div{
    font-size: 32px;
    font-weight: bold;
    padding-top: 26px;
}
.video{
    padding: 40px 0;
}
.ppjjsjmx{
    background: url('../../../common/image/sjmxbg.png') 0 0 no-repeat;
    background-size: 100% 100%;
    padding-top: 10px;
    padding-bottom: 60px;
}
.shmx{
    background: #d2cdca;
    margin-top: 0;
    padding: 10px 0;
}
.subnav{
    width: 750px;
    font-size: 26px;
    line-height: 100px;
    background: #fff;
    text-align: center;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
}
.subnav a{
    flex: 1;
    display: block;
}
.pzbtit .showtitle p{
    line-height: 2;
    padding-bottom: 40px;
}
.blod{
    font-weight: bold;
}
.weweima{
    width: 750px;
    margin: 40px auto;
}
.weweima ul{
    width: 100%;
    display: flex;
}
.weweima ul li{
    flex: 1;
    text-align: center;
}
.weweima ul li img{
    width: 120px;
    height: 120px;
}
.weweima ul li p{
    padding-top: 20px ;
}
.imgqp{
    width: 750px;
    padding-bottom: 100px;
}
.imgqp img{
    width: 750px;
}
.quanpin .pinpailishi ul li{
    line-height: 53px;
    text-align: left;
    padding-bottom: 20px;
}
.quanpin .pinpailishi ul li div{
    /* width: 670px; */
    padding:0 40px;
    font-weight: bold;
}
.quanpin .pinpailishi ul li p{
    width: 670px;
    padding:0 40px;
}
.shopcity{
    width: 670px;
    position: relative;
    margin: 0 auto;
    margin: 7.5px auto;
    height: 1px;
    background: #4c413d;
}
.shopcity>div{
    /* width: 100%; */
    width: calc(100% - 50px);
    position: absolute;
    left: 25px;
    top: -10px;
    overflow: hidden;
}
.shopcity ul{
    background: #fff;
    display: inline-flex;
    /* overflow: hidden; */
    line-height: 15px;
    padding: 0 15px;
}
.shopcity ul li{
    width: 15px;
    padding:0 15px;
    float: left;
}
.shopcity ul li .no{
    display: block;
}
.shopcity ul li .yes{
    display: none;
}
.shopcity ul li.active .no{
    display: none;
}
.shopcity ul li.active .yes{
    display: block;
}
.shopcity_left{
    height: 25px;
    width: 1px;
    background: #4c413d;
    position: absolute;
    left: 15px;
    top: -12px;
}
.shopcity_right{
    height: 25px;
    width: 1px;
    background: #4c413d;
    position: absolute;
    right: 15px;
    top: -12px;
}
.quanpin{
    padding-bottom: 40px;
}
.backb{
    /* background: #e5e2e0; */
    padding-top: 1px;
} 
.zhaomu{
    width: 750px;
    position: relative;
    margin-bottom: 60px;
}
.zhaomu ul{
    display: flex;
    width: 750px;
    justify-content: space-around;
}
.zhaomu ul li{
    /* flex: 1; */
    padding: 20px 0;
}
.zhaomu ul li.active{
    color: #f08518;
}
.zhizheng{
    width: 1px;
    height: 20px;
    background: #f08518;
    position: absolute;
    bottom: -10px;;
}
.zhaomu p{
    width: 670px;
    margin: 0 auto;
    height: 1px;
    background: #000;
}
.jxscont{
    width: 750px;
    padding-bottom: 40px;
    text-align: left;
    line-height: 2;
}
.jxscont>div{
    white-space: pre-wrap;
    width: 670px;
    margin: 0 auto;
}
.jxscont>div.img{
    width: 750px;
}
.jxscont >>> img{
    width: 100%;
}
.input{
    font-size: 26px;
    width: 670px;
    margin: 0 auto 40px;
}
.input input{
    width: 100%;
    line-height: 2;
    border: 1px solid #4c413d;
    text-align: center;
}
.zixuntian{
    width: 670px;
    margin: 40px auto 0;
}
.zixuntian>div{
    padding-bottom: 40px;
}
.zixuntian>div input{
    border-bottom: 1px solid #4c413d;
    font-size: 26px;
    width: 100%;
    background: none;
}
.mzixunbottom{
    width: 310px;
    margin: 0 auto 40px;
    line-height: 70px;
    border: 1px solid #4c413d;
    font-size: 26px;
    text-align: center;
}
.citynoshop{
    padding-top: 212px;
}
.tishi{
    width: 100%;
    color: #fff;
    position: fixed;
    top: 50%;
    margin-top: -30px;
    left: 0;
    text-align: center;
}
.tishi div{
    display: inline-block;
    padding: 20px 50px;
    background: #000;
    border-radius: 10px;
}
.mqrco{
    width: 670px;
    margin: 0 auto;
    padding: 100px 0;
}
.mqrco>div{
    width: 670px;
    display: flex;
}
.mqrco>div>div{
    flex: 1 25%;
    text-align: center;

}
.mqrco>div>div img{
    width: 65px;
    height: 65px;
}
.mqrco>div>div:nth-child(1) img{
    width: 80px;
}
.isshowqrcon{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 99999999999;
}
.isshowqrcon div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}
.isshowqrcon div img{
    margin: 0 auto;
    max-width: 100%;
}
.input{
    position: relative;
}
.input input{
    line-height: 70px;
    height: 70px;
}
.input .img{
    position: absolute;
    top: 2px;
    right: 0;
    text-align: center;
    padding: 11px;
    padding-right: 20px;
    cursor: pointer;
    background: none;
}
.input .img img{
    width: 40px;
    height: 40px;
}
.quanpin .pinpailishi ul li{
    position: relative;
}
.quanpin .pinpailishi ul li .addres{
    position: absolute;
    right: 0;
    top: 10px;
    overflow: hidden;
}
.quanpin .pinpailishi ul li .addres button{
    display: block;
    background: #fff;
    border: 1px solid;
    margin-bottom: 7.5px;
    width: 100px;
    cursor: pointer;
    text-align: center;
    height: 90px;
    float: right;
    background: #e5e2e0;
}
.hometopswipt{
    margin-bottom: 100px;
}
</style> 