import "../ployfill"    // 注意文件路径

import  i18n  from '../src/locales/index'
console.log(i18n)
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import './common/styles/reset.css'
import './common/styles/index.css'
import './util/rem'
import wx from 'weixin-js-sdk'
window.wx = wx
const app = createApp(App);
app.use(router).use(i18n)
app.mount('#app')

