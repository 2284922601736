<template>
    <div class="zhichi">
        <div v-html="condition"></div>
        <!-- <div>申请人要求</div>
        <p>合伙人数量少于等于2人，且必须明确股权分配以及主要负责人</p>
        <p>申请人需亲自经营该店</p>
        <p>认可威法的经营理念，并在发展的思路上达成共识</p>
        <p>具备一定的商业背景，有建材行业经验者优先</p>
        <p>掌握当地建材卖场、设计师资源者优先</p>
        <p>对投资的风险和收益有客观全面的认识。</p>
        <div>店面要求</div>
        <p>实用面积：400 ㎡以上</p>
        <div>团队配置</div>
        <p>店长1名</p>
        <p>设计师2名</p>
        <p>销售人员4名</p>
        <p>售后经理1名</p>
        <p>安装工2名</p>
        <div>资金要求</div>
        <p>初期固定投资总计350万（包括装修、柜体上样、电器上样、软装饰品、代理费、保证金）</p>
        <p class="colorop">投资需谨慎</p> -->
    </div>
</template>

<script>
import { getContactus } from "@/api/api";
export default {
    name:'jxstiaojian',
    data(){
        return{
            condition:"",
        }
    },
    mounted() {
        this.getContactusData()
    },
    methods:{
        getContactusData(){
            getContactus()
            .then(res => {
                console.log(res)
                this.condition = res.agentRecruit.conditionIta
            })
            .catch(res => {
                console.log(res,'catch')
            });
        },
    },
}
</script>

<style>

</style>