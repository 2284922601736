<template>
  <div class="case cases" @contextmenu.prevent="youjian()">
    <div :class="{ jianbianbanner: iscaseBaner }" ref="caseBaner">
      <div class="spaceCustomization_img homeDecoration storeDisplay mtn" ref="homeDecoration">
        <div class="storeShowImg tran" :style="{ width: storeWidtha + 'px' }">
          <div class="ssiamge" :style="{ width: storeWidtha + 'px', left: ssiamgelefta + 'px' }"
            :class="{ tran: noLast }">
            <img :src="'https://www.vifa.cn' + item.img" alt="" v-for="(item, index) in caseNav" :key="index"
              :style="{ width: storeImgWidtha + 'px' }" />
          </div>
        </div>
      </div>
    </div>
    <div class="caseNav" :class="{
      touxian: touxian,
      touxianxia: touxianxia,
      touxianshang: touxianshang,
    }">
      <div v-for="(item, index) in caseNav" :key="index" :class="{ avtive: caseNavActive == index }" @click="nav(index)">
        {{ item.nav }}
      </div>
    </div>
    <div class="homeDecoration" v-show="ishomeDecoration">
      <div class="founder spaceCustomization">
        <div class="yishu"></div>
        <div class="beijing">威法定制 大宅至简</div>
        <p>vifa携手全球设计师，</p>
        <p>致力于为中国精英家庭，</p>
        <p>提供优质的高端家装定制体验。</p>
        <p>我们想象并打造深入人心、引领未来的家居产品，</p>
        <p>以真实的案例提供直观的vifa家装效果。</p>
      </div>

      <div class="enterpriseDynamics_box mat0">
        <!-- <div class="enterpriseDynamics_news tran" :style="{marginLeft:newLfet + 'px'}">
                    <ul style="">
                        <li v-for="(item, index) in jiaxiangData" :key="index" @mouseenter="jiaxenter(index)" @mouseleave="jiaxleave()" > 
                            <div class="jiax">
                                <img :src="'https://www.vifa.cn' + item.image" alt="">
                                <p>{{item.title}}</p>
                                <div class="moreJiax" :class="{'moreJiaxmouse':moreJiaxmouse == index}">
                                    <router-link :to="{path:'/caseDetails',query:{caseId:item.id}}"><div @mouseleave="moremouseleave" :class="{'moreleav':ismoreleav}">了解更多</div></router-link>
                                </div>
                            </div>
                        </li>
                        <li></li>
                        <li></li>
                    </ul>
                </div> -->
        <div class="enterpriseDynamics_news tran pubuliu" ref="zsanlist">
          <ul ref="leftdome">
            <li v-for="(item, index) in jiaxiangData" :key="index" @mouseenter="jiaxenter(index)"
              @mouseleave="jiaxleave()">
              <div class="jiax">
                <img :src="'https://www.vifa.cn' + item.image" alt="" />
                <p>{{ item.title }}</p>
                <div class="moreJiax" :class="{ moreJiaxmouse: moreJiaxmouse == index }">
                  <router-link :to="{ path: '/caseDetails', query: { caseId: item.id } }">
                    <div @mouseleave="moremouseleave" :class="{ moreleav: ismoreleav }">
                      了解更多
                    </div>
                  </router-link>
                </div>
              </div>
            </li>
            <li></li>
          </ul>
          <!-- <ul ref="rightdome">
                        <li v-for="(item, index) in jiaxiangDataright" :key="index" @mouseenter="jiaxenter1(index)" @mouseleave="jiaxleave1()" > 
                            <div class="jiax">
                                <img :src="'https://www.vifa.cn' + item.image" alt="">
                                <p>{{item.title}}</p>
                                <div class="moreJiax" :class="{'moreJiaxmouse':moreJiaxmouse1 == index}">
                                    <router-link :to="{path:'/caseDetails',query:{caseId:item.id}}"><div @mouseleave="moremouseleave1" :class="{'moreleav':ismoreleav1}">了解更多</div></router-link>
                                </div>
                            </div>
                        </li>
                    </ul> -->
        </div>
        <div class="pages">
          <div class="prev" @click="prev">
            <p></p>
          </div>
          <ul>
            <li v-for="index in (pagestart, pageend)" :key="index" class="cur" @click="getpages(index)"
              :class="{ active: isactive == index }">
              <span class="gaoliang"><img src="@/common/image/jiajian/jia.png" alt="" class="pwj_jia"></span><span
                class="nogl"> <img src="@/common/image/jiajian/jian.png" alt="" class="pwj_jian"></span>
            </li>
          </ul>
          <div class="next" @click="next">
            <p></p>
          </div>
        </div>
      </div>
      <!-- <div class="newswi">
                <div class="left" @click="newswiLfet"><p></p></div>
                <div class="center">
                    <ul>
                        <li v-for="(item, index) in jiaxiangData" :key="index" :class="{'active':isactive == index}"><span class="no">一</span><span class="yes">十</span></li>
                    </ul>
                </div>
                <div class="right" @click="newswiRight"><p></p></div>
            </div> -->
    </div>

    <div class="homeDecoration isshopDecoration" v-show="isshopDecoration">
      <div class="founder spaceCustomization" style="padding-bottom: 75px">
        <div class="yishu"></div>
        <div class="beijing">威法定制 大宅至简</div>
        <p>vifa以优质的门店环境，</p>
        <p>为每一位到店的顾客提供周致的服务体验，</p>
        <p>以真实的装修实例，</p>
        <p>为您呈现多种理想家装组合形式。</p>
      </div>

      <div class="spaceCustomization_img homeDecoration storeDisplay ditumendian" ref="homeDecoration"
        @mouseenter="sdover" @mouseleave="sdout">
        <div class="storeShowImg tran" :style="{ width: storeWidth + 'px' }">
          <div class="ssiamge" :style="{ width: storeWidth + 'px', left: ssiamgeleft + 'px' }" :class="{ tran: noLast }">
            <img :src="'https://www.vifa.cn' + item.image" alt="" v-for="(item, index) in storeDisplayData" :key="index"
              :style="{ width: storeImgWidth + 'px' }" :ref="'storeShowImg' + index"
              :class="{ ssanin: ssanin == index }" />
          </div>
        </div>
        <div class="homeDecorationMask" :style="{ height: hdmHeight + 'px' }" :class="{ backgroundani: issdMask }">
          <!-- <div v-show="issdMask"> -->
          <div class="homeDecorationCenten" :style="{ height: hdmHeight + 'px' }">
            <ul>
              <li v-for="(item, index) in storeDisplayData" :key="index" :class="{ active: isStoerDisplay == index }"
                @click="storeCity(index)">
                {{ item.city }}
              </li>
            </ul>
            <div class="diannamezi" v-for="(item, index) in storeDisplayData" :key="index"
              v-show="isStoerDisplay == index">
              <p>{{ item.name }}</p>
              <span>{{ item.cityEname }}</span>
              <div class="more" @mouseleave="moremouseleave" :class="{ moreleav: ismoreleav }" @click="openttuji(index)">
                查看图集
              </div>
            </div>
            <div class="xiangqingcase" v-for="(item, index) in storeDisplayData" :key="index"
              v-show="isStoerDisplay == index">
              地址：{{ item.cityAddress }}<span></span>
            </div>
            <!-- <div class="more" @mouseleave="moremouseleave" :class="{moreleav:ismoreleav}" @click="openttuji()">了解更多</div> -->
          </div>
          <!-- </div> -->
        </div>
        <div class="switeName">
          <div class="switeNameBox">
            <div class="left" @click="swiperLeft">
              <p v-show="isleftno"></p>
              <span></span>
            </div>
            <!-- <div class="midle" v-for="(item, index) in storeDisplayData" :key="index" v-show="isStoerDisplay == index">{{item.storeName}}</div> -->
            <div class="right" @click="swiperRight">
              <p v-show="isrightno"></p>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <div class="alltuji" v-show="isalltujibox" @click="alltjclo">
        <ul ref="tujibigimageul">
          <li v-for="(item, index) in shopxData" :key="index" v-show="isalltujishow == index" class="tran">
            <img :src="'https://www.vifa.cn' + item" alt="" />
            <!-- <img :src=item.img alt="" @click="alltjclo"> -->
          </li>
        </ul>
        <div class="alltujileft" @click.stop="alltjleft">
          <div></div>
          <p></p>
        </div>
        <div class="alltujiright" @click.stop="alltjright">
          <div></div>
          <p></p>
        </div>
      </div>

      <!-- <div class="founder spaceCustomization">
                <div class="yishu"></div>           
            </div>
            
            <div class="shopx">
                <ul>
                    <li v-for="(item, index) in shopxData" :key="index" :class="{'scale':isshopximg ==index}" @click="shopximg(index)">
                        <div><img :src=item.img alt=""></div>
                    </li>
                </ul>
            </div> -->
    </div>

    <Zixun />
  </div>
</template>

<script>
import shopbanner from "@/common/image/jiazhuangbanner.jpg";
import shopbanner1 from "@/common/image/dianpubanner.jpg";
import shopx1 from "@/common/image/shopx1.png";
import shopx3 from "@/common/image/shopx3.png";
import shopx4 from "@/common/image/shopx4.png";
import shopx5 from "@/common/image/shopx5.png";
import { getCaseDisplaye, getCasePage } from "@/api/api";
import Zixun from "@/components/zixun";
export default {
  name: "case",
  data() {
    return {
      isalltujibox: false,
      isalltujishow: 0,
      issdMask: true,
      // hdmHeight:710,
      ssiamgeleft: 0,
      ssiamgelefta: 0,
      jiaxiangData: [],
      caseNav: [
        { nav: "家装实例", img: shopbanner },
        { nav: "门店展示", img: shopbanner1 },
      ],
      shopxData: [
        { img: shopx1 },
        { img: shopbanner },
        { img: shopx3 },
        { img: shopx4 },
        { img: shopx5 },
        { img: shopbanner },
      ],
      isStoerDisplay: 0,
      // isactive:0,
      newLfet: 0,
      moreJiaxmouse: 999999999,
      moreJiaxmouse1: 999999999,
      caseNavActive: 0,
      ishomeDecoration: true,
      isshopDecoration: false,
      shopImgWidth: document.body.clientWidth,
      shopWidth: 0,
      shopiamgeleft: 0,
      isShopDisplay: 0,
      ismoreleav: false,
      ismoreleav1: false,
      noposition: true,
      isshopximg: 999,
      touxian: false,
      touxianxia: false,
      touxianshang: false,
      iscaseBaner: false,
      storeWidth: 0,
      storeWidtha: 4000,

      noLast: true,
      storeDisplayData: [],
      storeImgWidth: document.body.clientWidth,
      storeImgWidtha: document.body.clientWidth,
      ssanin: 9999,
      hdmHeight: 710,
      isleftno: false,
      isrightno: true,

      jiaxiangDataleft: [],
      jiaxiangDataright: [],
      pages: {
        pageNo: 1,
        pageSize: 4,
      },
      pagesArr: [],
      pagestart: 0,
      pageend: 0,
      isactive: 1,
      zongyeshu: 0,
      liebiaoData: [],
    };
  },
  components: {
    Zixun,
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }

    if (this._isMobile()) {
      if (this.$route.query.bannertype == 2) {
        this.$router.replace(
          paths + "/mShopCase?page=" + this.$route.query.page
        );
      } else {
        this.$router.replace(paths + '/mCase');
      }
    } else {


      if (this.$route.query.bannertype) {
        this.$router.replace(paths + '/case?bannertype=' + this.$route.query.bannertype);
      } else {
        this.$router.replace(paths + '/case');
      }

    }
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.maskHeight();
    }, 1000);
    // this.storeSwiper()
    this.iscaseBaner = true;
    this.getCaseDisplayeData();
    if (this.$route.query.page && this.$route.query.page > 0) {
      this.pages.pageNo = this.$route.query.page;
    }
    this.getCasePages(this.pages);
    if (this.$route.query.bannertype == 2) {
      this.ishomeDecoration = false;
      this.isshopDecoration = true;
      this.ssiamgelefta = -this.storeImgWidtha * 1;
      this.noLast = true;
      this.caseNavActive = 1;
    } else if (this.$route.query.bannertype == 1) {
      this.ishomeDecoration = true;
      this.isshopDecoration = false;
      this.ssiamgelefta = 0;
      this.noLast = true;
      this.caseNavActive = 0;
    }
    window.exec_main(document.title);
    document.onkeydown = function () {
      if (window.event && window.event.keyCode == 123) {
        event.keyCode = 0;
        event.returnValue = false;
      }
    };
  },
  // created(){
  //     this.jiaxiangDataleft.push(this.jiaxiangData[0])
  //     this.jiaxiangDataright.push(this.jiaxiangData[1])
  //     console.log(this.jiaxiangDataleft,this.jiaxiangDataright,this.jiaxiangData)
  // },
  methods: {
    youjian() {
      console.log("点击了右键");
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    openttuji(index) {
      this.isalltujibox = true;
      this.shopxData = this.storeDisplayData[index].photoAlbumImages;
    },
    alltjclo() {
      this.isalltujibox = false;
    },
    huanclassname(index) {
      let tjimgheight =
        this.$refs.tujibigimageul.children[index].children[0].naturalHeight /
        document.documentElement.clientHeight;
      let tjimgwidth =
        this.$refs.tujibigimageul.children[index].children[0].naturalWidth /
        tjimgheight;
      if (tjimgwidth > document.documentElement.clientWidth) {
        this.$refs.tujibigimageul.children[index].children[0].className =
          "iswidthman";
      }
    },
    alltjleft() {
      if (this.isalltujishow > 0) {
        this.isalltujishow--;
      } else {
        this.isalltujishow = this.shopxData.length - 1;
      }
      this.huanclassname(this.isalltujishow);
    },
    alltjright() {
      if (this.isalltujishow < this.shopxData.length - 1) {
        this.isalltujishow++;
      } else {
        this.isalltujishow = 0;
      }
      this.huanclassname(this.isalltujishow);
    },
    getCaseDisplayeData() {
      getCaseDisplaye()
        .then((res) => {
          console.log("getCaseDisplayeData", res);
          this.storeDisplayData = res.homeVifaAround;
          this.caseNav = [
            { nav: "家装案例", img: res.caseDisplayBanner[0].homeCase },
            { nav: "门店展示", img: res.caseDisplayBanner[0].shopCase },
          ];
        })
        .catch((res) => {
          console.log(res, "catch");
        });
    },
    getCasePages(pages) {
      getCasePage(pages)
        .then((res) => {
          console.log("getCasePages", res);
          (this.jiaxiangDataleft = []),
            (this.jiaxiangDataright = []),
            (this.jiaxiangData = res.page.list);
          this.jiaxiangDataleft.push(this.jiaxiangData[0]);
          this.jiaxiangDataright.push(this.jiaxiangData[1]);
          this.jiaxiangData.forEach((item, index) => {
            if (index > 1) {
              this.$nextTick(() => {
                setTimeout(() => {
                  this.initData(item, index);
                }, 100);
              });
            }
          });
          this.zongyeshu = parseInt(res.page.last);

          if (this.$route.query.page) {
            // console.log("this.$route.query.page",this.$route.query.page)
            if (this.$route.query.page % 8 == 0) {
              this.isactive = 8;
            } else {
              this.isactive = this.$route.query.page % 8;
            }
            if (
              this.$route.query.page / 8 ==
              Math.floor(this.$route.query.page / 8)
            ) {
              if (Math.floor(this.$route.query.page / 8) == 0) {
                this.pagestart = 0;
              } else {
                this.pagestart =
                  (Math.floor(this.$route.query.page / 8) - 1) * 8;
              }
            } else {
              this.pagestart = Math.floor(this.$route.query.page / 8) * 8;
            }

            if (this.zongyeshu - this.pagestart >= 8) {
              this.pageend = 8;
            } else {
              this.pageend = this.zongyeshu - this.pagestart;
            }
          } else {
            this.pagestart = res.page.first;
            this.pages.pageNo = res.page.pageNo;
            if (res.page.last > 8) {
              this.pageend = 8;
            } else {
              this.pageend = res.page.last;
            }

            // if(res.page.last>8){
            //
            // }
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    prev() {
      // console.log(this.$refs)
      // if (this.pagestart + this.isactive>1) {
      //     this.isactive--
      //     this.pages.pageNo=this.isactive
      //     this.getCasePages(this.pages)
      //     // this.$router.replace({path:'/mCase',query:{page:this.isactive+this.pagestart}})
      //     // window.scroll(0,0)
      // }

      let pageNo = this.pages.pageNo - 1;
      if (pageNo <= 1) {
        this.isactive = 1;
      } else {
        this.isactive--;
      }
      console.log(pageNo, this.isactive);
      this.pages.pageNo = pageNo;
      console.log(this.pages);
      this.getCasePages(this.pages);
    },
    getpages(index) {
      console.log(this.$refs);
      // this.$router.replace({path:'/mCase',query:{page:index+this.pagestart}})
      // this.isactive = index
      //  this.pages.pageNo=this.pages.pageNo-(8-index)
      if (this.pages.pageNo > index) {
        this.pages.pageNo = this.pages.pageNo - (8 - index);
        this.isactive = index;
      } else {
        this.pages.pageNo = index;
        this.isactive = index;
      }

      this.getCasePages(this.pages);
      // window.scroll(0,0)
    },
    next() {
      let pageNo = this.pages.pageNo + 1;
      if (pageNo >= 8) {
        this.isactive = 8;
      } else {
        this.isactive++;
      }
      console.log(pageNo);
      this.pages.pageNo = pageNo;
      console.log(this.pages);
      this.getCasePages(this.pages);
    },
    initData() {
      // let leftHeight = this.$refs.leftdome.offsetHeight
      // let rightHieght = this.$refs.rightdome.offsetHeight
      // let jiaxiangDataleft = []
      // let jiaxiangDataright = []
      // if (leftHeight>rightHieght) {
      //     jiaxiangDataright.push(item)
      // }else if(leftHeight<rightHieght){
      //     jiaxiangDataleft.push(item)
      // }else if(leftHeight===rightHieght){
      //     if (index % 2 === 0) {
      //         jiaxiangDataleft.push(item)
      //     }else{
      //         jiaxiangDataright.push(item)
      //     }
      // }
      // this.jiaxiangDataleft = [...this.jiaxiangDataleft, ...jiaxiangDataleft]
      // this.jiaxiangDataright = [...this.jiaxiangDataright, ...jiaxiangDataright]
    },
    maskHeight() {
      this.storeWidth = this.storeImgWidth * this.storeDisplayData.length;
      this.storeWidtha = this.storeImgWidtha * this.caseNav.length;
      // this.shopWidth = this.shopImgWidth*this.shopbannerData.length
    },
    // storeSwiper(){
    //     setInterval(() => {
    //         this.noLast = true
    //         if (this.noposition) {
    //             if (this.shopbannerData.length - 1 > this.isShopDisplay) {
    //                 this.isShopDisplay++
    //                 this.shopiamgeleft = -this.shopImgWidth * this.isShopDisplay
    //             }else{
    //                 this.isShopDisplay = 0
    //                 this.shopiamgeleft = 0
    //             }
    //         }

    //     }, 5000)
    // },
    storeCity(index) {
      this.ssanin = index;
      this.noposition = false;
      this.isStoerDisplay = index;
      this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
      this.noLast = false;
      this.issdMask = false;
    },
    swiperLeft() {
      this.noLast = true;
      this.noposition = false;
      this.ssanin = 999;
      if (this.isStoerDisplay > 0) {
        this.isStoerDisplay = this.isStoerDisplay - 1;
        this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
        if (this.isStoerDisplay == 0) {
          this.isleftno = false;
          this.isrightno = true;
        } else {
          this.isleftno = true;
          this.isrightno = true;
        }
      } else if (this.isStoerDisplay == 0) {
        // this.isStoerDisplay = this.storeDisplayData.length - 1
        // this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
        // this.isleftno = false
        // this.isrightno = true
      }
    },
    swiperRight() {
      this.noposition = false;
      this.noLast = true;
      this.ssanin = 999;
      if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
        this.isStoerDisplay = this.isStoerDisplay + 1;
        this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay;
        if (this.isStoerDisplay == this.storeDisplayData.length - 1) {
          this.isleftno = true;
          this.isrightno = false;
        } else {
          this.isleftno = true;
          this.isrightno = true;
        }
      } else {
        // this.isStoerDisplay = 0
        // this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
        // this.noLast = false
        // this.isleftno = true
        // this.isrightno = false
      }
    },
    handleScroll() {
      this.$nextTick(() => {
        this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scroll > 785) {
          this.touxian = true;
          // if (e.deltaY<0) {
          this.touxianxia = true;
          this.touxianshang = false;
          // }else{
          //     this.touxianxia = false
          //     this.touxianshang = true
          // }
        } else {
          this.touxian = false;
          this.touxianxia = false;
          this.touxianshang = false;
        }
      })
    },
    // swiperLeft(){
    //     console.log(this.isStoerDisplay)
    //     if (this.isStoerDisplay>0) {
    //         this.isStoerDisplay = this.isStoerDisplay - 1
    //         this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
    //     }
    // },
    // swiperRight(){
    //     console.log(this.storeDisplayData.length)
    //     if (this.isStoerDisplay < this.storeDisplayData.length - 1) {
    //         this.isStoerDisplay = this.isStoerDisplay + 1
    //         this.ssiamgeleft = -this.storeImgWidth * this.isStoerDisplay
    //     }

    // },
    newswiLfet() {
      console.log("00");
      if (this.isactive > 0) {
        console.log("11");
        this.isactive = this.isactive - 1;
        this.newLfet = -1200 * this.isactive;
      }
    },
    newswiRight() {
      if (this.isactive < this.jiaxiangData.length - 1) {
        this.isactive = this.isactive + 1;
        this.newLfet = -1200 * this.isactive;
      }
    },
    jiaxenter(index) {
      this.moreJiaxmouse = index;
      this.ismoreleav = false;
    },
    jiaxleave() {
      this.moreJiaxmouse = 9999999;
    },
    jiaxenter1(index) {
      this.moreJiaxmouse1 = index;
      this.ismoreleav1 = false;
    },
    jiaxleave1() {
      this.moreJiaxmouse1 = 9999999;
    },
    nav(index) {
      this.caseNavActive = index;
      if (index == 0) {
        this.ishomeDecoration = true;
        this.isshopDecoration = false;
        this.ssiamgelefta = -0;
        this.noLast = true;
      } else if (index == 1) {
        this.ishomeDecoration = false;
        this.isshopDecoration = true;
        this.ssiamgelefta = -this.storeImgWidtha * 1;
        this.noLast = true;
      }
    },
    shopCity(index) {
      this.ssanin = index;
      this.noLast = false;
      this.isShopDisplay = index;
      this.shopiamgeleft = -this.shopImgWidth * this.isShopDisplay;
      this.noposition = false;
    },
    moremouseleave() {
      this.ismoreleav = true;
    },
    moremouseleave1() {
      this.ismoreleav1 = true;
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
    shopximg(index) {
      if (this.isshopximg == 999) {
        this.isshopximg = index;
        document.body.addEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.addEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      } else {
        this.isshopximg = 999;
        document.body.removeEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.removeEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      }
    },
    sdover() {
      this.issdMask = true;
      this.hdmHeight = this.$refs.homeDecoration.offsetHeight;
      this.ismoreleav = false;
      this.noposition = false;
    },
    sdout() {
      this.issdMask = false;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.query.bannertype == 1) {
          this.ishomeDecoration = true
          this.caseNavActive = 0
          this.ishomeDecoration = true
          this.ssiamgelefta = 0;
        } else {
          this.ishomeDecoration = false
          this.caseNavActive = 2
          this.ishomeDecoration = false
          this.ssiamgelefta = -this.storeImgWidtha * 1;
        }
      },
      deep: true,
      immediate: true
    }

  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    document.body.removeEventListener("touchmove", this.defferScroll, {
      passive: false,
    });
    document.body.removeEventListener("wheel", this.defferScroll, {
      passive: false,
    });
  }
};
</script>

<style>
@import "../../../common/styles/about.css";

.case .storeShowImg,
.case .storeShowImg img {
  height: 720px;
}

@keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-moz-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-o-keyframes kjdzleftani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-moz-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-o-keyframes kjdzrightani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-moz-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-o-keyframes kjdzmidleani {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-moz-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@-o-keyframes kjdzmidleanir {
  0% {
    left: 125px;
    opacity: 1;
  }

  50% {
    left: 0;
    opacity: 0;
  }

  50.1% {
    left: 250px;
    opacity: 0;
  }

  100% {
    left: 125px;
    opacity: 1;
  }
}

@keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-moz-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-o-keyframes pkjdzmidleani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-moz-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-o-keyframes pkjdzmidleanir {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -300px;
    opacity: 0;
  }

  50.1% {
    left: 300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-moz-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
  }

  50.1% {
    left: 200px;
    opacity: 1;
  }

  100% {
    left: 0;
  }
}

@-o-keyframes kjdzmrtyani {
  0% {
    left: 0;
    opacity: 1;
  }

  50% {
    left: -200px;
    opacity: 0;
  }

  50.1% {
    left: 200px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-moz-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-o-keyframes kjdzmltyani {
  0% {
    right: 0;
    opacity: 1;
  }

  50% {
    right: 200px;
    opacity: 0;
  }

  50.1% {
    right: -200px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

.kjdz ul.kjdzanimaleft li.left div div {
  animation: kjdzleftani 2s;
  -moz-animation: kjdzleftani 2s;
  -webkit-animation: kjdzleftani 2s;
  -o-animation: kjdzleftani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaright li.left div div {
  animation: kjdzmrtyani 2s;
  -moz-animation: kjdzmrtyani 2s;
  -webkit-animation: kjdzmrtyani 2s;
  -o-animation: kjdzmrtyani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaleft li.right div div {
  animation: kjdzmltyani 2s;
  -moz-animation: kjdzmltyani 2s;
  -webkit-animation: kjdzmltyani 2s;
  -o-animation: kjdzmltyani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaright li.right div div {
  animation: kjdzrightani 2s;
  -moz-animation: kjdzrightani 2s;
  -webkit-animation: kjdzrightani 2s;
  -o-animation: kjdzrightani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaright li.midl>div div {
  animation: kjdzmidleanir 2s;
  -moz-animation: kjdzmidleanir 2s;
  -webkit-animation: kjdzmidleanir 2s;
  -o-animation: kjdzmidleanir 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaleft li.midl>div div {
  animation: kjdzmidleani 2s;
  -moz-animation: kjdzmidleani 2s;
  -webkit-animation: kjdzmidleani 2s;
  -o-animation: kjdzmidleani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaright li.midl>div p {
  animation: pkjdzmidleanir 2s;
  -moz-animation: pkjdzmidleanir 2s;
  -webkit-animation: pkjdzmidleanir 2s;
  -o-animation: pkjdzmidleanir 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz ul.kjdzanimaleft li.midl>div p {
  animation: pkjdzmidleani 2s;
  -moz-animation: pkjdzmidleani 2s;
  -webkit-animation: pkjdzmidleani 2s;
  -o-animation: pkjdzmidleani 2s;
  /* animation-fill-mode: forwards; */
  -webkit-animation-timing-function: ease-in-out;
}

.logovideo {
  width: 100%;
  height: 100%;
  /* background:url('../common/image/logoloadbg.jpg') no-repeat 0 0;
    background-size: 100% 100%; */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
}

.logovideo .logoload {
  /* margin: 0 auto;
    vertical-align: middle; */

  width: 173px;
  height: 130px;
  /* margin-top: 16%;
    width: 10%; */
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -65px;
  margin-left: -86.5px;
}

.logovideo .logoloadbg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;
  animation: logovideoimg 4s;
  -moz-animation: logovideoimg 4s;
  -webkit-animation: logovideoimg 4s;
  -o-animation: logovideoimg 4s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes logovideoimg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@-moz-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@-o-keyframes logovideoimg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.logovideodan {
  animation: logovideodani 1s;
  -moz-animation: logovideodani 1s;
  -webkit-animation: logovideodani 1s;
  -o-animation: logovideodani 1s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  /* z-index: -1; */
}

@keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-webkit-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-moz-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-o-keyframes logovideodani {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

.case .homefounder {
  margin-top: 125px;
  opacity: 0;
  animation: homehomefounder 2s;
  -moz-animation: homehomefounder 2s;
  -webkit-animation: homehomefounder 2s;
  -o-animation: homehomefounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.hometopanima .homefounder {
  animation: hometopanimafounder 2s;
  -moz-animation: hometopanimafounder 2s;
  -webkit-animation: hometopanimafounder 2s;
  -o-animation: hometopanimafounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-moz-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-o-keyframes hometopanimafounder {
  0% {
    opacity: 0;
    margin-top: 125px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }

  100% {
    opacity: 0;
    margin-top: 125px;
  }
}

@-webkit-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }

  100% {
    opacity: 0;
    margin-top: 125px;
  }
}

@-moz-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }

  100% {
    opacity: 0;
    margin-top: 125px;
  }
}

@-o-keyframes homehomefounder {
  0% {
    opacity: 1;
    margin-top: 0;
  }

  100% {
    opacity: 0;
    margin-top: 125px;
  }
}

.case .storeDisplay ul {
  position: absolute;
  left: 50%;
  margin-left: -430px;
  bottom: 91%;
  padding-top: 0;
  margin-top: 0;
  line-height: 32px;
  margin-bottom: -16px;
  padding-bottom: 0;
}

.switeName {
  padding: 0;
}

.storeDisplay .more {
  position: absolute;
  left: 50%;
  bottom: 44%;
  margin-bottom: -31px;
  margin-left: -163px;
}

.cases .storeDisplay .more {
  bottom: -100px;
  width: 333px;
  padding: 0;
}

.case .kjdz ul {
  padding-top: 165px;
}

.jianbianq {
  opacity: 0;
  margin-top: 125px;
  animation: homehomefounder 2s;
  -moz-animation: homehomefounder 2s;
  -webkit-animation: homehomefounder 2s;
  -o-animation: homehomefounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .jianbianh {
  animation: hometopanimafounder 2s;
  -moz-animation: hometopanimafounder 2s;
  -webkit-animation: hometopanimafounder 2s;
  -o-animation: hometopanimafounder 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .kjdz ul li.midl>div {
  height: 90px;
  position: relative;
}

.case .kjdz ul li.midl div div {
  position: absolute;
  left: 125px;
  top: 0;
  width: 200px;
}

.case .kjdz ul li.midl p {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.kjdz .homeDecorationMask {
  animation: kjdzhomeDecorationMaska 2s;
  -moz-animation: kjdzhomeDecorationMaska 2s;
  -webkit-animation: kjdzhomeDecorationMaska 2s;
  -o-animation: kjdzhomeDecorationMaska 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz .spaceCustomization {
  position: absolute;
  left: 0;
  right: 0;
}

.kjdz .backgroundani {
  animation: kjdzbackgroundania 2s;
  -moz-animation: kjdzbackgroundania 2s;
  -webkit-animation: kjdzbackgroundania 2s;
  -o-animation: kjdzbackgroundania 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }

  100% {
    background: #00000066;
    opacity: 1;
  }
}

@-webkit-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }

  100% {
    background: #00000066;
    opacity: 1;
  }
}

@-moz-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }

  100% {
    background: #00000066;
    opacity: 1;
  }
}

@-o-keyframes kjdzbackgroundani {
  0% {
    background: none;
    opacity: 0;
  }

  100% {
    background: #00000066;
    opacity: 1;
  }
}

@keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }

  100% {
    background: none;
    opacity: 0;
  }
}

@-webkit-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }

  100% {
    background: none;
    opacity: 0;
  }
}

@-moz-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }

  100% {
    background: none;
    opacity: 0;
  }
}

@-o-keyframes kjdzhomeDecorationMask {
  0% {
    background: #00000066;
    opacity: 1;
  }

  100% {
    background: none;
    opacity: 0;
  }
}

@keyframes kjdzbackgroundania {
  0% {
    background: none;
  }

  100% {
    background: #00000066;
  }
}

@-webkit-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }

  100% {
    background: #00000066;
  }
}

@-moz-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }

  100% {
    background: #00000066;
  }
}

@-o-keyframes kjdzbackgroundania {
  0% {
    background: none;
  }

  100% {
    background: #00000066;
  }
}

@keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }

  100% {
    background: none;
  }
}

@-webkit-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }

  100% {
    background: none;
  }
}

@-moz-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }

  100% {
    background: none;
  }
}

@-o-keyframes kjdzhomeDecorationMaska {
  0% {
    background: #00000066;
  }

  100% {
    background: none;
  }
}

.case .switeName {
  padding: 0;
}

.ditumendian .homeDecorationMask {
  animation: kjdzhomeDecorationMask 2s;
  -moz-animation: kjdzhomeDecorationMask 2s;
  -webkit-animation: kjdzhomeDecorationMask 2s;
  -o-animation: kjdzhomeDecorationMask 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.ditumendian .backgroundani {
  animation: kjdzbackgroundani 2s;
  -moz-animation: kjdzbackgroundani 2s;
  -webkit-animation: kjdzbackgroundani 2s;
  -o-animation: kjdzbackgroundani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .brandBox {
  width: 1200px;
  position: relative;
  height: 720px;
}

.case .brand {
  position: absolute;
  left: 0;
  top: 0;
}

.case .brand .brandtext {
  animation: homebrand 1s;
  -moz-animation: homebrand 1s;
  -webkit-animation: homebrand 1s;
  -o-animation: homebrand 1s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .brand img {
  animation: homebrand 2s;
  -moz-animation: homebrand 2s;
  -webkit-animation: homebrand 2s;
  -o-animation: homebrand 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .brandani img {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.case .brandani .brandtext {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz .spaceCustomization {
  animation: homebrand 2s;
  -moz-animation: homebrand 2s;
  -webkit-animation: homebrand 2s;
  -o-animation: homebrand 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

.kjdz .spaceCustomizationani {
  animation: homebrandani 2s;
  -moz-animation: homebrandani 2s;
  -webkit-animation: homebrandani 2s;
  -o-animation: homebrandani 2s;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes homebrand {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes homebrand {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes homebrand {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes homebrand {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes homebrandani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes homebrandani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes homebrandani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes homebrandani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cases .ditumendian .switeName {
  background: none;
  bottom: 50%;
}

.diannamezi {
  font-size: 30px;
  position: absolute;
  width: 1200px;
  top: 41%;
  left: 0;
  line-height: 60px;
  margin-top: -60px;
}

.diannamezi p {
  font-size: 50px;
}

.diannamezi span {
  font-size: 30px;
}

.xiangqingcase {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}

.xiangqingcase span {
  padding-left: 25px;
}

.pubuliu {
  width: 1200px;
}

/* .pubuliu ul{
    width: 600px;
}
.case .pubuliu ul li{
    flex: 1 99%;
} */

.alltuji {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #000;
  z-index: 9999999999999999;
}

.alltuji ul {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.alltuji ul li {
  width: 100%;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.alltuji ul li img {
  width: auto;
  height: 100%;
}

.alltuji ul li img.iswidthman {
  width: 100%;
  height: auto;
}

.alltujileft {
  /* background: #00000052;
    position: absolute;
    left: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-right: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
  width: 150px;
  position: absolute;
  left: 2.5%;
  top: 50%;
  cursor: pointer;
  height: 25px;
  margin-top: -12.5px;
}

.alltujileft div {
  width: 150px;
  height: 1px;
  background: #fff;
  margin-top: 25px;
}

.alltujileft p {
  height: 25px;
  width: 1px;
  background: #fff;
  position: absolute;
  left: 25px;
  top: 12.5px;
}

.alltujiright {
  /* background: #00000052;
    position: absolute;
    right: 0;
    top: 50%;
    color: #fff;
    font-size: 150px;
    text-align: center;
    padding-left: 70px;
    line-height: 220px;
    margin-top: -110px;
    cursor: pointer; */
  width: 150px;
  position: absolute;
  right: 2.5%;
  top: 50%;
  cursor: pointer;
  height: 25px;
  margin-top: -12.5px;
}

.alltujiright div {
  width: 150px;
  height: 1px;
  background: #fff;
  margin-top: 25px;
}

.alltujiright p {
  height: 25px;
  width: 1px;
  background: #fff;
  position: absolute;
  right: 25px;
  top: 12.5px;
}

.pages {
  display: flex;
  justify-content: center;
  font-size: 26px;
  padding-top: 40px;

  border-bottom: 1px solid #000;
  width: 100%;
  margin: 40px auto;
  position: relative;
  padding: 0;
}

.pages div {
  /* border: 1px solid #f08518; */
  cursor: pointer;
}

.pages .prev {
  position: absolute;
  left: 0;
  top: -12px;
  width: 25px;
  height: 25px;
}

.pages .prev p {
  width: 1px;
  background: #000;
  height: 25px;
  margin: 0 auto;
}

.pages .next {
  position: absolute;
  right: 0;
  top: -12px;
  width: 25px;
  height: 25px;
}

.pages .next p {
  width: 1px;
  background: #000;
  height: 25px;
  margin: 0 auto;
}

.pages ul {
  display: flex;
  position: absolute;
  background: #fff;
  top: -15px;
}

.pages ul li {
  flex: 1;
  width: 30px;
  height: 30px;
  /* border: 1px solid #f08518; */
  margin: 0 10px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  /* font-size: 40px; */
}

.pages ul li.active {
  color: #f08518;
}

.mat0 {
  margin-bottom: 0;
}

.pages ul li .gaoliang {
  display: none;
}

.pages ul li .nogl {
  display: block;
}

.pages ul li.active .gaoliang {
  display: block;
}

.pages ul li.active .nogl {
  display: none;
}
</style>
