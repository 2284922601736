<template>
    <div class="zhichi">
        <ul>
            <li>
                <img src="@/common/image/hzlcicon1.png" alt="">
                <p>Both-party negotiatio</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon2.png" alt="">
                <p>Establish an agreement </p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon3.png" alt="">
                <p> Store site selection </p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon4.png" alt="">
                <p> Headquarters inspection approval</p>
            </li>
        </ul>
        <div class="guaiwang"></div>
        <ul>
            <li>
                <img src="@/common/image/hzlcicon5.png" alt="">
                <p>Decoration and sample delivery</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon6.png" alt="">
                <p>Store design and sample planning </p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon7.png" alt="">
                <p>Distribution contract signing</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon8.png" alt="">
                <p>Fee paying and security deposit</p>
            </li>
        </ul>
        <div class="guaiwangLeft"></div>
        <ul>
            <li>
                <img src="@/common/image/hzlcicon9.png" alt="">
                <p>Staff training</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon10.png" alt="">
                <p>Soft installation and electric appliance in place</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon11.png" alt="">
                <p>Business planning</p>
            </li>
            <li class="henggang"></li>
            <li>
                <img src="@/common/image/hzlcicon12.png" alt="">
                <p>Store opening</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name:'hezuoliucheng',

}
</script>

<style>
.zhichi ul{
    text-align: center;
    display: flex;
}
.zhichi ul li{
    flex: 1 150px;
}
.zhichi ul li img{}
.zhichi ul li p{
    padding: 15px 0;
}
.zhichi ul .henggang{
    flex: 1 200px;
    height: 1px;
    background: #000;
    margin-top: 30px;
}
.guaiwang{
    height: 40px;
    border-right: 1px solid #000;
    margin-right: 75px;
    margin-bottom:25px;
}
.guaiwangLeft{
    height: 40px;
    border-left: 1px solid #000;
    margin-left: 75px;
    margin-bottom:25px;
}
</style>