<template>
  <div class="case alllist">
    <div class="storeShowImg">
      <img src="@/common/image/allListbanner.png" alt="" />
    </div>
    <div class="enterpriseDynamics newlist">
      <div class="newlix">
        <div class="enterpriseDynamics_box">
          <div class="enterpriseDynamics_news tran">
            <ul>
              <li v-for="(item, index) in newListData" :key="index">
                <div class="newImg">
                  <img :src="'https://www.vifa.cn' + item.image" alt="" />
                </div>
                <p>{{ item.topicEng }}</p>
                <div class="newName">{{ item.titleEng }}</div>
                <div class="newMore">
                  <span>{{ item.publishDate }}</span>
                  <button>
                    <router-link
                      :to="{
                        path: '/newDetails',
                        query: {
                          id: item.id,
                          isnew: this.$route.query.listbanner,
                        },
                      }"
                      >Learn More</router-link
                    >
                  </button>
                </div>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutVifaall, saveNewCaChe, saveDynamicCaChe } from "@/api/api";

import usePoint from "@/hooks/usePoint.js";

export default {
  data() {
    return {
      newListData: [],
    };
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(
        paths + "/mnewsList?listbanner=" + this.$route.query.listbanner
      );
    } else {
      this.$router.replace(
        paths + "/allList?listbanner=" + this.$route.query.listbanner
      );
    }
    this.getnewsData(this.$route.query.listbanner);
    console.log(this.$route.query.listbanner);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

   
    async getFanyi(arr, key) {
      // let arr1 = [];
      // let arr2 = [];

      for (let index = 0; index < arr.length; index++) {
        if (arr[index].titleEng && arr[index].titleEng != "") {
          arr[index].titleEng = await arr[index].titleEng;
          arr[index].topicEng = await arr[index].topicEng;

          arr[index].titleEng = arr[index].titleEng.slice(0, 1).toUpperCase() + arr[index].titleEng.slice(1).toLowerCase();
          arr[index].topicEng = arr[index].topicEng.slice(0, 1).toUpperCase() + arr[index].topicEng.slice(1).toLowerCase();

          arr[index].titleEng = arr[index].titleEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicEng = arr[index].topicEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicEng = arr[index].topicEng.replace(/&quot;/g, " ")
          arr[index].titleEng = arr[index].titleEng.replace(/&quot;/g, " ")


        } else {
          arr[index].titleEng = (
            await usePoint.getTranslation(arr[index].title, "en")
          ).data.trans_result[0].dst;
          arr[index].topicEng = (
            await usePoint.getTranslation(arr[index].topic, "en")
          ).data.trans_result[0].dst;

          arr[index].titleEng = arr[index].titleEng.slice(0, 1).toUpperCase() + arr[index].titleEng.slice(1).toLowerCase();
          arr[index].topicEng = arr[index].topicEng.slice(0, 1).toUpperCase() + arr[index].topicEng.slice(1).toLowerCase();

          arr[index].titleEng = arr[index].titleEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicEng = arr[index].topicEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")
          arr[index].topicEng = arr[index].topicEng.replace(/&quot;/g, " ")
          arr[index].titleEng = arr[index].titleEng.replace(/&quot;/g, " ")

          if (key == "enterpriseNews") {
            await saveNewCaChe({
              objId: arr[index].id,
              titleEng: arr[index].titleEng,
              topicEng: arr[index].topicEng,
              titleIta: arr[index].titleIta,
              topicIta: arr[index].topicIta,
            });
          } else {
            await saveDynamicCaChe({
              objId: arr[index].id,
              topicEng: arr[index].topicEng,
              topicIta: arr[index].topicIta,
              titleEng: arr[index].titleEng,
              titleIta: arr[index].titleIta,
            });
          }
        }
      }

     
      return arr;
    },

    async getnewsData(listbanner) {
      let res = await getAboutVifaall();
      if (listbanner == 0) {
        this.newListData = await this.getFanyi(res.enterpriseNews,"enterpriseNews");
      } else {
        this.newListData = await this.getFanyi(res.enterpriseDynamic,"enterpriseDynamic");
      }
    },
  },
};
</script>

<style>
@import "../../../common/styles/about.css";

.case .storeShowImg,
.case .storeShowImg img {
  height: 720px;
}

.newlist .newlix .enterpriseDynamics_news ul,
.newlist .newlix .enterpriseDynamics_box {
  width: 1200px;
}

.newlist .enterpriseDynamics_news {
  width: 1200px;
}

.case .newlist .enterpriseDynamics_news ul li {
  flex: 1 32%;
  width: 32%;
  margin-bottom: 2%;
}

.alllist .enterpriseDynamics_news ul li > p {
  height: 60px;
}

.case .newlist .enterpriseDynamics_news ul li img {
  width: 100%;
}
</style>
