<template>
  <div class="mhome">
    <div class="homelogo">
      <img :src="'https://www.vifa.cn' + aboutVifaBanner.mobileImage" alt="" />
      <div class="pwjstrone">VIFA Customization Supreme Simplicity</div>
    </div>
    <div class="mnew">
      <div class="mnewtitle">
        <div class="pwjbig">Corporate News</div>
        <router-link :to="{ path: '/mnewsList', query: { listbanner: 0 } }"
          ><p class="pwjbig">All news</p></router-link
        >
      </div>
      <ul>
        <li
          v-for="(item, index) in enterpriseNews"
          :key="index"
          @click="tonewdetails(item.id)"
        >
          <div class="mnewleft">
            <div>{{ item.titleEng }}</div>
            <p>{{ item.topicEng }}</p>
            <span>{{ item.publishDate }}</span>
          </div>
          <div class="mnewright">
            <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div class="mnew">
      <div class="mnewtitle">
        <div class="pwjbig"> Business informatio</div>
        <router-link :to="{ path: '/mnewsList', query: { listbanner: 1 } }"
          ><p class="pwjbig">All dynamic</p></router-link
        >
      </div>
      <ul>
        <li
          v-for="(item, index) in enterpriseDynamic"
          :key="index"
          @click="toenterpriseDynamicdetails(item.id)"
        >
          <div class="mnewleft">
            <div>{{ item.titleEng }}</div>
            <p>{{ item.topicEng }}</p>
            <span>{{ item.publishDate }}</span>
          </div>
          <div class="mnewright">
            <img :src="'https://www.vifa.cn' + item.mobileImage" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div class="subnav pwjsubnavEn">
      <router-link to="/En/mAbout">Brand introduction</router-link>
      <router-link to="/En/mBrandConcept">Brand conceptn</router-link>
      <router-link to="/En/mEnterpriseDynamics" class="active">Business informatioptn</router-link>
    </div>
  </div>
</template>

<script>
import { getAboutVifa, saveNewCaChe, saveDynamicCaChe   } from "@/api/api";
import usePoint from "@/hooks/usePoint.js";
export default {
  data() {
    return {
      aboutVifaBanner: {},
      enterpriseNews: [],
      enterpriseDynamic: [],
    };
  },
  components: {
    // Mzixun
  },
  mounted() {
    let paths;
    if (sessionStorage.getItem("language") == 1) {
      paths = "";
    } else if (sessionStorage.getItem("language") == 2) {
      paths = "/En";
    } else {
      paths = "/It";
    }
    if (this._isMobile()) {
      this.$router.replace(paths + "/mEnterpriseDynamics");
    } else {
      this.$router.replace(paths + "/about");
    }
    this.getPageData();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    async getFanyi(arr, key) {
      // let arr1 = [];
      // let arr2 = [];

      for (let index = 0; index < arr.length; index++) {
        if (arr[index].titleEng && arr[index].titleEng != "") {
          arr[index].titleEng = await arr[index].titleEng;
          arr[index].topicEng = await arr[index].topicEng;

          arr[index].titleEng = arr[index].titleEng.slice(0, 1).toUpperCase() + arr[index].titleEng.slice(1).toLowerCase();
          arr[index].topicEng = arr[index].topicEng.slice(0, 1).toUpperCase() + arr[index].topicEng.slice(1).toLowerCase();

          arr[index].titleEng = arr[index].titleEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")

          arr[index].topicEng = arr[index].topicEng.replace(/Vifa|vifa|VIFA|viva|weifa|Viva/g, "vifa")


        } else {
          arr[index].titleEng = (
            await usePoint.getTranslation(arr[index].title, "en")
          ).data.trans_result[0].dst;
          arr[index].topicEng = (
            await usePoint.getTranslation(arr[index].topic, "en")
          ).data.trans_result[0].dst;

          arr[index].titleEng = arr[index].titleEng.slice(0, 1).toUpperCase() + arr[index].titleEng.slice(1).toLowerCase();
          arr[index].topicEng = arr[index].topicEng.slice(0, 1).toUpperCase() + arr[index].topicEng.slice(1).toLowerCase();

          arr[index].titleEng = arr[index].titleEng.replace(/Vifa|vifa|VIFA|viva|viva/g, "vifa")

          arr[index].topicEng = arr[index].topicEng.replace(/Vifa|vifa|VIFA|viva|viva/g, "vifa")


          if (key == "enterpriseNews") {
            await saveNewCaChe({
              objId: arr[index].id,
              titleEng: arr[index].titleEng,
              topicEng: arr[index].topicEng,
              titleIta: arr[index].titleIta,
              topicIta: arr[index].topicIta,
            });
          } else {
            await saveDynamicCaChe({
              objId: arr[index].id,
              topicEng: arr[index].topicEng,
              topicIta: arr[index].topicIta,
              titleEng: arr[index].titleEng,
              titleIta: arr[index].titleIta,
            });
          }
        }
      }

     
      return arr;
    },
    async getPageData() {
      let res = await getAboutVifa();
      this.aboutVifaBanner = res.aboutVifaBanner;

      this.enterpriseNews = await this.getFanyi(res.enterpriseNews,"enterpriseNews");
      this.enterpriseDynamic = await this.getFanyi(res.enterpriseDynamic,"enterpriseDynamic");
    },

    tonewdetails(id) {
      this.$router.push({ path: "/mnewDetails", query: { id: id, isnew: 0 } });
    },
    toenterpriseDynamicdetails(id) {
      this.$router.push({ path: "/mnewDetails", query: { id: id } });
    },
  },
};
</script>

<style scoped>
.homelogo {
  width: 750px;
  position: relative;
}
.homelogo img,
.video {
  width: 750px;
}
.homelogo div {
  width: 750px;
  text-align: center;
  font-size: 45px;
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 90px;
  top: 50%;
  margin-top: -45px;
}
.subnav {
  width: 750px;
  font-size: 24px;
  line-height: 100px;
  background: #000000cc;
  text-align: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.subnav a {
  flex: 1;
  display: block;
  color: #fff;
}
.subnav .active {
  color: #f08518;
}
.mnew {
  width: 670px;
  margin: 40px auto;
  font-size: 26px;
  line-height: 1.8;
  text-align: justify;
}
.mnew .mnewtitle {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mnew .mnewtitle div {
  font-size: 38px;
  font-weight: bold;
  float: left;
}
.mnew .mnewtitle p {
  color: #f08518;
  float: right;
  /* padding-top: 22.5px; */
}
.mnew ul {
}
.mnew ul li {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #b1adac;
}
.mnew ul li .mnewleft {
  width: 400px;
  flex: 1 360px;
}
.mnew ul li .mnewleft div {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  word-break: break-all;
}
.mnew ul li .mnewleft p {
  font-size: 18px;
}
.mnew ul li .mnewleft span {
  font-size: 18px;
  color: #b1adac;
}
.mnew ul li .mnewright {
  width: 250px;
  flex: 1 250px;
  text-align: right;
}
.mnew ul li .mnewright img {
  width: 250px;
  height: 175px;
}
.pwjsubnavEn{
  white-space:nowrap;
  /* word-break:break-all;
  word-wrap:break-word; */
}
</style>
