<template>
    <div class="zhichi">
        <!-- <div>选址支持</div>
        <p>威法拥有红星美凯龙、居然之家等全国性高端建材卖场的铺位信息资源，可协助经销商选定铺位。若经销商自己拥有铺位资源，威法可协助评判铺位是否合适。</p>
        <div>店面设计</div>
        <p>威法拥有专业的店面装修设计团队，为经销商提供店面测量、装修设计、产品布局设计、软装布局规划、形象展示设计等一系列服务，打造统一、规范、高端、有调性的视觉形象。</p>
        <div>专业培训</div>
        <p>威法拥有优秀的销售及管理培训团队，为经销商及其员工提供为期15天的专业培训，内容包括产品知识、销售技巧、专业设计软件应用、安装流程、团队协作及管理技巧等，并配合在店面进行的模拟销售，让团队每一位成员在开业前已熟悉掌握各项知识。</p>
        <div>开业策划</div>
        <p>威法提供开业活动的策划方案、宣传资料设计、广宣品配送等。</p>
        <div>驻店支持</div>
        <p>为巩固新员工的各项能力、支持店面运营及推动前期销售，新店开业初期威法派驻区域经理驻店15天。</p> -->
        <div v-html="support"></div>
    </div>
</template>

<script>
import { getContactus } from "@/api/api";
export default {
    name:'zhichi',
    data(){
        return{
            support:"",
        }
    },
    mounted() {
        this.getContactusData()
    },
    methods:{
        getContactusData(){
            getContactus()
            .then(res => {
                console.log(res)
                this.support = res.agentRecruit.support
            })
            .catch(res => {
                console.log(res,'catch')
            });
        },
    },

}
</script>

<style>
</style>